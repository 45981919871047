import React, { useCallback } from 'react';
import FormikTextField from 'shared/FormikField/FormikTextField/FormikTextField';
import { GRID_DIRECTION } from 'shared/FormikField/FormikField.model';
import SearchIcon from '@mui/icons-material/Search';
import { fields } from 'pages/WirelineEngagements/shared/AddOrderModalForm/constants';
import { debounce } from 'lodash';
import { debounceTime } from 'core/constants';

interface IAddOrderSearchProductField {
  itemCount: number;
  getProducts: (itemCount: number) => void;
}

const AddOrderSearchProductField: React.FC<IAddOrderSearchProductField> = ({
  itemCount, getProducts,
}) => {
  const debounceGetProducts = useCallback(
    debounce(getProducts, debounceTime),
    [getProducts],
  );

  return (
    <FormikTextField
      StartIcon={SearchIcon}
      label="Search by Product Name"
      gridDirection={GRID_DIRECTION.COLUMN}
      onInputChange={() => debounceGetProducts(itemCount)}
      fieldName={`${fields.orders}[${itemCount}].${fields.productName}`}
    />
  );
};

export default AddOrderSearchProductField;

import { Status } from '@EngagementsSowData/sowData.model';
import { ITableCell } from 'shared/Table/Table.models';

export interface IEngagementsTotalItem {
    // eslint-disable-next-line no-restricted-globals
    name: string,
    value: number,
}

export interface IEngagementsRevenueItem {
  // eslint-disable-next-line no-restricted-globals
  name: string,
  value: {
    mrc: number,
    nrc: number,
  }
}

export interface IEngagementsOrdersByPeriodItem {
    year: number;
    month: number;
    new: number;
    canceled: number;
    inProgress: number;
    completed: number;
    reOrder: number;
}

export interface IEngagementsOrdersByPeriodItemChart extends IEngagementsOrdersByPeriodItem {
    xDateKey: string;
}

export enum DASHBOARD_TABS {
    WIRELINE = 'wireline',
    MOBILITY = 'mobility',
    CUSTOMER_SUPPORT_REQUEST = 'customer_support_request',
    ESCALATION_SUPPORT = 'escalation_support',
    CUSTOMER_EMAILING = 'customer_emailing',
}

export enum SELLER_DEPARTMENT_TABS {
    SALES = 'sales',
    INSIDE_SALES = 'inside_sales',
    CONTRACT_SPECIALIST = 'contract_specialist',
    PROJECT_MANAGER = 'project_manager',
    BILLING = 'billing_specialist',
    RETENTION = 'retention',
    TECHNICAL_OVERLAY = 'technical_overlay',
}

export interface IBookingDataItem {
    seller: string
    contractSpecialist: string
    billingSpecialist: string
    dataBooked: string
    customerName: string
    oppIds: string
    // eslint-disable-next-line no-restricted-globals
    location: string
    bandwidth: string
    product: string
    // eslint-disable-next-line no-restricted-globals
    status: Status | null,
    mrc: string | null,
    nrc: string | null,
    signed: string | null
}

export type ITableCellBookingDataItem = ITableCell<IBookingDataItem>

export interface IEscalationSupportDataItem {
    companyName: string,
    escalationType: string,
    // eslint-disable-next-line no-restricted-globals
    location: string,
    product: string,
    createdBy: string,
    // eslint-disable-next-line no-restricted-globals
    status: string,
    details: string,
}

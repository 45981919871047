import React from 'react';

import { useAppSelector } from 'core/store';
import EngagementsTotalsChart from '@dashboardWirelineDepartments/shared/EngagementsTotalsChart';

const SellerEngagementsTotalsChart = () => {
  const engagementsTotalOrders = useAppSelector((state) => state.dashboard.engagementsTotals.data);
  const isLoading = useAppSelector((state) => state.dashboard.engagementsTotals.isLoading);

  return (
    <EngagementsTotalsChart
      isLoading={isLoading}
      list={engagementsTotalOrders}
    />
  );
};

export default SellerEngagementsTotalsChart;

import { useActionsPermissions } from 'core/hooks/useActionsPermissions';
import { ACTIONS_INSIDE_SALES_PATHS } from 'core/models/actionsPermissions';

const {
  PROPOSALS_GET,
  ATTACHMENTS_GET,
  CANCEL_REQUEST_CREATE,
  REQUEST_ACCEPTED_CREATE,
  INSIDE_SALES_REQUEST_CREATE,
  INSIDE_SALES_REQUEST_PATRIAL_EDIT,
} = ACTIONS_INSIDE_SALES_PATHS;

const actionsPaths = {
  isAvailableGetProposals: PROPOSALS_GET,
  isAvailableGetAttachments: ATTACHMENTS_GET,
  isAvailableCancelRequest: CANCEL_REQUEST_CREATE,
  isAvailableRequestAccepted: REQUEST_ACCEPTED_CREATE,
  isAvailableCreateRequest: INSIDE_SALES_REQUEST_CREATE,
  isAvailableEditRequest: INSIDE_SALES_REQUEST_PATRIAL_EDIT,
};

const useInsideSalesListPermissions = () => {
  const {
    isAvailableEditRequest,
    isAvailableGetProposals,
    isAvailableCancelRequest,
    isAvailableCreateRequest,
    isAvailableGetAttachments,
    isAvailableRequestAccepted,
  } = useActionsPermissions(actionsPaths);

  const isAvailableEditSidebar = isAvailableEditRequest
  || isAvailableGetAttachments
  || isAvailableGetProposals;

  return {
    isAvailableEditSidebar,
    isAvailableEditRequest,
    isAvailableCancelRequest,
    isAvailableCreateRequest,
    isAvailableRequestAccepted,
  };
};

export default useInsideSalesListPermissions;

export const mockProjectStatusData = [
  {
    name: 'In Progress',
    value: 5,
  },
  {
    name: 'Completed',
    value: 10,
  },
  {
    name: 'Pending cancellation/disconnect',
    value: 20,
  },
  {
    name: 'Canceled',
    value: 30,
  },
  {
    name: 'Disconnect',
    value: 40,
  },
];

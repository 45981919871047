import React from 'react';
import NoData from 'shared/NoData';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const AttachmentsEmpty = () => (
  <NoData
    icon={<AttachFileIcon fontSize="large" />}
    title="No attachments yet."
    description="Please add attachment using button below."
  />
);

export default AttachmentsEmpty;

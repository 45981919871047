import React from 'react';
import {
  useWirelineEngagementsCreateContext,
} from 'pages/WirelineEngagements/WirelineEngagementsCreate/WirelineEngagementsCreateContext';
import CustomersCreateQuickFormWithModal from './CustomersCreateQuickFormWithModal';

const CustomersCreateQuickForm = () => {
  const {
    isCreateCustomerModalOpen,
  } = useWirelineEngagementsCreateContext();

  if (!isCreateCustomerModalOpen) {
    return null;
  }

  return (
    <CustomersCreateQuickFormWithModal />);
};

export default CustomersCreateQuickForm;

import { netsparkAxios } from 'core/services/HTTPService';
import { IRequestPaging } from 'core/services/HTTPService.models';
import { usersPath } from 'core/api/constants';
// eslint-disable-next-line no-unused-vars
import { IUserWithTeamRoles, ITeamRole } from 'pages/UserManagement/UserManagement.models';
import { IAssociationsConfigItem } from 'pages/UserManagement/UserManagementCreate/UserManagementGeneralForm/UserManagementGeneralForm.models';

export const getRelatedToByTeamRole = (teamRole: ITeamRole) => {
  const { name, team: { department, alignment } } = teamRole;

  return netsparkAxios.get<IAssociationsConfigItem[]>(`/staff/teams/${alignment}/${department}/roles/${name}/associations/`);
};

export const getUserList = (params) => (
  netsparkAxios.get<IRequestPaging<IUserWithTeamRoles>>(usersPath, { params })
);

import React from 'react';
import Table from 'shared/Table';
import { useTableContext } from 'shared/Table/TableContext';
import { useInsideSalesDepartmentContext } from '@dashboardWirelineInsideSalesDepartment/InsideSalesDepartmentContext';
import { useUpdateEffect } from 'core/hooks/useUpdateEffect';
import { useAppSelector } from 'core/store';
import { maxTableHeight } from '@dashboardWirelineInsideSalesDepartment/InsideSalesBookingList/InsideSalesTable/constants';

const InsideSalesTable = () => {
  const { getTableData } = useTableContext();
  const { period } = useInsideSalesDepartmentContext();

  const isBookingDataLoading = useAppSelector(
    (state) => state.dashboard.insideSalesBookingData.isLoading,
  );
  const bookingDataCount = useAppSelector(
    (state) => state.dashboard.insideSalesBookingData.count,
  );
  const bookingData = useAppSelector(
    (state) => state.dashboard.insideSalesBookingData.results,
  );

  useUpdateEffect(() => {
    getTableData();
  }, [period]);

  return (
    <Table
      isLoading={isBookingDataLoading}
      rowCount={bookingDataCount}
      showColumnFilters
      showGlobalFilter={false}
      rows={bookingData}
      showToolbar={false}
      enableStickyHeader
      muiTableContainerProps={{ sx: { maxHeight: maxTableHeight } }}
    />
  );
};

export default InsideSalesTable;

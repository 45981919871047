import { netsparkAxios } from 'core/services/HTTPService';
import { getInsideSalesUsersUrl } from 'pages/Dashboard/api/apiUrls';
// eslint-disable-next-line no-unused-vars
import { TPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';
import { IInsideSalesUsersItem } from '@dashboardWirelineInsideSalesDepartment/InsideSalesDepartment.models';

const getInsideSalesUsers = (signal: AbortSignal, params: TPeriod) => netsparkAxios
  .get<IInsideSalesUsersItem[]>(getInsideSalesUsersUrl, { signal, params });

export default getInsideSalesUsers;

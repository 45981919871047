import {
  GET_INSIDE_SALES_BOOKING_DATA_REQUEST,
  GET_INSIDE_SALES_BOOKING_DATA_SUCCESS,
  GET_INSIDE_SALES_BOOKING_DATA_FAILURE,
} from '@dashboardWirelineInsideSalesDepartment/GetInsideSalesTableData/actionsTypes';

export const getInsideSalesBookingDataRequest = () => (
  { type: GET_INSIDE_SALES_BOOKING_DATA_REQUEST }
);

export const getInsideSalesBookingDataSuccess = (data) => ({
  type: GET_INSIDE_SALES_BOOKING_DATA_SUCCESS,
  data,
});

export const getInsideSalesBookingDataFailure = (error) => ({
  type: GET_INSIDE_SALES_BOOKING_DATA_FAILURE,
  error,
});

import React from 'react';
import { TableActiveRowContextProvider } from 'shared/Table/TableActiveRowContext';
import WirelineEngagementsSowDataContainer from '@EngagementsSowData/WirelineEngagementsSowDataContainer';

const WirelineEngagementsSowData = () => (
  <TableActiveRowContextProvider>
    <WirelineEngagementsSowDataContainer />
  </TableActiveRowContextProvider>
);

export default WirelineEngagementsSowData;

import {
  CREATE_CUSTOMERS_LOCATION_REQUEST,
  CREATE_CUSTOMERS_LOCATION_SUCCESS,
  CREATE_CUSTOMERS_LOCATION_FAILURE,
}
  from 'pages/Customers/CustomersLocations/CreateLocation/actionTypes';

export const createLocationRequest = () => ({ type: CREATE_CUSTOMERS_LOCATION_REQUEST });

export const createLocationSuccess = (location) => (
  { type: CREATE_CUSTOMERS_LOCATION_SUCCESS, location });

export const createLocationFailure = (errors) => (
  { type: CREATE_CUSTOMERS_LOCATION_FAILURE, errors });

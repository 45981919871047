import React from 'react';
import Table from 'shared/Table';
import { useAppSelector } from 'core/store';
import { colDefOptionActionsTwoIcons, colDefOptionSelect } from 'shared/Table/constants';
import {
  defaultColumnPinning,
  muiTableContainerProps,
} from 'pages/WirelineEngagements/WirelineEngagementsTables/constants';
import { IRenewalSowDataItem } from '@EngagementsRenewalSowData/renewalSowData.models';
import EngagementAcceptedButton from '@EngagementsRenewalSowData/EngagementAccepted/EngagementAcceptedButton';
import RenewalSowDataTableRowActions from '@EngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableRowActions';
import RenewalSowDataTableModals
  from '@EngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableModals/RenewalSowDataTableModals';

const RenewalSowDataTable = () => {
  const isRenewalSowLoading = useAppSelector(
    (state) => state.wirelineEngagements.renewalSow.isLoading,
  );

  const renewalSowData = useAppSelector(
    (state) => state.wirelineEngagements.renewalSow.results,
  );

  const renewalSowCount = useAppSelector(
    (state) => state.wirelineEngagements.renewalSow.count,
  );

  return (
    <>
      <Table
        className="wireline-table"
        isLoading={isRenewalSowLoading}
        rowCount={renewalSowCount}
        showColumnFilters={false}
        showGlobalFilter
        rows={renewalSowData}
        initialColumnPinning={defaultColumnPinning}
        muiTableContainerProps={muiTableContainerProps}
        enableStickyHeader
        actionsButton={(
          <EngagementAcceptedButton />
      )}
        renderRowActions={({ row: { original } }) => (
          <RenewalSowDataTableRowActions
            row={original as unknown as IRenewalSowDataItem}
          />
        )}
        displayColumnDefOptions={{
          ...colDefOptionSelect,
          ...colDefOptionActionsTwoIcons,
        }}
      />
      <RenewalSowDataTableModals />
    </>
  );
};
export default RenewalSowDataTable;

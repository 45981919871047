import React from 'react';
import CustomCircularProgress from 'shared/CustomCircularProgress';
import PropTypes from 'prop-types';

const Preloader = ({ size, thickness }) => (
  <div className="preloader" data-testid="preloader">
    <CustomCircularProgress
      size={size}
      thickness={thickness}
    />
  </div>
);

Preloader.propTypes = {
  size: PropTypes.number,
  thickness: PropTypes.number,
};

Preloader.defaultProps = {
  size: 73,
  thickness: 2,
};

export default Preloader;

import React from 'react';
import { Grid, Typography, IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate, useParams } from 'react-router-dom';
import { getEngagementsEditPath } from 'core/routing/paths';

import './BackToEditEngagementButton.scss';

interface ISowDataBackButtonProps {
  title: string;
}

const BackToEditEngagementButton: React.FC<ISowDataBackButtonProps> = ({ title }) => {
  const navigate = useNavigate();

  const { wirelineEngagementId } = useParams();

  const onGoBack = () => {
    navigate(getEngagementsEditPath(wirelineEngagementId));
  };

  return (
    <Grid
      container
      className="back-to-edit-engagement__button_wrap"
      alignItems="center"
      pt={2}
      pl={2}
    >
      <IconButton
        className="back-to-edit-engagement__button"
        color="primary"
        size="medium"
        onClick={onGoBack}
        data-testid="back-to-edit-engagement"
      >
        <ArrowBackIosIcon
          color="action"
        />
      </IconButton>
      <Typography
        variant="h6"
        component="h2"
        className="back-to-edit-engagement__title"
      >
        {title}
      </Typography>
    </Grid>
  );
};

export default BackToEditEngagementButton;

import React from 'react';
import { Grid, Typography, IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useParams, useNavigate } from 'react-router-dom';
import paths from 'core/routing/paths';

const UserManagementBackButton = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const onGoBack = () => {
    navigate(paths.userManagement);
  };

  return (
    <Grid
      container
      alignItems="center"
      pt={2}
      pl={2}
    >
      <IconButton
        className="user-management-create-form__back-button"
        color="primary"
        size="medium"
        onClick={onGoBack}
      >
        <ArrowBackIosIcon
          color="action"
        />
      </IconButton>
      <Typography
        className="user-management-create-form__section-title"
        variant="h6"
        component="h2"
      >
        {id ? 'Edit User' : 'Create User'}
      </Typography>
    </Grid>
  );
};

export default UserManagementBackButton;

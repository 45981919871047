import React from 'react';

import WirelineEngagementsWidgets from 'pages/WirelineEngagements/WirelineEngagementsWidgets';
import CustomersCreateQuickForm from 'pages/Customers/CustomersCreate/CustomersCreateQuickForm';
import EngagementFormsWrappe from 'pages/WirelineEngagements/WirelineEngagementsCreate/EngagementFormsWrapper';
import AddOrderModalFormContextWrap from 'pages/WirelineEngagements/WirelineEngagementsCreate/AddOrderModalFormContextWrap';
import WirelineEngagementsCreateBackButton from 'pages/WirelineEngagements/WirelineEngagementsCreate/WirelineEngagementsCreateBackButton';
import WirelineEngagementsCreateProvider from 'pages/WirelineEngagements/WirelineEngagementsCreate/WirelineEngagementsCreateContext';

const WirelineEngagementsCreate = () => (
  <WirelineEngagementsCreateProvider>
    <EngagementFormsWrappe />
    <WirelineEngagementsWidgets />
    <WirelineEngagementsCreateBackButton />
    <CustomersCreateQuickForm />
    <AddOrderModalFormContextWrap />
  </WirelineEngagementsCreateProvider>
);

export default WirelineEngagementsCreate;

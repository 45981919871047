import { netsparkAxios } from 'core/services/HTTPService';
import { IEngagementsTotalItem } from 'pages/Dashboard/Dashboard.models';
import { getTechnicalOverlayUrl } from 'pages/Dashboard/api/apiUrls';
// eslint-disable-next-line no-unused-vars
import { ITechnicalOverlayParams } from '@dashboardWirelineSellerDepartment/TechnicalOverlay/TechnicalOverlay.models';

const getTechnicalOverlay = (signal: AbortSignal, params: ITechnicalOverlayParams) => netsparkAxios
  .get<IEngagementsTotalItem[]>(getTechnicalOverlayUrl, { signal, params });

export default getTechnicalOverlay;

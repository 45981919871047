import React from 'react';
import { Box } from '@mui/material';
import { defaultWidthToReduce } from 'shared/ContentShrink/constants';

interface IContentShrink {
    children: JSX.Element | JSX.Element[];
    isShrinked: boolean;
    widthToReduce?: number;
}

const ContentShrink: React.FC<IContentShrink> = ({ children, isShrinked, widthToReduce }) => {
  const boxStyles = {
    flexGrow: 1,
    transition: 'all 0.3s ease',
    width: '100%',
    ...(isShrinked && {
      width: `calc(100% - ${widthToReduce}px)`,
    }),
  };

  return (
    <Box sx={boxStyles}>
      {children}
    </Box>
  );
};

ContentShrink.defaultProps = {
  widthToReduce: defaultWidthToReduce,
};

export default ContentShrink;

import React from 'react';
import { useAppDispatch, useAppSelector } from 'core/store';

import { HTTPService } from 'core/services';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { notifierMessage } from '@dashboardWirelineDepartments/shared/constants';
import EngagementsMrcChart from '@dashboardWirelineDepartments/shared/EngagementsMrcChart';
import { getEngagementsOrdersTOMrcAction } from '@dashboardWirelineDepartments/TechnicalOverlayDepartment/actions/GetEngagementsOrdersTOMrc';

const TOEngagementsMrcChart = () => {
  const dispatch = useAppDispatch();
  const { showErrorNotifier } = useNotifiers();

  const engagementsMrc = useAppSelector((state) => state.dashboard.engagementsTOMrc.data);
  const isLoading = useAppSelector((state) => state.dashboard.engagementsTOMrc.isLoading);

  const getEngagementMrsData = async (newPeriod) => {
    try {
      const engagementsMrcController = HTTPService.getController();

      await dispatch(getEngagementsOrdersTOMrcAction(
        { controller: engagementsMrcController, params: newPeriod },
      )).unwrap();
    } catch (e) {
      showErrorNotifier(notifierMessage.engagementsMrc.error, e);
    }
  };

  return (
    <EngagementsMrcChart
      list={engagementsMrc}
      isLoading={isLoading}
      onPeriodChange={getEngagementMrsData}
    />
  );
};

export default TOEngagementsMrcChart;

import React from 'react';
import { Grid, InputLabel } from '@mui/material';
import { GRID_DIRECTION } from 'shared/FormikField/FormikField.model';

interface IFormikTextareaFieldWrapperProps {
  fieldName: string,
  label?: string,
  required: boolean,
  children: JSX.Element | JSX.Element[],
  gridDirection: GRID_DIRECTION
}

const FormikTextareaFieldWrapper: React.FC<
  IFormikTextareaFieldWrapperProps
> = ({
  fieldName, label, required, children, gridDirection,
}) => {
  if (!label) {
    return children as JSX.Element;
  }

  const isColumnDirection = gridDirection === GRID_DIRECTION.COLUMN;

  return (
    <Grid
      item
      container
      direction="row"
      xs={12}
    >
      <Grid
        item
        container
        direction="row"
        xs={12}
        sm={isColumnDirection ? 12 : 4}
        mb={1}
      >
        <Grid item>
          <InputLabel
            className="formik-textarea-field__title"
            htmlFor={fieldName}
            required={required as boolean}
          >
            {label}
          </InputLabel>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={isColumnDirection ? 12 : 8}
      >
        {children}
      </Grid>
    </Grid>
  );
};

FormikTextareaFieldWrapper.defaultProps = {
  label: '',
};

export default FormikTextareaFieldWrapper;

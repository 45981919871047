import { createAsyncThunk } from '@reduxjs/toolkit';
import { postOrderCancelById } from 'core/api/sales.api';
// eslint-disable-next-line no-unused-vars
import { IOrderCancel } from '@EngagementsSowData/salesSow.model';

const postCancelOrderAction = createAsyncThunk(
  'postCancelOrderAction',
  async ({ id, params }: { id: string | number, params: IOrderCancel },
    { rejectWithValue }) => {
    try {
      await postOrderCancelById(id, params);
    } catch (error) {
      return rejectWithValue(error);
    }
  });

export default postCancelOrderAction;

import { isEmpty, sum } from 'lodash';
import { colorsOfCharts, totalColor } from '@dashboardWirelineRetentionDepartment/constants';
import { IChartItem } from 'core/models/Recharts.models';

interface IPreparedData {
    chartData: IChartItem[];
    colors: string[];
}

export const prepareChartData = (
  selectedProducts: string[], data: { [key: string]: number },
): IPreparedData => {
  let chartData: IChartItem[];
  let colors: string[];

  if (isEmpty(selectedProducts)) {
    chartData = [{ name: 'Total', value: sum(Object.values(data)) }];
    colors = totalColor;
  } else {
    chartData = selectedProducts.map((product) => ({ name: product, value: data[product] || 0 }));
    colors = colorsOfCharts;
  }

  return { chartData, colors };
};

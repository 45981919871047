import React from 'react';
import flatMap from 'lodash/flatMap';
import startCase from 'lodash/startCase';
import { useLocation } from 'react-router-dom';
import Breadcrumb from 'layouts/Header/HeaderBreadcrumbs/Breadcrumb';
import { listPath } from 'core/routing/paths';

const HeaderBreadcrumbs = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean);

  const breadcrumbPaths = flatMap(pathSegments, (segment, index, segments) => {
    if (segment === listPath.slice(1)) {
      return [];
    }

    const isCurrentNumeric = !Number.isNaN(Number(segment));
    const isNextNumeric = !Number.isNaN(Number(segments[index + 1]));

    if (isCurrentNumeric) {
      return [];
    }

    let path;
    if (isNextNumeric) {
      path = `/${(segments as string[]).slice(0, index + 2).join('/')}`;
    } else {
      path = `/${(segments as string[]).slice(0, index + 1).join('/')}`;
    }

    return [{ segment, path }];
  })
    .map(({ segment, path }) => ({
      breadcrumbName: startCase(segment),
      path,
    }));

  return (
    <Breadcrumb breadcrumbPaths={breadcrumbPaths} />
  );
};

export default HeaderBreadcrumbs;

import { netsparkAxios } from 'core/services/HTTPService';
import { IRequestPaging } from 'core/services/HTTPService.models';
import {
  ICustomerListItem,
  ICustomerLocation,
  ICustomer,
  ICustomerLoaProfile,
  ICustomerAttachment,
  ICustomerComment,
} from 'pages/Customers/Customers.models';
// eslint-disable-next-line no-unused-vars
import { IDraftComment } from 'shared/widgets/Comments/Comments.models';
// eslint-disable-next-line no-unused-vars
import { IDraftAttachment } from 'shared/widgets/Attachments/Attachments.model';
// eslint-disable-next-line no-unused-vars
import { IDraftLocation } from 'shared/widgets/Locations/Locations.models';
import {
  customersPath,
  getCustomerByIdUrl,
  sendLoaPath,
  getCustomerCommentsUrl,
  getCustomerEditCommentsUrl,
  getCustomerAttachmentsUrl,
  getCustomerEditAttachmentsUrl,
  getCustomerLocationsUrl,
  getCustomerEditLocationsUrl,
  getBulkUploadCustomerLocationUrl,
} from 'core/api/constants';

export const getCustomers = (params) => netsparkAxios.get<
  IRequestPaging<ICustomerListItem>
>(customersPath, { params });

export const getLocationsByCustomerId = (customerId: number | string) => netsparkAxios.get<
  ICustomerLocation[]
>(getCustomerLocationsUrl(customerId));

export const getCustomerLocations = (
  signal: AbortSignal,
  customerId: number | string,
) => netsparkAxios.get<ICustomerLocation[]>(getCustomerLocationsUrl(customerId), { signal });

export const createCustomerLocation = (
  customerId: number | string,
  draftLocation: IDraftLocation,
) => netsparkAxios.post<ICustomerLocation>(getCustomerLocationsUrl(customerId), draftLocation);

export const editCustomerLocation = (
  customerId: number | string,
  locationId: number | string,
  draftLocation: IDraftLocation,
) => netsparkAxios.put(getCustomerEditLocationsUrl(customerId, locationId), draftLocation);

export const removeCustomerLocation = (
  customerId: number | string,
  locationId: number | string,
) => netsparkAxios.delete(getCustomerEditLocationsUrl(customerId, locationId));

export const bulkUploadCustomerLocation = (
  customerId: number | string,
  uploadLocationsFile: FormData,
) => netsparkAxios.post(getBulkUploadCustomerLocationUrl(customerId), uploadLocationsFile);

export const getCustomerById = (customerId) => (
  netsparkAxios.get<ICustomer>(getCustomerByIdUrl(customerId)));

export const sendLOAByCustomerId = (
  customerId: number| string,
  params: ICustomerLoaProfile,
) => netsparkAxios.post<ICustomerLoaProfile>(
  `${customersPath}${customerId}/${sendLoaPath}`, params,
);

export const getCustomerComments = (signal: AbortSignal, customerId: string | number) => (
  netsparkAxios.get<ICustomerComment[]>(getCustomerCommentsUrl(customerId), { signal })
);

export const createCustomerComment = (
  customerId: string | number,
  draftComment: IDraftComment,
) => (
  netsparkAxios.post<ICustomerComment>(getCustomerCommentsUrl(customerId), draftComment)
);

export const removeCustomerComment = (customerId: string | number, commentId: string | number) => (
  netsparkAxios.delete(getCustomerEditCommentsUrl(customerId, commentId))
);

export const editCustomerComment = (
  customerId: string | number,
  commentId: string | number,
  draftComment: IDraftComment,
) => (
  netsparkAxios.put<ICustomerComment>(
    getCustomerEditCommentsUrl(customerId, commentId), draftComment)
);

export const getCustomerAttachments = (signal: AbortSignal, customerId: string | number) => (
  netsparkAxios.get<ICustomerAttachment[]>(getCustomerAttachmentsUrl(customerId), { signal })
);

export const removeCustomerAttachment = (
  customerId: string | number,
  attachmentId: string | number,
) => (
  netsparkAxios.delete(getCustomerEditAttachmentsUrl(customerId, attachmentId))
);

export const editCustomerAttachmentName = (
  customerId: string | number,
  attachmentId: string | number,
  draftAttachment: IDraftAttachment,
) => (
  netsparkAxios.put<ICustomerAttachment>(getCustomerEditAttachmentsUrl(
    customerId, attachmentId), draftAttachment)
);

export const createCustomerAttachment = (
  customerId: string | number,
  attachmentFile: FormData,
) => (
  netsparkAxios.post(getCustomerAttachmentsUrl(customerId), attachmentFile)
);

import {
  GET_INSIDE_SALES_REQUEST,
  GET_INSIDE_SALES_SUCCESS,
  GET_INSIDE_SALES_FAILURE,
} from 'core/actions/crm/InsideSales/GetInsideSalesList/actionsTypes';

export const getInsideSalesRequest = () => ({ type: GET_INSIDE_SALES_REQUEST });

export const getInsideSalesSuccess = (data) => ({
  type: GET_INSIDE_SALES_SUCCESS,
  data,
});

export const getInsideSalesFailure = (error) => ({
  type: GET_INSIDE_SALES_FAILURE,
  error,
});

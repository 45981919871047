import {
  getStatesRequest,
  getStatesSuccess,
  getStatesFailure,
} from './actionsCreators';
import getStates from './api.service';

const getStatesAction = (controller) => async (dispatch) => {
  try {
    const { signal } = controller;

    dispatch(getStatesRequest());

    const { data } = await getStates(signal);

    dispatch(getStatesSuccess(data));

    return data;
  } catch (error) {
    dispatch(getStatesFailure(error));
    throw error;
  }
};

export default getStatesAction;

import Yup from 'core/utilities/Yup';

const contractFormSchema = Yup.object().shape({
  signerName: Yup
    .string()
    .required('Name is required')
    .trim()
    .default(''),
  signerPhone: Yup
    .string()
    .required('Phone number is required')
    .default(''),
  signerEmail: Yup
    .string()
    .required('Email is required')
    .email('Invalid email address')
    .default(''),
  additionalContactName: Yup
    .string()
    .default(''),
  additionalContactEmail: Yup
    .string()
    .email('Invalid email address')
    .default(''),
  additionalContacts: Yup
    .string()
    .default(''),
  generateContractPerServiceAddress: Yup
    .boolean()
    .default(false),
  esign: Yup
    .boolean()
    .default(false),
  mergeEsignForMultipleContracts: Yup
    .boolean()
    .default(false),
});

export default contractFormSchema;

import { forEach, uniqueId } from 'lodash';
// eslint-disable-next-line no-unused-vars
import { IOption } from 'shared/FormikField/FormikField.model';
// eslint-disable-next-line no-unused-vars
import { ICustomerLocation } from 'pages/Customers/Customers.models';
import {
  // eslint-disable-next-line no-unused-vars
  ISalesTSDItem, IProductCategory, IFormikOrder, IProduct, IFormRequestTypeProductItem,
  // eslint-disable-next-line no-unused-vars
  ICreateOrderItem, IFormikOrderProduct,
  // eslint-disable-next-line no-unused-vars
  IProductParams, IProductFormParams, ICarrier, IFormikProductValues, IFormRequestTypeValues,
} from 'pages/WirelineEngagements/shared/AddOrderModalForm/product.model';
// eslint-disable-next-line no-unused-vars
import { ADD_ORDER_TYPE, TAddOrderType } from 'pages/WirelineEngagements/WirelineEngagements.models';
import { fields, sellerRequestTypeValues } from 'pages/WirelineEngagements/shared/AddOrderModalForm/constants';

export const getOptionsList = <T extends Record<string, any>>(
  {
    list = [],
    excludesLabels,
    labelKey = 'name' as keyof T,
    valueKey = 'id' as keyof T,
  }:
  {
    list: T[],
    labelKey?: keyof T,
    valueKey?: keyof T,
    excludesLabels?: (T[keyof T])[]
    },
) : IOption[] => {
  const updatedList = excludesLabels?.length
    ? list.filter((item) => !excludesLabels.includes(item[labelKey]))
    : list;

  return updatedList.map(({
    [labelKey]: label, [valueKey]: value,
  }) => ({ label, value }));
};

export const checkIfSelectedProducts = (products: IFormikOrderProduct) => Object.values(products)
  .some((product) => product.quantity !== 0);

const cleanObject = (object) => Object.fromEntries(
  Object.entries(object)
    .filter(([, value]) => value),
);

export const getOrdersToSubmit = (
  values: IFormRequestTypeValues,
  orderModalType: TAddOrderType,
): ICreateOrderItem[] => {
  const requestData = [] as ICreateOrderItem[];

  forEach(values.products, (product) => {
    const {
      tsdId, carrier, locationId, productId, sellerRequestType, params,
    } = product;
    const validParams = cleanObject(params);

    requestData.push({
      ...(carrier ? { carrier } : {}),
      locationId,
      flowType: orderModalType as ADD_ORDER_TYPE,
      tsdId: Number(tsdId),
      productId: Number(productId),
      quantity: 1,
      sellerRequestType,
      ...validParams,
    });
  });

  return requestData;
};

export const getActiveTSDIndex = (data: IFormikProductValues) => {
  const activeIndex = data.orders.findIndex((item) => item.tsdId === data.currentTSD);
  return activeIndex >= 0 ? activeIndex : 0;
};

export const getProductParams = ({
  data,
  categories,
}: {
  data?: IFormikOrder | undefined,
  categories: number[],
}): IProductParams => {
  if (!data) return {};
  const categoryId = data[fields.categoryId] ? [data[fields.categoryId]] : categories;
  const name = data[fields.productName] || '';

  return ({
    isActive: true,
    categoryId,
    ...(name ? { name } : {}),
  });
};

export const getFormOrdersProductList = ({
  list,
  attTSDId,
  locations,
  existedProductsList,
}:{
  attTSDId: number,
  list: IFormikOrder[],
  locations: ICustomerLocation[],
  existedProductsList: IFormRequestTypeProductItem[],
}): IFormRequestTypeValues => {
  const orderProducts: IFormRequestTypeProductItem[] = [];

  list.forEach((item) => {
    const {
      [fields.tsdId]: tsdId,
      [fields.carrier]: carrier,
      [fields.products]: products,
      [fields.locationIds]: locationIds,
    } = item;

    locationIds.forEach((locationId) => {
      Object.keys(products).forEach((productId) => {
        const { name: productName, quantity } = products[productId];
        const {
          address, city, state, zipCode,
        } = locations
          .find((locationData) => locationData.id === Number(locationId)) || {};
        const fullLocation = `${address}, ${city}, ${state}, ${zipCode}`;

        const existedProducts = existedProductsList.filter((
          existedProduct,
        ) => existedProduct.locationId === Number(locationId)
         && existedProduct.tsdId === Number(tsdId)
         && existedProduct.productId === Number(productId));

        const quantityDiff = quantity - existedProducts.length;
        const isMoreNewProducts = quantityDiff > 0;
        const isSameLenth = quantityDiff === 0;

        const entries = isMoreNewProducts ? [...Array(quantityDiff)].map(() => ({
          id: uniqueId(),
          tsdId: Number(tsdId),
          carrier,
          locationName: fullLocation,
          locationId: Number(locationId),
          productName,
          productId: Number(productId),
          [fields.sellerRequestType]: Number(tsdId) === attTSDId ? '' : sellerRequestTypeValues.contractSupport,
          params: {
            [fields.contractSupport]: '',
            [fields.projectManagementSupport]: '',
            [fields.billingSupport]: '',
            [fields.customerAssistPm]: '',
            [fields.customerAssistBilling]: '',
          },
        }) as IFormRequestTypeProductItem) : [];

        const updadtedExistedProducts = isMoreNewProducts || isSameLenth
          ? existedProducts
          : existedProducts.slice(0, quantityDiff);

        orderProducts.push(...[...updadtedExistedProducts, ...entries]);
      });
    });
  });

  return { products: orderProducts };
};

export const getUpdatedOrdersByRemovedProducts = (
  orders: IFormikOrder[],
  products: IFormRequestTypeProductItem[],
) => {
  const copyOrders = [...orders];

  products.forEach((productData) => {
    const orderData = copyOrders.find((order) => Number(order.tsdId) === productData.tsdId);
    if (orderData) {
      const product = orderData.products[productData.productId];

      product.quantity -= 1;
    }
  });

  return copyOrders.filter(
    (order) => Object.values(order.products).some((item) => item.quantity > 0),
  );
};

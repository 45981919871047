import Yup from 'core/utilities/Yup';
import { getOneOfFromType, getOneOfFromOption } from 'core/utilities/Yup/YupHelpers';
import {
  UserTeamRoleName,
  UserTeamRoleTeamAlignment,
  UserTeamRoleTeamDepartment,
} from 'pages/UserManagement/UserManagement.models';
import {
  userTeamRolesTeamDepartmentsOptionsMobility,
  userTeamRolesTeamDepartmentsOptionsWireline,
} from 'pages/UserManagement/constants';

const teamSchemaWithValidation = Yup.object().shape({
  alignment: Yup
    .mixed<UserTeamRoleTeamAlignment>()
    .oneOf(getOneOfFromType(UserTeamRoleTeamAlignment))
    .required('Alignment is required'),
  department: Yup
    .mixed<UserTeamRoleTeamDepartment>()
    .oneOf(getOneOfFromType(UserTeamRoleTeamDepartment))
    .when('alignment', {
      is: UserTeamRoleTeamAlignment.WIRELINE,
      then: (schema) => schema.oneOf(
        getOneOfFromOption<
          UserTeamRoleTeamDepartment
        >(userTeamRolesTeamDepartmentsOptionsWireline),
      ),
      otherwise: (schema) => schema.oneOf(
        getOneOfFromOption<
          UserTeamRoleTeamDepartment
        >(userTeamRolesTeamDepartmentsOptionsMobility),
      ),
    })
    .required('Department is required'),
});

const teamSchema = Yup.object().shape({
  alignment: Yup
    .mixed<UserTeamRoleTeamAlignment>()
    .oneOf(getOneOfFromType(UserTeamRoleTeamAlignment)),
  department: Yup
    .mixed<UserTeamRoleTeamDepartment>()
    .oneOf(getOneOfFromType(UserTeamRoleTeamDepartment)),
});

const associatesSchema = Yup
  .array()
  .of(
    Yup.object().shape({
      user: Yup
        .string()
        .email()
        .required('Association is required'),
      team: teamSchema,
    }),
  )
  .default([]);

export const teamRolesSchema = Yup
  .array()
  .of(
    Yup.object().shape({
      name: Yup
        .mixed<UserTeamRoleName>()
        .oneOf(getOneOfFromType(UserTeamRoleName))
        .required('User role is required'),
      team: teamSchemaWithValidation,
      associates: associatesSchema,
    }),
  )
  .test(
    'unique department',
    'Only unique departments allowed',
    (teamRoles = []) => {
      const usedTeamRolesDepartments = new Set<string>([]);
      const errors: Yup.ValidationError[] = [];

      teamRoles.forEach((teamRole, index) => {
        const { name, team: { department, alignment } } = teamRole;
        const departmentKey = `${name}-${alignment}-${department}`;

        if (usedTeamRolesDepartments.has(departmentKey)) {
          const message = 'User type combination must be unique';

          errors.push(new Yup.ValidationError(message, null, `[${index}].key`));
          errors.push(new Yup.ValidationError(message, null, `[${index}].team.department`));
          errors.push(new Yup.ValidationError(message, null, `[${index}].team.alignment`));
        }
        usedTeamRolesDepartments.add(departmentKey);
      });

      if (errors.length === 0) {
        return true;
      }

      return new Yup.ValidationError(errors);
    })
  .default([]);

export default teamRolesSchema;

import React from 'react';

import { useSellerDepartmentContext } from '@dashboardWirelineSellerDepartment/SellerDepartmentContext';

import FiltersByPeriod from 'pages/Dashboard/shared/FiltersByPeriod';

const SellerFiltersByPeriod = () => {
  const {
    getDashboardData,
    period,
  } = useSellerDepartmentContext();

  return (
    <FiltersByPeriod
      period={period}
      onChange={getDashboardData}
    />
  );
};

export default SellerFiltersByPeriod;

import React from 'react';
import { useFormikContext } from 'formik';
import { useAppDispatch } from 'core/store';
import { clearAttachmentsAction } from 'core/actions/crm/InsideSales/InsideSalesAttachments';
import CustomModal from 'shared/CustomModal';
import InsideSalesCreateForm from 'pages/InsideSales/InsideSalesList/InsideSalesListTable/InsideSalesListTableModals/InsideSalesCreateModalForm/InsideSalesCreateForm';
import InsideSalesAttachments from 'pages/InsideSales/InsideSalesList/InsideSalesListTable/InsideSalesListTableModals/InsideSalesCreateModalForm/InsideSalesAttachments';

interface IInsideSalesCreateModalProps {
  isOpen: boolean;
  isLoading: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const InsideSalesCreateModal: React.FC<
  IInsideSalesCreateModalProps
> = ({ isOpen, isLoading, setIsOpen }) => {
  const dispatch = useAppDispatch();

  const {
    dirty, isSubmitting, submitForm, resetForm,
  } = useFormikContext();

  const withLeaveConfirmation = dirty && !isSubmitting;

  const onCloseModal = () => {
    resetForm();
    setIsOpen(false);
    dispatch(clearAttachmentsAction());
  };

  return (
    <CustomModal
      areButtonsVisible
      isModalOpen={isOpen}
      isLoading={isLoading}
      title="Create request"
      onCloseModal={onCloseModal}
      onClickConfirm={submitForm}
      isConfirmDialog={withLeaveConfirmation}
    >
      <>
        <InsideSalesCreateForm />
        <InsideSalesAttachments />
      </>
    </CustomModal>
  );
};

export default InsideSalesCreateModal;

/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getEngagementsScopeDescription } from 'core/api/wirelineEngagements.api';
// eslint-disable-next-line no-unused-vars
import { IScopeDescription, IScopeDescriptionParams } from 'pages/WirelineEngagements/WirelineEngagementsCreate/EngagementFormsWrapper/ScopeDescription/ScopeDescription.model';

export interface IState {
    isLoading: boolean
    error: object | null
    data: IScopeDescription
}

const initialState: IState = {
  error: null,
  isLoading: false,
  data: {} as IScopeDescription,
};

export const getScopeDescriptionAction = createAsyncThunk(
  'getScopeDescriptionAction',
  async (params: IScopeDescriptionParams, { rejectWithValue }) => {
    try {
      const { data } = await getEngagementsScopeDescription(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const ScopeDescriptionSlice = createSlice({
  name: 'getScopeDescription',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getScopeDescriptionAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getScopeDescriptionAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(getScopeDescriptionAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
        state.data = {} as IScopeDescription;
      });
  },
});

export const { clear: clearGetScopeDescriptionAction } = ScopeDescriptionSlice.actions;
export const getScopeDescriptionReducer = ScopeDescriptionSlice.reducer;

import React from 'react';
import { useAppSelector } from 'core/store';
import styles from 'assets/styles/_resources.scss';
import SimpleBarChart from 'pages/Dashboard/shared/SimpleBarChart';

const BillingEngagementStatusChart = () => {
  const billingEngagementStatusData = useAppSelector(
    (state) => state.dashboard.billingEngagementStatus.data,
  );
  const isLoading = useAppSelector(
    (state) => state.dashboard.billingEngagementStatus.isLoading,
  );

  return (
    <SimpleBarChart
      data={billingEngagementStatusData}
      color={styles.primaryColor}
      isLoading={isLoading}
      title="Billing Engagement Status"
      isNeedSeparateLine
      barSize={20}
    />
  );
};

export default BillingEngagementStatusChart;

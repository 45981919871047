import React from 'react';
import map from 'lodash/map';
import { useAppSelector } from 'core/store';
import CustomSelect from 'shared/CustomSelect';
import { modifiers } from '@dashboardWirelineSellerDepartment/TechnicalOverlay/constants';

interface IUsersSelectProps {
  setSelectedUsers: (users: string[]) => void
  selectedUsers: string[]
}

const UsersSelect: React.FC<IUsersSelectProps> = ({ selectedUsers, setSelectedUsers }) => {
  const technicalOverlayData = useAppSelector((state) => state.dashboard.technicalOverlay.data);

  const options = map(technicalOverlayData, ({ sellerFullname }) => sellerFullname);

  return (
    <CustomSelect
      className={modifiers.usersFilter}
      label="Select Users"
      setSelectedOptions={setSelectedUsers}
      selectedOptions={selectedUsers}
      options={options}
    />
  );
};

export default UsersSelect;

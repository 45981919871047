import { removeCustomerComment } from 'core/api/customer.api';
import {
  removeCommentRequest,
  removeCommentSuccess,
  removeCommentFailure,
} from 'pages/Customers/CustomersComments/RemoveComment/actionCreators';
import get from 'lodash/get';

export const removeCommentAction = (
  customerId: string | number,
  commentId: string | number,
) => async (dispatch) => {
  try {
    dispatch(removeCommentRequest(commentId));
    await removeCustomerComment(customerId, commentId);
    dispatch(removeCommentSuccess(commentId));
  } catch (error) {
    const detail = get(error, 'response.data.detail', '') || get(error, 'error.detail', '');

    dispatch(removeCommentFailure(commentId, detail));
    throw error;
  }
};

export default removeCommentAction;

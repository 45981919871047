import React from 'react';
import { Box, ListItem } from '@mui/material';
import { startCase } from 'lodash';
import { IBulkError } from 'shared/widgets/Locations/Locations.models';

interface IErrorItemProps {
    error: IBulkError | { error: string };
}

const ErrorItem: React.FC<IErrorItemProps> = ({ error }) => (
  <>
    {Object.entries(error).map(([key, value]) => {
      if (key === 'rowNumber') {
        return null;
      }

      const messageContent = Array.isArray(value) ? value.join(', ') : value;

      return (
        <ListItem key={key}>
          <Box color="error" className="bulk-errors-display__error">
            <span className="bulk-errors-display__error-name">
              {startCase(key)}
            </span>
            <span className="bulk-errors-display__error-description">
              {messageContent}
            </span>
          </Box>
        </ListItem>
      );
    })}
  </>
);

export default ErrorItem;

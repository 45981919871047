import dayjs from 'dayjs';
import { dateFormat } from 'core/constants';

const formatDatetime = (
  date: string | number | Date | null | undefined,
  format = dateFormat,
): string | null => {
  if (!date) return null;

  return dayjs(date).format(format);
};

export default formatDatetime;

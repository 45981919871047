/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createEngagementsScopeDescription } from 'core/api/wirelineEngagements.api';
// eslint-disable-next-line no-unused-vars
import { IScopeDescription, IScopeDescriptionForm } from 'pages/WirelineEngagements/WirelineEngagementsCreate/EngagementFormsWrapper/ScopeDescription/ScopeDescription.model';

export interface IState {
    isLoading: boolean
    error: object | null
    data: IScopeDescription
}

const initialState: IState = {
  error: null,
  isLoading: false,
  data: {} as IScopeDescription,
};

export const createScopeDescriptionAction = createAsyncThunk(
  'createScopeDescriptionAction',
  async (
    { engagementId, params }: {
      engagementId: string | number,
      params: IScopeDescriptionForm }, { rejectWithValue }) => {
    try {
      const { data } = await createEngagementsScopeDescription(engagementId, params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const ScopeDescriptionSlice = createSlice({
  name: 'createScopeDescription',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(createScopeDescriptionAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createScopeDescriptionAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(createScopeDescriptionAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
        state.data = {} as IScopeDescription;
      });
  },
});

export const { clear: clearCreateScopeDescriptionAction } = ScopeDescriptionSlice.actions;
export const createScopeDescriptionReducer = ScopeDescriptionSlice.reducer;

export const notifierMessage = {
  bulkUpdate: {
    success: 'Location was updated successfully.',
    error: 'Failed to update Location, please try again later.',
  },
  addLocation: {
    success: 'Location was created successfully.',
    error: 'Failed to crete Location, please try again later.',
  },
};

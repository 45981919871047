import { editEngagementAttachmentName } from 'core/api/wirelineEngagements.api';
// eslint-disable-next-line no-unused-vars
import { IEditAttachmentError, IDraftAttachment } from 'shared/widgets/Attachments/Attachments.model';
import {
  editEngagementsAttachmentNameRequest,
  editEngagementsAttachmentNameSuccess,
  editEngagementsAttachmentNameFailure,
} from 'pages/WirelineEngagements/WirelineEngagementsAttachments/EditEngagementsAttachmentName/actionsCreators';

const editEngagementsAttachmentNameAction = (
  engagementId: string | number,
  attachmentId: string | number,
  draftAttachment: IDraftAttachment,
) => async (dispatch) => {
  try {
    dispatch(editEngagementsAttachmentNameRequest(attachmentId, draftAttachment));
    const { data } = await editEngagementAttachmentName(
      engagementId, attachmentId, draftAttachment);

    dispatch(editEngagementsAttachmentNameSuccess(data));
  } catch (error) {
    const { response: { data: { text, detail, name } } } = error as IEditAttachmentError;
    dispatch(editEngagementsAttachmentNameFailure(attachmentId, detail || text || name));
    throw error;
  }
};

export default editEngagementsAttachmentNameAction;

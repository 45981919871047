import React from 'react';
import cx from 'classnames';
import { Grid, Tab, Tabs } from '@mui/material';
import Dotdotdot from 'react-dotdotdot';
import { useAppSelector } from 'core/store';

interface IRequestOrderFormTabsProps {
  selectedOrderId: string,
  setSelectedOrderId: (value: string) => void,
  isDisabled: boolean,
}

const RequestOrderFormTabs: React.FC<
  IRequestOrderFormTabsProps
> = ({ selectedOrderId, setSelectedOrderId, isDisabled }) => {
  const ordersToSend = useAppSelector((state) => state.wirelineEngagements.salesSow.results);

  const onChange = (event, newValue) => {
    setSelectedOrderId(newValue);
  };

  return (
    <Grid
      container
      alignItems="center"
      pb={2}
    >

      <Tabs
        value={selectedOrderId}
        onChange={onChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        {
          ordersToSend.map(({ id, product, customerLocation }) => {
            const isSelected = selectedOrderId === String(id);

            return (
              <Tab
                key={id}
                value={String(id)}
                disabled={isDisabled}
                label={(
                  <>
                    <div
                      className={
                        cx(
                          'request-order-form__tab-product',
                          { 'request-order-form__tab-product__selected': isSelected },
                        )
                      }
                    >
                      {product}
                    </div>
                    <Dotdotdot
                      className="request-order-form__tab-location"
                      clamp={1}
                    >
                      {customerLocation}
                    </Dotdotdot>
                  </>
                )}
              />
            );
          })
        }
      </Tabs>
    </Grid>
  );
};

export default RequestOrderFormTabs;

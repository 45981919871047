/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getSalesSowById } from 'core/api/sales.api';
import { IOrderData } from 'pages/WirelineEngagements/WirelineEngagementsTables/wirelineEngagementsTables.model';

export interface IState {
  isLoading: boolean
  error: object | null
  result: IOrderData
}

const initialState: IState = {
  result: {},
  error: null,
  isLoading: false,
};

export const getSalesSowItemAction = createAsyncThunk(
  'getSalesSowItemAction',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const { data } = await getSalesSowById(id);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  });

const getSalesSowItemSlice = createSlice({
  name: 'getSalesSowItem',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(
        getSalesSowItemAction.pending,
        (state) => {
          state.result = {};
          state.isLoading = true;
        },
      )
      .addCase(
        getSalesSowItemAction.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.result = action.payload;
        })
      .addCase(
        getSalesSowItemAction.rejected,
        (state, action) => {
          state.result = {};
          state.isLoading = false;
          state.error = action.error;
        });
  },
});

export const { clear: clearGetSalsesSowItemData } = getSalesSowItemSlice.actions;

export const getSalesSowItemReducer = getSalesSowItemSlice.reducer;

import React, { useMemo, useCallback } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import Table from 'shared/Table';
import Loader from 'shared/Loader';
import { useAppSelector } from 'core/store';
import CreateButton from 'shared/CreateButton';
import { useNavigate } from 'react-router-dom';
import { TRow } from 'shared/Table/Table.models';
import { tableIds } from 'shared/Table/constants';
import { TableProvider } from 'shared/Table/TableContext';
import paths, { getEngagementsEditPath } from 'core/routing/paths';
import { MRT_ColumnDef as ColumnDef } from 'material-react-table';
import { getColumnsFromOpenapiSchema } from 'core/utilities/openapiSchema/getColumnsFromOpenapiSchema';
import getEngagementsLisAction from 'pages/WirelineEngagements/WirelineEngagementsMainPage/WirelineEngagementsList/GetEngagementsList/actions';
import { useWirelineEngagementsListPermission } from 'pages/WirelineEngagements/WirelineEngagementsMainPage/WirelineEngagementsList/useWirelineEngagementsListPermission';
import {
  columns,
  defaultVisibility,
  createEngagementButtonText,
  createEngagementMobileButtonText,
  endpointToGetEngagementListSchemaName,
} from 'pages/WirelineEngagements/WirelineEngagementsMainPage/WirelineEngagementsList/constants';

import './WirelineEngagementsList.scss';

const WirelineEngagementsList = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const listData = useAppSelector((state) => state.wirelineEngagements.list.results);
  const listCount = useAppSelector((state) => state.wirelineEngagements.list.count);
  const isLoading = useAppSelector((state) => state.wirelineEngagements.list.isLoading);

  const isOpenapiSchemasLoading = useAppSelector((state) => state.openapiSchemas.isLoading);
  const schema = useAppSelector((state) => state.openapiSchemas.schemas);
  const openApiPaths = useAppSelector((state) => state.openapiSchemas.paths);

  const {
    isAvailableGetEngagement,
    isAvailableCreateEngagement,
  } = useWirelineEngagementsListPermission();

  const onGoToCreateEngagement = () => {
    navigate(paths.wirelineEngagementsCreate);
  };

  const createEngagementAction = isAvailableCreateEngagement ? (
    <CreateButton
      className="user-engagement-btn"
      onClick={onGoToCreateEngagement}
      text={isMobile ? createEngagementMobileButtonText : createEngagementButtonText}
    />
  ) : undefined;

  const handleRowClick = isAvailableGetEngagement ? useCallback((row) => {
    navigate(getEngagementsEditPath(row.original.id));
  }, [navigate]) : undefined;

  const engagementColumnsFromSchema = useMemo(() => getColumnsFromOpenapiSchema(
    schema,
    openApiPaths,
    endpointToGetEngagementListSchemaName,
    columns,
  ), [schema, openApiPaths]);

  const engagementColumns = useMemo(() => {
    if (!listData.length) {
      return engagementColumnsFromSchema;
    }

    const availableColumns = Object.keys(listData[0]);

    return engagementColumnsFromSchema.filter((
      item,
    ) => availableColumns.includes(item.accessorKey as string));
  }, [listData, engagementColumnsFromSchema]);

  if (isOpenapiSchemasLoading) {
    return <Loader />;
  }

  return (
    <div className="engagement-list">
      <TableProvider
        id={tableIds.wirelineEngagements}
        getDataAction={getEngagementsLisAction}
        columns={engagementColumns satisfies ColumnDef<TRow>[]}
      >
        <Table
          showGlobalFilter
          showColumnFilters
          rows={listData}
          rowCount={listCount}
          isLoading={isLoading}
          handleRowClick={handleRowClick}
          defaultVisibility={defaultVisibility}
          toolBarAction={createEngagementAction}
        />
      </TableProvider>
    </div>
  );
};

export default WirelineEngagementsList;

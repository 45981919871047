import styles from 'assets/styles/_resources.scss';

export const colorsTransactionalOrdersChart = [
  styles.primaryColor,
  styles.secondaryColor,
  styles.tealColor500,
  styles.yellowColor500,
];

export const TransactionalOrdersProducts = [
  'AT & T Business Fiber(ABF)',
  'Broadband / DSL',
  'POTS',
  'AT & T Broadband Agg',
];

export const notifierMessage = {
  transactionalOrders: {
    error: 'Failed to complete your request for Transactional Orders , please try again later.',
  },
  openRequests: {
    error: 'Failed to complete your request for Open Requests, please try again later.',
  },
};

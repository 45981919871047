export interface IPayload {
  dataKey: string,
  payload: {
    cx: number
    cy: number
    fill: string
    // eslint-disable-next-line no-restricted-globals
    name: string
  },
  // eslint-disable-next-line no-restricted-globals
  name: string,
  stroke: string,
  color:string
  type: undefined| string|number,
  value: number| string,
}

export interface ITickPayload {
  coordinate : number;
  index: number;
  isShow: boolean;
  offset: number;
  tickCoord: number;
  value: number;
}

export interface IChartItem {
  // eslint-disable-next-line no-restricted-globals
  name: string,
  value: number | string,
}

export enum LabelPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
  INSIDE = 'inside',
}

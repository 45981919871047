import { netsparkAxios } from 'core/services/HTTPService';
// eslint-disable-next-line no-unused-vars
import { CombinedExportTableParams } from 'shared/ExportTableXlsxFile/ExportTableXlsxFile.models';
import { getExportBookingSalesDataUrl, getExportBookingReportDataUrl } from 'pages/Dashboard/api/apiUrls';
// eslint-disable-next-line no-unused-vars
import { IExportBookingReportParams } from '@dashboardWireline/WirelineDepartments/WIrelineDepartament.models';

export const exportBookingSalsesData = (params: CombinedExportTableParams) => netsparkAxios
  .get(getExportBookingSalesDataUrl, { responseType: 'blob', params });

export const exportBookingReportData = (
  params: IExportBookingReportParams,
) => netsparkAxios
  .get(getExportBookingReportDataUrl, { responseType: 'blob', params });

// eslint-disable-next-line no-unused-vars
import React, { FC } from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';

interface IFormikTextFieldEndAdornmentProps {
  type?: string | undefined
  isShowPassword: boolean
  toggleIsShowPassword: () => void
}

const FormikTextFieldEndAdornment: FC<IFormikTextFieldEndAdornmentProps> = ({
  type,
  isShowPassword,
  toggleIsShowPassword,
}) => {
  if (type !== 'password') {
    return null;
  }

  return (
    <InputAdornment position="end">
      <IconButton
        data-testid="toggle-password-visibility"
        aria-label="toggle password visibility"
        onClick={toggleIsShowPassword}
        onMouseDown={(e) => e.preventDefault()}
        edge="end"
      >
        {isShowPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );
};

FormikTextFieldEndAdornment.defaultProps = {
  type: 'text',
};

export default FormikTextFieldEndAdornment;

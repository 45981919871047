export const selectAllOption = 'Select all';
export const selectActiveOption = 'Select active';

const selectItemHeight = 48;
const selectItemPaddingTop = 8;

export const SelectMenuProps = {
  PaperProps: {
    style: {
      maxHeight: selectItemHeight * 4.5 + selectItemPaddingTop,
      width: 250,
    },
  },
};

import React from 'react';
import { useAppSelector } from 'core/store';
import {
  createBrowserRouter, createRoutesFromElements, Navigate, Route,
  RouterProvider as ReactRouterProvider,
} from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import PageWrapper from 'shared/PageWrapper';
import { useAvailableRoutes } from 'core/routing/useAvailableRoutes';
import paths from 'core/routing/paths';
import BaseLayout from 'layouts/BaseLayout';

const RouterProvider = () => {
  const userProfileData = useAppSelector((state) => state.userProfile.data);
  const isUserProfileData = !isEmpty(userProfileData.authorizedModules);

  const availableRoutes = useAvailableRoutes();

  const router = createBrowserRouter(createRoutesFromElements(
    isUserProfileData ? (
      (
        <Route path="/" element={<BaseLayout />}>
          {
            availableRoutes
              .map((route) => (
                <Route
                  key={route.path}
                  {...route}
                  element={<PageWrapper {...route} />}
                >
                  {route.child && (
                  <>
                    {route.child.map((childRoute) => (
                      <Route
                        key={childRoute.path}
                        {...childRoute}
                        element={<PageWrapper {...childRoute} />}
                      >
                        {childRoute.child && (
                          <>
                            {childRoute.child.map((childChildRoute) => (
                              <Route
                                key={childChildRoute.path}
                                {...childChildRoute}
                                element={<PageWrapper {...childChildRoute} />}
                              />
                            ))}
                            <Route
                              path={childRoute.path}
                              element={<Navigate to={childRoute.redirect} />}
                            />
                          </>
                        )}
                      </Route>
                    ))}
                    <Route
                      path={route.path}
                      element={<Navigate to={route.redirect} />}
                    />
                  </>
                  )}
                </Route>
              ))
}
          <Route
            path="/"
            element={<Navigate to={paths.dashboard} />}
          />
        </Route>
      )
    ) : (<Route path="/" element={<BaseLayout />} />),
  ));

  return <ReactRouterProvider router={router} />;
};

export default RouterProvider;

import React from 'react';
import {
  Collapse, Grid, IconButton, Typography,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Show from 'shared/Show';
import { IAssociationsConfigItem } from 'pages/UserManagement/UserManagementCreate/UserManagementGeneralForm/UserManagementGeneralForm.models';

interface IUserManagementCreateFormUserTypeItemAssociationsProps {
  isAssociationsAvailable: boolean;
  isLoading: boolean;
  associationsConfig: IAssociationsConfigItem[];
}

const UserManagementCreateFormTeamRoleItemAssociationsHeader: React.FC<
  IUserManagementCreateFormUserTypeItemAssociationsProps
> = ({
  isAssociationsAvailable, isLoading, associationsConfig,
}) => {
  const onRemoveTeamRole = () => {
    console.log('Delete is not available yet');
  };

  return (
    <Grid item className="user-management-create-form__section-header">
      <Typography
        className="user-management-create-form__section-title"
        variant="body1"
        component="p"
      >
        <Show on={isAssociationsAvailable && !isLoading && associationsConfig.length > 0}>
          <span>
            Related to:
          </span>
        </Show>
      </Typography>
      {/* TODO: implement delete */}
      <Collapse in={false}>
        <IconButton
          onClick={onRemoveTeamRole}
          disabled
        >
          <DeleteOutlineIcon fontSize="medium" />
        </IconButton>
      </Collapse>
    </Grid>
  );
};

export default UserManagementCreateFormTeamRoleItemAssociationsHeader;

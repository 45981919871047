import React from 'react';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { IconButton } from '@mui/material';
import { useSidebarContext } from 'layouts/Sidebar/SidebarContext';
import './SidebarToggle.scss';

const SidebarToggle = () => {
  const { switchIsSidebarCollapsed, switchIsSidebarToggledMobile } = useSidebarContext();

  return (
    <>
      <IconButton
        onClick={switchIsSidebarCollapsed}
        className="sidebar-toggle-btn"
      >
        <MenuOutlinedIcon fontSize="medium" />
      </IconButton>
      <IconButton
        onClick={switchIsSidebarToggledMobile}
        className="sidebar-toggle-btn_mob"
        data-testid="sidebar-toggle-btn"
      >
        <MenuOutlinedIcon fontSize="medium" />
      </IconButton>
    </>
  );
};

export default SidebarToggle;

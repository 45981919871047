import {
  GET_ENGAGEMENTS_ORDERS_MRC_REQUEST,
  GET_ENGAGEMENTS_ORDERS_MRC_SUCCESS,
  GET_ENGAGEMENTS_ORDERS_MRC_FAILURE,
} from './actionsTypes';

export const getEngagementsOrdersMrcRequest = () => (
  { type: GET_ENGAGEMENTS_ORDERS_MRC_REQUEST }
);

export const getEngagementsOrdersMrcSuccess = (data) => ({
  type: GET_ENGAGEMENTS_ORDERS_MRC_SUCCESS,
  data,
});

export const getEngagementsOrdersMrcFailure = (error) => ({
  type: GET_ENGAGEMENTS_ORDERS_MRC_FAILURE,
  error,
});

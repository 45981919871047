/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IEngagementsTotalItem } from 'pages/Dashboard/Dashboard.models';
import getTechnicalOverlay from '@dashboardWirelineSellerDepartment/TechnicalOverlay/GetTechnicalOverlay/api.service';
// eslint-disable-next-line no-unused-vars
import { ITechnicalOverlayParams } from '@dashboardWirelineSellerDepartment/TechnicalOverlay/TechnicalOverlay.models';

export interface IState {
  data: IEngagementsTotalItem[];
  isLoading: boolean;
  error: object | null;
}

const initialState: IState = {
  data: [],
  isLoading: false,
  error: null,
};

export const getTechnicalOverlayAction = createAsyncThunk(
  'getTechnicalOverlayAction',
  async ({ controller, params }: { controller: AbortController, params: ITechnicalOverlayParams },
    { rejectWithValue }) => {
    try {
      const { signal } = controller;
      const { data } = await getTechnicalOverlay(signal, params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const technicalOverlaySlice = createSlice({
  name: 'getTechnicalOverlay',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getTechnicalOverlayAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTechnicalOverlayAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(getTechnicalOverlayAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
        state.data = [];
      });
  },
});

export const getTechnicalOverlayReducer = technicalOverlaySlice.reducer;

import {
  EDIT_CUSTOMERS_COMMENT_REQUEST,
  EDIT_CUSTOMERS_COMMENT_SUCCESS,
  EDIT_CUSTOMERS_COMMENT_FAILURE,
} from 'pages/Customers/CustomersComments/EditComment/actionTypes';

export const editCommentRequest = (commentId) => (
  { type: EDIT_CUSTOMERS_COMMENT_REQUEST, commentId });

export const editCommentSuccess = (editedComment) => (
  { type: EDIT_CUSTOMERS_COMMENT_SUCCESS, editedComment }
);

export const editCommentFailure = (commentId, error) => ({
  type: EDIT_CUSTOMERS_COMMENT_FAILURE,
  commentId,
  error,
});

import React from 'react';
import { Grid } from '@mui/material';
import RequestOrderFormIContactProfile
  from '@EngagementsSowData/RequestOrderForm/RequestOrderFormContacts/RequestOrderFormIContactProfile';
import RequestOrderFormContactsLocation
  from '@EngagementsSowData/RequestOrderForm/RequestOrderFormContacts/RequestOrderFormContactsLocation';
import { locationFields, profileFields } from '@EngagementsSowData/RequestOrderForm/constants';

interface IRequestOrderFormContactsProps {
  isEditMode: boolean
}

const RequestOrderFormContacts: React.FC<
  IRequestOrderFormContactsProps
> = ({ isEditMode }) => (
  <Grid
    className="request-order-form__section"
    item
    container
    md={6}
  >
    {
      profileFields.map(
        ({ fieldName, title }) => (
          <RequestOrderFormIContactProfile
            key={fieldName}
            fieldName={fieldName}
            title={title}
            isDisabled={!isEditMode}
          />
        ),
      )
    }
    {
      locationFields.map(
        ({ fieldName, title }) => (
          <RequestOrderFormContactsLocation
            key={fieldName}
            fieldName={fieldName}
            title={title}
          />
        ),
      )
    }
  </Grid>
);

export default RequestOrderFormContacts;

import {
  GET_CUSTOMER_BY_ID_REQUEST,
  GET_CUSTOMER_BY_ID_SUCCESS,
  GET_CUSTOMER_BY_ID_FAILURE,
} from './actionTypes';

const initialState = {
  isLoading: false,
  customerData: {
    contractSignerProfile: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
    },
  },
};

const getCustomerReducer = (state = initialState, {
  type, customer, error,
}) => {
  switch (type) {
    case GET_CUSTOMER_BY_ID_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_CUSTOMER_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        customerData: {
          ...customer.data,
        },
      };

    case GET_CUSTOMER_BY_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        error,
      };

    default:
      return state;
  }
};

export default getCustomerReducer;

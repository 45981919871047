import React from 'react';
import { useAppSelector } from 'core/store';
import DashboardPieChart from 'pages/Dashboard/shared/DashboardPieChart';
import { colorsOfCharts } from '@dashboardWirelineRetentionDepartment/constants';

const TotalEngagementsChart = () => {
  const totalEngagementsData = useAppSelector(
    (state) => state.dashboard.totalEngagements.data,
  );
  const isLoading = useAppSelector(
    (state) => state.dashboard.totalEngagements.isLoading,
  );

  return (
    <DashboardPieChart
      colors={colorsOfCharts}
      data={totalEngagementsData}
      isLoading={isLoading}
      innerRadius={60}
      paddingAngle={2}
    />
  );
};

export default TotalEngagementsChart;

import { useAppSelector } from 'core/store';
// eslint-disable-next-line no-unused-vars
import { UserTeamRoleName } from 'pages/UserManagement/UserManagement.models';

const useIsUserTeamRoleName = (roleName: UserTeamRoleName) => {
  const userProfileData = useAppSelector((state) => state.userProfile.data);

  const isDepartmentMember = userProfileData.teamRoles.some(
    (teamRole) => teamRole.name === roleName,
  );

  return Boolean(isDepartmentMember);
};

export default useIsUserTeamRoleName;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { contractBundles } from 'core/api/sales.api/sales.api';
// eslint-disable-next-line no-unused-vars
import { IContractBundles } from '@EngagementsSowData/salesSow.model';

const contractBundlesAction = createAsyncThunk(
  'contractBundles',
  async (params: IContractBundles, { rejectWithValue }) => {
    try {
      await contractBundles(params);
    } catch (error) {
      return rejectWithValue(error);
    }
  });

export default contractBundlesAction;

import { netsparkAxios } from 'core/services/HTTPService';
import { IChartItem } from 'core/models/Recharts.models';
import { getBillingEngagementStatusChartUrl } from 'pages/Dashboard/api/apiUrls';
// eslint-disable-next-line no-unused-vars
import { TPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';

const getBillingEngagementStatus = (signal: AbortSignal, params: TPeriod) => netsparkAxios
  .get<IChartItem[]>(getBillingEngagementStatusChartUrl, { signal, params });

export default getBillingEngagementStatus;

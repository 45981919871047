import { useMemo } from 'react';
import isNil from 'lodash/isNil';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  type MRT_ColumnFiltersState as ColumnFiltersState,
  type MRT_PaginationState as PaginationState,
  type MRT_ColumnOrderState as ColumnOrderState,
} from 'material-react-table';
import {
  firstPage, defaultPageSize as defaultPageSizeConstant, pageSizeFieldName, sortFieldName,
  searchFieldName, pageFieldName, fieldFieldName, filtersList, orderList,
} from 'shared/Table/constants';
import { ColumnSort } from 'shared/Table/Table.models';
import { getInitialParameter } from 'shared/Table/hooks/useTableSearchParamsUtilities';

interface ITableSearchParamsReturn {
  setParamsToUrlAndLocalStorage: (
      pageIndex: number, pageSize: string | number,
      sort?: ColumnSort,
      globalFilter?: string, columnFilters? : ColumnFiltersState, columnOrder? :ColumnOrderState,
  ) => void
  defaultPagination: PaginationState,
  initialSort: ColumnSort,
  initialSearchValue: string,
  initialFilters: ColumnFiltersState | null
  initialOrder: ColumnOrderState| null
}

const useTableSearchParams = (
  id: string,
  defaultSort?: ColumnSort,
  defaultPage = firstPage,
  defaultPageSize = defaultPageSizeConstant,
) : ITableSearchParamsReturn => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();

  const setParamsToUrlAndLocalStorage = (
    pageIndex, pageSize, sort,
    globalFilter, columnFilters, columnOrder,
  ) => {
    if (
      !pageIndex && !pageSize && !sort
      && !globalFilter && !columnFilters && !columnOrder
    ) {
      return;
    }

    const newSearchParams = new URLSearchParams();

    if (pageIndex) {
      newSearchParams.set(pageFieldName, pageIndex);
    }
    if (pageSize) {
      newSearchParams.set(pageSizeFieldName, pageSize);
    }
    if (sort) {
      newSearchParams.set(sortFieldName, sort.desc);
      newSearchParams.set(fieldFieldName, sort.id);
    }
    if (globalFilter) {
      newSearchParams.set(searchFieldName, globalFilter);
    }
    if (columnFilters && columnFilters?.length) {
      newSearchParams.set(filtersList, JSON.stringify(columnFilters));
    }
    if (columnOrder) {
      newSearchParams.set(orderList, JSON.stringify(columnOrder));
    }

    localStorage.setItem(id, newSearchParams.toString());

    // will set query params if user stays at the same page
    if (pathname === window.location.pathname) {
      setSearchParams(newSearchParams);
    }
  };

  const defaultPagination = useMemo(() => ({
    pageIndex: Number(searchParams.get(pageFieldName)) || defaultPage,
    pageSize: Number(searchParams.get(pageSizeFieldName)) || defaultPageSize,
  }), []);

  const initialSort = useMemo(() => {
    const desc = getInitialParameter({ tableId: id, paramName: sortFieldName, searchParams });

    if (isNil(desc)) {
      return defaultSort;
    }

    return {
      id: searchParams.get(fieldFieldName),
      desc,
    };
  }, []);

  const initialSearchValue = useMemo(() => (
    getInitialParameter({ tableId: id, paramName: searchFieldName, searchParams })
  ), []);

  const initialFilters = useMemo(() => (
    getInitialParameter({ tableId: id, paramName: filtersList, searchParams })
  ), []);

  const initialOrder = useMemo(() => (
    getInitialParameter({ tableId: id, paramName: orderList, searchParams })
  ), []);

  return <ITableSearchParamsReturn>{
    setParamsToUrlAndLocalStorage,
    defaultPagination,
    initialSort,
    initialSearchValue,
    initialFilters,
    initialOrder,
  };
};

export default useTableSearchParams;

/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { putEscalationSupport, getEscalationSupportItem } from 'core/api/sales.api';
// eslint-disable-next-line no-unused-vars
import { IPutEscalationSupportParams } from '@EngagementsSowData/salesSow.model';

export const putEscalationSupportAction = createAsyncThunk(
  'putEscalationSupportAction',
  async (
    { id, params }: { id: string | number, params: IPutEscalationSupportParams },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await putEscalationSupport(id, params);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  });

export interface IState {
  result: object;
  isLoading: boolean;
}

const initialState: IState = {
  result: {},
  isLoading: false,
};

export const getEscalationSupportItemAction = createAsyncThunk(
  'getEscalationSupportItemAction',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const { data } = await getEscalationSupportItem(id);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  });

const escalationSupportItemSlice = createSlice({
  name: 'escalationSupportItem',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        getEscalationSupportItemAction.pending, (state) => {
          state.isLoading = true;
        })
      .addCase(
        getEscalationSupportItemAction.fulfilled, (state, action) => {
          state.isLoading = false;
          state.result = action.payload;
        })
      .addCase(getEscalationSupportItemAction.rejected, (state) => {
        state.isLoading = false;
        state.result = {};
      });
  },
});

export const escalationSupportItemReducer = escalationSupportItemSlice.reducer;

import React from 'react';
import { Outlet } from 'react-router-dom';
import { dashboardRoutes } from 'pages/Dashboard/routes';
import accessNames from 'core/routing/accessRoutesNames';
import TabsRouting from 'shared/Tabs/TabsRouting/TabsRouting';
import { useAvailableSubRoutes } from 'core/routing/useAvailableRoutes';

const BusinessDirections = () => {
  const availableDashboardRoutes = useAvailableSubRoutes(dashboardRoutes, [accessNames.dashboard]);

  return (
    <>
      <TabsRouting routes={availableDashboardRoutes} />
      <Outlet />
    </>
  );
};

export default BusinessDirections;

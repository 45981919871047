export enum BoolEnum {
  YES = 'YES',
  NO = 'NO',
}

export enum ROLE_STATUS {
  FUTURE = 'FUTURE',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  RESOLVED_PENDING_COMPLETION = 'RESOLVED_PENDING_COMPLETION',
  PENDING_CANCELLATION = 'PENDING_CANCELLATION',
}

export enum COMMISSION_TYPE {
  NEW = 'NEW',
  RENEWAL = 'RENEWAL',
  RE_ORDER = 'RE_ORDER',
  DOWNGRADE = 'DOWNGRADE',
  UPGRADE = 'UPGRADE',
  CANCELED = 'CANCELED',
}

export interface ITableColumnMetaItem {
  // eslint-disable-next-line no-restricted-globals
  name: string,
  label: string,
}

type IOrderDataFieldValue = unknown | {[key: string]: IOrderDataFieldValue}
export interface IParsedOrderData {
  product?: string
  pmEngagementType?: string
  [key: string]: IOrderDataFieldValue
}

export interface IOrderData {
  [key: string]: {
    editable: boolean
    value: IOrderDataFieldValue
  }
}

const customers = 'customers';
const staff = 'staff';
const crm = 'crm';
const users = 'users';
const teams = 'teams';
const locations = 'locations';
const engagements = 'engagements';
const draft = 'draft';
const sendLoa = 'send-loa';
export const comments = 'comments';
const attachments = 'attachments';
const bulkUpload = 'bulk-upload';
const teamRoles = 'team-roles';
const insideSalesRequest = 'inside-sales-request';
const setInsideSalesManager = 'set_inside_sales_manager';
const cancelRequest = 'cancel_request';
const scopeDescription = 'scope-description';
const seller = 'seller';

export const usersPath = `${staff}/${users}/`;
export const teamsPath = `${staff}/${teams}/`;
export const teamRolesPath = `${staff}/${teamRoles}/`;
export const customersPath = `${crm}/${customers}/`;
export const customersLocationsPath = `${locations}/`;

export const engagementsPath = `${crm}/${engagements}/`;

export const insideSalesRequestPath = `${crm}/${insideSalesRequest}/`;

export const getEngagementsScopeDescriptionUrl = (id, engagementId) => `${engagementsPath}${engagementId}/${scopeDescription}/${id}/`;

export const getCreateEngagementsScopeDescriptionUrl = (engagementId: string | number) => `${engagementsPath}${engagementId}/${scopeDescription}/`;

export const getInsideSalesRequestListItem = (id: number | string) => (
  `${insideSalesRequestPath}${id}/`
);

export const getInsideSalesAttachmentsUrl = (
  insideSalesRequestId: string | number,
) => `${insideSalesRequestPath}${insideSalesRequestId}/${attachments}/`;

export const getInsideSalesAttachmentsItemUrl = (
  insideSalesRequestId: string | number,
  attachmentId: string | number,
) => `${getInsideSalesAttachmentsUrl(insideSalesRequestId)}${attachmentId}/`;

export const getSetInsideSalesManagerUrl = (id: number | string) => `${insideSalesRequestPath}${id}/${setInsideSalesManager}/`;
export const getPostInsideSalesCancelRequestUrl = (id: number | string) => `${insideSalesRequestPath}${id}/${cancelRequest}/`;

export const draftPath = `${draft}/`;
export const sendLoaPath = `${sendLoa}/`;

export const getCustomerByIdUrl = (customerId) => `/${crm}/${customers}/${customerId}/`;

export const getCustomerLocationsUrl = (customerId) => `${getCustomerByIdUrl(customerId)}${locations}/`;

export const getCustomerEditLocationsUrl = (customerId, locationId) => `${getCustomerLocationsUrl(customerId)}${locationId}/`;

export const getBulkUploadCustomerLocationUrl = (customerId) => `${getCustomerLocationsUrl(customerId)}${bulkUpload}/`;

export const downloadBulkLocationUrl = '/crm/location-bulk-upload-template/';

export const getCustomerCommentsUrl = (customerId: number | string) => `${getCustomerByIdUrl(customerId)}${comments}/`;

export const getCustomerEditCommentsUrl = (customerId: number | string, commentId: number | string) => `${getCustomerCommentsUrl(customerId)}${commentId}/`;

export const getCustomerAttachmentsUrl = (customerId: number | string) => `${getCustomerByIdUrl(customerId)}${attachments}/`;

export const getCustomerEditAttachmentsUrl = (customerId: number | string, attachmentId: number | string) => `${getCustomerAttachmentsUrl(customerId)}${attachmentId}/`;

export const getEngagementCommentsUrl = (engagementId: number | string) => `${engagementsPath}${engagementId}/${comments}/`;

export const getEngagementEditCommentsUrl = (
  engagementId: number | string,
  commentId: number | string,
) => `${getEngagementCommentsUrl(engagementId)}${commentId}/`;

export const getEngagementAttachmentsUrl = (engagementId: number | string) => `${engagementsPath}${engagementId}/${attachments}/`;

export const getEngagementEditAttachmentsUrl = (
  engagementId: number | string,
  attachmentId: number | string,
) => `${getEngagementAttachmentsUrl(engagementId)}${attachmentId}/`;

export const getUpdateCustomerSellerUrl = (id: number | string) => `${customersPath}${id}/${seller}/`;

/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line no-unused-vars
import { TPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';
import getEscalationSupportEscalationType
  from '@dashboardEscalationSupport/EscalationSupportCharts/Charts/EscalationType/GetEscalationSupportEscalationType/api.service';
import { IEscalationSupportTypeItem } from '@dashboardEscalationSupport/EscalationSupportCharts/models';

export interface IState {
  data: IEscalationSupportTypeItem[];
  isLoading: boolean;
  error: object | null;
}

const initialState: IState = {
  data: [],
  isLoading: false,
  error: null,
};

export const getEscalationSupportEscalationTypeAction = createAsyncThunk(
  'getEscalationSupportEscalationTypeAction',
  async ({ controller, params }: { controller: AbortController, params: TPeriod },
    { rejectWithValue }) => {
    try {
      const { signal } = controller;
      const { data } = await getEscalationSupportEscalationType(signal, params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const EscalationSupportEscalationTypeSlice = createSlice({
  name: 'getEscalationSupportEscalationType',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getEscalationSupportEscalationTypeAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEscalationSupportEscalationTypeAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(getEscalationSupportEscalationTypeAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
        state.data = [];
      });
  },
});

export const getEscalationSupportEscalationTypeReducer = EscalationSupportEscalationTypeSlice
  .reducer;

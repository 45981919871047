import {
  REMOVE_ENGAGEMENTS_COMMENT_REQUEST,
  REMOVE_ENGAGEMENTS_COMMENT_SUCCESS,
  REMOVE_ENGAGEMENTS_COMMENT_FAILURE,
} from 'pages/WirelineEngagements/WirelineEngagementsComments/RemoveComment/actionTypes';

export const removeCommentRequest = (commentId) => (
  { type: REMOVE_ENGAGEMENTS_COMMENT_REQUEST, commentId });

export const removeCommentSuccess = (commentId) => (
  { type: REMOVE_ENGAGEMENTS_COMMENT_SUCCESS, commentId });

export const removeCommentFailure = (commentId, error) => ({
  type: REMOVE_ENGAGEMENTS_COMMENT_FAILURE,
  commentId,
  error,
});

import React from 'react';
import { BookingStatusCellClasses } from 'shared/Table/shared/BookingStatusCell/model';

import './BookingStatusCell.scss';

interface IEscalationSupportStatus {
  className: BookingStatusCellClasses
  children: string
}

const BookingStatusCell: React.FC<IEscalationSupportStatus> = (
  { className, children },
) => (
  <div className={`table__booking-status ${className}`}>
    {children}
  </div>
);

export default BookingStatusCell;

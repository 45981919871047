import React from 'react';
import { Button } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useAppSelector } from 'core/store';
import { useTableSelectedRows } from 'shared/Table/hooks/useTableSelectedRows';
import { IInsideSalesListItem } from 'pages/InsideSales/model';

interface IEngagementAcceptedButtonProps {
  setIsInsideSalesAcceptedModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const InsideSalesAcceptedButton: React.FC<
  IEngagementAcceptedButtonProps
> = ({ setIsInsideSalesAcceptedModalOpen }) => {
  const rows = useAppSelector((state) => state.insideSales.list.results);

  const selectedRowsData = useTableSelectedRows<IInsideSalesListItem>(
    { rows },
  );

  const onOpen = () => {
    setIsInsideSalesAcceptedModalOpen(true);
  };

  return (
    <Button
      className="inside-sales-accepted__button"
      disabled={isEmpty(selectedRowsData)}
      onClick={onOpen}
      data-testid="inside-sales-accepted-button-test-id"
    >
      <CheckCircleOutlineIcon className="inside-sales-accepted__button__icon" />
      <div className="inside-sales-accepted__button__text">
        request accepted
      </div>
    </Button>
  );
};

export default InsideSalesAcceptedButton;

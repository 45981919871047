import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  LabelList,
} from 'recharts';
import { useMediaQuery, useTheme } from '@mui/material';
import { chartNoData, cursorColor } from 'pages/Dashboard/shared/constants';
import styles from 'assets/styles/_resources.scss';
import ChartTooltip from 'pages/Dashboard/shared/ChartTooltip';
import VerticalBarChartSkeleton from 'pages/Dashboard/shared/VerticalBarChart/VerticalBarChartSkeleton';

interface IVerticalBarChartContentProps {
  data: IVerticalChartItem[];
  isLoading: boolean;
  colors: string[];
}

interface ICustomLabel {
  x: number;
  y: number;
  width: number;
  height: number;
  value: number;
}

export interface IVerticalChartItem {
  // eslint-disable-next-line no-restricted-globals
  name: string;
  value: Record<string, number>;
}

const MIN_LABEL_WIDTH = 30;
const HALF_BAR = 2;

const renderVerticalBarLabel = ({
  x,
  y,
  width,
  height,
  value,
}: ICustomLabel) => {
  if (value === 0 || width < MIN_LABEL_WIDTH) return null;

  const xCalc = x + width / HALF_BAR;
  const yCalc = y + height / HALF_BAR;

  return (
    <text
      x={xCalc}
      y={yCalc}
      fill={styles.darkColor500}
      textAnchor="middle"
      dominantBaseline="middle"
      fontSize={10}
    >
      {value}
    </text>
  );
};

const CustomYAxisTick = ({
  x,
  y,
  payload,
  width,
}) => {
  const calcX = x - (width + 10);
  const calcY = y - 20;

  return (
    <foreignObject x={calcX} y={calcY} width={width} height="40">
      <div
        xmlns="http://www.w3.org/1999/xhtml"
        className="multi-line-ellipsis"
      >
        {payload.value}
      </div>
    </foreignObject>
  );
};

const VerticalBarChartContent: React.FC<IVerticalBarChartContentProps> = ({
  isLoading,
  data,
  colors,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const barChartMargin = isMobile
    ? {
      top: -12, right: 30, left: 10, bottom: -10,
    }
    : {
      top: -12, right: 30, left: 20, bottom: 20,
    };

  if (isLoading) {
    return <VerticalBarChartSkeleton />;
  }

  if (!data.length) {
    return chartNoData;
  }

  const valueKeys = Object.keys(data[0]?.value || {});
  const tickStyle = { fill: styles.darkColor500, fontSize: '11px' };

  const calculateSum = (values: Record<string, number>) => Object.values(values)
    .reduce((sum, val) => sum + (val || 0), 0);

  const getColor = (index: number) => colors[index % colors.length];

  return (
    <ResponsiveContainer width="99%" height={320}>
      <BarChart
        data={data}
        layout="vertical"
        margin={barChartMargin}
      >
        <CartesianGrid horizontal={false} strokeDasharray="3 3" stroke={styles.lightColor200} />
        <XAxis
          tick={tickStyle}
          interval="preserveStartEnd"
          tickLine={false}
          orientation="top"
          stroke={styles.lightColor100}
          type="number"
        />
        <YAxis
          tick={<CustomYAxisTick />}
          stroke={styles.lightColor100}
          dataKey="name"
          type="category"
          width={150}
          tickFormatter={(name) => name}
        />
        <Tooltip
          cursor={{ fill: cursorColor }}
          content={<ChartTooltip />}
          isVerticalTooltip
        />
        <Legend
          iconType="circle"
          iconSize={8}
        />
        {valueKeys.map((key, index) => (
          <Bar
            key={key}
            dataKey={`value.${key}`}
            name={key}
            stackId="a"
            maxBarSize={15}
            fill={getColor(index)}
          >
            <LabelList dataKey={`value.${key}`} content={renderVerticalBarLabel} />
            {data.map((entry) => (
              <Cell
                key={entry.name}
                fill={getColor(index)}
              />
            ))}
          </Bar>
        ))}

        <Bar
          dataKey={(entry) => calculateSum(entry.value)}
          fill="transparent"
          isAnimationActive={false}
        >
          <LabelList
            position="right"
            content={({
              x,
              y,
              width,
              height,
              value,
            }: any) => {
              if (value === 0) return null;

              const xOffset = 5;
              const xCalc = x + width + xOffset;
              const yCalc = y - (height / HALF_BAR);

              return (
                <text
                  x={xCalc}
                  y={yCalc}
                  fill={styles.darkColor500}
                  fontSize={12}
                >
                  {value}
                </text>
              );
            }}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default VerticalBarChartContent;

import React, { memo, ReactElement } from 'react';
import { Typography, IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';

interface INavigateBackToolbarProps {
  title: string
  path: string
  rightContent?: ReactElement | JSX.Element | undefined
}

const NavigateBackToolbar: React.FC<INavigateBackToolbarProps> = ({
  path,
  title,
  rightContent,
}) => {
  const navigate = useNavigate();

  const onGoBack = () => {
    navigate(path);
  };

  return (
    <div className="navigate-back-toolbar">
      <div className="navigate-back-toolbar__wrapper">
        <div className="navigate-back-toolbar__wrapper__left-content">
          <IconButton
            size="medium"
            color="primary"
            onClick={onGoBack}
            className="navigate-back-toolbar__wrapper__left-content-button"
          >
            <ArrowBackIosIcon
              color="action"
              fontSize="small"
            />
          </IconButton>
          <Typography
            className="navigate-back-toolbar__wrapper__left-content-title"
            variant="h6"
            component="h2"
          >
            {title}
          </Typography>
        </div>
        <div>
          {rightContent}
        </div>
      </div>
    </div>
  );
};

NavigateBackToolbar.defaultProps = {
  rightContent: undefined,
};

export default memo(NavigateBackToolbar);

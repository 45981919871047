import { IOption } from 'shared/FormikField/FormikField.model';
// eslint-disable-next-line no-unused-vars
import { IUser, IUserWithTeamRoles, IUserListItem } from 'pages/UserManagement/UserManagement.models';

export const getOptionsFromUser = (
  users: IUser[] | IUserWithTeamRoles[] | IUserListItem[],
  valueField = 'email',
) => users.map((item) => ({
  label: `${item.firstName} ${item.lastName}`,
  value: item[valueField],
} as IOption));

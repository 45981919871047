import {
  GET_CUSTOMERS_ATTACHMENTS_REQUEST,
  GET_CUSTOMERS_ATTACHMENTS_SUCCESS,
  GET_CUSTOMERS_ATTACHMENTS_FAILURE,
} from 'pages/Customers/CustomersAttachments/GetCustomersAttachments/actionTypes';

export const getCustomersAttachmentsRequest = () => (
  { type: GET_CUSTOMERS_ATTACHMENTS_REQUEST });

export const getCustomersAttachmentsSuccess = (attachments) => (
  { type: GET_CUSTOMERS_ATTACHMENTS_SUCCESS, attachments });

export const getCustomersAttachmentsFailure = (error) => (
  { type: GET_CUSTOMERS_ATTACHMENTS_FAILURE, error });

import React from 'react';
import cx from 'classnames';
import { useParams } from 'react-router-dom';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import CustomersLocations from 'pages/Customers/CustomersLocations';
import { useActionsPermissions } from 'core/hooks/useActionsPermissions';
import { ACTIONS_WIRELINE_ENGAGEMENTS_PATHS } from 'core/models/actionsPermissions';
import WirelineEngagementsComments from 'pages/WirelineEngagements/WirelineEngagementsComments';
import WirelineEngagementsAttachments from 'pages/WirelineEngagements/WirelineEngagementsAttachments';
import { useWirelineEngagementsCreateContext } from 'pages/WirelineEngagements/WirelineEngagementsCreate/WirelineEngagementsCreateContext';

const {
  COMMENT_GET,
  LOCATION_GET,
  ATTACHMENT_GET,
  COMMENT_CREATE,
  ATTACHMENT_CREATE,
} = ACTIONS_WIRELINE_ENGAGEMENTS_PATHS;

const actionsPaths = {
  isAvailableGetComments: COMMENT_GET,
  isAvailableGetLocations: LOCATION_GET,
  isAvailableCreateComment: COMMENT_CREATE,
  isAvailableGetAttachments: ATTACHMENT_GET,
  isAvailableCreateAttachment: ATTACHMENT_CREATE,
};

const WirelineEngagementsWidgets = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { isInitializing, initialCustomerId } = useWirelineEngagementsCreateContext();
  const { wirelineEngagementId } = useParams();

  const {
    isAvailableGetComments,
    isAvailableGetLocations,
    isAvailableCreateComment,
    isAvailableGetAttachments,
    isAvailableCreateAttachment,
  } = useActionsPermissions(actionsPaths);

  const hasAvailableWidgets = isAvailableGetComments
   || isAvailableGetAttachments
   || isAvailableGetLocations;

  if (isInitializing || !wirelineEngagementId || !initialCustomerId || !hasAvailableWidgets) {
    return null;
  }

  return (
    <Box className={cx('engagements-widgets', { 'engagements-widgets__mobile': isMobile })}>
      {
        isAvailableGetComments && (
          <WirelineEngagementsComments
            engagementId={wirelineEngagementId}
            isAvailableCreate={isAvailableCreateComment}
          />
        )
      }
      {
        isAvailableGetAttachments && (
          <WirelineEngagementsAttachments
            engagementId={wirelineEngagementId}
            isAvailableCreate={isAvailableCreateAttachment}
          />
        )
      }
      {
        isAvailableGetLocations && (
          <CustomersLocations
            customerId={initialCustomerId}
          />
        )
      }
    </Box>
  );
};

export default WirelineEngagementsWidgets;

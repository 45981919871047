import React from 'react';
import { Grid } from '@mui/material';
import FormikTextField from 'shared/FormikField/FormikTextField/FormikTextField';
import FormikSelectField from 'shared/FormikField/FormikSelectField/FormikSelectField';
import { USStatesOptions } from 'core/models/USStatesConstants';
import FormSection from './FormSection/FormSection';

const CustomersCreateFormBillingInformation = () => (
  <FormSection title="billing information">
    <Grid
      container
      item
      rowSpacing={3}
    >
      <FormikTextField
        label="Address 1"
        fieldName="billingInfo.address"
        required
        fullWidth
      />
      <FormikTextField
        label="Suite"
        fieldName="billingInfo.suite"
        fullWidth
      />
      <FormikTextField
        label="City"
        fieldName="billingInfo.city"
        required
        fullWidth
      />
      <FormikSelectField
        label="State"
        fieldName="billingInfo.state"
        options={USStatesOptions}
        required
        fullWidth
      />
      <FormikTextField
        label="Zip"
        fieldName="billingInfo.zipCode"
        required
        fullWidth
      />
    </Grid>
  </FormSection>
);

export default CustomersCreateFormBillingInformation;

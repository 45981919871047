import {
  GET_ESCALATION_SUPPORT_DATA_REQUEST,
  GET_ESCALATION_SUPPORT_DATA_SUCCESS,
  GET_ESCALATION_SUPPORT_DATA_FAILURE,
} from './actionsTypes';

export const getEscalationSupportDataRequest = () => (
  { type: GET_ESCALATION_SUPPORT_DATA_REQUEST }
);

export const getEscalationSupportDataSuccess = (data) => ({
  type: GET_ESCALATION_SUPPORT_DATA_SUCCESS,
  data,
});

export const getEscalationSupportDataFailure = (error) => ({
  type: GET_ESCALATION_SUPPORT_DATA_FAILURE,
  error,
});

import {
  GET_RETENTION_BOOKING_DATA_REQUEST,
  GET_RETENTION_BOOKING_DATA_SUCCESS,
  GET_RETENTION_BOOKING_DATA_FAILURE,
} from './actionsTypes';

export const getRetentionBookingDataRequest = () => (
  { type: GET_RETENTION_BOOKING_DATA_REQUEST }
);

export const getRetentionBookingDataSuccess = (data) => ({
  type: GET_RETENTION_BOOKING_DATA_SUCCESS,
  data,
});

export const getRetentionBookingDataFailure = (error) => ({
  type: GET_RETENTION_BOOKING_DATA_FAILURE,
  error,
});

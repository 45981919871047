import { removeCustomerAttachment } from 'core/api/customer.api';
import { IEditAttachmentError } from 'shared/widgets/Attachments/Attachments.model';
import {
  removeCustomersAttachmentFailure,
  removeCustomersAttachmentRequest,
  removeCustomersAttachmentSuccess,
} from 'pages/Customers/CustomersAttachments/RemoveCustomersAttachment/actionsCreators';

const removeCustomersAttachmentAction = (
  customerId: number | string,
  attachmentId: number | string,
) => async (dispatch) => {
  try {
    dispatch(removeCustomersAttachmentRequest(attachmentId));
    await removeCustomerAttachment(customerId, attachmentId);
    dispatch(removeCustomersAttachmentSuccess(attachmentId));
  } catch (error) {
    const { response: { data: { text, detail, name } } } = error as IEditAttachmentError;
    dispatch(removeCustomersAttachmentFailure(attachmentId, detail || text || name));
    throw error;
  }
};
export default removeCustomersAttachmentAction;

import React from 'react';
import { Button } from '@mui/material';
import { useFormikContext } from 'formik';

interface ILocationFormButtonsProps {
    handleCancel: ()=> void;
    isEditLocation: boolean;
}
const LocationFormButtons: React.FC<ILocationFormButtonsProps> = (
  { handleCancel, isEditLocation },
) => {
  const { submitForm } = useFormikContext();

  const submitButtonName = isEditLocation ? 'Change' : 'Add';

  return (
    <div className="locations-form__btn-wrap">
      <Button
        color="primary"
        className="locations-form__btn locations-form__btn-add"
        variant="contained"
        onClick={submitForm}
      >
        {submitButtonName}
      </Button>
      <Button
        className="locations-form__btn locations-form__btn-cancel"
        onClick={handleCancel}
        variant="outlined"
        color="primary"
      >
        Cancel
      </Button>
    </div>

  );
};

export default LocationFormButtons;

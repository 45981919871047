/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getSalesSowMeta } from 'core/api/sales.api';
import { ITableColumnMetaItem } from 'pages/WirelineEngagements/WirelineEngagementsTables/wirelineEngagementsTables.model';

export interface ISalesSowMetaState {
  isLoading: boolean;
  meta: ITableColumnMetaItem[];
  error: any;
}

const initialState: ISalesSowMetaState = {
  isLoading: true,
  meta: [],
  error: null,
};

export const getSalesSowMetaAction = createAsyncThunk(
  'getSalesSowMetaAction',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getSalesSowMeta();
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const SalesSowMeta = createSlice({
  name: 'getSalesSowMeta',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getSalesSowMetaAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSalesSowMetaAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.meta = action.payload;
        state.error = null;
      })
      .addCase(getSalesSowMetaAction.rejected, (state, action) => {
        state.meta = [];
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export const { clear: clearSalesSowMeta } = SalesSowMeta.actions;
export const getSalesSowMetaReducer = SalesSowMeta.reducer;

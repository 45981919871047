import React, { useState, useMemo, useEffect } from 'react';
import get from 'lodash/get';
import { useParams } from 'react-router-dom';
import Loader from 'shared/Loader';
import ContentShrink from 'shared/ContentShrink';
import { tableIds } from 'shared/Table/constants';
import { TableProvider } from 'shared/Table/TableContext';
import { useAppDispatch, useAppSelector } from 'core/store';
import { parseOpenapiSchema } from 'core/utilities/openapiSchema';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { getWidthToReduce } from 'pages/WirelineEngagements/shared/utilites';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import ActiveNumbersTable from '@EngagementsActiveNumbers/ActiveNumbersTable';
// eslint-disable-next-line no-unused-vars
import { IRequestParams } from '@EngagementsActiveNumbers/activeNumbers.model';
import { useUserPermissions } from '@EngagementsActiveNumbers/useUserPermissions';
import { clearSalesActiveNumbersData } from 'core/actions/Sales/GetSalesActiveNumbers';
import getSalesActiveNumbersAction from 'core/actions/Sales/GetSalesActiveNumbers/actions';
import { getColumnsFromMetaAndShchema } from 'core/utilities/getColumnsFromMetaAndShchema';
import BackToEditEngagementButton from 'pages/WirelineEngagements/shared/BackToEditEngagementButton';
import { getSalesActiveNumbersMetaAction, clearSalesActiveNumbersMetaAction } from 'core/actions/Sales/GetSalesActiveNumbersMeta';
import { isOrderNonAttForRoles, isActiveNumbersItemCanceled } from 'pages/WirelineEngagements/WirelineEngagementsTables/utilities';
import {
  emptyOrderNumberCondition,
  isOrderRequestTypesNotMatchRoles,
  emptyInitiatedEngagementDateForBS,
} from 'pages/WirelineEngagements/WirelineEngagementsTables/WirelineEngagementsActiveNumbers/utilities';
import {
  columns,
  activeNumbersTitle,
  engagementAcceptedPMDateKey,
  engagementAcceptedBSDateKey,
  dynamicActiveNumbersProductSchemaName,
} from '@EngagementsActiveNumbers/constants';
import 'pages/WirelineEngagements/WirelineEngagementsTables/WirelineEngagementsTables.scss';
import './WirelineEngagementsActiveNumbers.scss';

const WirelineEngagementsActiveNumbersContainer = () => {
  const dispatch = useAppDispatch();
  const { customerId } = useParams();
  const [locationId, setLocationId] = useState<number | null>(null);

  const openApiSchemas = useAppSelector((state) => state.openapiSchemas.schemas);
  const isOpenapiSchemasLoading = (useAppSelector((state) => state.openapiSchemas.isLoading));
  const metaColumns = useAppSelector((
    state,
  ) => state.wirelineEngagements.salesActiveNumbersMeta.data);
  const isLoadingMeta = useAppSelector((
    state,
  ) => state.wirelineEngagements.salesActiveNumbersMeta.isLoading);

  const retrieveSchema = useMemo(() => parseOpenapiSchema(
    openApiSchemas,
    dynamicActiveNumbersProductSchemaName,
  ), [openApiSchemas],
  );

  const activeNumbersColumns = useMemo(
    () => getColumnsFromMetaAndShchema({ columns, metaColumns, retrieveSchema }),
    [retrieveSchema, metaColumns]);

  const { activeRowMode, secondarySidebarMode } = useTableActiveRowContext();
  const { isEditMode, isViewMode, isCommentMode } = getTableRowModeBoolean(activeRowMode);

  const {
    isAdmin,
    isProjectManager,
    isEditingAvailable,
    isBillingSpecialist,
    isCancelOrderAvailable,
    isProjectManagerNotDepartmentHead,
    isEngagementAcceptedDropdownAvalable,
  } = useUserPermissions();

  const enableRowSelection = (
    isEditingAvailable && ((row) => {
      const isCanceled = isActiveNumbersItemCanceled(row.original);
      const isNonAttTSDForRoles = isOrderNonAttForRoles(
        row.original, [isBillingSpecialist, isProjectManager, isAdmin],
      );
      const isRequestTypesNotMatchRoles = isOrderRequestTypesNotMatchRoles({
        row: row.original, isBillingSpecialist,
      });

      const isEmptyInitiatedEngagementDateForBS = emptyInitiatedEngagementDateForBS({
        row: row.original, isBillingSpecialist,
      });

      const idEmptyOrderNumberCondition = emptyOrderNumberCondition(row.original);
      const isEmptyOrderForPM = isProjectManager
        && idEmptyOrderNumberCondition;

      const alreadyAcceptedEngagementForPM = isProjectManagerNotDepartmentHead && get(row, `original.${engagementAcceptedPMDateKey}`);
      const alreadyAcceptedEngagementForBS = isBillingSpecialist && get(row, `original.${engagementAcceptedBSDateKey}`);
      const alreadyAcceptedAllEngagementForAdmin = isEngagementAcceptedDropdownAvalable
        && get(row, `original.${engagementAcceptedPMDateKey}`)
        && get(row, `original.${engagementAcceptedBSDateKey}`);

      return (
        !isCanceled
        && !isEmptyOrderForPM
        && !isNonAttTSDForRoles
        && !isRequestTypesNotMatchRoles
        && !alreadyAcceptedEngagementForPM
        && !alreadyAcceptedEngagementForBS
        && !isEmptyInitiatedEngagementDateForBS
        && !alreadyAcceptedAllEngagementForAdmin
      );
    })
  );

  const isTableShrank = isEditMode || isViewMode || isCommentMode;

  const getSalesActiveNumbersMeta = () => {
    dispatch(getSalesActiveNumbersMetaAction());
  };

  const clearReduxData = () => {
    dispatch(clearSalesActiveNumbersData());
    dispatch(clearSalesActiveNumbersMetaAction());
  };

  useEffect(() => {
    getSalesActiveNumbersMeta();
    return () => {
      clearReduxData();
    };
  }, []);

  if (!customerId) {
    return null;
  }

  const requestParams: IRequestParams = {
    customerId,
    locationId,
  };

  if (isLoadingMeta || isOpenapiSchemasLoading) {
    return <Loader />;
  }

  return (
    <div className="active-numbers">
      <BackToEditEngagementButton title={activeNumbersTitle} />
      <ContentShrink
        isShrinked={isTableShrank}
        widthToReduce={getWidthToReduce(!!secondarySidebarMode)}
      >
        <TableProvider<IRequestParams>
          id={tableIds.activeNumbers}
          columns={activeNumbersColumns}
          getDataAction={getSalesActiveNumbersAction}
          requestParams={requestParams}
          enableEditing={isEditingAvailable || isCancelOrderAvailable}
          enableRowSelection={enableRowSelection}
        >
          <ActiveNumbersTable
            setLocationId={setLocationId}
            locationId={locationId}
          />
        </TableProvider>
      </ContentShrink>
    </div>
  );
};

export default WirelineEngagementsActiveNumbersContainer;

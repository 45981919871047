import { getCustomerLocations } from 'core/api/customer.api';
import { ILocationError } from 'shared/widgets/Locations/Locations.models';
import {
  getLocationsRequest,
  getLocationsSuccess,
  getLocationsFailure,
} from 'pages/Customers/CustomersLocations/GetLocations/actionCreators';

const getLocationsAction = (
  controller: AbortController,
  customerId: string | number,
) => async (dispatch) => {
  try {
    const { signal } = controller;

    dispatch(getLocationsRequest());

    const { data } = await getCustomerLocations(signal, customerId);
    dispatch(getLocationsSuccess(data));
  } catch (error) {
    const { error: { message } } = error as ILocationError;
    dispatch(getLocationsFailure(message));
    throw error;
  }
};

export default getLocationsAction;

import Yup from 'core/utilities/Yup';

const scopeDescriptionSchema = Yup
  .object()
  .shape({
    text: Yup
      .string()
      .required()
      .max(1000, 'Scope Description must be less than 1000 characters'),
  })
  .default({ text: '' });

export default scopeDescriptionSchema;

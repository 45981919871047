import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';

const NoData = ({ icon, title, description }) => (
  <div className="no-data__wrap" data-testid="no-data">
    <div
      className="no-data__icon-wrap"
    >
      {icon}
    </div>
    <Typography variant="h3" component="h3" className="no-data__title">
      {title}
    </Typography>
    <Typography variant="body1" className="no-data__text">
      {description}
    </Typography>
  </div>
);

NoData.propTypes = {
  icon: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
};

NoData.defaultProps = {
  title: 'No data',
  description: '',
};

export default NoData;

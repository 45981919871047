import React from 'react';
import { rowSelectName, rowActionsName } from 'shared/Table/constants';
import ActionEdit from 'shared/Table/Actions/ActionEdit/ActionEdit';
import { TableRowMode } from 'shared/Table/Table.models';

// TODO: Remove when possible
// @deprecated: Do not use it, use renderRowActions & displayColumnDefOptions props instead.
export const getTableDisplayOptions = <T extends object >(
  editRowId: string | number | null | undefined,
  setEditRow:(row: T | null) => void,
  disabledCheckboxRowKey?: string,
) => ({
    muiSelectCheckboxProps: ({ row: { original } }) => ({
      disabled: !!editRowId || (disabledCheckboxRowKey && !!original[disabledCheckboxRowKey]),
    }),
    displayColumnDefOptions: {
      [rowSelectName]: {
        header: '',
      },
      [rowActionsName]: {
        header: '',
        Cell: ({ row: { original } }) => (
          <ActionEdit
            row={original}
            editRowId={editRowId}
            setEditRow={setEditRow}
          />
        ),
      },
    },
  });

export const getTableRowModeBoolean = (tableRowMod: TableRowMode | null | undefined) => {
  const isViewMode = tableRowMod === TableRowMode.VIEW;
  const isEditMode = tableRowMod === TableRowMode.EDIT;
  const isCancelOrderMode = tableRowMod === TableRowMode.CANCEL_ORDER;
  const isEscalationSupportMode = tableRowMod === TableRowMode.ESCALATION_SUPPORT;
  const isCommentMode = tableRowMod === TableRowMode.COMMENT;
  const isDuplicateMode = tableRowMod === TableRowMode.DUPLICATE;
  const isDataMode = tableRowMod === TableRowMode.DATA;

  return {
    isDataMode,
    isViewMode,
    isEditMode,
    isCancelOrderMode,
    isEscalationSupportMode,
    isCommentMode,
    isDuplicateMode,
  };
};

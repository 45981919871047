import React from 'react';
import VerticalBarChartContent, { IVerticalChartItem } from 'pages/Dashboard/shared/VerticalBarChart/VerticalBarChartContent';
import './VerticalBarChart.scss';

interface IVerticalBarChart {
  data: IVerticalChartItem[]
  title: string;
  isLoading?: boolean;
  colors: string[];
}

const VerticalBarChart: React.FC<IVerticalBarChart> = ({
  data,
  title,
  isLoading,
  colors,
}) => (
  <div className="engagements-revenue-chart">
    <h3 className="dashboard-bar-chart__title">
      {
        title
      }
    </h3>
    <VerticalBarChartContent
      data={data}
      isLoading={isLoading as boolean}
      colors={colors}
    />
  </div>
);

VerticalBarChart.defaultProps = {
  isLoading: false,
};

export default VerticalBarChart;

import { IInsideSalesDataItem } from '@dashboardWirelineInsideSalesDepartment/InsideSalesBookingList/InsideSalesBookingList.models';

import {
  GET_INSIDE_SALES_BOOKING_DATA_REQUEST,
  GET_INSIDE_SALES_BOOKING_DATA_SUCCESS,
  GET_INSIDE_SALES_BOOKING_DATA_FAILURE,
} from '@dashboardWirelineInsideSalesDepartment/GetInsideSalesTableData/actionsTypes';

interface IGetBookingDataState {
  isLoading: boolean;
  count: number;
  results: IInsideSalesDataItem[];
  error: object;
}

const initialState: IGetBookingDataState = {
  isLoading: false,
  count: 0,
  results: [],
  error: {},
};

const getInsideSalesBookingDataReducer = (state = initialState, {
  type, data, error,
}) => {
  if (type === GET_INSIDE_SALES_BOOKING_DATA_REQUEST) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (type === GET_INSIDE_SALES_BOOKING_DATA_SUCCESS) {
    return {
      ...state,
      ...data,
      isLoading: false,
    };
  }

  if (type === GET_INSIDE_SALES_BOOKING_DATA_FAILURE) {
    return {
      ...state,
      isLoading: false,
      results: [],
      count: 0,
      error,
    };
  }

  return state;
};
export default getInsideSalesBookingDataReducer;

import React from 'react';
import DashboardPieChart from 'pages/Dashboard/shared/DashboardPieChart';
import { colorsOfInsideSalesCharts } from '@dashboardWirelineInsideSalesDepartment/constants';
import { useAppSelector } from 'core/store';
import { map, startCase, toLower } from 'lodash';

const RequestStatusChart = () => {
  const requestStatusData = useAppSelector((state) => state.dashboard.requestStatus.data);
  const isLoading = useAppSelector((state) => state.dashboard.requestStatus.isLoading);

  const formattedData = map(requestStatusData, (item) => ({
    ...item,
    name: startCase(toLower(item.name)),
  }));

  return (
    <DashboardPieChart
      colors={colorsOfInsideSalesCharts}
      data={formattedData}
      innerRadius={60}
      paddingAngle={2}
      title="Request Status"
      isLoading={isLoading}
    />
  );
};

export default RequestStatusChart;

import { PERIOD_SELECT } from 'shared/PeriodAutocomplete/PeriodAutocomplete.models';

export const periodOptions = [
  {
    value: PERIOD_SELECT.ONE_MONTH,
    label: 'Current month',
  },
  {
    value: PERIOD_SELECT.THREE_MONTHS,
    label: '3 months',
  },
  {
    value: PERIOD_SELECT.SIX_MONTHS,
    label: '6 months',
  },
  {
    value: PERIOD_SELECT.ONE_YEAR,
    label: '1 year',
  },
];

export const dateFormat = 'YYYY-MM-DD';

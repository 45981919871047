import {
  getEscalationSupportDataRequest,
  getEscalationSupportDataSuccess,
  getEscalationSupportDataFailure,
} from '@dashboardEscalationSupport/EscalationSupportTable/GetEscalationSupport/actionsCreators';

import getEscalationSupportData from '@dashboardEscalationSupport/EscalationSupportTable/GetEscalationSupport/api.service';

const getEscalationSupportDataAction = (
  controller: AbortController,
  params: {
    page: number;
    page_size: number;
    search: string;
    ordering?: string | undefined;
  },
) => async (dispatch) => {
  const { signal } = controller;

  try {
    dispatch(getEscalationSupportDataRequest());

    const { data } = await getEscalationSupportData(signal, params);
    dispatch(getEscalationSupportDataSuccess(data));
  } catch (error) {
    dispatch(getEscalationSupportDataFailure(error));
  }
};

export default getEscalationSupportDataAction;

import React from 'react';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import { Grid, TextField, Typography } from '@mui/material';

interface IRequestOrderFormContactsLocationProps {
  fieldName: string
  title: string
}

const RequestOrderFormContactsLocation: React.FC<
  IRequestOrderFormContactsLocationProps
> = ({ fieldName, title }) => {
  const { values } = useFormikContext();

  const address = get(values, `${fieldName}.address`, '');
  const city = get(values, `${fieldName}.city`, '');
  const state = get(values, `${fieldName}.state`, '');
  const zipCode = get(values, `${fieldName}.zipCode`, '');

  const renderValue = `${address}, ${city}, ${state}, ${zipCode}`;

  return (
    <>
      <Grid
        item
        container
        xs={12}
        pb={3}
      >
        <Typography
          className="request-order-form__section-title"
          variant="body1"
          component="p"
        >
          {title}
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        pb={3}
        rowSpacing={3}
      >
        <TextField
          disabled
          value={renderValue}
        />
      </Grid>
    </>
  );
};

export default RequestOrderFormContactsLocation;

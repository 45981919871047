import React, { useState } from 'react';
import { InputAdornment } from '@mui/material';
import { AssignmentReturnedOutlined } from '@mui/icons-material';
import AddButton from 'shared/AddButton';
import LocationForm from 'shared/widgets/Locations/LocationForm';
import LocationList from 'shared/widgets/Locations/LocationsList';
import WidgetTemplate from 'shared/widgets/WidgetTemplate';
import { ICustomerLocation } from 'pages/Customers/Customers.models';
import { IBulkError, IDraftLocation } from 'shared/widgets/Locations/Locations.models';
import styles from 'assets/styles/_resources.scss';

import './Locations.scss';

interface ILocationsProps {
  removeLocation: (id: number) => Promise<void>;
  addLocation: (newLocation: ICustomerLocation) => Promise<void>;
  cancelEditor: () => void;
  editLocation: (locationId: number, editLocation: ICustomerLocation) => Promise<void>;
  bulkUploadLocation: (file: File) => void;
  bulkDownloadLocation: () => void;
  initialLocations?: ICustomerLocation[] | null;
  isLoading?: boolean;
  bulkErrors?: IBulkError[] | null;
}

const Locations: React.FC<ILocationsProps> = ({
  addLocation, editLocation,
  bulkErrors, bulkDownloadLocation,
  isLoading, bulkUploadLocation, initialLocations, removeLocation, cancelEditor,
}) => {
  const [formData, setFormData] = useState<IDraftLocation | null>(null);
  const [isEditMode, setIsEditMode] = useState(false);

  const switchToEditMode = () => {
    setIsEditMode(true);
  };

  const switchToViewMode = () => {
    setFormData(null);
    setIsEditMode(false);
  };

  const handleShowEditLocation = (location) => {
    setFormData(location);
    switchToEditMode();
  };

  const handleAddLocation = async (location) => {
    await addLocation(location);
  };

  const handleEditLocation = async (location, locationId) => {
    await editLocation(locationId, location);
  };

  const addBulkFile = (file) => {
    bulkUploadLocation(file);
  };

  const handleBulkDownloadLocation = async (e) => {
    e.preventDefault();
    await bulkDownloadLocation();
  };

  const handleCancelEditor = () => {
    cancelEditor();
    switchToViewMode();
  };

  const content = isEditMode ? (
    <LocationForm
      location={formData}
      addNewLocation={handleAddLocation}
      editLocation={handleEditLocation}
      handleCancel={handleCancelEditor}
      switchToViewMode={switchToViewMode}
    />
  ) : (
    <LocationList
      locations={initialLocations as ICustomerLocation[]}
      bulkErrors={bulkErrors as IBulkError[]}
      handleShowCreateLocation={switchToEditMode}
      addBulkFile={addBulkFile}
      handleShowEditLocation={handleShowEditLocation}
      handleRemoveLocation={removeLocation}
      clearErrors={cancelEditor}
    />
  );

  const bulkDownloadButton = (
    <AddButton
      onClick={handleBulkDownloadLocation}
      color={styles.secondaryColor50}
    >
      <InputAdornment position="start">
        <AssignmentReturnedOutlined />
      </InputAdornment>
      Bulk form template
    </AddButton>
  );

  return (
    <WidgetTemplate
      title="Locations"
      isLoading={isLoading as boolean}
      className="widget-locations"
      content={content}
      headerButton={bulkDownloadButton}
    />
  );
};

Locations.defaultProps = {
  initialLocations: null,
  isLoading: false,
  bulkErrors: null,
};

export default Locations;

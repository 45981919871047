import React from 'react';

import DashboardPieChart from 'pages/Dashboard/shared/DashboardPieChart';
import { IEngagementsTotalItem } from 'pages/Dashboard/Dashboard.models';
import { colorsOfTotalCharts } from '@dashboardWirelineDepartments/shared/constants';
import { engagementsTotalOrdersMapper } from '@dashboardWirelineDepartments/shared/EngagementsTotalsChart/constants';

interface IEngagementsTotalsChart {
  isLoading: boolean;
  list: IEngagementsTotalItem[];
}

const EngagementsTotalsChart: React.FC<IEngagementsTotalsChart> = ({ list, isLoading }) => {
  const chartData = list.map((item) => ({
    ...item,
    name: engagementsTotalOrdersMapper[item.name],
  }));

  return (
    <DashboardPieChart
      title="Total"
      innerRadius={60}
      paddingAngle={5}
      data={chartData}
      isLoading={isLoading}
      colors={colorsOfTotalCharts}
      className="engagements-totals"
    />
  );
};

export default EngagementsTotalsChart;

// eslint-disable-next-line no-unused-vars
import { IEngagementComment } from 'pages/WirelineEngagements/WirelineEngagements.models';
import {
  clearErrorsComment,
} from 'pages/WirelineEngagements/WirelineEngagementsComments/ClearErrorsComments/actionCreators';

const clearCommentsErrorsAction = (
  comments: IEngagementComment[],
) => async (dispatch) => {
  dispatch(clearErrorsComment(comments));
};

export default clearCommentsErrorsAction;

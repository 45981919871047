import React from 'react';
import { Formik, Form } from 'formik';
import { CancelOrderFormSchema } from 'pages/WirelineEngagements/shared/CancelOrderModalForm/CancelOrderFormSchema';
import { useAppDispatch } from 'core/store';
import { useTableContext } from 'shared/Table/TableContext';
import { TableRowMode } from 'shared/Table/Table.models';
// eslint-disable-next-line no-unused-vars
import { IOrderCancel } from '@EngagementsSowData/salesSow.model';
import CancelOrderModal from 'pages/WirelineEngagements/shared/CancelOrderModalForm/CancelOrderModal';
import postCancelOrderAction from 'pages/WirelineEngagements/shared/CancelOrderModalForm/CancelOrder';

const CancelOrderModalForm = ({ activeRowMode, activeRow, cleanActiveRow }) => {
  const dispatch = useAppDispatch();
  const { getTableData } = useTableContext();

  const cancelOrderRowId = activeRow?.id;
  const isCancelOrderMode = activeRowMode === TableRowMode.CANCEL_ORDER;

  const initialValues = CancelOrderFormSchema.getDefault();

  const onSubmit = async (params: IOrderCancel, { setErrors, resetForm }) => {
    if (!cancelOrderRowId) return;

    // Manipulate cancellationReason here
    const manipulatedParams = {
      ...params,
      text: `Cancellation reason: ${params.text}`,
    };

    await dispatch(postCancelOrderAction({
      id: cancelOrderRowId,
      params: manipulatedParams,
    }))
      .unwrap()
      .then(() => {
        cleanActiveRow();
        getTableData();
        resetForm();
      })
      .catch((e) => {
        setErrors(e?.response?.data);
      });
  };

  if (!cancelOrderRowId || !isCancelOrderMode) {
    return null;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={CancelOrderFormSchema}
    >
      <Form>
        <CancelOrderModal
          activeRow={activeRow}
          cleanActiveRow={cleanActiveRow}
        />
      </Form>
    </Formik>
  );
};

export default CancelOrderModalForm;

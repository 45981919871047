import React from 'react';
import TechnicalOverlayContent from '@dashboardWirelineDepartments/TechnicalOverlayDepartment/TechnicalOverlayDepartmentContent';
import TechnicalOverlayDepartmentProvider from '@dashboardWirelineDepartments/TechnicalOverlayDepartment/TechnicalOverlayDepartmentContext';

const TechnicalOverlay = () => (
  <TechnicalOverlayDepartmentProvider>
    <TechnicalOverlayContent />
  </TechnicalOverlayDepartmentProvider>
);
export default TechnicalOverlay;

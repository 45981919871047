import React from 'react';
import { useFormikContext } from 'formik';
import { useAppSelector } from 'core/store';
import CustomSidebar from 'shared/CustomSidebar';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import EditRowRenewalSowFormFields
  from '@EngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableModals/EditRowRenewalSowSidebar/EditRowRenewalSowFormFields';
import { IRenewalSowDataItem } from '@EngagementsRenewalSowData/renewalSowData.models';
import { getDrawerStyle, getOpenCommentsButton } from 'pages/WirelineEngagements/shared/utilites';

const endpointToGetSchemaName = '/api/sales/renewal-sow/{id}/';

const EditRowRenewalSowForm = () => {
  const { submitForm, setErrors, resetForm } = useFormikContext();

  const {
    activeRowMode, activeRowId, cleanActiveRow,
    setSecondarySidebarMode, secondarySidebarMode,
  } = useTableActiveRowContext<IRenewalSowDataItem>();

  const { isEditMode } = getTableRowModeBoolean(activeRowMode);

  const editRowId = isEditMode ? activeRowId : undefined;

  const isLoadingRenewalSowItem = useAppSelector(
    (state) => state.wirelineEngagements.renewalSowItem.isLoading,
  );

  const closeEditRowSidebar = () => {
    cleanActiveRow();
    setErrors({});
    resetForm();
    setSecondarySidebarMode(null);
  };

  const openCommentsButton = getOpenCommentsButton(setSecondarySidebarMode);
  const drawerStyle = getDrawerStyle(!!secondarySidebarMode);

  return (
    <CustomSidebar
      isOpen={isEditMode}
      onCloseSideBar={closeEditRowSidebar}
      title="Renewal SOW Date Edit"
      onClickSave={submitForm}
      drawerStyle={drawerStyle}
      topAction={openCommentsButton}
    >
      <EditRowRenewalSowFormFields
        editRowId={editRowId}
        endpointToGetSchemaName={endpointToGetSchemaName}
        isLoadingItem={isLoadingRenewalSowItem}
      />
    </CustomSidebar>
  );
};

export default EditRowRenewalSowForm;

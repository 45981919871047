import { createAsyncThunk } from '@reduxjs/toolkit';
import { postSalesOrderActionsProvide } from 'core/api/sales.api/sales.api';

const ProvideOrderAction = createAsyncThunk(
  'ProvideOrderAction',
  async (id: number | string, { rejectWithValue }) => {
    try {
      await postSalesOrderActionsProvide(id);
    } catch (error) {
      return rejectWithValue(error);
    }
  });

export default ProvideOrderAction;

import React from 'react';
import { Grid, Typography } from '@mui/material';
import FormikTextField from 'shared/FormikField/FormikTextField/FormikTextField';
import FormikMobileMaskField from 'shared/FormikField/FormikMobileMaskField/FormikMobileMaskField';

interface IRequestOrderFormIContactInfoItemProps {
  fieldName: string
  title: string
  isDisabled: boolean
}

const RequestOrderFormIContactProfile: React.FC<
  IRequestOrderFormIContactInfoItemProps
> = ({ fieldName, title, isDisabled }) => (
  <>
    <Grid
      item
      container
      xs={12}
      pb={3}
    >
      <Typography
        className="request-order-form__section-title"
        variant="body1"
        component="p"
      >
        {title}
      </Typography>
    </Grid>
    <Grid
      item
      container
      xs={12}
      pb={3}
      rowSpacing={3}
    >
      <FormikTextField
        fieldName={`${fieldName}.firstName`}
        label="First Name"
        disabled={isDisabled}
        isTooltip
      />
      <FormikTextField
        fieldName={`${fieldName}.lastName`}
        label="Last Name"
        disabled={isDisabled}
        isTooltip
      />
      <FormikTextField
        fieldName={`${fieldName}.email`}
        label="Email"
        disabled={isDisabled}
        isTooltip
      />
      <FormikMobileMaskField
        fieldName={`${fieldName}.phoneNumber`}
        label="Phone"
        disabled={isDisabled}
      />
      <FormikTextField
        fieldName={`${fieldName}.title`}
        label="Title"
        disabled={isDisabled}
        isTooltip
      />
    </Grid>
  </>
);

export default RequestOrderFormIContactProfile;

import React from 'react';
import cx from 'classnames';
import { Tooltip, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import './OrderModalItem.scss';

interface IOrderModalItemProps {
  // eslint-disable-next-line no-restricted-globals
  location: string
  product: string
  accepted?: boolean
}

const OrderModalItem: React.FC<IOrderModalItemProps> = ({ location, product, accepted }) => (
  <div className={cx('order-modal-item-wrap', { accepted })}>
    <div className="order-modal-item-adres-wrap">
      <CheckCircleOutlineIcon className={cx('order-modal-item-icon', { accepted })} />
      <Tooltip title={location} placement="top">
        <Typography
          noWrap
          variant="inherit"
          className="order-modal-item-adres"
        >
          {location}
        </Typography>
      </Tooltip>
    </div>
    <Tooltip title={product} placement="top">
      <Typography
        noWrap
        variant="inherit"
        className="order-modal-item-product"
      >
        {product}
      </Typography>
    </Tooltip>
  </div>
);

OrderModalItem.defaultProps = {
  accepted: false,
};

export default OrderModalItem;

import {
  UserTeamRoleName,
  UserTeamRoleTeamAlignment,
  UserTeamRoleTeamDepartment,
} from 'pages/UserManagement/UserManagement.models';

export const formikFieldSelectorUserSelectConfigs = [
  {
    fieldName: 'sellerId',
    label: 'Seller',
    department: UserTeamRoleTeamDepartment.SELLER,
    errorMessage: 'Failed to load sellers',
  },
  {
    fieldName: 'technicalOverlayId',
    label: 'Technical Overlay',
    department: UserTeamRoleTeamDepartment.SELLER,
    alignment: UserTeamRoleTeamAlignment.WIRELINE,
    role: UserTeamRoleName.TECHNICAL_OVERLAY,
    errorMessage: 'Failed to load technical overlays',
  },
  {
    fieldName: 'projectManagerId',
    label: 'Project Manager',
    department: UserTeamRoleTeamDepartment.PROJECT_MANAGER,
    errorMessage: 'Failed to load project managers',
  },
  {
    fieldName: 'billingSpecialistId',
    label: 'Billing Specialist',
    department: UserTeamRoleTeamDepartment.BILLING_SPECIALIST,
    errorMessage: 'Failed to load billing specialists',
  },
  {
    fieldName: 'contractSpecialistId',
    label: 'Contract Specialist',
    department: UserTeamRoleTeamDepartment.CONTRACT_SPECIALIST,
    errorMessage: 'Failed to load contract specialists',
  },
  {
    fieldName: 'retentionManagerId',
    label: 'Retention Manager',
    department: UserTeamRoleTeamDepartment.RETENTION_MANAGER,
    errorMessage: 'Failed to load retention managers',
  },
  {
    fieldName: 'insideSalesManagerEmail',
    label: 'Inside Sales',
    department: UserTeamRoleTeamDepartment.INSIDE_SALES,
    errorMessage: 'Failed to load inside sales',
    selectValueField: 'email',
  },
];

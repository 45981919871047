import React, { useCallback, useMemo } from 'react';
import { useField } from 'formik';
import { debounce } from 'lodash';
import { Button } from '@mui/material';
import { debounceTime } from 'core/constants';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useAppDispatch, useAppSelector } from 'core/store';
import { createProductAction } from 'core/actions/Sales/CreateProduct';
// eslint-disable-next-line no-unused-vars
import { GRID_DIRECTION, IOption } from 'shared/FormikField/FormikField.model';
import FormikAutocompleteField from 'shared/FormikField/FormikAutocompleteField';
import { fields } from 'pages/WirelineEngagements/shared/AddOrderModalForm//constants';
// eslint-disable-next-line no-unused-vars
import { IProduct } from 'pages/WirelineEngagements/shared/AddOrderModalForm/product.model';
import { getOptionsList } from 'pages/WirelineEngagements/shared/AddOrderModalForm/utilites';

interface IAddOrderProductField {
  itemCount: number
  nonATTCategoriesIds: number[]
  getProducts: (itemCount: number) => void
}

const AddOrderProductField: React.FC<IAddOrderProductField> = ({
  itemCount,
  getProducts,
  nonATTCategoriesIds,
}) => {
  const dispatch = useAppDispatch();
  const { showErrorNotifier } = useNotifiers();
  const [{ value: selectedProducts },, { setValue: setProduct }] = useField(`${fields.orders}[${itemCount}][${fields.products}]`);
  const [field,, { setValue: setProductName }] = useField(`${fields.orders}[${itemCount}][${fields.productName}]`);
  const products = useAppSelector((state) => state.products.data);
  const isLoading = useAppSelector((state) => state.products.isLoading);
  const isNoProduct = !field.value.trim();

  const productsOptions = useMemo(
    () => getOptionsList({ list: products }),
    [products]);

  const debounceGetProducts = useCallback(
    debounce(getProducts, debounceTime),
    [getProducts],
  );

  const onInputChange = (_, newValue) => {
    setProductName(newValue);
    if (newValue) debounceGetProducts(itemCount);
  };

  const addExistingProduct = (productOption: IOption) => setProduct({
    ...selectedProducts,
    [productOption.value as string]: {
      name: productOption.label,
      quantity: 1,
    },
  });

  const addNewProduct = (newProduct: IProduct) => setProduct({
    ...selectedProducts,
    [newProduct.id]: {
      name: newProduct.name,
      quantity: 1,
    },
  });

  const createNewProduct = async () => {
    const requestData = { name: field.value, categoryId: nonATTCategoriesIds[0] };
    try {
      const newProduct = await dispatch(createProductAction(requestData)).unwrap();
      addNewProduct(newProduct);
    } catch (e) {
      showErrorNotifier('Failed to create product', e);
    }
  };

  const onAddProduct = () => {
    if (isNoProduct) return;

    const existingProduct = productsOptions?.find(
      (productOption) => productOption.label === field.value,
    );
    if (existingProduct) {
      addExistingProduct(existingProduct);
    } else {
      createNewProduct();
    }
  };

  return (
    <FormikAutocompleteField
      freeSolo
      fieldName="product"
      isLoading={isLoading}
      inputValue={field.value}
      options={productsOptions}
      label="Enter Product Name"
      onInputChange={onInputChange}
      gridDirection={GRID_DIRECTION.COLUMN}
      Action={(
        <Button
          variant="outlined"
          disabled={isNoProduct}
          onClick={onAddProduct}
        >
          Add
        </Button>
)}
    />
  );
};

export default AddOrderProductField;

import React, { useState, memo, ReactElement } from 'react';
import cx from 'classnames';
import {
  Button, ButtonPropsVariantOverrides, ListItemIcon, ListItemText, Menu, MenuItem,
} from '@mui/material';
import { OverridableStringUnion } from '@mui/types';

interface IMenuItem {
  onClick: () => void
  label: string
  icon?: ReactElement
  isDisabled?: boolean
}

type TButtonVariant = OverridableStringUnion<'text' | 'outlined' | 'contained', ButtonPropsVariantOverrides>

interface IButtonMenuProps {
  label: string
  isDisabled: boolean
  menuItems: IMenuItem[]
  buttonVariant?: TButtonVariant,
  buttonStartIcon?: ReactElement | undefined
}

const ButtonMenu: React.FC<IButtonMenuProps> = ({
  label, menuItems, isDisabled, buttonStartIcon, buttonVariant,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => setAnchorEl(event.currentTarget);

  const handleCloseMenu = () => setAnchorEl(null);

  const onMenuItemClick = (callbackFunction: () => void, isDisabledItem?: boolean) => {
    if (isDisabledItem) return;

    callbackFunction();
    handleCloseMenu();
  };

  return (
    <>
      <Button
        aria-haspopup="true"
        disabled={isDisabled}
        onClick={handleOpenMenu}
        startIcon={buttonStartIcon}
        id="button-menu-actions-btn"
        data-testid="button-menu-button"
        variant={buttonVariant as TButtonVariant}
        aria-expanded={isOpen ? 'true' : undefined}
        aria-controls={isOpen ? 'button-menu' : undefined}
      >
        {label}
      </Button>
      <Menu
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        id="button-menu"
        MenuListProps={{
          'aria-labelledby': 'button-menu-actions-btn',
        }}
      >
        {menuItems.map(({
          onClick, isDisabled: isItemDisabled, icon, label: menuItemLable,
        }) => (
          <MenuItem
            key={menuItemLable}
            disabled={!!isItemDisabled}
            onClick={() => onMenuItemClick(onClick, isItemDisabled)}
          >
            {
            icon
              && (
                <ListItemIcon
                  className={cx({
                    'item-disabled': isItemDisabled,
                  })}
                >
                  {icon}
                </ListItemIcon>
              )
            }
            <ListItemText>
              {menuItemLable}
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

ButtonMenu.defaultProps = {
  buttonVariant: 'outlined',
  buttonStartIcon: undefined,
};

export default memo(ButtonMenu);

import React, { useMemo } from 'react';
import { useAppSelector } from 'core/store';
import ExportTableXlsxFile from 'shared/ExportTableXlsxFile';
// eslint-disable-next-line no-unused-vars
import { IBookingReportPeriod } from '@dashboardWireline/WirelineDepartments/WIrelineDepartament.models';
import { exportBoogingSalesFileName, exportBoogingReportFileName } from '@dashboardWireline/WirelineDepartments/constants';
import { exportBookingSalsesData, exportBookingReportData } from '@dashboardWirelineSellerDepartment/ExportBookingTableData/api.service';

interface IExportBookingTable {
  reportPeriod: string | null
}

const ExportBookingTable: React.FC<IExportBookingTable> = ({
  reportPeriod,
}) => {
  const bookingFiltersParams = useAppSelector((state) => state.dashboard.bookingTableData.params);

  const exportFileName = useMemo(() => (
    reportPeriod
      ? `${exportBoogingReportFileName}_${reportPeriod.replace('-', '_')}.xlsx`
      : `${exportBoogingSalesFileName}.xlsx`),
  [reportPeriod]);

  const exportActiveNumbersData = reportPeriod ? exportBookingReportData : exportBookingSalsesData;

  const params = {
    requestParams: reportPeriod ? { bookingReportPeriod: reportPeriod } : undefined,
    filtersParams: bookingFiltersParams,
  };

  return (
    <ExportTableXlsxFile<IBookingReportPeriod | undefined>
      params={params}
      fileName={exportFileName}
      exportAction={exportActiveNumbersData}
    />
  );
};

export default ExportBookingTable;

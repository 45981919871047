import paths from 'core/routing/paths';
import { IRoutingConfigItem } from 'core/routing/models';
import WirelineEngagementsMainPage from 'pages/WirelineEngagements/WirelineEngagementsMainPage';
import WirelineEngagementsCreate from 'pages/WirelineEngagements/WirelineEngagementsCreate';
import WirelineEngagementsSowData from '@EngagementsSowData/index';
import ContractFormCreate from '@EngagementsSowData/ContractForm/ContractFormCreate';
import ContractFormView from '@EngagementsSowData/ContractForm/ContractFormView';
import RequestOrderFormCreate from '@EngagementsSowData/RequestOrderForm/RequestOrderFormCreate';
import RequestOrderFormView from '@EngagementsSowData/RequestOrderForm/RequestOrderFormView';
import WirelineEngagementsActiveNumbers from 'pages/WirelineEngagements/WirelineEngagementsTables/WirelineEngagementsActiveNumbers';
import WirelineEngagementsRenewalSowData from 'pages/WirelineEngagements/WirelineEngagementsTables/WirelineEngagementsRenewalSowData';

const wirelineEngagementsRoutes = [
  {
    path: paths.wirelineEngagementsList,
    component: WirelineEngagementsMainPage,
  },
  {
    path: paths.wirelineEngagementsCreate,
    component: WirelineEngagementsCreate,
  },
  {
    path: paths.wirelineEngagementsEditWithId,
    component: WirelineEngagementsCreate,
  },
  {
    path: paths.wirelineEngagementsSowData,
    component: WirelineEngagementsSowData,
  },
  {
    path: paths.wirelineEngagementsContractForm,
    component: ContractFormCreate,
  },
  {
    path: paths.wirelineEngagementsContractFormView,
    component: ContractFormView,
  },
  {
    path: paths.wirelineEngagementsRequestOrder,
    component: RequestOrderFormCreate,
  },
  {
    path: paths.wirelineEngagementsRequestOrderView,
    component: RequestOrderFormView,
  },
  {
    path: paths.wirelineEngagementsActiveNumbers,
    component: WirelineEngagementsActiveNumbers,
  },
  {
    path: paths.wirelineEngagementsRenewalSow,
    component: WirelineEngagementsRenewalSowData,
  },
] as IRoutingConfigItem[];

export default wirelineEngagementsRoutes;

import React from 'react';
import { useAppSelector } from 'core/store';

import EngagementsRevenueChart from '@dashboardWirelineDepartments/shared/EngagementsRevenueChart';

const SellerEngagementsRevenueChart = () => {
  const engagementsRevenueData = useAppSelector((state) => state.dashboard.engagementsRevenue.data);
  const isLoading = useAppSelector((state) => state.dashboard.engagementsRevenue.isLoading);

  return (
    <EngagementsRevenueChart
      isLoading={isLoading}
      list={engagementsRevenueData}
    />
  );
};

export default SellerEngagementsRevenueChart;

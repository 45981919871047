import {
  GET_ENGAGEMENTS_REVENUE_REQUEST,
  GET_ENGAGEMENTS_REVENUE_SUCCESS,
  GET_ENGAGEMENTS_REVENUE_FAILURE,
} from './actionsTypes';

export const getEngagementsRevenueRequest = () => (
  { type: GET_ENGAGEMENTS_REVENUE_REQUEST }
);

export const getEngagementsRevenueSuccess = (data) => ({
  type: GET_ENGAGEMENTS_REVENUE_SUCCESS,
  data,
});

export const getEngagementsRevenueFailure = (error) => ({
  type: GET_ENGAGEMENTS_REVENUE_FAILURE,
  error,
});

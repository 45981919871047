import get from 'lodash/get';
import { removeEngagementAttachment } from 'core/api/wirelineEngagements.api';
import {
  removeEngagementsAttachmentRequest,
  removeEngagementsAttachmentSuccess,
  removeEngagementsAttachmentFailure,
} from 'pages/WirelineEngagements/WirelineEngagementsAttachments/RemoveEngagementsAttachment/actionsCreators';

const removeEngagementsAttachmentAction = (
  engagementId: number | string,
  attachmentId: number | string,
) => async (dispatch) => {
  try {
    dispatch(removeEngagementsAttachmentRequest(attachmentId));
    await removeEngagementAttachment(engagementId, attachmentId);
    dispatch(removeEngagementsAttachmentSuccess(attachmentId));
  } catch (error) {
    const detail = get(error, 'response.data.detail', '') || get(error, 'error.detail', '');

    dispatch(removeEngagementsAttachmentFailure(attachmentId, detail));
    throw error;
  }
};
export default removeEngagementsAttachmentAction;

import { getRetentionBookingList } from 'core/api/sales.api';
import {
  getRetentionBookingDataRequest,
  getRetentionBookingDataSuccess,
  getRetentionBookingDataFailure,
} from './actionsCreators';

const getRetentionBookingDataAction = (
  controller,
  params: {
    page: number;
    page_size: number;
    search: string;
    ordering?: string | undefined;
  },
) => async (dispatch) => {
  const { signal } = controller;

  try {
    dispatch(getRetentionBookingDataRequest());

    const { data } = await getRetentionBookingList(signal, params);
    dispatch(getRetentionBookingDataSuccess(data));
  } catch (error) {
    dispatch(getRetentionBookingDataFailure(error));
  }
};

export default getRetentionBookingDataAction;

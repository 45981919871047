import styles from 'assets/styles/_resources.scss';

export const colorsOfCharts = [
  styles.primaryColor,
  styles.secondaryColor,
  styles.blueColor,
  styles.darkColor100,
  styles.tealColor500,
  styles.yellowColor500,
];

export const engagementsRevenueMapper = {
  mrc: 'MRC',
  nrc: 'NRC',
};

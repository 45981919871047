import { RenewalPeriodFilter } from 'shared/RenewalPeriodAutocomplete/RenewalPeriodAutocomplete.models';

export const getRenewalPeriod = (daysOut: RenewalPeriodFilter) => {
  if (daysOut === RenewalPeriodFilter.DAYS_OUT_120) {
    return { daysToRenewalFrom: 91, daysToRenewalTo: 120 };
  } if (daysOut === RenewalPeriodFilter.DAYS_OUT_90) {
    return { daysToRenewalFrom: 61, daysToRenewalTo: 90 };
  } if (daysOut === RenewalPeriodFilter.DAYS_OUT_60) {
    return { daysToRenewalFrom: 0, daysToRenewalTo: 60 };
  }
  return { daysToRenewalFrom: 0, daysToRenewalTo: 60 };
};

/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { editEngagementsScopeDescription } from 'core/api/wirelineEngagements.api';
// eslint-disable-next-line no-unused-vars
import { IScopeDescription, IScopeDescriptionForm, IScopeDescriptionParams } from 'pages/WirelineEngagements/WirelineEngagementsCreate/EngagementFormsWrapper/ScopeDescription/ScopeDescription.model';

export interface IState {
    isLoading: boolean
    error: object | null
    data: IScopeDescription
}

const initialState: IState = {
  error: null,
  isLoading: false,
  data: {} as IScopeDescription,
};

export const editScopeDescriptionAction = createAsyncThunk(
  'editScopeDescriptionAction',
  async (
    { urlParams, params }: {
      urlParams: IScopeDescriptionParams,
      params: IScopeDescriptionForm }, { rejectWithValue }) => {
    try {
      const { data } = await editEngagementsScopeDescription(urlParams, params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const ScopeDescriptionSlice = createSlice({
  name: 'editScopeDescription',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(editScopeDescriptionAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editScopeDescriptionAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(editScopeDescriptionAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
        state.data = {} as IScopeDescription;
      });
  },
});

export const { clear: clearEditScopeDescriptionAction } = ScopeDescriptionSlice.actions;
export const editScopeDescriptionReducer = ScopeDescriptionSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import { IRenewalSowDataItem } from 'pages/WirelineEngagements/WirelineEngagementsTables/WirelineEngagementsRenewalSowData/renewalSowData.models';

export interface ISalesRenewalSowState {
  count: number
  isLoading: boolean
  error: object | null
  results: IRenewalSowDataItem[]
}

const initialState: ISalesRenewalSowState = {
  count: 0,
  error: null,
  results: [],
  isLoading: true,
};

const SalesRenewalSowSlice = createSlice({
  name: 'getSalesRenewalSowData',
  initialState,
  reducers: {
    getSalesRenewalSowRequest: (state) => ({
      ...state,
      isLoading: true,
    }),
    getSalesRenewalSowSuccess: (state, action) => ({
      ...state,
      ...action.payload,
      isLoading: false,
    }),
    getSalesRenewalSowFailure: (state, action) => ({
      ...state,
      results: [],
      isLoading: false,
      error: action.payload,
    }),
    clearSalesRenewalSowData: () => initialState,
  },
});

export const {
  clearSalesRenewalSowData,
  getSalesRenewalSowRequest,
  getSalesRenewalSowSuccess,
  getSalesRenewalSowFailure,
} = SalesRenewalSowSlice.actions;

export const getSalesRenewalSowReducer = SalesRenewalSowSlice.reducer;

import React from 'react';

import { useTechnicalOverlayDepartmentContext } from '@dashboardWirelineDepartments/TechnicalOverlayDepartment/TechnicalOverlayDepartmentContext';

import FiltersByPeriod from 'pages/Dashboard/shared/FiltersByPeriod';

const TechnicalOverlayFiltersByPeriod = () => {
  const {
    getDashboardData,
    period,
  } = useTechnicalOverlayDepartmentContext();

  return (
    <FiltersByPeriod
      period={period}
      onChange={getDashboardData}
    />
  );
};

export default TechnicalOverlayFiltersByPeriod;

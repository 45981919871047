import React from 'react';
import RouterFormConfirmDialog from 'shared/RouterFormConfirmDialog';
import { useUserManagementCreateContext } from 'pages/UserManagement/UserManagementCreate/UserManagementCreateContext';

const UserManagementConfirmDialog = () => {
  const {
    isGeneralFormDirty, isTeamRoleFormDirty, isEditModeGeneral, isEditModeTeamRole,
  } = useUserManagementCreateContext();

  const isGeneralWhen = isGeneralFormDirty && isEditModeGeneral;
  const isTeamRoleWhen = isTeamRoleFormDirty && isEditModeTeamRole;

  const when = isGeneralWhen || isTeamRoleWhen;

  return (
    <RouterFormConfirmDialog when={when} />
  );
};

export default UserManagementConfirmDialog;

import React from 'react';
import getRetentionBookingDataAction from 'core/actions/Sales/GetRetentionBookingData/actions';
import { TableProvider } from 'shared/Table/TableContext';
import { tableIds } from 'shared/Table/constants';
import RetentionBookingTable from '@dashboardWirelineRetentionDepartment/RetentionBookingList/RetentionBookingTable';
import { columns } from '@dashboardWirelineRetentionDepartment/RetentionBookingList/constants';
import { MRT_ColumnDef as ColumnDef } from 'material-react-table';
import { TRow } from 'shared/Table/Table.models';

const RetentionBookingList = () => (
  <TableProvider
    id={tableIds.dashboardRetentionManager}
    columns={columns as ColumnDef<TRow>[]}
    getDataAction={getRetentionBookingDataAction}
  >
    <RetentionBookingTable />
  </TableProvider>
);

export default RetentionBookingList;

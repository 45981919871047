/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { getRenewalSowItemById, patchRenewalSowItemById } from 'core/api/sales.api';
// eslint-disable-next-line no-unused-vars
import { IRenewalSowDataItem } from '@EngagementsRenewalSowData/renewalSowData.models';

export const patchRenewalSowItemAction = createAsyncThunk(
  'patchRenewalSowItemAction',
  async (
    { id, params }: { id: string | number, params: IRenewalSowDataItem },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await patchRenewalSowItemById(id, params);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export interface IState {
  result: IRenewalSowDataItem
  isLoading: boolean
}

const initialState: IState = {
  result: {} as IRenewalSowDataItem,
  isLoading: false,
};

export const getSalesRenewalSowItemAction = createAsyncThunk(
  'getSalesRenewalSowItemAction',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const { data } = await getRenewalSowItemById(id);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const salesRenewalSowItemSlice = createSlice({
  name: 'salesRenewalSowItem',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        getSalesRenewalSowItemAction.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.result = action.payload;
        })
      .addCase(
        getSalesRenewalSowItemAction.rejected,
        (state) => {
          state.isLoading = false;
          state.result = {} as IRenewalSowDataItem;
        })
      .addMatcher(
        isAnyOf(
          getSalesRenewalSowItemAction.fulfilled,
          getSalesRenewalSowItemAction.rejected,
          patchRenewalSowItemAction.rejected,
        ),
        (state) => {
          state.isLoading = false;
        },
      )
      .addMatcher(
        isAnyOf(getSalesRenewalSowItemAction.pending, patchRenewalSowItemAction.pending),
        (state) => {
          state.isLoading = true;
        });
  },
});

export const salesRenewalSowItemReducer = salesRenewalSowItemSlice.reducer;

import React, { useMemo } from 'react';
import Loader from 'shared/Loader';
import { Box, Grid } from '@mui/material';
import { useAppSelector } from 'core/store';
import { useActionsPermissions } from 'core/hooks/useActionsPermissions';
import { parseOpenapiSchemaByEndpoint } from 'core/utilities/openapiSchema';
import { ACTIONS_INSIDE_SALES_PATHS } from 'core/models/actionsPermissions';
import FormikSingleChackboxField from 'shared/FormikField/FormikSingleChackboxField';
import FormikFieldsFromSchema from 'pages/WirelineEngagements/shared/FormikFieldsFromSchema';
import InsideSalesEditAttachments from 'pages/InsideSales/InsideSalesList/InsideSalesListTable/InsideSalesListTableModals/InsideSalesEditRowSidebarForm/InsideSalesEditAttachments';

const {
  PROPOSALS_GET,
  ATTACHMENTS_GET,
  STATUS_PARTIAL_EDIT,
  INSIDE_SALES_REQUEST_PATRIAL_EDIT,
} = ACTIONS_INSIDE_SALES_PATHS;

const actionsPaths = {
  isAvailableGetProposals: PROPOSALS_GET,
  isAvailableGetAttachments: ATTACHMENTS_GET,
  isAvailableEditStatus: STATUS_PARTIAL_EDIT,
  isAvailableEditRequest: INSIDE_SALES_REQUEST_PATRIAL_EDIT,
};
interface IEditRowSidebarFieldsProps {
  isDisabled?: boolean;
  isViewMode?: boolean;
  isLoadingItem: boolean;
  allowedFields: string[];
  showStatusField: boolean;
  editRowId: string | number | undefined | null;
}

const endpointToGetSchemaName = '/api/crm/inside-sales-request/{id}/';

const EditRowSidebarFields: React.FC<IEditRowSidebarFieldsProps> = ({
  editRowId,
  isDisabled,
  isViewMode,
  allowedFields,
  isLoadingItem,
  showStatusField,
}) => {
  const openApiPaths = useAppSelector((state) => state.openapiSchemas.paths);
  const openApiSchemas = useAppSelector((state) => state.openapiSchemas.schemas);
  const isLoadingSchemas = useAppSelector((state) => state.openapiSchemas.isLoading);

  const {
    isAvailableEditStatus,
    isAvailableEditRequest,
    isAvailableGetProposals,
    isAvailableGetAttachments,
  } = useActionsPermissions(actionsPaths);

  const isVisibleAttachments = isAvailableGetProposals || isAvailableGetAttachments;

  const retrieveSchema = useMemo(() => {
    const parsedSchema = (
      parseOpenapiSchemaByEndpoint(
        openApiSchemas,
        openApiPaths,
        endpointToGetSchemaName,
      )
    );

    return parsedSchema.filter((item) => allowedFields.includes(item.fieldName));
  }, [openApiPaths, openApiSchemas, allowedFields]);

  if (!editRowId) {
    return null;
  }

  if (isLoadingSchemas) {
    return (
      <Loader className="inside-sales-edit-row-sidebar__loader" />
    );
  }

  return (
    <>
      {
        isAvailableEditRequest && (
          <Box className="inside-sales-edit-row-sidebar__fields">
            {isLoadingItem && <Loader />}
            <Grid
              container
              item
              rowSpacing={3}
            >
              <FormikFieldsFromSchema
                retrieveSchema={retrieveSchema}
                isDisabled={isDisabled as boolean}
              />
            </Grid>
          </Box>
        )
      }
      {
        isAvailableEditStatus && showStatusField && (
          <Box className="inside-sales-edit-row-sidebar__complete-button-wrapper">
            <FormikSingleChackboxField
              label="Status"
              fieldName="engagementType"
              additionalLabel="Accepted"
              disabled={isDisabled as boolean}
            />
          </Box>
        )
      }
      {
        isVisibleAttachments && (
          <InsideSalesEditAttachments
            editRowId={editRowId}
            isViewMode={isViewMode as boolean}
          />
        )
      }
    </>
  );
};

EditRowSidebarFields.defaultProps = {
  isDisabled: false,
  isViewMode: false,
};

export default EditRowSidebarFields;

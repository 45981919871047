import {
  createCustomersDraftRequest,
  createCustomersDraftSuccess,
  createCustomersDraftFailure,
} from './actionCreators';

import createCustomersDraft from './api.service';

export const createCustomersDraftAction = () => async (dispatch) => {
  try {
    dispatch(createCustomersDraftRequest());
    const { data } = await createCustomersDraft();
    dispatch(createCustomersDraftSuccess(data));
  } catch (e) {
    // @ts-ignore
    dispatch(createCustomersDraftFailure(e));
    throw e;
  }
};

export default { createCustomersDraftAction };

/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IChartItem } from 'core/models/Recharts.models';
// eslint-disable-next-line no-unused-vars
import { TPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';
import getBillingEngagementStatus from '@dashboardWirelineBillingDepartment/GetBillingEngagementStatus/api.service';

export interface IState {
  data: IChartItem[];
  isLoading: boolean;
  error: object | null;
}

const initialState: IState = {
  data: [],
  isLoading: false,
  error: null,
};

export const getBillingEngagementStatusAction = createAsyncThunk(
  'getBillingEngagementStatusAction',
  async ({ controller, params }: { controller: AbortController, params: TPeriod },
    { rejectWithValue }) => {
    try {
      const { signal } = controller;
      const { data } = await getBillingEngagementStatus(signal, params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const billingEngagementStatusSlice = createSlice({
  name: 'getBillingEngagementStatus',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getBillingEngagementStatusAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBillingEngagementStatusAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(getBillingEngagementStatusAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
        state.data = [];
      });
  },
});

export const getBillingEngagementStatusReducer = billingEngagementStatusSlice.reducer;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const PageWrapper = ({ component: Feature, ...rest }) => {
  const getFeature = () => <Feature {...rest} />;

  useEffect(() => {
    window.scrollTo(0, 0);
  },
  []);

  return getFeature();
};

PageWrapper.propTypes = {
  component: PropTypes.func,
};

PageWrapper.defaultProps = {
  component: undefined,
};

export default PageWrapper;

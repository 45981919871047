import dayjs from 'dayjs';
import { dateFormat } from 'core/constants';
import MoneyCell from 'shared/Table/shared/MoneyCell';
// eslint-disable-next-line no-unused-vars
import { ITableCellRetentionBookingItem } from '@dashboardWirelineRetentionDepartment/RetentionManagerDepartment.models';
import { engagementTypeLabels } from 'pages/InsideSales/constants';
import { EngagementType } from 'pages/InsideSales/model';

export const columns = [
  {
    header: 'Seller',
    accessorKey: 'sellerFullname',
  },
  {
    header: 'Customer Name',
    accessorKey: 'customerName',
  },
  {
    header: 'Data Booked',
    accessorKey: 'dataBooked',
    Cell: ({ renderedCellValue }: ITableCellRetentionBookingItem) => renderedCellValue && (
      dayjs(renderedCellValue as string).format(dateFormat)
    ),
  },
  {
    header: 'Engagement Type',
    accessorKey: 'engagementType',
    Cell: ({ renderedCellValue }: ITableCellRetentionBookingItem) => (
      renderedCellValue && engagementTypeLabels[renderedCellValue as EngagementType]
    ),
  },
  {
    header: 'Original contract ID',
    accessorKey: 'contractId',
  },
  {
    header: 'New Contract Id',
    accessorKey: 'newContractId',
  },
  {
    header: 'Original Term',
    accessorKey: 'term',
  },
  {
    header: 'New Term',
    accessorKey: 'newTerm',
  },
  {
    header: 'Circuit Id',
    accessorKey: 'circuitId',
  },
  {
    header: 'Origin Account Number',
    accessorKey: 'accountNumber',
  },
  {
    header: 'New Account Number',
    accessorKey: 'newAccountNumber',
  },
  {
    header: 'Original MRC',
    accessorKey: 'mrc',
    Cell: MoneyCell,
  },
  {
    header: 'New MRC',
    accessorKey: 'newMrc',
    Cell: MoneyCell,
  },
  {
    header: 'NRC',
    accessorKey: 'newNrc',
    Cell: MoneyCell,
  },
  {
    header: 'Signed',
    accessorKey: 'renewalSigned',
    Cell: MoneyCell,
  },
  {
    header: 'Notes',
    accessorKey: 'renewalNotes',
  },
];

import * as Yup from 'yup';
import { getOneOfFromType } from 'core/utilities/Yup/YupHelpers';
import { ESCALATION_TYPE } from '@EngagementsSowData/sowData.model';

export const EscalationSupportFormSchema = Yup.object().shape({
  type: Yup
    .string()
    .oneOf(getOneOfFromType(ESCALATION_TYPE))
    .required('Escalation Type is required')
    .default(''),
  details: Yup
    .string()
    .max(1000, 'Should be no more than 1000 characters')
    .required('Message is required')
    .default(''),
});

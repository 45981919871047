import React, { useState } from 'react';
import noop from 'lodash/noop';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { CircularProgress } from '@mui/material';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { FileDownloadOutlined } from '@mui/icons-material';
import { OnChangeStateFn } from 'core/models/general.models';
import { downloadBlobFile } from 'core/utilities/downloadBlobFile';
import { MRT_RowSelectionState as RowSelectionState } from 'material-react-table';
// eslint-disable-next-line no-unused-vars
import { IExportTableParams, CombinedExportTableParams } from 'shared/ExportTableXlsxFile/ExportTableXlsxFile.models';

interface IExportTableXlsxFileProps<T> {
  fileName: string;
  params: IExportTableParams<T>;
  enableExportSelected?: boolean;
  selectedRows?: RowSelectionState;
  setRowSelection?: OnChangeStateFn<RowSelectionState>;
  exportAction: (params: CombinedExportTableParams<T>)=> Promise<{ data: BlobPart }>;
}

const ExportTableXlsxFile = <T extends unknown>({
  params, fileName, selectedRows, enableExportSelected, setRowSelection, exportAction,
}: IExportTableXlsxFileProps<T>) => {
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { requestParams = {}, filtersParams = {} } = params || {};

  const { showErrorNotifier } = useNotifiers();

  const isExportMenuOpen = Boolean(anchorEl);

  const selectedRowsIds = enableExportSelected && selectedRows ? Object.keys(selectedRows).join(',') : '';

  const openExportMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeExportMenu = () => {
    setAnchorEl(null);
  };

  const exportXlsxFile = async (exportParams: CombinedExportTableParams<T>) => {
    setIsLoading(true);
    closeExportMenu();

    try {
      const { data } = await exportAction(exportParams);

      downloadBlobFile(data, fileName);
    } catch (e) {
      showErrorNotifier(`Failed Export ${fileName}. Please try again.`, e);
    } finally {
      setIsLoading(false);

      if (enableExportSelected && selectedRowsIds && setRowSelection) {
        setRowSelection({});
      }
    }
  };

  const exportAllData = () => {
    exportXlsxFile(requestParams);
  };

  const exportPageData = () => {
    exportXlsxFile({ ...requestParams as object, ...filtersParams });
  };

  const exportSelectedData = () => {
    exportXlsxFile({
      ...requestParams as object,
      orderId: selectedRowsIds,
    });
  };

  const exportText = isLoading ? <CircularProgress size={20} /> : <span>Export</span>;

  return (
    <div>
      <Button
        id="export-button"
        aria-controls={isExportMenuOpen ? 'export-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isExportMenuOpen ? 'true' : undefined}
        onClick={openExportMenu}
        startIcon={<FileDownloadOutlined />}
        disabled={isLoading}
        variant="outlined"
        sx={{ width: '108px' }}
      >
        {exportText}
      </Button>
      <Menu
        id="export-menu"
        anchorEl={anchorEl}
        open={isExportMenuOpen}
        onClose={closeExportMenu}
        MenuListProps={{
          'aria-labelledby': 'export-button',
        }}
      >
        <MenuItem onClick={exportAllData}>Export All Data</MenuItem>
        <MenuItem onClick={exportPageData}>Export Page</MenuItem>
        {enableExportSelected && (
          <MenuItem
            onClick={exportSelectedData}
            disabled={!selectedRowsIds}
          >
            Export Selected
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

ExportTableXlsxFile.defaultProps = {
  setRowSelection: noop,
  enableExportSelected: false,
  selectedRows: {},
};

export default ExportTableXlsxFile;

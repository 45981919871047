import React from 'react';
import times from 'lodash/times';
import { Skeleton } from '@mui/material';

const DashboardBarChartSkeleton = () => (
  <>
    <Skeleton
      variant="rectangular"
      height={285}
      width="99%"
    />
    <div
      className="dashboard-bar-chart__skeleton__legend"
      data-testid="dashboard-bar-chart__skeleton__legend"
    >
      {
        times(5, (i) => (
          <Skeleton
            key={i}
            className="dashboard-bar-chart__skeleton__legend-item"
            height={15}
            width="12%"
          />
        ))
      }
    </div>
  </>

);

export default DashboardBarChartSkeleton;

import map from 'lodash/map';
import compact from 'lodash/compact';
// eslint-disable-next-line no-unused-vars
import { IOption } from 'shared/FormikField/FormikField.model';

const matchPattern = /`([^`]*)`[^-]*-([^]*)/;

export const getOptionFromEnumDescription = (description: string): IOption[] => {
  const lines = description.split('\n').filter(((line) => !!line));

  const options = map(lines, (line) => {
    const matchResult = line.match(matchPattern);

    if (matchResult && matchResult.length === 3) {
      const [value, label] = matchResult.slice(1, 3).map((str) => str.trim());

      return { label, value };
    }

    return null;
  });

  return compact(options);
};

export const getConstantsFromEnumDescription = (
  description: string,
): Record<string, string> => description
  .split('\n')
  .filter((line) => !!line)
  .reduce((acc, line) => {
    const matchResult = line.match(matchPattern);

    if (matchResult && matchResult.length === 3) {
      const [value, label] = matchResult.slice(1, 3).map((str) => str.trim());
      acc[value] = label;
    }

    return acc;
  }, {} as Record<string, string>);

export const createUserNotifications = {
  success: 'User created successfully',
  error: 'User creation failed',
};

export const updateUserNotifications = {
  success: 'User updated successfully',
  error: 'User update failed',
};

export const loadUserNotifications = {
  error: 'User loading failed',
};

export const creatTeamRolesNotifications = {
  success: 'Team roles created successfully',
  error: 'Team roles creation failed',
};

export const updateTeamRolesNotifications = {
  success: 'Team roles updated successfully',
  error: 'Team roles update failed',
};

import paths from 'core/routing/paths';
import UserManagementList from './UserManagementList';
import UserManagementCreate from './UserManagementCreate/UserManagementCreate';

const userManagementRoutes = [
  {
    path: paths.userManagementList,
    component: UserManagementList,
  },
  {
    path: paths.userManagementCreate,
    component: UserManagementCreate,
  },
  {
    path: paths.userManagementEditWithId,
    component: UserManagementCreate,
  },
];

export default userManagementRoutes;

const downloadFile = (dataUrl, fileName, linkParams = {}) => {
  const link = document.createElement('a');
  link.href = dataUrl;
  link.download = fileName;

  if (linkParams?.target) {
    link.target = linkParams?.target;
  }

  link.click();
};

export default downloadFile;

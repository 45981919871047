import React from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import RequestOrderForm from '@EngagementsSowData/RequestOrderForm/RequestOrderForm';
import RequestOrderFormBackButton from '@EngagementsSowData/RequestOrderForm/RequestOrderFormBackButton';

const RequestOrderFormView = () => {
  const { requestOrderId } = useParams();

  if (!requestOrderId) return null;

  return (
    <Box className="request-order-form__wrapper">
      <RequestOrderFormBackButton isViewMode />
      <RequestOrderForm
        orderId={requestOrderId}
        isViewMode
      />
    </Box>
  );
};

export default RequestOrderFormView;

import React, { ReactElement } from 'react';
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import './ErrorDialog.scss';

interface IErrorDialogProps {
  onCloseDialog: () => void;
  isOpen: boolean;
  children : ReactElement;
  title: string;
}

const ErrorDialog:React.FC<IErrorDialogProps> = ({
  onCloseDialog, isOpen, children, title,
}) => (
  <Dialog
    onClose={onCloseDialog}
    open={isOpen}
    className="error-dialog"
  >
    <div className="error-dialog-wrap">
      <div className="error-dialog__title-wrap">
        <ErrorIcon fontSize="large" color="error" />
        <DialogTitle className="error-dialog__title">
          {title}
        </DialogTitle>

        <IconButton
          className="error-dialog__close-btn"
          onClick={onCloseDialog}
        >
          <CloseIcon fontSize="medium" />
        </IconButton>
      </div>
      {children}
    </div>
  </Dialog>
);

export default ErrorDialog;

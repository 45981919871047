import { IEngagementsOrdersByPeriodItem } from 'pages/Dashboard/Dashboard.models';

import {
  GET_PROJECT_STATUS_REQUEST,
  GET_PROJECT_STATUS_SUCCESS,
  GET_PROJECT_STATUS_FAILURE,
} from '@dashboardWirelinePMDepartment/GetProjectStatus/actionsTypes';

interface IGetProjectStatusState {
  data: IEngagementsOrdersByPeriodItem[];
  isLoading: boolean;
  error: object;
}

const initialState: IGetProjectStatusState = {
  isLoading: false,
  data: [],
  error: {},
};

const getProjectStatusReducer = (state = initialState, {
  type, data, error,
}) => {
  if (type === GET_PROJECT_STATUS_REQUEST) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (type === GET_PROJECT_STATUS_SUCCESS) {
    return {
      ...state,
      data,
      isLoading: false,
    };
  }

  if (type === GET_PROJECT_STATUS_FAILURE) {
    return {
      ...state,
      isLoading: false,
      error,
      data: [],
    };
  }

  return state;
};
export default getProjectStatusReducer;

import { useEffect, useRef } from 'react';

export const useUpdateEffect = (callback, deps) => {
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    callback();
  }, deps);
};

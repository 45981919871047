import React, { ReactElement, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogContent, DialogTitle, Grid,
  IconButton, Paper,
} from '@mui/material';
import Loader from 'shared/Loader';
import InsideModalConfirmation from 'shared/InsideModalConfirmation';

import './CustomModal.scss';

interface ICustomModalProps {
    isModalOpen: boolean;
    onCloseModal: (isModalOpen: boolean) => void;
    title: string;
    children?: ReactElement;
    onClickConfirm?: () => void;
    areButtonsVisible?: boolean;
    isLoading?: boolean;
    confirmButtonDisable?: boolean;
    confirmButtonText?: string;
    cancelButtonText?: string;
    preloaderComponent?: ReactElement;
    maxWidth?: string;
    isConfirmDialog?: boolean,
    buttonWidth?: string,
    isCancelButtonVisible?: boolean,
}

const CustomModal = ({
  isModalOpen,
  onCloseModal,
  title,
  children,
  onClickConfirm,
  isLoading,
  confirmButtonText,
  cancelButtonText,
  confirmButtonDisable,
  areButtonsVisible,
  preloaderComponent,
  maxWidth,
  isConfirmDialog,
  buttonWidth,
  isCancelButtonVisible,
  ...props
}: ICustomModalProps) => {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const onCloseConfirmDialog = () => {
    setIsConfirmDialogOpen(false);
  };

  const onConfirmDialogConfirm = () => {
    onCloseConfirmDialog();
    onCloseModal(false);
  };

  const onClose = () => {
    if (isConfirmDialog) {
      setIsConfirmDialogOpen(true);
    } else {
      onCloseModal(false);
    }
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={onClose}
      className="custom-modal"
      data-testid="custom-modal"
      {...props}
    >
      <Paper className="custom-modal__wrap" sx={{ maxWidth }}>
        <div className="custom-modal__title-wrap">
          <DialogTitle className="custom-modal__title">
            {title}
          </DialogTitle>

          <IconButton
            className="custom-modal__close-btn"
            onClick={onClose}
          >
            <CloseIcon fontSize="medium" />
          </IconButton>
        </div>
        {isLoading && preloaderComponent}
        {children && (
        <DialogContent className="custom-modal__content">
          {children}
        </DialogContent>
        )}

        {areButtonsVisible && (
        <Grid
          sx={{ mt: 2 }}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="contained"
            onClick={onClickConfirm}
            disabled={confirmButtonDisable as boolean}
            sx={{ mr: 2, width: buttonWidth }}
          >
            {confirmButtonText}
          </Button>
          {isCancelButtonVisible && (
          <Button
            variant="outlined"
            onClick={onClose}
            sx={{ width: buttonWidth }}
          >
            {cancelButtonText}
          </Button>
          )}
        </Grid>
        )}
      </Paper>
      <InsideModalConfirmation
        isVisible={isConfirmDialogOpen}
        onCancel={onCloseConfirmDialog}
        onConfirm={onConfirmDialogConfirm}
      />
    </Dialog>
  );
};

CustomModal.defaultProps = {
  onClickConfirm: () => {},
  areButtonsVisible: false,
  isLoading: false,
  confirmButtonDisable: false,
  confirmButtonText: 'Create',
  cancelButtonText: 'Cancel',
  preloaderComponent: <Loader className="custom-modal__loader" />,
  maxWidth: '516px',
  isConfirmDialog: false,
  buttonWidth: '157px',
  isCancelButtonVisible: true,
  children: null,
};
export default CustomModal;

import React, { useEffect } from 'react';
import Comments from 'shared/widgets/Comments';
import { HTTPService } from 'core/services';
import { useAppDispatch, useAppSelector } from 'core/store';
import getCommentsAction from 'pages/WirelineEngagements/WirelineEngagementsComments/GetComments/actions';
import createCommentAction from 'pages/WirelineEngagements/WirelineEngagementsComments/CreateComment/actions';
import editCommentAction from 'pages/WirelineEngagements/WirelineEngagementsComments/EditComment/actions';
import removeCommentAction from 'pages/WirelineEngagements/WirelineEngagementsComments/RemoveComment/actions';
import clearCommentsErrorsAction from 'pages/WirelineEngagements/WirelineEngagementsComments/ClearErrorsComments/actions';

let engagementsCommentsController = HTTPService.getController();

interface IEngagementsCommentsProps {
  isAvailableCreate: boolean
  engagementId: number | string
}

const WirelineEngagementsComments: React.FC<IEngagementsCommentsProps> = ({
  engagementId, isAvailableCreate,
}) => {
  const dispatch = useAppDispatch();
  const comments = useAppSelector((state) => state.wirelineEngagements.comments.data.results);
  const isLoading = useAppSelector((state) => state.wirelineEngagements.comments.isLoading);
  const commentsError = useAppSelector((state) => state.wirelineEngagements.comments.error);

  const cancelGetEngagementCommentsRequest = () => {
    HTTPService.cancelRequest(engagementsCommentsController);
  };

  const clearEngagementCommentsErrors = () => {
    dispatch(clearCommentsErrorsAction(comments));
  };

  const deleteComment = (commentId) => {
    dispatch(removeCommentAction(engagementId, commentId));
  };

  const editComment = (commentId, data) => {
    const formData = { text: data };
    dispatch(editCommentAction(engagementId, commentId, formData));
  };

  const createComment = (data) => {
    const formData = { text: data };
    dispatch(createCommentAction(engagementId, formData));
  };

  const getComments = async () => {
    cancelGetEngagementCommentsRequest();

    engagementsCommentsController = HTTPService.getController();
    await dispatch(getCommentsAction(engagementsCommentsController, engagementId));
  };
  useEffect(() => {
    getComments();

    return () => cancelGetEngagementCommentsRequest();
  }, []);

  return (
    <Comments
      isLoading={isLoading}
      comments={comments}
      deleteComment={deleteComment}
      addNewComment={createComment}
      error={commentsError}
      updateComment={editComment}
      isAvailableAddComment={isAvailableCreate}
      clearCommentsErrors={clearEngagementCommentsErrors}
    />
  );
};

export default WirelineEngagementsComments;

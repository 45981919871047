import React from 'react';
import { IChartItem } from 'core/models/Recharts.models';
import SimpleBarChartContent from 'pages/Dashboard/shared/SimpleBarChart/SimpleBarChartContent';
import './SimpleBarChart.scss';

interface ISimpleBarChart {
  data: IChartItem[],
  title?: string | null,
  color: string,
  barSize?: number,
  isNeedSeparateLine?: boolean,
  isLoading?: boolean,
}

const SimpleBarChart: React.FC<ISimpleBarChart> = ({
  data, title, color, barSize, isNeedSeparateLine, isLoading,
}) => (
  <div className="simple-bar-chart">
    {title && (
    <h3 className="simple-bar-chart__title">
      {title}
    </h3>
    )}
    <SimpleBarChartContent
      data={data}
      color={color}
      barSize={barSize as number}
      isNeedSeparateLine={isNeedSeparateLine}
      isLoading={isLoading as boolean}
    />
  </div>
);

SimpleBarChart.defaultProps = {
  barSize: 30,
  isNeedSeparateLine: false,
  title: null,
  isLoading: false,
};

export default SimpleBarChart;

import React from 'react';
import { useParams } from 'react-router-dom';
import { exportSowData } from 'core/api/sales.api';
import ExportTableXlsxFile from 'shared/ExportTableXlsxFile';
import { useTableContext } from 'shared/Table/TableContext';
// eslint-disable-next-line no-unused-vars
import { ISowDataListItem, IExportSowDataRequestParams } from '@EngagementsSowData/sowData.model';

interface IExportSowTable {
  sowDataItem?: ISowDataListItem
}

const ExportSowTable: React.FC<IExportSowTable> = ({ sowDataItem }) => {
  const { rowSelection, tableFilters, setRowSelection } = useTableContext();
  const { customerId } = useParams();
  const { pagination: { pageSize, pageIndex } } = tableFilters;

  const { customerCompanyName } = sowDataItem || {};

  const exportFileName = customerCompanyName ? `${customerCompanyName}_SOW.xlsx` : 'SOW.xlsx';

  const exportParams = {
    requestParams: {
      customerId: customerId!,
    },
    filtersParams: {
      page: pageIndex + 1,
      page_size: pageSize,
    },
  };

  return (
    <ExportTableXlsxFile<IExportSowDataRequestParams>
      enableExportSelected
      params={exportParams}
      fileName={exportFileName}
      selectedRows={rowSelection}
      exportAction={exportSowData}
      setRowSelection={setRowSelection}
    />
  );
};

ExportSowTable.defaultProps = {
  sowDataItem: {} as ISowDataListItem,
};

export default ExportSowTable;

import React from 'react';
import EscalationSupportTable from '@dashboardEscalationSupport/EscalationSupportTable';
import EscalationSupportCharts from '@dashboardEscalationSupport/EscalationSupportCharts';
import EscalationSupportChartsProvider from '@dashboardEscalationSupport/EscalationSupportCharts/EscalationSupportChartsContext';

import './EscalationSupport.scss';

const EscalationSupport = () => (
  <div className="escalation-support">
    <EscalationSupportChartsProvider>
      <EscalationSupportCharts />
    </EscalationSupportChartsProvider>
    <EscalationSupportTable />
  </div>
);

export default EscalationSupport;

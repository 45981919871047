import React from 'react';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CheckIcon from '@mui/icons-material/Check';

import { sendLOAByCustomerId } from 'core/api/customer.api';
import downloadFile from 'core/utilities/downloadFile';
import Loader from 'shared/Loader';
import Show from 'shared/Show';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { ICustomer } from 'pages/Customers/Customers.models';
import { sendLOANotifierMessage } from 'pages/Customers/CustomersCreate/constants';
import { loaInfoProfileSchema, loaInfoProfileSchemaOptions } from 'pages/Customers/CustomersCreate/CustomersCreateForm/CustomersCreateFormSchema';

interface ILoaInformationButtonsProps {
  isLoading: boolean
  setIsLoading: (value: boolean) => void
  isBlockDisabled: boolean
}

const LoaInformationButtons: React.FC<ILoaInformationButtonsProps> = ({
  isLoading, setIsLoading, isBlockDisabled,
}) => {
  const { values, setFieldValue } = useFormikContext<ICustomer>();

  const { customerId } = useParams();

  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();

  const isLOAValid = loaInfoProfileSchema.isValidSync(
    values.loaInfoProfile,
    loaInfoProfileSchemaOptions,
  );

  const isEditMode = !!customerId;

  const isSendLoadDisabled = !isLOAValid || !isEditMode || isBlockDisabled;

  const onSendLOA = async () => {
    if (!customerId || isLoading || !isLOAValid) return;

    setIsLoading(true);
    try {
      const { data } = await sendLOAByCustomerId(customerId, values.loaInfoProfile);
      setFieldValue('loaInfoProfile', data);
      showSuccessNotifier(sendLOANotifierMessage.success);
    } catch (e) {
      showErrorNotifier(sendLOANotifierMessage.error, e);
    } finally {
      setIsLoading(false);
    }
  };

  const onLoadLOAPdf = () => {
    if (!values.loaPdf) return;

    downloadFile(values.loaPdf, 'LOA.pdf', { target: '_blank' });
  };

  return (
    <>
      <Grid item xs={4} />
      <Grid item xs={8}>
        <Show on={!values.loaPdf}>
          <Button
            className="customers-create-form__LOA-button"
            color="secondary"
            variant="contained"
            onClick={onSendLOA}
            disabled={isSendLoadDisabled}
            fullWidth
          >
            <span className="customers-create-form__LOA-button-icon">
              {isLoading
                ? <Loader size={15} type="icon" />
                : <SendIcon />}
            </span>
            send sp loa
          </Button>
        </Show>
        <Show on={!!values.loaPdf}>
          <Button
            className="customers-create-form__LOA-button"
            color="secondary"
            variant="contained"
            onClick={onLoadLOAPdf}
            fullWidth
          >
            <span className="customers-create-form__LOA-button-icon">
              <FileDownloadIcon />
            </span>
            download signed loa
          </Button>
          <div className="customers-create-form__LOA-hint-success">
            <CheckIcon
              className="customers-create-form__LOA-hint-success__icon"
            />
            Document was signed successfully
          </div>
        </Show>
      </Grid>
    </>
  );
};

export default LoaInformationButtons;

// eslint-disable-next-line no-unused-vars
import { ITableFiltersParams } from 'core/models/general.models';
// eslint-disable-next-line no-unused-vars
import { IBookingReportPeriod } from '@dashboardWireline/WirelineDepartments/WIrelineDepartament.models';
import getBookingSellerDataAction from '@dashboardWirelineSellerDepartment/GetBookingSellerData/actions';
import getBookingReportDataAction from '@dashboardWirelineSellerDepartment/GetBookingReportData/actions';

const getBookingTableDataAction = (
  controller,
  params: ITableFiltersParams,
  bookingReportPeriod?: IBookingReportPeriod | undefined) => (
  bookingReportPeriod
    ? getBookingReportDataAction(controller, params, bookingReportPeriod)
    : getBookingSellerDataAction(controller, params)
);

export default getBookingTableDataAction;

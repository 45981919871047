import React, { useState, ReactElement } from 'react';
import cx from 'classnames';
import Loader from 'shared/Loader';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Paper,
  IconButton,
  DialogTitle,
  LinearProgress,
  Typography,
} from '@mui/material';

import './CustomDialog.scss';

interface ICustomDialogSteperContentProps {
  title: string;
  maxWidth?: string;
  onClose: () => void;
  isLoading?: boolean;
  classNameTitle?: string;
  preloaderComponent?: ReactElement;
  className?: string;
  steps: (goNextStep: ()=>void, goBackStep: ()=>void) => ReactElement[];

}

const CustomDialogSteperContent = ({
  steps,
  title,
  onClose,
  maxWidth,
  isLoading,
  className,
  classNameTitle,
  preloaderComponent,
}: ICustomDialogSteperContentProps) => {
  const [activeStep, setActiveStep] = useState(0);

  const onBackHandler = () => setActiveStep((value) => value - 1);
  const onNextHandler = () => setActiveStep((value) => value + 1);

  const stepsList = steps(onNextHandler, onBackHandler);

  const stepProgressValue = (100 / stepsList.length) * (activeStep + 1);

  const isVisibleStep = !isLoading && !!stepsList.length;

  return (
    <Box className={className}>
      <LinearProgress variant="determinate" value={stepProgressValue} />
      <Paper className="custom-dialog-wrapper__wrap" sx={{ maxWidth }}>
        <div className={cx(classNameTitle, 'custom-dialog-wrapper__title-wrap')}>
          <DialogTitle className="custom-dialog-wrapper__title">
            {title}
            <Typography variant="body1">
              Step
              {' '}
              <span className="custom-dialog-wrapper__step_count">{activeStep + 1}</span>
              {' '}
              of
              {' '}
              {stepsList.length}
            </Typography>
          </DialogTitle>

          <IconButton
            className="custom-dialog-wrapper__close-btn"
            onClick={onClose}
          >
            <CloseIcon fontSize="medium" />
          </IconButton>
        </div>
        {isLoading && preloaderComponent}
        {isVisibleStep && stepsList[activeStep]}
      </Paper>
    </Box>
  );
};

CustomDialogSteperContent.defaultProps = {
  className: '',
  isLoading: false,
  maxWidth: '516px',
  classNameTitle: '',
  preloaderComponent: <Loader className="custom-dialog-wrapper__loader" />,
};
export default CustomDialogSteperContent;

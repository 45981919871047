import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useAppDispatch } from 'core/store';
import { HTTPService } from 'core/services';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import FiltersByPeriod from 'pages/Dashboard/shared/FiltersByPeriod';
import { useActionsPermissions } from 'core/hooks/useActionsPermissions';
import { defaultPeriod } from '@dashboardWirelinePMDepartment/constants';
import { notifierMessage } from '@dashboardWirelineBillingDepartment/constants';
import RequestTypeChart from '@dashboardWirelineBillingDepartment/RequestTypeChart';
import { getRequestTypeAction } from '@dashboardWirelineBillingDepartment/GetRequestType';
import { ACTIONS_DASHBOARD_WIRELINE_BILING_SPECIALIST_PATHS } from 'core/models/actionsPermissions';
import { getBillingEngagementStatusAction } from '@dashboardWirelineBillingDepartment/GetBillingEngagementStatus';
import BillingEngagementStatusChart from 'pages/Dashboard/BusinessDirections/Wireline/WirelineDepartments/BillingDepartment/BillingEngagementStatusChart';

import './BillingDepartment.scss';

const {
  REQUEST_TYPE_CHART_GET,
  ENGAGEMENT_STATUS_CHART_GET,
} = ACTIONS_DASHBOARD_WIRELINE_BILING_SPECIALIST_PATHS;

const actionsPaths = {
  isAvailableRequestTypeChart: REQUEST_TYPE_CHART_GET,
  isAvailableEngagementStatueChart: ENGAGEMENT_STATUS_CHART_GET,
};

let requestTypeController = HTTPService.getController();
let billingEngagementStatusController = HTTPService.getController();

const BillingDepartment = () => {
  const { showErrorNotifier } = useNotifiers();

  const dispatch = useAppDispatch();

  const {
    isAvailableRequestTypeChart,
    isAvailableEngagementStatueChart,
  } = useActionsPermissions(actionsPaths);

  const [period, setPeriod] = useState(defaultPeriod);

  const cancelRequestTypeRequest = () => {
    HTTPService.cancelRequest(requestTypeController);
  };

  const cancelBillingEngagementStatusRequest = () => {
    HTTPService.cancelRequest(billingEngagementStatusController);
  };

  const getRequestType = (newPeriod) => {
    if (!isAvailableRequestTypeChart) return;
    cancelRequestTypeRequest();

    requestTypeController = HTTPService.getController();

    dispatch(getRequestTypeAction({
      controller: requestTypeController, params: newPeriod,
    }))
      .unwrap()
      .catch((e) => {
        showErrorNotifier(notifierMessage.requestType.error, e);
      });
  };

  const getBillingEngagementStatus = (newPeriod) => {
    if (!isAvailableEngagementStatueChart) return;
    cancelBillingEngagementStatusRequest();

    billingEngagementStatusController = HTTPService.getController();

    dispatch(getBillingEngagementStatusAction({
      controller: billingEngagementStatusController, params: newPeriod,
    }))
      .unwrap()
      .catch((e) => {
        showErrorNotifier(notifierMessage.billingEngagementStatus.error, e);
      });
  };

  const updateCharts = (newPeriod) => {
    getRequestType(newPeriod);
    getBillingEngagementStatus(newPeriod);

    setPeriod(newPeriod);
  };

  const cancelRequests = () => {
    cancelRequestTypeRequest();
    cancelBillingEngagementStatusRequest();
  };

  useEffect(() => {
    updateCharts(period);

    return () => cancelRequests();
  }, []);

  return (
    <Box className="billing-specialist">
      <Box className="billing-specialist__filters">
        <FiltersByPeriod
          period={period}
          onChange={updateCharts}
        />
      </Box>
      <Box className="billing-specialist__charts">
        {isAvailableRequestTypeChart && <RequestTypeChart />}
        {isAvailableEngagementStatueChart && <BillingEngagementStatusChart />}
      </Box>
    </Box>
  );
};

export default BillingDepartment;

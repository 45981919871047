import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

const Title = ({ variant, className, title }) => (
  <Typography variant={variant} className={className}>
    {title}
  </Typography>
);

Title.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
};

Title.defaultProps = {
  variant: 'h6',
  className: 'title',
  title: 'Customers',
};

export default Title;

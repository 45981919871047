import React, {
  createContext, useContext, useMemo, useState,
} from 'react';
import useEngagementsForm from '@EngagementsForm/useEngagementsForm';
import { IEngagement, TAddOrderType } from 'pages/WirelineEngagements/WirelineEngagements.models';

interface IWirelineEngagementsCreateContext {
  newCompanyName: string
  isInitializing: boolean
  isEditModeGeneral: boolean
  addOrderModalType: TAddOrderType
  isCreateCustomerModalOpen: boolean
  initialCustomerId: number | undefined
  engagementsInitialValues: IEngagement | null
  setNewCompanyName: (companyName: string) => void
  getEngagement: (engagementId: number | string) => void
  setIsEditModeGeneral: (isInitializing: boolean) => void
  setAddOrderModalType: (orderModalType: TAddOrderType) => void
  setIsCreateCustomerModalOpen: (isCreateCustomerModalOpen: boolean) => void
}

interface IWirelineEngagementsCreateProviderProps {
  children: JSX.Element[];
}

const WirelineEngagementsCreateContext = createContext<
  IWirelineEngagementsCreateContext
>({} as IWirelineEngagementsCreateContext);

export const WirelineEngagementsCreateProvider: React.FC<
  IWirelineEngagementsCreateProviderProps
> = ({ children }) => {
  const [isCreateCustomerModalOpen, setIsCreateCustomerModalOpen] = useState(false);
  const [addOrderModalType, setAddOrderModalType] = useState<TAddOrderType>(null);

  const [newCompanyName, setNewCompanyName] = useState('');

  const {
    isInitializing,
    initialCustomerId,
    isEditModeGeneral,
    engagementsInitialValues,
    getEngagement,
    setIsEditModeGeneral,
  } = useEngagementsForm();

  const value = useMemo(() => ({
    isInitializing,
    newCompanyName,
    initialCustomerId,
    addOrderModalType,
    isEditModeGeneral,
    engagementsInitialValues,
    isCreateCustomerModalOpen,
    getEngagement,
    setNewCompanyName,
    setAddOrderModalType,
    setIsEditModeGeneral,
    setIsCreateCustomerModalOpen,
  }), [
    isCreateCustomerModalOpen,
    addOrderModalType,
    engagementsInitialValues,
    isInitializing,
    isEditModeGeneral,
    newCompanyName,
  ]);

  return (
    <WirelineEngagementsCreateContext.Provider value={value}>
      {children}
    </WirelineEngagementsCreateContext.Provider>
  );
};

export const useWirelineEngagementsCreateContext = () => (
  useContext(WirelineEngagementsCreateContext)
);

export default WirelineEngagementsCreateProvider;

import { getEngagementComments } from 'core/api/wirelineEngagements.api';
import { ICommentError } from 'shared/widgets/Comments/Comments.models';

import {
  getCommentsRequest,
  getCommentsSuccess,
  getCommentsFailure,
} from 'pages/WirelineEngagements/WirelineEngagementsComments/GetComments/actionCreators';

const getCommentsAction = (
  controller: AbortController,
  engagementId: string | number,
) => async (dispatch) => {
  try {
    const { signal } = controller;

    dispatch(getCommentsRequest());

    const { data } = await getEngagementComments(signal, engagementId);

    dispatch(getCommentsSuccess(data));
  } catch (error) {
    const { error: { detail } } = error as ICommentError;
    dispatch(getCommentsFailure(detail));
    throw error;
  }
};

export default getCommentsAction;

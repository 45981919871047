import React, { ReactElement } from 'react';
import cx from 'classnames';
import { noop } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import {
  Paper,
  IconButton,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import Loader from 'shared/Loader';
import CustomDialogButtons from './CustomDialogButtons';

import './CustomDialog.scss';

interface ICustomDialogContentProps {
  title: string;
  maxWidth?: string;
  onClose: () => void;
  isLoading?: boolean;
  buttonWidth?: string;
  classNameTitle?: string;
  children?: ReactElement;
  classNameContent?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  onClickConfirm?: () => void;
  areButtonsVisible?: boolean;
  confirmButtonDisable?: boolean;
  isCancelButtonVisible?: boolean;
  preloaderComponent?: ReactElement;
}

const CustomDialogContent = ({
  title,
  onClose,
  children,
  onClickConfirm,
  isLoading,
  confirmButtonText,
  cancelButtonText,
  confirmButtonDisable,
  areButtonsVisible,
  preloaderComponent,
  maxWidth,
  buttonWidth,
  isCancelButtonVisible,
  classNameTitle,
  classNameContent,
}: ICustomDialogContentProps) => (
  <Paper className="custom-dialog-wrapper__wrap" sx={{ maxWidth }}>
    <div className={cx(classNameTitle, 'custom-dialog-wrapper__title-wrap')}>
      <DialogTitle className="custom-dialog-wrapper__title">
        {title}
      </DialogTitle>

      <IconButton
        className="custom-dialog-wrapper__close-btn"
        onClick={onClose}
      >
        <CloseIcon fontSize="medium" />
      </IconButton>
    </div>
    {isLoading && preloaderComponent}
    {children && (
    <DialogContent className={cx(classNameContent, 'custom-dialog-wrapper__content')}>
      {children}
    </DialogContent>
    )}
    <CustomDialogButtons
      onClickSecondButton={onClose}
      buttonWidth={buttonWidth}
      onClickFirstButton={onClickConfirm}
      secondButtonText={cancelButtonText}
      areButtonsVisible={areButtonsVisible}
      firstButtonText={confirmButtonText}
      firstButtonDisable={confirmButtonDisable}
      isSecondButtonVisible={isCancelButtonVisible}
    />
  </Paper>
);

CustomDialogContent.defaultProps = {
  children: null,
  isLoading: false,
  maxWidth: '516px',
  classNameTitle: '',
  buttonWidth: '157px',
  classNameContent: '',
  onClickConfirm: noop,
  areButtonsVisible: false,
  cancelButtonText: 'Cancel',
  confirmButtonDisable: false,
  confirmButtonText: 'Create',
  isCancelButtonVisible: true,
  preloaderComponent: <Loader className="custom-dialog-wrapper__loader" />,
};
export default CustomDialogContent;

import React from 'react';
import CustomModal from 'shared/CustomModal';
import { useAppDispatch } from 'core/store';
import { useTableContext } from 'shared/Table/TableContext';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import engagementAcceptedAction from '@EngagementsSowData/EngagementAccepted/EngagementAcceptedAction';
import EngagementAcceptedItems from '@EngagementsSowData/EngagementAccepted/EngagementAcceptedModal/EngagementAcceptedItems';
import {
  buttonWidth,
  engagementAcceptedButtonText,
  engagementAcceptedModalTitle, engagementAcceptedNotifierMessage,
} from '@EngagementsSowData/EngagementAccepted/constants';
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';

interface IEngagementAcceptedModalProps {
  isModalOpen: boolean;
  onCloseModal: ()=> void;
  selectedSowData: ISowDataListItem[];
}

const EngagementAcceptedModal: React.FC<IEngagementAcceptedModalProps> = (
  { isModalOpen, onCloseModal, selectedSowData },
) => {
  const { rowSelection, getTableData } = useTableContext();
  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();

  const dispatch = useAppDispatch();

  const selectedRowIds = Object.keys(rowSelection);

  const onEngagementAccepted = async () => {
    const filteredRowIds = selectedRowIds.filter((id) => {
      const engagement = selectedSowData.find((item) => item.id === Number(id));
      return !engagement?.contractEngagementAcceptedDate;
    }).map(Number);

    try {
      await Promise.all(
        filteredRowIds.map(async (id) => {
          await dispatch(engagementAcceptedAction(id)).unwrap();
          showSuccessNotifier(engagementAcceptedNotifierMessage.success);
        }),
      );
    } catch (e) {
      showErrorNotifier(engagementAcceptedNotifierMessage.error, e);
    }

    getTableData();
    onCloseModal();
  };

  return (
    <CustomModal
      isModalOpen={isModalOpen}
      onCloseModal={onCloseModal}
      title={engagementAcceptedModalTitle}
      confirmButtonText={engagementAcceptedButtonText.confirmButtonText}
      cancelButtonText={engagementAcceptedButtonText.cancelButtonText}
      onClickConfirm={onEngagementAccepted}
      areButtonsVisible
      buttonWidth={buttonWidth}
    >
      <EngagementAcceptedItems
        selectedSowData={selectedSowData}
      />
    </CustomModal>
  );
};

export default EngagementAcceptedModal;

/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { postCancelInsideSalesRequest } from 'core/api/insideSales.api';
// eslint-disable-next-line no-unused-vars
import { IInsideSalesRequestCancelRequest } from 'pages/InsideSales/model';

export const CancelRequestAction = createAsyncThunk(
  'CancelRequestAction',
  async (
    { id, params } : { id: string | number, params: IInsideSalesRequestCancelRequest },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await postCancelInsideSalesRequest(id, params);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export interface IState {
  isLoading: boolean;
}

const initialState: IState = {
  isLoading: false,
};

export const CancelRequestSlice = createSlice({
  name: 'CancelRequestSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        CancelRequestAction.fulfilled,
        (state) => { state.isLoading = false; },
      )
      .addCase(
        CancelRequestAction.rejected,
        (state) => { state.isLoading = false; },
      )
      .addCase(
        CancelRequestAction.pending,
        (state) => { state.isLoading = true; },
      );
  },
});

export const CancelRequestReducer = CancelRequestSlice.reducer;

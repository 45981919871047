import React from 'react';
import { status } from 'shared/constants';
import cx from 'classnames';
import './EngagementsStatus.scss';

const statusMapping = {
  true: { class: 'active', text: status.active },
  false: { class: 'inactive', text: status.inactive },
};

interface IEngagementsStatusProps {
  engagementType: 0 | 1;
}

const EngagementsStatus:React.FC<IEngagementsStatusProps> = ({ engagementType }) => {
  const { class: statusClass, text: statusText } = statusMapping[String(!!engagementType)];

  const className = cx('status', statusClass);

  return (
    <div className={className}>
      {statusText}
    </div>
  );
};

export default EngagementsStatus;

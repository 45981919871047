import find from 'lodash/find';
import { PERIOD_SELECT } from 'shared/PeriodAutocomplete/PeriodAutocomplete.models';

export const periodOptions = [
  {
    value: PERIOD_SELECT.THREE_MONTHS,
    label: '3 months',
  },
  {
    value: PERIOD_SELECT.SIX_MONTHS,
    label: '6 months',
  },
  {
    value: PERIOD_SELECT.ONE_YEAR,
    label: '1 year',
  },
];

export const defaultPeriodValue = PERIOD_SELECT.THREE_MONTHS;
export const defaultPeriodOption = find(periodOptions, { value: defaultPeriodValue });

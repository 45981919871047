import {
  GET_USER_MANAGEMENT_LIST_REQUEST,
  GET_USER_MANAGEMENT_LIST_SUCCESS,
  GET_USER_MANAGEMENT_LIST_FAILURE,
} from './actionsTypes';

const initialState = {
  isLoading: false,
  count: 0,
  results: [],
  errors: {},
};

const getUserManagementLisReducer = (state = initialState, {
  type, data, error,
}) => {
  if (type === GET_USER_MANAGEMENT_LIST_REQUEST) {
    return {
      ...state,
      isLoading: true,
      results: [],
    };
  }

  if (type === GET_USER_MANAGEMENT_LIST_SUCCESS) {
    return {
      ...state,
      ...data,
      isLoading: false,
    };
  }

  if (type === GET_USER_MANAGEMENT_LIST_FAILURE) {
    return {
      ...state,
      isLoading: false,
      results: [],
      error,
    };
  }

  return state;
};
export default getUserManagementLisReducer;

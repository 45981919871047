import React from 'react';
import { TextField } from '@mui/material';
import { useField } from 'formik';
import { GRID_DIRECTION } from 'shared/FormikField/FormikField.model';
import FormikTextareaFieldWrapper from 'shared/FormikField/FormikTextareaField/FormikTextareaFieldWrapper';

import './FormikTextareaField.scss';

interface IFormikTextareaFieldProps {
  fieldName: string,
  label?: string,
  required?: boolean,
  disabled?: boolean,
  gridDirection?: GRID_DIRECTION
}

const FormikTextareaField: React.FC<
  IFormikTextareaFieldProps
> = ({
  fieldName, label, required, disabled, gridDirection,
}) => {
  const [field, error] = useField(fieldName);

  const isError = Boolean(error.error && error.touched);
  const errorText = isError ? error.error : ' ';

  return (
    <FormikTextareaFieldWrapper
      fieldName={fieldName}
      label={label as string}
      required={required as boolean}
      gridDirection={gridDirection as GRID_DIRECTION}
    >
      <TextField
        {...field}
        multiline
        rows={2}
        error={isError}
        disabled={disabled as boolean}
        required={required as boolean}
        fullWidth
        helperText={errorText}
      />
    </FormikTextareaFieldWrapper>
  );
};

FormikTextareaField.defaultProps = {
  label: '',
  required: false,
  disabled: false,
  gridDirection: GRID_DIRECTION.COLUMN,
};

export default FormikTextareaField;

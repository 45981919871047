import { editCustomerAttachmentName } from 'core/api/customer.api';
// eslint-disable-next-line no-unused-vars
import { IDraftAttachment, IEditAttachmentError } from 'shared/widgets/Attachments/Attachments.model';
import {
  editCustomersAttachmentNameRequest,
  editCustomersAttachmentNameSuccess,
  editCustomersAttachmentNameFailure,
} from './actionsCreators';

const editCustomersAttachmentNameAction = (
  customerId: string | number,
  attachmentId: string | number,
  draftAttachment: IDraftAttachment,
) => async (dispatch) => {
  try {
    dispatch(editCustomersAttachmentNameRequest(attachmentId, draftAttachment));
    const { data } = await editCustomerAttachmentName(customerId, attachmentId, draftAttachment);
    dispatch(editCustomersAttachmentNameSuccess(data));
  } catch (error) {
    const { response: { data: { text, detail, name } } } = error as IEditAttachmentError;
    dispatch(editCustomersAttachmentNameFailure(attachmentId, detail || text || name));
    throw error;
  }
};

export default editCustomersAttachmentNameAction;

import React from 'react';
import { StatusCellClasses } from 'shared/Table/shared/StatusCell/model';

import './StatusCell.scss';

interface IEscalationSupportStatus {
  className: StatusCellClasses
  children: string
}

const StatusCell: React.FC<IEscalationSupportStatus> = (
  { className, children },
) => (
  <div className={`table__status ${className}`}>
    {children}
  </div>
);

export default StatusCell;

import { IEscalationSupportDataItem } from 'pages/Dashboard/Dashboard.models';
import {
  GET_ESCALATION_SUPPORT_DATA_REQUEST,
  GET_ESCALATION_SUPPORT_DATA_SUCCESS,
  GET_ESCALATION_SUPPORT_DATA_FAILURE,
} from '@dashboardEscalationSupport/EscalationSupportTable/GetEscalationSupport/actionsTypes';

interface IGetEscalationSupportDataState {
  isLoading: boolean;
  count: number;
  results: IEscalationSupportDataItem[];
  error: object;
}

const initialState: IGetEscalationSupportDataState = {
  isLoading: false,
  count: 0,
  results: [],
  error: {},
};

const getEscalationSupportDataReducer = (state = initialState, {
  type, data, error,
}) => {
  if (type === GET_ESCALATION_SUPPORT_DATA_REQUEST) {
    return {
      ...state,
      isLoading: true,
      results: [],
    };
  }

  if (type === GET_ESCALATION_SUPPORT_DATA_SUCCESS) {
    return {
      ...state,
      ...data,
      isLoading: false,
    };
  }

  if (type === GET_ESCALATION_SUPPORT_DATA_FAILURE) {
    return {
      ...state,
      isLoading: false,
      results: [],
      count: 0,
      error,
    };
  }

  return state;
};
export default getEscalationSupportDataReducer;

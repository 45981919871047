import React from 'react';
import { TRow } from 'shared/Table/Table.models';
import MoneyCell from 'shared/Table/shared/MoneyCell';
import formatDate from 'core/utilities/dateUtilities/formatDate';
import { MRT_ColumnDef as ColumnDef } from 'material-react-table';
import BookingStatusCell from 'shared/Table/shared/BookingStatusCell';
// eslint-disable-next-line no-unused-vars
import { ITableCellBookingDataItem } from 'pages/Dashboard/Dashboard.models';
import convertDataFromFormToTable from 'core/utilities/convertDataFromFormToTable';
import { BookingStatusCellClasses } from 'shared/Table/shared/BookingStatusCell/model';
import { BookingStatus } from '@dashboardWireline/WirelineDepartments/WIrelineDepartament.models';
import { bookingStatusLabels, serviceProductTypeLabels } from '@dashboardWireline/WirelineDepartments//labels';
import {
  BookingStatusOptions,
  CommissionTypeOptions,
} from '@dashboardWireline/WirelineDepartments/FilterSelectOptions';
import {
  whosLoginLabels,
  termLabels,
  systemLabels,
  pbxInterfaceTypeLabels,
  commissionTypeLabels,
} from 'pages/WirelineEngagements/WirelineEngagementsTables/constants';

const bookingStatusClassNames = {
  [BookingStatus.BOOKED]: BookingStatusCellClasses.GREEN,
  [BookingStatus.TRANSACTIONAL]: BookingStatusCellClasses.ROSE,
  [BookingStatus.PROJECT_BOOKED]: BookingStatusCellClasses.PURPLE,
  [BookingStatus.WORKING_ENGAGEMENT]: BookingStatusCellClasses.BLUE,
  [BookingStatus.CANCELED_ENGAGEMENT]: BookingStatusCellClasses.DARK,
  [BookingStatus.PENDING_ORDER_NUMBER]: BookingStatusCellClasses.ORANGE,
  [BookingStatus.PENDING_CUSTOMER_SIGNATURE]: BookingStatusCellClasses.YELLOW,
};

export const columns = [
  {
    header: 'Booking Status',
    accessorKey: 'bookingStatus',
    filterVariant: 'select',
    filterSelectOptions: convertDataFromFormToTable(BookingStatusOptions),
    muiTableHeadCellFilterTextFieldProps: { placeholder: 'Select type' },
    Cell: ({ renderedCellValue }: ITableCellBookingDataItem) => {
      if (!renderedCellValue) {
        return null;
      }

      const className = bookingStatusClassNames[renderedCellValue as string];
      const renderValue = bookingStatusLabels[renderedCellValue as string];

      return (
        <BookingStatusCell className={className}>
          {renderValue}
        </BookingStatusCell>
      );
    },
  },
  {
    header: 'Seller',
    accessorKey: 'sellerFullname',
    enableColumnFilter: true,
  },
  {
    header: 'Customer Name',
    accessorKey: 'customerCompanyName',
  },
  {
    header: 'TSD',
    accessorKey: 'tsd',
    filterVariant: 'select',
    filterSelectOptions: [],
    muiTableHeadCellFilterTextFieldProps: { placeholder: 'Select type' },
  },
  {
    header: 'Commission Type',
    accessorKey: 'commissionType',
    filterVariant: 'select',
    filterSelectOptions: convertDataFromFormToTable(CommissionTypeOptions),
    muiTableHeadCellFilterTextFieldProps: { placeholder: 'Select type' },
    Cell: ({ renderedCellValue }: ITableCellBookingDataItem) => renderedCellValue && (
      commissionTypeLabels[renderedCellValue as string]
    ),
  },
  {
    header: 'SPID',
    accessorKey: 'spid',
    enableColumnFilter: false,
    // TODO return when filters will be ready
    // filterVariant: 'select',
    // filterSelectOptions: convertDataFromFormToTable(SpidOptions),
    // muiTableHeadCellFilterTextFieldProps: { placeholder: 'Select type' },
  },
  {
    header: 'Existing Service MRC',
    accessorKey: 'existingMrc',
    enableColumnFilter: false,
    Cell: MoneyCell,
  },
  {
    header: 'New Service MRC',
    accessorKey: 'newServiceMrc',
    enableColumnFilter: false,
    Cell: MoneyCell,
  },
  {
    header: 'Signed',
    accessorKey: 'signed',
    enableColumnFilter: false,
    Cell: MoneyCell,
  },
  {
    header: 'Projected EOM',
    accessorKey: 'projectEom',
    enableColumnFilter: false,
    Cell: MoneyCell,
  },
  {
    header: 'Booked MRC',
    accessorKey: 'bookedMrc',
    enableColumnFilter: false,
    Cell: MoneyCell,
  },
  {
    header: 'Booked NRC',
    accessorKey: 'bookedNrc',
    enableColumnFilter: false,
    Cell: MoneyCell,
  },
  {
    header: 'Opp ID',
    enableColumnFilter: false,
    accessorKey: 'oppId',
  },
  {
    header: 'Order Number',
    enableColumnFilter: false,
    accessorKey: 'orderNumber',
  },
  {
    header: 'MDS/HALO ID/Response Entry',
    enableColumnFilter: false,
    accessorKey: 'mdsHaloId',
  },
  {
    header: 'Location',
    accessorKey: 'location',
  },
  {
    header: 'Product',
    accessorKey: 'product',
  },
  {
    header: 'Bandwidth',
    accessorKey: 'bandwidth',
  },
  {
    header: 'System',
    accessorKey: 'system',
    enableColumnFilter: false,
    // TODO return when filters will be ready
    // filterVariant: 'select',
    // filterSelectOptions: convertDataFromFormToTable(SystemOptions),
    // muiTableHeadCellFilterTextFieldProps: { placeholder: 'Select type' },
    Cell: ({ renderedCellValue }: ITableCellBookingDataItem) => renderedCellValue && (
      systemLabels[renderedCellValue as string]
    ),
  },
  {
    header: 'Quantity',
    accessorKey: 'quantity',
    enableColumnFilter: false,
    // TODO return when filters will be ready
    // muiTableHeadCellFilterTextFieldProps: {
    // placeholder: 'Enter number',
    // type: 'number',
    // },
  },
  {
    header: 'Contract Term',
    accessorKey: 'term',
    enableColumnFilter: false,
    // TODO return when filters will be ready
    // filterVariant: 'select',
    // filterSelectOptions: convertDataFromFormToTable(ContractTermOptions),
    // muiTableHeadCellFilterTextFieldProps: { placeholder: 'Select type' },
    Cell: ({ renderedCellValue }: ITableCellBookingDataItem) => {
      const value = Number(renderedCellValue);
      return renderedCellValue == null ? '' : termLabels[value];
    },
  },
  {
    header: 'BVoIP Interface Type',
    accessorKey: 'pbx',
    enableColumnFilter: false,
    // TODO return when filters will be ready
    // filterVariant: 'select',
    // filterSelectOptions: convertDataFromFormToTable(BVoIPInterfaceTypeOptions),
    // muiTableHeadCellFilterTextFieldProps: { placeholder: 'Select type' },
    Cell: ({ renderedCellValue }: ITableCellBookingDataItem) => renderedCellValue && (
      pbxInterfaceTypeLabels[renderedCellValue as string]
    ),
  },
  {
    header: 'Engagement Date',
    accessorKey: 'createdDatetime',
    enableColumnFilter: false,
    Cell: ({ renderedCellValue }: ITableCellBookingDataItem) => renderedCellValue && (
      formatDate(renderedCellValue as string)),
  },
  {
    header: 'Booked Date',
    accessorKey: 'bookedDate',
    enableColumnFilter: false,
    Cell: ({ renderedCellValue }: ITableCellBookingDataItem) => renderedCellValue && (
      formatDate(renderedCellValue as string)),
  },
  {
    header: 'Contract Request Date',
    accessorKey: 'contractRequestDate',
    enableColumnFilter: false,
    Cell: ({ renderedCellValue }: ITableCellBookingDataItem) => renderedCellValue && (
      formatDate(renderedCellValue as string)),
  },
  {
    header: 'Contract Sent Date',
    accessorKey: 'contractSentDate',
    enableColumnFilter: false,
    Cell: ({ renderedCellValue }: ITableCellBookingDataItem) => renderedCellValue && (
      formatDate(renderedCellValue as string)),
  },
  {
    header: 'Countersign Date',
    accessorKey: 'contractCountersigningDate',
    enableColumnFilter: false,
    Cell: ({ renderedCellValue }: ITableCellBookingDataItem) => renderedCellValue && (
      formatDate(renderedCellValue as string)),
  },
  {
    header: 'Order Handoff Date',
    accessorKey: 'orderHandoffDate',
    enableColumnFilter: false,
    Cell: ({ renderedCellValue }: ITableCellBookingDataItem) => renderedCellValue && (
      formatDate(renderedCellValue as string)),
  },
  {
    header: 'LOA Sent',
    enableColumnFilter: false,
    accessorKey: 'loaSent',
    Cell: ({ renderedCellValue }: ITableCellBookingDataItem) => (
      renderedCellValue ? 'Yes' : 'No'
    ),
  },
  {
    header: 'Contract Specialist',
    accessorKey: 'contractSpecialistFullname',
  },
  {
    header: 'Who\'s Login',
    accessorKey: 'whosLogin',
    enableColumnFilter: false,
    Cell: ({ renderedCellValue }: ITableCellBookingDataItem) => renderedCellValue && (
      whosLoginLabels[renderedCellValue as string]
    ),
  },
  {
    header: 'Sub-Agent',
    enableColumnFilter: false,
    accessorKey: 'subAgentName',
  },
  {
    header: 'Existing Contract ID',
    enableColumnFilter: false,
    accessorKey: 'existingContractId',
  },
  {
    header: 'New Service Contract ID',
    enableColumnFilter: false,
    accessorKey: 'newServiceContractId',
  },
  {
    header: 'Existing Service Account Number',
    enableColumnFilter: false,
    accessorKey: 'existingServiceAccountNumber',
  },
  {
    header: 'New Service Account Number',
    enableColumnFilter: false,
    accessorKey: 'newServiceAccountNumber',
  },
  {
    header: 'Circuit ID',
    enableColumnFilter: false,
    accessorKey: 'newServiceCircuitId',
  },
  {
    header: 'Port ID',
    enableColumnFilter: false,
    accessorKey: 'portId',
  },
  {
    header: 'Service Type',
    enableColumnFilter: false,
    accessorKey: 'serviceProductType',
    Cell: ({ renderedCellValue }: ITableCellBookingDataItem) => renderedCellValue && (
      serviceProductTypeLabels[renderedCellValue as string]
    ),
  },
  {
    header: 'Notes',
    enableColumnFilter: false,
    accessorKey: 'notes',
  },
] as ColumnDef<TRow>[];

export const exportBoogingSalesFileName = 'BookingTable';
export const exportBoogingReportFileName = 'BookingReport';

import React from 'react';
// @ts-ignore
import welcomePageImage from 'assets/images/welcome-page-icon.svg';

const WelcomePage = () => (
  <div className="welcome-page__wrap" data-testid="welcome-page">
    <div className="welcome-page">
      {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
      <img className="welcome-page__image" src={welcomePageImage} alt="welcome page image" />
      <h1 className="welcome-page__title">Welcome to the NetSpark system</h1>
      <p className="welcome-page__text">
        The account was created successfully.
        The administrator will receive an email and set up your account according to your position.
      </p>
      <p className="welcome-page__text">After that you will receive an email and will be able to use the system.</p>
    </div>
  </div>
);

export default WelcomePage;

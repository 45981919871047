import React from 'react';
import { Outlet } from 'react-router-dom';
import WirelineDepartmentsTabsContent
  from '@dashboardWireline/WirelineDepartments/WirelineDepartmentsTabs/WirelineDepartmentsTabsContent';
import { useAvailableSubRoutes } from 'core/routing/useAvailableRoutes';
import { dashboardWirelineRoutes } from 'pages/Dashboard/routes';
import accessNames from 'core/routing/accessRoutesNames';
import { DASHBOARD_TABS } from 'pages/Dashboard/Dashboard.models';

const WirelineDepartmentsTabs = () => {
  const availableDashboardWirelineRoutes = useAvailableSubRoutes(dashboardWirelineRoutes, [
    accessNames.dashboard, DASHBOARD_TABS.WIRELINE,
  ]);

  const isAvailableOneTab = availableDashboardWirelineRoutes.length === 1;

  if (isAvailableOneTab) {
    return <Outlet />;
  }

  return (
    <WirelineDepartmentsTabsContent
      routes={availableDashboardWirelineRoutes}
    />
  );
};

export default WirelineDepartmentsTabs;

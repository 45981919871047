import { IEngagementsOrdersByPeriodItem } from 'pages/Dashboard/Dashboard.models';

import {
  GET_ENGAGEMENTS_ORDERS_BY_PERIOD_REQUEST,
  GET_ENGAGEMENTS_ORDERS_BY_PERIOD_SUCCESS,
  GET_ENGAGEMENTS_ORDERS_BY_PERIOD_FAILURE,
} from './actionsTypes';

interface IGetEngagementsOrdersByPeriodState {
  data: IEngagementsOrdersByPeriodItem[];
  isLoading: boolean;
  error: object;
}

const initialState: IGetEngagementsOrdersByPeriodState = {
  isLoading: false,
  data: [],
  error: {},
};

const getEngagementsOrdersByPeriodReducer = (state = initialState, {
  type, data, error,
}) => {
  if (type === GET_ENGAGEMENTS_ORDERS_BY_PERIOD_REQUEST) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (type === GET_ENGAGEMENTS_ORDERS_BY_PERIOD_SUCCESS) {
    return {
      ...state,
      data,
      isLoading: false,
    };
  }

  if (type === GET_ENGAGEMENTS_ORDERS_BY_PERIOD_FAILURE) {
    return {
      ...state,
      isLoading: false,
      error,
      data: [],
    };
  }

  return state;
};
export default getEngagementsOrdersByPeriodReducer;

import paths from 'core/routing/paths';
import ContractManagementList from './ContractManagementList';

const contractManagementRoutes = [
  {
    path: paths.contractManagementList,
    component: ContractManagementList,
  },
];

export default contractManagementRoutes;

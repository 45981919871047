/* eslint-disable no-param-reassign */
import {
  getSalesOrderCommentsListAction, createSalesOrderCommentAction,
  deleteSalesOrderCommentItemAction, editSalesOrderCommentItemAction,
} from 'core/actions/Sales/OrderComments/Actions';
import { IComment } from 'shared/widgets/Comments/Comments.models';
import createListSlice from 'core/actions/utilities/createListSlice';

const initialState = {
  error: '',
  isLoading: false,
  results: [],
};

const SalesOrderCommentsSlice = createListSlice<
  IComment
>({
  name: 'SalesOrderCommentsSlice',
  initialState,
  createItemAction: createSalesOrderCommentAction,
  getListAction: getSalesOrderCommentsListAction,
  editItemAction: editSalesOrderCommentItemAction,
  deleteItemAction: deleteSalesOrderCommentItemAction,
});

export const {
  clearErrorsAction: clearCommentsErrorsAction,
  clearListAction: clearCommentsAction,
} = SalesOrderCommentsSlice.actions;

export const salesOrderCommentsReducer = SalesOrderCommentsSlice.reducer;

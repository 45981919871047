import { createEngagementAttachment } from 'core/api/wirelineEngagements.api';
import {
  createEngagementsAttachmentRequest,
  createEngagementsAttachmentSuccess,
  createEngagementsAttachmentFailure,
} from 'pages/WirelineEngagements/WirelineEngagementsAttachments/CreateEngagementsAttachment/actionsCreators';

const createEngagementsAttachmentAction = (
  engagementId: number | string,
  attachmentFile: FormData,
) => async (dispatch) => {
  try {
    dispatch(createEngagementsAttachmentRequest());
    const { data } = await createEngagementAttachment(engagementId, attachmentFile);
    dispatch(createEngagementsAttachmentSuccess(data));
  } catch (error) {
    dispatch(createEngagementsAttachmentFailure(error));
    throw error;
  }
};

export default createEngagementsAttachmentAction;

import React from 'react';
import { TableActiveRowContextProvider } from 'shared/Table/TableActiveRowContext';
import RenewalSowDataContainer from '@EngagementsRenewalSowData/RenewalSowDataContainer';

const RenewalSowData = () => (
  <TableActiveRowContextProvider>
    <RenewalSowDataContainer />
  </TableActiveRowContextProvider>
);

export default RenewalSowData;

import { IEngagementsOrdersByPeriodItem } from 'pages/Dashboard/Dashboard.models';

import {
  GET_PROJECT_ACCEPTANCE_REQUEST,
  GET_PROJECT_ACCEPTANCE_SUCCESS,
  GET_PROJECT_ACCEPTANCE_FAILURE,
} from '@dashboardWirelinePMDepartment/GetProjectAcceptance/actionsTypes';

interface IGetProjectAcceptanceState {
  data: IEngagementsOrdersByPeriodItem[];
  isLoading: boolean;
  error: object;
}

const initialState: IGetProjectAcceptanceState = {
  isLoading: false,
  data: [],
  error: {},
};

const getProjectAcceptanceReducer = (state = initialState, {
  type, data, error,
}) => {
  if (type === GET_PROJECT_ACCEPTANCE_REQUEST) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (type === GET_PROJECT_ACCEPTANCE_SUCCESS) {
    return {
      ...state,
      data,
      isLoading: false,
    };
  }

  if (type === GET_PROJECT_ACCEPTANCE_FAILURE) {
    return {
      ...state,
      isLoading: false,
      error,
      data: [],
    };
  }

  return state;
};
export default getProjectAcceptanceReducer;

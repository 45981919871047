import React from 'react';
import { useAppSelector } from 'core/store';

import EngagementsRevenueChart from '@dashboardWirelineDepartments/shared/EngagementsRevenueChart';

const TOEngagementsRevenueChart = () => {
  const engagementsRevenueData = useAppSelector(
    (state) => state.dashboard.engagementsTORevenue.data,
  );
  const isLoading = useAppSelector((state) => state.dashboard.engagementsTORevenue.isLoading);

  return (
    <EngagementsRevenueChart
      isLoading={isLoading}
      list={engagementsRevenueData}
    />
  );
};

export default TOEngagementsRevenueChart;

import React from 'react';
import { useFormikContext } from 'formik';
import CustomSidebar from 'shared/CustomSidebar';
import Loader from 'shared/Loader';
import FormikSelectField from 'shared/FormikField/FormikSelectField';
import { statusOptions } from '@dashboardEscalationSupport/EscalationSupportTable/constants';
import { IEscalationSupportItem } from '@dashboardEscalationSupport/EscalationSupportTable/model';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';

interface IEditRowEscalationSupportSidebarProps {
  title: string;
  isLoadingItem: boolean,
}

const EditRowEscalationSupportSidebar: React.FC<IEditRowEscalationSupportSidebarProps> = (
  { title, isLoadingItem },
) => {
  const { submitForm, setErrors, resetForm } = useFormikContext();
  const { activeRowId, cleanActiveRow } = useTableActiveRowContext<IEscalationSupportItem>();

  const closeEditRowSidebar = () => {
    cleanActiveRow();
    setErrors({});
    resetForm();
  };

  return (
    <CustomSidebar
      isOpen={!!activeRowId}
      onCloseSideBar={closeEditRowSidebar}
      title={title}
      onClickSave={submitForm}
    >
      {isLoadingItem ? <Loader /> : (
        <FormikSelectField
          label="Status"
          fieldName="status"
          options={statusOptions}
          required
          fullWidth
        />
      )}
    </CustomSidebar>
  );
};

export default EditRowEscalationSupportSidebar;

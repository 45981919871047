import React from 'react';
import PropTypes from 'prop-types';
import { ButtonBase } from '@mui/material';
import { withStyles } from '@mui/styles';
import styles from 'assets/styles/_resources.scss';

const TabComponent = (props, ref) => {
  const {
    icon, title, dataComponent, onclick, disabled, selected, ...tabProps
  } = props;

  const StyledTab = withStyles({
    root: {
      borderBottom: '1px solid transparent !important',
      '&:hover': {
        color: `${styles.primaryColor}!important`,
        borderBottom: `1px solid ${styles.primaryColor} !important`,
        transition: 'all 0.8s',
        '& path': {
          stroke: styles.primaryColor,
        },
        '& svg': {
          color: `${styles.primaryColor}!important`,
        },
        '& .tabs-tab-component__title': {
          color: `${styles.primaryColor}!important`,
        },
      },
      '&[aria-selected="true"]': {
        color: `${styles.primaryColor}!important`,
        '& path': {
          stroke: styles.primaryColor,
        },
        '& svg': {
          color: styles.primaryColor,
        },
        '& .tabs-tab-component__title': {
          color: `${styles.primaryColor}!important`,
        },
      },
      '&.Mui-disabled': {
        color: `${styles.disabledColor}!important`,
        '& path': {
          stroke: styles.disabledColor,
        },
        '& svg': {
          color: styles.ddisabledColor,
        },
        '& .tabs-tab-component__title': {
          color: styles.disabledColor,
        },
      },
    },
  })(ButtonBase);

  const newTabProps = {
    ...tabProps,
    children: (
      <>
        {icon}
        <span className="tabs-tab-component__title">{title}</span>
        {!disabled && dataComponent}
        {tabProps.children}
      </>),
  };

  return (
    <StyledTab
      role="tab"
      className="tabs-tab-component"
      onClick={onclick}
      disabled={disabled}
      aria-selected={selected}
      data-testid="tab-component"
      ref={{ ref }}
      {...newTabProps}
    />
  );
};

TabComponent.propTypes = {
  icon: PropTypes.instanceOf(Object),
  title: PropTypes.string,
  dataComponent: PropTypes.element,
  onclick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  selected: PropTypes.bool.isRequired,
};

TabComponent.defaultProps = {
  disabled: false,
  dataComponent: null,
  title: null,
  icon: null,

};
export default TabComponent;

import { configureStore } from '@reduxjs/toolkit';
// eslint-disable-next-line no-unused-vars
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import logger from 'redux-logger';

import rootReducer from 'core/rootReducer';

const isDevelopment = process.env.NODE_ENV === 'development';
const middlewares = [];

if (isDevelopment) {
  // @ts-ignore
  middlewares.push(logger);
}
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(
    {
      immutableCheck: false,
      serializableCheck: false,
    },
  ).concat(middlewares),
});

type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch;

export type RootState = ReturnType<typeof store.getState>

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;

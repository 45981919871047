// eslint-disable-next-line no-unused-vars
import { IOption } from './FormikField/FormikField.model';

export const status = {
  active: 'Active',
  inactive: 'Inactive',
};

export const numericStatusOptions: IOption[] = [
  { value: 1, label: status.active },
  { value: 0, label: status.inactive },
];

export default { status, numericStatusOptions };

import React, { useCallback, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { getCustomers } from 'core/api/customer.api';
import { debounceTime } from 'core/constants';
import useNotifiers from 'shared/Notifier/UseNotifiers';
// eslint-disable-next-line no-unused-vars
import { IOption } from 'shared/FormikField/FormikField.model';
import FormikAutocompleteField from 'shared/FormikField/FormikAutocompleteField';
import { ICustomerListItem } from 'pages/Customers/Customers.models';

const getOptionsFromCustomers = (customers: ICustomerListItem[]): IOption[] => (
  customers.map(({ companyName }) => ({
    value: companyName,
    label: companyName,
  }))
);

const CompanyNameSearch = () => {
  const { showErrorNotifier } = useNotifiers();

  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [customers, setCustomers] = useState<ICustomerListItem[]>([]);

  const loadOptions = useCallback(
    async (newInputValue: string) => {
      if (!newInputValue) return;

      try {
        setIsLoading(true);
        const { data: { results } } = await getCustomers({ search: newInputValue });
        setCustomers(results);
      } catch (e) {
        showErrorNotifier('Error while loading customers', e);
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, setCustomers],
  );

  const loadOptionsDebounce = useCallback(
    debounce(loadOptions, debounceTime),
    [loadOptions, loadOptions],
  );

  const onInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    setCustomers([]);

    loadOptionsDebounce(newInputValue);
  };

  const options = useMemo(() => getOptionsFromCustomers(customers), [customers]);

  return (
    <FormikAutocompleteField
      fieldName="companyName"
      label="Customer Name"
      inputValue={inputValue}
      onInputChange={onInputChange}
      options={options}
      isLoading={isLoading}
      required
      fullWidth
    />
  );
};

export default CompanyNameSearch;

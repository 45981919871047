import React, { useState } from 'react';
import filter from 'lodash/filter';
import Tabs from 'shared/Tabs';
import AttachmentList from 'shared/widgets/Attachments/AttachmentList';
import { Attachment, IAttachmentConfigItem } from 'shared/widgets/Attachments/Attachments.model';
import WidgetTemplate from 'shared/widgets/WidgetTemplate';
import Dropzone from 'shared/widgets/Attachments/Dropzone';

interface IAttachmentsProps {
  attachments: Attachment[],
  config: IAttachmentConfigItem[]
  isLoading: boolean
  createAttachment: (attachmentFile: FormData) => void
  editAttachment: (attachmentId: number | string, attachment: Attachment) => void
  removeAttachment: (attachmentId: number) => void
  clearAttachmentsErrors: () => void
  isAvailableAddAttachments?: boolean
}

const Attachments: React.FC<IAttachmentsProps> = ({
  config,
  isLoading,
  attachments,
  editAttachment,
  removeAttachment,
  createAttachment,
  clearAttachmentsErrors,
  isAvailableAddAttachments,
}) => {
  const [tabCategoryIndex, setTabCategoryIndex] = useState(0);
  const [numberDownloadFiles, setNumberDownloadFiles] = useState(attachments.length);
  const [attachmentInEditMode, setAttachmentInEditMode] = useState<Attachment | null>(null);
  const isTabInViewMode = config[tabCategoryIndex].isViewMode;

  const onDrop = (acceptedFiles: File[]) => {
    clearAttachmentsErrors();
    setNumberDownloadFiles(acceptedFiles.length);

    acceptedFiles.forEach((file) => {
      const attachmentFile = new FormData();
      attachmentFile.append('file', file);
      attachmentFile.append('category', config[tabCategoryIndex].category);
      createAttachment(attachmentFile);
    });
  };

  const changeAttachmentName = (e) => {
    if (!attachmentInEditMode) return;

    const name = e.target.value;
    setAttachmentInEditMode({ ...attachmentInEditMode, name });
  };

  const clearRedactionAttachment = () => {
    setAttachmentInEditMode(null);
  };

  const editAttachmentName = (attachmentId: string | number) => {
    if (!attachmentInEditMode) return;

    editAttachment(
      attachmentId,
      attachmentInEditMode,
    );
    clearRedactionAttachment();
  };

  const focusAttachment = (attachment: Attachment) => {
    setAttachmentInEditMode({ ...attachment });
    clearAttachmentsErrors();
  };

  const tabItemsConfig = config.map(({
    category, tabName, isViewMode,
  }) => {
    const categoryAttachments = filter(attachments, { category });

    return {
      name: tabName,
      dataComponent: (
        <span className="widget-attachments-tab__data">
          {categoryAttachments.length}
        </span>
      ),
      children: (
        <AttachmentList
          attachments={categoryAttachments}
          focusAttachment={focusAttachment}
          editAttachmentName={editAttachmentName}
          removeAttachment={removeAttachment}
          changeAttachmentName={changeAttachmentName}
          isLoadingAttachments={isLoading}
          attachmentInEditMode={attachmentInEditMode}
          numberDownloadFiles={numberDownloadFiles}
          isViewMode={isViewMode}
        />
      ),
    };
  });

  const attachmentsContent = (
    <Tabs
      tabItemsConfig={tabItemsConfig}
      setDefaultTabIndex={setTabCategoryIndex}
      defaultTabIndex={tabCategoryIndex}
      isScrollable
    />
  );

  const buttonsBlock = !isTabInViewMode && isAvailableAddAttachments
    ? <Dropzone onDrop={onDrop} />
    : null;

  return (
    <WidgetTemplate
      title="Attachments"
      isLoading={isLoading}
      buttonBlock={buttonsBlock}
      content={attachmentsContent}
      className="widget-attachments"
    />
  );
};

Attachments.defaultProps = {
  isAvailableAddAttachments: true,
};

export default Attachments;

import React from 'react';
import {
  ListItem, ListItemAvatar, ListItemText, IconButton, Typography, ListItemSecondaryAction,
} from '@mui/material';
import { LocationOnOutlined, EditOutlined, DeleteOutlined } from '@mui/icons-material';
import { ICustomerLocation } from 'pages/Customers/Customers.models';

interface ILocationProps {
    // eslint-disable-next-line no-restricted-globals
    location: ICustomerLocation
    onEdit: (editLocation: ICustomerLocation) => void;
    onRemove: (id) => void;
}

const LocationItem: React.FC<ILocationProps> = (
  { location, onEdit, onRemove },
) => (
  <ListItem alignItems="flex-start" disableGutters dense>
    <ListItemAvatar>
      <LocationOnOutlined />
    </ListItemAvatar>
    <div data-testid="location-item">
      <div className="location-list__name">{location.name}</div>
      <ListItemText
        disableTypography
        primary={<Typography className="location-list__address">{location.address}</Typography>}
        secondary={(
          <Typography className="location-list__address">
            {location.city}
            ,
            {location.state}
            ,
            {location.zipCode}
          </Typography>
                  )}
      />
    </div>
    <ListItemSecondaryAction>
      <IconButton
        size="small"
        aria-label="Edit location"
        onClick={() => onEdit(location)}
        disableRipple
        disabled={!location.isEditable}
      >
        <EditOutlined />
      </IconButton>
      <IconButton
        size="small"
        aria-label="Remove location"
        onClick={() => onRemove(location.id)}
        disableRipple
        disabled={!location.isEditable}
      >
        <DeleteOutlined />
      </IconButton>
    </ListItemSecondaryAction>
  </ListItem>
);

export default LocationItem;

import React from 'react';
// @ts-ignore
import logo from 'assets/images/logo.svg';
import LogoutIcon from '@mui/icons-material/Logout';
import { IconButton, Tooltip } from '@mui/material';
import SidebarToggle from 'layouts/Sidebar/SidebarToggle';
import KeycloakService from 'core/services/KeycloakService';
import HeaderBreadcrumbs from './HeaderBreadcrumbs';
import UserAccount from './UserAccount';

const Header = () => {
  const onLogout = () => KeycloakService.doLogout();

  return (
    <div className="header" data-testid="header">
      <div className="header__logo-wrap">
        <div className="header__sidebar-open-btn">
          <SidebarToggle />
        </div>
        <div className="header__logo">
          <img src={logo} alt="Netspark logo" />
        </div>
      </div>
      <div className="header__breadcrumbs">
        <HeaderBreadcrumbs />
      </div>
      <div className="header__user-account">
        <UserAccount />
        <Tooltip
          title="Log Out"
          arrow
          placement="bottom-end"
          PopperProps={{
            sx: {
              top: ' -10px !important',
              '& .MuiTooltip-tooltip': {
                padding: ' 10px 20px !important',
                fontSize: '14px !important',
                width: '98px',
              },
            },
          }}
        >
          <IconButton
            onClick={onLogout}
            className="header__logout"
            data-testid="logout"
          >
            <LogoutIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};
export default Header;

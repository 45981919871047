import React from 'react';
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import CustomTooltip from 'shared/CustomTooltip';
import { TooltipPlacement } from 'shared/CustomTooltip/customTooltip.models';
import OrderModalItem from 'shared/OrderModalItem';
import './EngagementAcceptedItems.scss';

interface IEngagementAcceptedItemsProps {
  selectedSowData: ISowDataListItem[];
}

const EngagementAcceptedItems: React.FC<IEngagementAcceptedItemsProps> = ({
  selectedSowData,
}) => (
  <div className="sow-engagement-accepted-items">
    {selectedSowData.map(({
      customerLocation,
      product,
      id,
      contractEngagementAcceptedDate,
    }) => {
      const isAccepted = !!contractEngagementAcceptedDate;

      return (
        <CustomTooltip
          title="The engagement has been accepted"
          isHidden={!isAccepted}
          placement={TooltipPlacement.TOP}
          key={id}
        >
          <OrderModalItem
            product={product}
            accepted={isAccepted}
            location={customerLocation}
          />
        </CustomTooltip>
      );
    })}
  </div>
);

export default EngagementAcceptedItems;

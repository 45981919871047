// eslint-disable-next-line no-unused-vars
import React, { FC } from 'react';

interface IShowProps {
  children: JSX.Element | JSX.Element[],
  on: boolean,
}

const Show: FC<IShowProps> = ({ children, on }) => {
  if (!on) {
    return null;
  }

  if (Array.isArray(children)) {
    return (
      <>
        {children.map((child) => child)}
      </>
    );
  }

  return children;
};

export default Show;

import React from 'react';
import { useEscalationSupportChartsContext } from '@dashboardEscalationSupport/EscalationSupportCharts/EscalationSupportChartsContext';
import EscalationSupportFiltersByPeriod from '@dashboardEscalationSupport/EscalationSupportCharts/EscalationSupportFiltersByPeriod';
import EscalationSupportChartsTotal from '@dashboardEscalationSupport/EscalationSupportCharts/Charts/Total/EscalationSupportChartsTotal';
import EscalationSupportEscalationTypeChart
  from '@dashboardEscalationSupport/EscalationSupportCharts/Charts/EscalationType/EscalationSupportEscalationTypeChart';

const EscalationSupportCharts = () => {
  const { actionsPermissions } = useEscalationSupportChartsContext();

  const {
    isAvailableTotalChart,
    isAvailableEscalationTypeChart,
  } = actionsPermissions;

  return (
    <>
      <EscalationSupportFiltersByPeriod />
      <div className="escalation-support__charts">
        {isAvailableTotalChart && <EscalationSupportChartsTotal />}
        {isAvailableEscalationTypeChart && <EscalationSupportEscalationTypeChart />}
      </div>
    </>
  );
};

export default EscalationSupportCharts;

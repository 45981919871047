import React from 'react';
import { useAppDispatch, useAppSelector } from 'core/store';
import { PostSetInsideSalesManagerAction } from 'core/actions/crm/InsideSales/PostSetInsideSalesManager';
import { useTableSelectedRows } from 'shared/Table/hooks/useTableSelectedRows';
import CustomModal from 'shared/CustomModal';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useTableContext } from 'shared/Table/TableContext';
import { IInsideSalesListItem } from 'pages/InsideSales/model';

interface IInsideSalesAcceptedModalProps {
  isInsideSalesAcceptedModalOpen: boolean
  setIsInsideSalesAcceptedModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const InsideSalesAcceptedModal: React.FC<
  IInsideSalesAcceptedModalProps
> = ({
  isInsideSalesAcceptedModalOpen,
  setIsInsideSalesAcceptedModalOpen,
}) => {
  const dispatch = useAppDispatch();
  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();
  const { getTableData, setRowSelection } = useTableContext();

  const rows = useAppSelector((state) => state.insideSales.list.results);

  const selectedRowsData = useTableSelectedRows<IInsideSalesListItem>({ rows });

  const onAccept = async () => {
    await Promise.all(selectedRowsData.map(
      async ({ id, companyName, sellerFullname }) => {
        try {
          await dispatch(PostSetInsideSalesManagerAction({ id })).unwrap();
          showSuccessNotifier(
            ` Successfully accepted request for ${companyName} by ${sellerFullname}.`,
          );
        } catch (e) {
          showErrorNotifier(
            `Failed to accept request for ${companyName} by ${sellerFullname}.`,
            e,
          );
        }
      }));

    setRowSelection({});
    getTableData();
    setIsInsideSalesAcceptedModalOpen(false);
  };

  return (
    <CustomModal
      title="Are you sure you want to accept selected request/s?"
      isModalOpen={isInsideSalesAcceptedModalOpen}
      confirmButtonText="Yes"
      cancelButtonText="No"
      onClickConfirm={onAccept}
      onCloseModal={setIsInsideSalesAcceptedModalOpen}
      areButtonsVisible
    >
      <>
        {
          selectedRowsData.map(({ id, companyName, sellerFullname }) => (
            <div
              key={id}
              className="custom-modal__list__item"
            >
              Request for
              {' '}
              {companyName}
              {' '}
              has been created by
              {' '}
              {sellerFullname}
              .
            </div>
          ))
        }
      </>
    </CustomModal>
  );
};

export default InsideSalesAcceptedModal;

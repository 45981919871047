import React from 'react';
import { Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import paths from 'core/routing/paths';

interface IUserManagementCreateFormButtonsProps {
  isLoading: boolean;
}

const UserManagementCreateButtons: React.FC<
  IUserManagementCreateFormButtonsProps
> = ({ isLoading }) => {
  const navigate = useNavigate();

  const onCancel = () => {
    navigate(paths.userManagement);
  };

  return (
    <Grid
      sx={{ mt: 2 }}
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Button
        onClick={onCancel}
        disabled={isLoading}
        variant="outlined"
      >
        back to users list
      </Button>
    </Grid>
  );
};

export default UserManagementCreateButtons;

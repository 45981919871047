import React, { useMemo } from 'react';
import cx from 'classnames';
import { Button } from '@mui/material';
import { useFormikContext } from 'formik';

import ActiveIcon from '@mui/icons-material/LockOpen';
import DeactivateIcon from '@mui/icons-material/LockOutlined';
import { ICustomerListItem } from 'pages/Customers/Customers.models';
import { customerStatus } from 'pages/Customers/CustomersCreate/constants';

interface ICustomersCreateFormActivateButtonProps {
  isLoading: boolean
}

const CustomersCreateFormActivateButton: React.FC<ICustomersCreateFormActivateButtonProps> = ({
  isLoading,
}) => {
  const {
    values,
    setFieldValue,
  } = useFormikContext<ICustomerListItem>();

  const { status } = values || {};

  const { icon, label, buttonStyle } = useMemo(() => ({
    label: status ? 'Deactivate' : 'Activate',
    icon: status ? <DeactivateIcon /> : <ActiveIcon />,
    buttonStyle: cx(
      'customers-create-form__status-button',
      { 'customers-create-form__status-button-deactivate': status },
    ),
  }), [status]);

  const onToggleStatus = () => {
    const updatedStatus = values.status === customerStatus.active
      ? customerStatus.inactive
      : customerStatus.active;
    setFieldValue('status', updatedStatus);
  };

  return (
    <Button
      type="button"
      color="primary"
      startIcon={icon}
      variant="outlined"
      disabled={isLoading}
      className={buttonStyle}
      onClick={onToggleStatus}
    >
      {label}
    </Button>
  );
};

export default CustomersCreateFormActivateButton;

import React from 'react';
import { MRT_ColumnDef as ColumnDef } from 'material-react-table';
import { TRow } from 'shared/Table/Table.models';
// eslint-disable-next-line no-unused-vars
import FilterDatePicker from 'shared/Table/shared/FilterDatePicker';
import dayjs from 'dayjs';

import { dateFormat } from 'core/constants';

export const maxTableHeight = '600px';

export const columns = [
  {
    header: 'Customer Name',
    accessorKey: 'companyName',
  },
  {
    header: 'Seller',
    accessorKey: 'sellerFullname',
  },
  {
    header: 'Request Creation Date',
    accessorKey: 'createdDatetime',
    Cell: ({ cell }) => {
      const value = cell.getValue<Date>();
      return value ? dayjs(value).format(dateFormat) : '';
    },
    filterFn: 'greaterThanOrEqualTo',
    sortingFn: 'date',
    Filter: ({ column }) => (
      <FilterDatePicker
        getFilterValue={() => column.getFilterValue() as string | null}
        setFilterValue={(value: string | null) => column.setFilterValue(value)}
      />
    ),
  },
  {
    header: 'Request Accepted Date',
    accessorKey: 'acceptedDatetime',
    Cell: ({ cell }) => {
      const value = cell.getValue<Date>();
      return value ? dayjs(value).format(dateFormat) : '';
    },
    Filter: ({ column }) => (
      <FilterDatePicker
        getFilterValue={() => column.getFilterValue() as string | null}
        setFilterValue={(value: string | null) => column.setFilterValue(value)}
      />
    ),
  },
  {
    header: 'Inside Sales Executive',
    accessorKey: 'insideSalesManagerFullname',
  },
  {
    header: 'Canceled Request Date',
    accessorKey: 'canceledDatetime',
    Cell: ({ cell }) => {
      const value = cell.getValue<Date>();
      return value ? dayjs(value).format(dateFormat) : '';
    },
    Filter: ({ column }) => (
      <FilterDatePicker
        getFilterValue={() => column.getFilterValue() as string | null}
        setFilterValue={(value: string | null) => column.setFilterValue(value)}
      />
    ),
  },
  {
    header: 'Completion Date',
    accessorKey: 'completedDatetime',
    Cell: ({ cell }) => {
      const value = cell.getValue<Date>();
      return value ? dayjs(value).format(dateFormat) : '';
    },
    Filter: ({ column }) => (
      <FilterDatePicker
        getFilterValue={() => column.getFilterValue() as string | null}
        setFilterValue={(value: string | null) => column.setFilterValue(value)}
      />
    ),
  },
] as ColumnDef<TRow>[];

import React from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { wirelineDepartmentsTabsRootStyles, wirelineDepartmentsTabStyles } from '@dashboardWireline/WirelineDepartments/WirelineDepartmentsTabs/wirelineDepartmentsTabsStyles';
import { IRoutingConfigItem } from 'core/routing/models';

interface IWirelineDepartmentsTabsContent {
  routes: IRoutingConfigItem[],
}

const WirelineDepartmentsTabsContent: React.FC<
  IWirelineDepartmentsTabsContent
> = ({ routes }) => {
  const { pathname } = useLocation();
  const value = routes.find(({ path }) => pathname.includes(path))?.path;

  return (
    <Box>
      <Tabs
        value={value}
        aria-label="button tabs"
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        sx={{ ...wirelineDepartmentsTabsRootStyles }}
      >
        {routes.map(({ path, label }) => (
          <Tab
            key={path}
            sx={{ ...wirelineDepartmentsTabStyles }}
            aria-label={label}
            label={label}
            to={path}
            value={path}
            component={NavLink}
          />
        ),
        )}
      </Tabs>
      <Outlet />
    </Box>
  );
};

export default WirelineDepartmentsTabsContent;

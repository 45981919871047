import React, { useState } from 'react';
import {
  Paper, useMediaQuery, useTheme, Hidden, Collapse, IconButton,
} from '@mui/material';
import Title from 'shared/Title';
import Preloader from 'shared/Preloader';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface WidgetTemplateProps {
  title: string;
  content: React.ReactNode;
  buttonBlock?: React.ReactNode;
  isLoading?: boolean;
  className?: string;
  headerButton?: React.ReactNode;
}

const WidgetTemplate: React.FC<WidgetTemplateProps> = ({
  title, content, buttonBlock, isLoading, className, headerButton,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isExpanded, setIsExpanded] = useState(isMobile);

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Paper className={`widget ${className}`} sx={{ p: 3 }}>
      <div className="widget__title-wrap">
        <Title title={title} variant="h3" className="widget__title title" />
        <div>
          {headerButton}
          <Hidden mdUp>
            <IconButton
              onClick={toggleIsExpanded}
              aria-selected={isExpanded}
              className="widget__toggle"
            >
              <ExpandMoreIcon />
            </IconButton>
          </Hidden>
        </div>
      </div>
      {isLoading && <Preloader />}
      <Collapse in={!isMobile || isExpanded}>
        <div className="widget__content">
          {content}
          <div className="widget__content-btn">
            {buttonBlock}
          </div>
        </div>
      </Collapse>
    </Paper>
  );
};

WidgetTemplate.defaultProps = {
  isLoading: false,
  className: '',
  headerButton: null,
  buttonBlock: null,
};

export default WidgetTemplate;

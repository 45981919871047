import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { MuiPaletteColors } from 'core/models/MUI.models';

interface IIconButtonWithTooltipProps {
  title: string
  onClick: () => void
  children: JSX.Element
  color?: MuiPaletteColors
  disabled?: boolean
}

const IconButtonWithTooltip: React.FC<IIconButtonWithTooltipProps> = ({
  children, title, onClick, color, disabled,
}) => (
  <Tooltip title={title}>
    <span>
      <IconButton
        onClick={onClick}
        color={color as MuiPaletteColors}
        disabled={disabled as boolean}
      >
        {children}
      </IconButton>
    </span>
  </Tooltip>
);

IconButtonWithTooltip.defaultProps = {
  color: 'primary',
  disabled: false,
};

export default IconButtonWithTooltip;

import {
  getAppVariablesFailure,
  getAppVariablesRequest,
  getAppVariablesSuccess,
} from './actionsCreators';
import getAppVariables from './api.service';

export const getAppVariablesAction = () => async (dispatch) => {
  try {
    dispatch(getAppVariablesRequest());

    const { data } = await getAppVariables();

    dispatch(getAppVariablesSuccess(data));

    return data;
  } catch (error) {
    dispatch(getAppVariablesFailure(error));
    throw error;
  }
};

export default getAppVariablesAction;

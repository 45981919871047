import React from 'react';
import { CircularProgress, IconButton } from '@mui/material';
import { DeleteOutlineOutlined, EditOutlined } from '@mui/icons-material';
import { Attachment } from 'shared/widgets/Attachments/Attachments.model';

interface IAttachmentListItemButtonsProps {
  attachment: Attachment
  isEditMode: boolean
  focusAttachment: (attachment: Attachment) => void
  removeAttachment: (attachmentId: number) => void
}

const AttachmentListItemButtons: React.FC<IAttachmentListItemButtonsProps> = ({
  isEditMode, focusAttachment, attachment, removeAttachment,
}) => {
  const { id, isLoading, isEditable } = attachment;

  if (isLoading) {
    return <CircularProgress color="secondary" size={22} />;
  }

  return (
    <>
      {!isEditMode && (
        <IconButton
          onClick={() => focusAttachment(attachment)}
          size="small"
          aria-label="Edit attach"
          disableRipple
          disabled={!isEditable}
        >
          <EditOutlined />
        </IconButton>
      )}
      <IconButton
        onClick={() => removeAttachment(id)}
        size="small"
        aria-label="Remove attach"
        disableRipple
        disabled={!isEditable || isEditMode}
      >
        <DeleteOutlineOutlined />
      </IconButton>
    </>
  );
};

export default AttachmentListItemButtons;

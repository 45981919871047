import { removeCustomerLocation } from 'core/api/customer.api';
import {
  removeLocationRequest,
  removeLocationSuccess,
  removeLocationFailure,
} from 'pages/Customers/CustomersLocations/RemoveLocation/actionCreators';

const removeLocationAction = (
  customerId: number | string,
  locationId: number | string,
) => async (dispatch) => {
  try {
    dispatch(removeLocationRequest());
    const { data } = await removeCustomerLocation(customerId, locationId);
    dispatch(removeLocationSuccess(data));
  } catch (error) {
    dispatch(removeLocationFailure(error));
    throw error;
  }
};

export default removeLocationAction;

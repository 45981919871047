import { ICustomerAttachment } from 'pages/Customers/Customers.models';
import { IEngagementAttachment } from 'pages/WirelineEngagements/WirelineEngagements.models';
import { IInsideSalesRequestAttachment } from 'pages/InsideSales/model';

export interface IAttachment {
  id: number | string
  file: string
  // eslint-disable-next-line no-restricted-globals
  name: string
  isEditable: boolean
  creatorFirstName: string
  creatorLastName: string
  createdDatetime: string
  isLoading?: boolean
  error?: string
  category: string
}

export interface IDraftAttachment {
  // eslint-disable-next-line no-restricted-globals
  name: string
}

export interface IAttachmentInEditMode {
  // eslint-disable-next-line no-restricted-globals
  name: string
  id: number | string
}

export interface IAttachmentError {
  error: {
    detail: string
  }
}

export interface IEditAttachmentError {
  response: {
    data: {
      text: string[]
      detail: string[]
      // eslint-disable-next-line no-restricted-globals
      name: string[]
    }
  }
}

export enum AttachmentsCategory {
  QUOTES = 'QUOTES',
  CONTRACTS = 'CONTRACTS',
  ATTACHMENTS = 'ATTACHMENTS',
  NETWORK_DIAGRAMS = 'NETWORK_DIAGRAMS',
}

export type Attachment = ICustomerAttachment | IEngagementAttachment | IInsideSalesRequestAttachment

export interface IAttachmentConfigItem {
  category: string
  tabName: string
  isViewMode?: boolean
}

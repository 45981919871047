import React, { useMemo } from 'react';
import { useField } from 'formik';
import {
  Grid, Tooltip, IconButton, Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import FormikSelectField from 'shared/FormikField/FormikSelectField';
import { TAddOrderType, ADD_ORDER_TYPE } from 'pages/WirelineEngagements/WirelineEngagements.models';
import { IFormRequestTypeProductItem } from 'pages/WirelineEngagements/shared/AddOrderModalForm//product.model';
import AddOrderRequestTypeField from 'pages/WirelineEngagements/shared/AddOrderModalForm/AddOrderRequestTypeForm/AddOrderRequestTypeField';
import {
  fields,
  schemaSellerRequestType,
  renewelOrderSellerRequestType,
  newOrdersSellerRequestTypeATTOptions,
  newOrdersSellerRequestTypeNonATTOptions,
} from 'pages/WirelineEngagements/shared/AddOrderModalForm/constants';

interface IAddOrderRequestTypeItem {
  attTSDId: number
  itemCount: number
  canRemove: boolean
  onRemove: () => void
  orderModalType: TAddOrderType
  data: IFormRequestTypeProductItem
}

const AddOrderRequestTypeItem: React.FC<IAddOrderRequestTypeItem> = ({
  data, attTSDId, itemCount, onRemove, canRemove, orderModalType,
}) => {
  const [sellerRequestTypeField] = useField(`products[${itemCount}][${fields.sellerRequestType}]`);
  const [paramsField,, paramsHelpers] = useField(`products[${itemCount}].params`);

  const { locationName, productName, tsdId } = data;

  const schema = sellerRequestTypeField.value && orderModalType
  && schemaSellerRequestType?.[orderModalType]?.[sellerRequestTypeField.value];

  const sellerRequestTypeOptions = useMemo(() => {
    const isATT = tsdId === attTSDId;

    switch (orderModalType) {
      case ADD_ORDER_TYPE.NEW_ORDER: {
        return isATT
          ? newOrdersSellerRequestTypeATTOptions
          : newOrdersSellerRequestTypeNonATTOptions;
      }
      case ADD_ORDER_TYPE.RENEWAL_ORDER: {
        return renewelOrderSellerRequestType;
      }
      default: {
        return [];
      }
    }
  }, [tsdId, attTSDId, orderModalType]);

  const onChangeHandler = (event) => {
    const emptyParamsData = Object.fromEntries(Object.entries(paramsField.value).map(([key]) => ([key, ''])));
    const nestedItemSchema = orderModalType
     && schemaSellerRequestType?.[orderModalType]?.[event.target.value];
    const { options, fieldName } = nestedItemSchema || {};

    const updatedParamsData = options?.length === 1
      ? { ...emptyParamsData, [fieldName]: options[0].value }
      : emptyParamsData;

    paramsHelpers.setValue(updatedParamsData);
    paramsHelpers.setTouched(false);
  };

  return (
    <Grid
      item
      xs={12}
      container
      columnSpacing={3}
    >
      <Grid
        item
        xs={2}
      >
        <Tooltip
          arrow
          placement="top"
          title={locationName}
        >
          <Typography
            noWrap
            variant="body1"
          >
            {locationName}
          </Typography>
        </Tooltip>
      </Grid>
      <Grid
        item
        xs={2}
      >
        <Tooltip
          arrow
          placement="top"
          title={productName}
        >
          <Typography
            noWrap
            variant="body1"
          >
            {productName}
          </Typography>
        </Tooltip>
      </Grid>
      <Grid
        item
        xs={7}
        container
        columnSpacing={2}
      >
        <Grid
          item
          xs={4}
        >
          <FormikSelectField
            required
            fullWidth
            onChange={onChangeHandler}
            options={sellerRequestTypeOptions}
            fieldName={`products[${itemCount}][${fields.sellerRequestType}]`}
          />
        </Grid>
        { !!schema && (
          <AddOrderRequestTypeField
            schema={schema}
            itemCount={itemCount}
          />
        )}
      </Grid>
      {canRemove
      && (
      <Grid
        item
        xs={1}
        container
        alignItems="flex-start"
        justifyContent="flex-end"
      >
        <IconButton
          onClick={onRemove}
        >
          <DeleteIcon
            fontSize="medium"
          />
        </IconButton>
      </Grid>
      )}
    </Grid>
  );
};

export default AddOrderRequestTypeItem;

import * as Yup from 'yup';

export function requiredConditionally(this: any, conditionField: string, message) {
  return this.when(
    conditionField,
    { is: true, then: (schema) => schema.required(message) },
  );
}

Yup.addMethod<any>(Yup.string, 'requiredConditionally', requiredConditionally);

export default Yup;

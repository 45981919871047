import React from 'react';
import { useAppSelector } from 'core/store';
import DashboardPieChart from 'pages/Dashboard/shared/DashboardPieChart';
import { colorsTransactionalOrdersChart } from '@dashboardWirelineContractSpecialistDepartment/constants';

const TransactionalOrdersChart = () => {
  const transactionalOrdersData = useAppSelector(
    (state) => state.dashboard.transactionalOrders.data,
  );
  const isLoading = useAppSelector(
    (state) => state.dashboard.transactionalOrders.isLoading,
  );

  return (
    <DashboardPieChart
      colors={colorsTransactionalOrdersChart}
      data={transactionalOrdersData}
      isLoading={isLoading}
      innerRadius={60}
      paddingAngle={2}
      title="Transactional Orders"
    />
  );
};

export default TransactionalOrdersChart;

/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IChartItem } from 'core/models/Recharts.models';
// eslint-disable-next-line no-unused-vars
import { TPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';
import getRequestType from '@dashboardWirelineBillingDepartment/GetRequestType/api.service';

export interface IState {
  data: IChartItem[];
  isLoading: boolean;
  error: object | null;
}

const initialState: IState = {
  data: [],
  isLoading: false,
  error: null,
};

export const getRequestTypeAction = createAsyncThunk(
  'getRequestTypeAction',
  async ({ controller, params }: { controller: AbortController, params: TPeriod },
    { rejectWithValue }) => {
    try {
      const { signal } = controller;
      const { data } = await getRequestType(signal, params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const requestTypeSlice = createSlice({
  name: 'getRequestType',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getRequestTypeAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRequestTypeAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(getRequestTypeAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
        state.data = [];
      });
  },
});

export const getRequestTypeReducer = requestTypeSlice.reducer;

import React, {
  useEffect, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { getUserById } from 'core/api/user.api';
import { getTeams } from 'core/api/teams.api';
import Loader from 'shared/Loader';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import {
  ITeamRole, IUserWithTeamRoles, IUser,
} from 'pages/UserManagement/UserManagement.models';
import { getUserEditFromUserList } from 'pages/UserManagement/utilities';
import UserManagementCreateProvider from 'pages/UserManagement/UserManagementCreate/UserManagementCreateContext';
import { loadUserNotifications } from 'pages/UserManagement/UserManagementCreate/constants';
import { ITeam } from 'pages/UserManagement/UserManagementCreate/UserManagementGeneralForm/UserManagementGeneralForm.models';
import { userManagementGeneralFormSchema } from 'pages/UserManagement/UserManagementCreate/UserManagementGeneralForm/userManagementGeneralFormSchema';
import { teamRolesSchema } from 'pages/UserManagement/UserManagementCreate/UserManagementTeamRoleForm/UserManagementTeamRoleFormSchema';
import UserManagementGeneralForm from 'pages/UserManagement/UserManagementCreate/UserManagementGeneralForm/UserManagementGeneralForm';
import UserManagementBackButton from 'pages/UserManagement/UserManagementCreate/UserManagementBackButton';
import UserManagementCreateButtons from 'pages/UserManagement/UserManagementCreate/UserManagementCreateButtons';
import UserManagementTeamRoleForm from 'pages/UserManagement/UserManagementCreate/UserManagementTeamRoleForm';
import UserManagementConfirmDialog from 'pages/UserManagement/UserManagementCreate/UserManagementConfirmDialog';

const UserManagementCreate = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [initialUserValues, setInitialUserValues] = useState<IUser | undefined>();
  const [initialTeamRoles, setInitialTeamRoles] = useState<ITeamRole[] | undefined>();
  const [teams, setTeams] = useState<ITeam[]>([]);

  const isInitialLoading = !initialUserValues && !initialTeamRoles && isLoading;

  const { showErrorNotifier } = useNotifiers();

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        if (id) {
          const { data: userData } = await getUserById(id);
          const { teamRoles, ...user } = getUserEditFromUserList(userData);

          setInitialUserValues({
            ...user,
            type: user.type || userManagementGeneralFormSchema.getDefault().type,
          });
          setInitialTeamRoles(teamRoles);
        } else {
          setInitialUserValues(userManagementGeneralFormSchema.getDefault() as IUserWithTeamRoles);
          setInitialTeamRoles(teamRolesSchema.getDefault() as ITeamRole[]);
        }

        const { data: teamsData } = await getTeams();
        setTeams(teamsData);
      } catch (e) {
        showErrorNotifier(loadUserNotifications.error, e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [id]);

  if (isInitialLoading) {
    return <Loader />;
  }

  return (
    <UserManagementCreateProvider
      teams={teams}
      initialUserValues={initialUserValues as IUser}
    >
      <Box className="user-management-create-form__wrapper">
        <UserManagementBackButton />
        {isLoading && <Loader />}
        <UserManagementGeneralForm
          initialUserValues={initialUserValues as IUser}
          setInitialUserValues={setInitialUserValues}
          setIsLoading={setIsLoading}
        />
        <UserManagementTeamRoleForm
          initialTeamRoles={initialTeamRoles as ITeamRole[]}
          setInitialTeamRoles={setInitialTeamRoles}
          setIsLoading={setIsLoading}
        />
      </Box>
      <UserManagementCreateButtons isLoading={isLoading} />
      <UserManagementConfirmDialog />
    </UserManagementCreateProvider>
  );
};

export default UserManagementCreate;

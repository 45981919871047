import React from 'react';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import NoData from 'shared/NoData';

const CommentsEmpty = () => (
  <NoData
    icon={<ChatBubbleOutlineIcon fontSize="large" />}
    title="No Comments yet."
    description="Please enter a comment below to add a comment."
  />
);

export default CommentsEmpty;

import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import { IOption } from 'shared/FormikField/FormikField.model';
import { modifiers } from '@dashboardWirelineSellerDepartment/TechnicalOverlay/constants';
import { DateFilter } from '@dashboardWirelineSellerDepartment/TechnicalOverlay/TechnicalOverlay.models';

interface IDateAutocomplete {
    onChange: (date: DateFilter) => void
    options: IOption<DateFilter>[]
    value: DateFilter
}
const DateAutocomplete: React.FC<IDateAutocomplete> = ({ onChange, options, value }) => {
  const onChangePeriod = (event, option: IOption<DateFilter>) => {
    onChange(option.value);
  };

  const autocompleteValue = options.find((option) => option.value === value);

  const renderInput = (params) => (
    <TextField
      {...params}
      variant="outlined"
      fullWidth
      size="medium"
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <EventIcon color="action" />
        ),
      }}
    />
  );

  return (
    <Autocomplete
      value={autocompleteValue as IOption<DateFilter>}
      onChange={onChangePeriod}
      disableClearable
      data-testid={modifiers.dateFilter}
      options={options}
      sx={{ width: 170 }}
      renderInput={renderInput}
      className={modifiers.dateFilter}
    />
  );
};
export default DateAutocomplete;

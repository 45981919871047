import React, {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react';
import { useAppDispatch } from 'core/store';
import { HTTPService } from 'core/services';
import periodMapping from 'shared/PeriodAutocomplete/constants';
import { useActionsPermissions } from 'core/hooks/useActionsPermissions';
import calculatePeriod from 'core/utilities/dateUtilities/calculatePeriod';
import { notifierMessage } from '@dashboardWirelineDepartments/shared/constants';
import { PERIOD_SELECT } from 'shared/PeriodAutocomplete/PeriodAutocomplete.models';
import { ACTIONS_DASHBOARD_WIRELINE_SALES_PATHS } from 'core/models/actionsPermissions';
import { TPeriod, TSetPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';
import getEngagementsRevenueAction from '@dashboardWirelineSellerDepartment/GetEngagementsRevenue/actions';
import getEngagementsOrdersByPeriodAction from '@dashboardWirelineSellerDepartment/GetEngagementsOrdersByPeriod/actions';
import getEngagementsTotalOrdersAction from '@dashboardWirelineSellerDepartment/GetEngagementsTotalOrders/actions';
import useNotifiers from 'shared/Notifier/UseNotifiers';

const {
  MRC_CHART_GET,
  TOTAL_CHART_GET,
  BOOKING_TABLE_GET,
  BOOK_ORDER_CREATE,
  BY_PERIOD_CHART_GET,
  BY_REVENUE_CHART_GET,
  EXPORT_BOOGING_TABLE_GET,
  BOOGING_TABLE_REPORT_GET,
  TECHNICAL_OVERLAY_CHART_GET,
} = ACTIONS_DASHBOARD_WIRELINE_SALES_PATHS;

const actionsPaths = {
  isAvailableGetMRCChart: MRC_CHART_GET,
  isAvailableBookOrder: BOOK_ORDER_CREATE,
  isAvailableGetTotalChart: TOTAL_CHART_GET,
  isAvailableGetBookingTable: BOOKING_TABLE_GET,
  isAvailableExportTable: EXPORT_BOOGING_TABLE_GET,
  isAvailableGetByPeriodChart: BY_PERIOD_CHART_GET,
  isAvailableGetByRevenueChart: BY_REVENUE_CHART_GET,
  isAvailableGetBookingReport: BOOGING_TABLE_REPORT_GET,
  isAvailableGetTechnicalOverlayChart: TECHNICAL_OVERLAY_CHART_GET,
};

interface IActionsPermissions {
  isAvailableBookOrder: boolean;
  isAvailableExportTable: boolean;
  isAvailableGetMRCChart: boolean;
  isAvailableGetTotalChart: boolean;
  isAvailableGetBookingTable: boolean;
  isAvailableGetByPeriodChart: boolean;
  isAvailableGetBookingReport: boolean;
  isAvailableGetByRevenueChart: boolean;
  isAvailableGetTechnicalOverlayChart: boolean;
}

interface ISellerDepartmentContext {
  getDashboardData: TSetPeriod;
  period: TPeriod;
  actionsPermissions: IActionsPermissions;
}

interface ISellerDepartmentProviderProps {
  children: React.ReactNode;
}

const SellerDepartmentContext = createContext<
    ISellerDepartmentContext
>({} as ISellerDepartmentContext);

let engagementsTotalsController = HTTPService.getController();
let engagementsByPeriodController = HTTPService.getController();
let engagementsMrcController = HTTPService.getController();
const engagementsRevenueController = HTTPService.getController();

const SellerDepartmentProvider: React.FC<ISellerDepartmentProviderProps> = ({
  children,
}) => {
  const dispatch = useAppDispatch();

  const { showErrorNotifier } = useNotifiers();

  const actionsPermissions = useActionsPermissions<IActionsPermissions>(actionsPaths);

  const {
    isAvailableGetTotalChart,
    isAvailableGetByPeriodChart,
    isAvailableGetByRevenueChart,
  } = actionsPermissions;

  const defaultPeriod = calculatePeriod(periodMapping, PERIOD_SELECT.ONE_MONTH);
  const [period, setPeriod] = useState(defaultPeriod);

  const cancelGetRevenueRequest = () => {
    HTTPService.cancelRequest(engagementsRevenueController);
  };

  const cancelGetEngagementTotalsRequest = () => {
    HTTPService.cancelRequest(engagementsTotalsController);
  };

  const cancelGetEngagementByPeriodRequest = () => {
    HTTPService.cancelRequest(engagementsByPeriodController);
  };

  const getEngagementsRenevue = async (newPeriod) => {
    if (!isAvailableGetByRevenueChart) return;
    cancelGetRevenueRequest();

    try {
      engagementsMrcController = HTTPService.getController();
      await dispatch(getEngagementsRevenueAction(
        engagementsMrcController, newPeriod,
      ));
    } catch (e) {
      showErrorNotifier(notifierMessage.engagementsRevenue.error, e);
    }
  };

  const getEngagementsByPeriod = async (newPeriod) => {
    if (!isAvailableGetByPeriodChart) return;
    cancelGetEngagementByPeriodRequest();

    try {
      engagementsByPeriodController = HTTPService.getController();
      await dispatch(getEngagementsOrdersByPeriodAction(
        engagementsByPeriodController, newPeriod,
      ));
    } catch (e) {
      showErrorNotifier(notifierMessage.engagementsTotals.error, e);
    }
  };

  const getEngagementsTotals = async (newPeriod) => {
    if (!isAvailableGetTotalChart) return;
    cancelGetEngagementTotalsRequest();

    try {
      engagementsTotalsController = HTTPService.getController();

      await dispatch(getEngagementsTotalOrdersAction(
        engagementsTotalsController, newPeriod,
      ));
    } catch (e) {
      showErrorNotifier(notifierMessage.engagementsTotals.error, e);
    }
  };

  const getDashboardData = (newPeriod: TPeriod, isRange?: boolean) => {
    if (!isRange) {
      getEngagementsByPeriod(newPeriod);
    }
    getEngagementsTotals(newPeriod);
    getEngagementsRenevue(newPeriod);
    setPeriod(newPeriod);
  };

  const cancelRequests = () => {
    cancelGetEngagementTotalsRequest();
    cancelGetEngagementByPeriodRequest();
    cancelGetRevenueRequest();
  };

  useEffect(() => {
    getDashboardData(period);

    return () => cancelRequests();
  }, []);

  const value = useMemo(() => ({
    getDashboardData,
    period,
    actionsPermissions,
  }), [period]);

  return (
    <SellerDepartmentContext.Provider value={value}>
      {children}
    </SellerDepartmentContext.Provider>
  );
};

export const useSellerDepartmentContext = () => useContext(SellerDepartmentContext);

export default SellerDepartmentProvider;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { postOrderDuplicateById } from 'core/api/sales.api';

const postDuplicateOrderAction = createAsyncThunk(
  'postDuplicateOrderAction',
  async ({ id }: { id: string | number },
    { rejectWithValue }) => {
    try {
      await postOrderDuplicateById(id);
    } catch (error) {
      return rejectWithValue(error);
    }
  });

export default postDuplicateOrderAction;

// eslint-disable-next-line no-unused-vars
import { IOption } from 'shared/FormikField/FormikField.model';

const convertDataFromFormToTable = (
  data: IOption[],
) => data.map((item) => ({
  value: item.value,
  text: item.label,
}));

export default convertDataFromFormToTable;

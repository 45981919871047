import {
  GET_ENGAGEMENTS_TOTAL_ORDERS_REQUEST,
  GET_ENGAGEMENTS_TOTAL_ORDERS_SUCCESS,
  GET_ENGAGEMENTS_TOTAL_ORDERS_FAILURE,
} from './actionsTypes';

export const getEngagementsTotalOrdersRequest = () => (
  { type: GET_ENGAGEMENTS_TOTAL_ORDERS_REQUEST }
);

export const getEngagementsTotalOrdersSuccess = (data) => ({
  type: GET_ENGAGEMENTS_TOTAL_ORDERS_SUCCESS,
  data,
});

export const getEngagementsTotalOrdersFailure = (error) => ({
  type: GET_ENGAGEMENTS_TOTAL_ORDERS_FAILURE,
  error,
});

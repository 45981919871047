import React from 'react';
import { Tooltip } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import cx from 'classnames';
import { ISowDataListItem, OrderActions } from '@EngagementsSowData/sowData.model';
import { orderActionsItemTooltip } from '@EngagementsSowData/constants';
import CustomTooltip from 'shared/CustomTooltip';
import { TooltipPlacement } from 'shared/CustomTooltip/customTooltip.models';
import './OrderItem.scss';

interface IOrderItemProps {
  order: ISowDataListItem;
  orderAction: OrderActions;
}

const OrderItem: React.FC<IOrderItemProps> = ({
  order,
  orderAction,
}) => {
  const {
    customerLocation, product, requestQuoteDatetime,
    provideQuoteDatetime, provideContractDatetime,
    provideOrderActionDatetime, requestOrderActionDatetime,
  } = order;

  const checkedOrder = {
    [OrderActions.REQUEST_QUOTES]: requestQuoteDatetime,
    [OrderActions.PROVIDE_QUOTES]: provideQuoteDatetime,
    [OrderActions.PROVIDE_CONTRACTS]: provideContractDatetime,
    [OrderActions.PROVIDE_ORDERS]: provideOrderActionDatetime,
    [OrderActions.REQUEST_ORDERS]: requestOrderActionDatetime,
  };

  const isTooltip = !!(orderActionsItemTooltip[orderAction] && checkedOrder[orderAction]);

  return (
    <CustomTooltip
      title={orderActionsItemTooltip[orderAction]}
      isHidden={!isTooltip}
      placement={TooltipPlacement.TOP}
    >
      <div className={cx({
        'actions-modal__order-wrap': true,
        check: checkedOrder[orderAction],
      })}
      >
        <div className="actions-modal__order-adres-wrap">
          <CheckCircleOutlineIcon className="actions-modal__order-icon" />
          <Tooltip title={customerLocation} placement="top">
            <span className="actions-modal__order-adres">
              {customerLocation}
            </span>
          </Tooltip>
        </div>
        <Tooltip title={product} placement="top">
          <span className="actions-modal__order-product">
            {product}
          </span>
        </Tooltip>
      </div>
    </CustomTooltip>
  );
};

export default OrderItem;

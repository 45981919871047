import React from 'react';
import { NavLink } from 'react-router-dom';
import ArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';

interface IBreadcrumbProps {
  breadcrumbPaths: {
    breadcrumbName: string,
    path: string,
  }[];
}

const Breadcrumb: React.FC<IBreadcrumbProps> = ({ breadcrumbPaths }) => (
  <div className="header__breadcrumb">
    {breadcrumbPaths.map(({ breadcrumbName, path }, index) => (
      <React.Fragment key={breadcrumbName}>
        {index > 0 && <ArrowRightRoundedIcon className="header__breadcrumb-icon" fontSize="small" />}
        {index === breadcrumbPaths.length - 1 ? (
          <span
            className="header__title header__breadcrumb-title"
          >
            {breadcrumbName}
          </span>
        ) : (
          <NavLink
            className="header__title header__breadcrumb-subtitle"
            to={path}
          >
            {breadcrumbName}
          </NavLink>
        )}
      </React.Fragment>
    ))}
  </div>
);
export default Breadcrumb;

import {
  EDIT_CUSTOMERS_ATTACHMENT_NAME_REQUEST,
  EDIT_CUSTOMERS_ATTACHMENT_NAME_SUCCESS,
  EDIT_CUSTOMERS_ATTACHMENT_NAME_FAILURE,
} from 'pages/Customers/CustomersAttachments/EditCustomersAttachmentName/actionTypes';

export const editCustomersAttachmentNameRequest = (attachmentId, editedAttachmentName) => ({
  type: EDIT_CUSTOMERS_ATTACHMENT_NAME_REQUEST,
  attachmentId,
  editedAttachmentName,
});

export const editCustomersAttachmentNameSuccess = (editedAttachmentName) => ({
  type: EDIT_CUSTOMERS_ATTACHMENT_NAME_SUCCESS,
  editedAttachmentName,
});

export const editCustomersAttachmentNameFailure = (attachmentId, error) => ({
  type: EDIT_CUSTOMERS_ATTACHMENT_NAME_FAILURE,
  attachmentId,
  error,
});

export const getSearchParamsFromLocalStorage = (tableId: string, paramName: string) => {
  const locStorageParams = localStorage.getItem(tableId);

  if (!locStorageParams) {
    return null;
  }

  const params = new URLSearchParams(locStorageParams);
  const localStorageFilters = params.get(paramName);

  if (localStorageFilters) {
    try {
      return JSON.parse(localStorageFilters);
    } catch (e) {
      return localStorageFilters;
    }
  }

  return null;
};

export const getInitialParameter = ({
  tableId, paramName, searchParams,
}: {
  tableId: string, paramName: string, searchParams: URLSearchParams,
}) => {
  const paramValue = searchParams.get(paramName);

  if (paramValue) {
    try {
      return JSON.parse(paramValue);
    } catch (e) {
      return paramValue;
    }
  }

  const localStorageParamValue = getSearchParamsFromLocalStorage(tableId, paramName);

  if (localStorageParamValue) {
    return localStorageParamValue;
  }

  return null;
};

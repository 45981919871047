import { bulkUploadCustomerLocation } from 'core/api/customer.api';
import {
  uploadBulkLocationFailure,
  uploadBulkLocationRequest,
  uploadBulkLocationSuccess,
} from 'pages/Customers/CustomersLocations/UploadBulkLocation/actionCreators';

const uploadBulkLocationAction = (
  customerId: number | string,
  formData: FormData,
) => async (dispatch) => {
  try {
    dispatch(uploadBulkLocationRequest());
    const { data } = await bulkUploadCustomerLocation(customerId, formData);
    dispatch(uploadBulkLocationSuccess(data));
  } catch (error) {
    dispatch(uploadBulkLocationFailure(error));
    throw error;
  }
};

export default uploadBulkLocationAction;

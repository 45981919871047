import { getCustomerById } from 'core/api/customer.api';
import {
  getCustomerRequest,
  getCustomerSuccess,
  getCustomerFailure,
} from './actionCreators';

const getCustomerAction = (customerId) => async (dispatch) => {
  try {
    dispatch(getCustomerRequest());

    const data = await getCustomerById(customerId);

    dispatch(getCustomerSuccess(data));
  } catch (error) {
    dispatch(getCustomerFailure(error));
    throw error;
  }
};

export default getCustomerAction;

import Yup from 'core/utilities/Yup';
import dayjs from 'dayjs';

export const minDate = dayjs();
const defaultDate = minDate.toDate();

export const reassignSellerModalFormSchema = (
  Yup.object().shape({
    seller: Yup
      .number()
      .nullable()
      .required('Seller is required')
      .typeError('Seller is required')
      .default(null),
    expirationDate: Yup
      .date()
      .nullable()
      .required('Date is required')
      .typeError('Date is required')
      .min(defaultDate, 'Date field must be later or equal than today')
      .default(defaultDate),
  })
);

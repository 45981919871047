import React from 'react';

import { IEngagementsRevenueItem } from 'pages/Dashboard/Dashboard.models';
import VerticalBarChart from 'pages/Dashboard/shared/VerticalBarChart/VerticalBarChart';
import { colorsOfCharts, engagementsRevenueMapper } from '@dashboardWirelineDepartments/shared/EngagementsRevenueChart/constants';

import './EngagementsRevenueChart.scss';

interface IEngagementsRevenueChartProps {
  isLoading: boolean;
  list: IEngagementsRevenueItem[];
}

const EngagementsRevenueChart: React.FC<IEngagementsRevenueChartProps> = ({
  list,
  isLoading,
}) => {
  const chartData = list.map((el) => ({
    ...el,
    value: {
      [engagementsRevenueMapper.mrc]: el.value.mrc,
      [engagementsRevenueMapper.nrc]: el.value.nrc,
    },
  }));

  return (
    <VerticalBarChart
      data={chartData}
      isLoading={isLoading}
      title="By Revenue"
      colors={colorsOfCharts}
    />
  );
};

export default EngagementsRevenueChart;

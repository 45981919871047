/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { postInsideSalesCreateRequest } from 'core/api/insideSales.api';
// eslint-disable-next-line no-unused-vars
import { IInsideSalesListItem, IInsideSalesCreateRequest } from 'pages/InsideSales/model';

export const insideSalesCreateRequestAction = createAsyncThunk(
  'insideSalesCreateRequestAction',
  async (
    params: IInsideSalesCreateRequest,
    { rejectWithValue },
  ) => {
    try {
      const { data } = await postInsideSalesCreateRequest(params);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export interface IState {
  isLoading: boolean
  error: object | null
  data: IInsideSalesListItem
}

const initialState: IState = {
  data: {} as IInsideSalesListItem,
  error: null,
  isLoading: false,
};

const insideSalesCreateRequestSlice = createSlice({
  name: 'insideSalesItemDraftSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(insideSalesCreateRequestAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(insideSalesCreateRequestAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(insideSalesCreateRequestAction.rejected, (state, action) => {
        state.data = {} as IInsideSalesListItem;
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export const insideSalesCreateRequesReducer = insideSalesCreateRequestSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';

export interface ISalesSowState {
  count: number
  isLoading: boolean
  error: object | null
  results: ISowDataListItem[]
}

const initialState: ISalesSowState = {
  count: 0,
  error: null,
  results: [],
  isLoading: true,
};

const SalesSowSlice = createSlice({
  name: 'getSalesSowData',
  initialState,
  reducers: {
    getSalesSowRequest: (state) => ({
      ...state,
      isLoading: true,
    }),
    getSalesSowSuccess: (state, action) => ({
      ...state,
      ...action.payload,
      isLoading: false,
    }),
    getSalesSowFailure: (state, action) => ({
      ...state,
      results: [],
      isLoading: false,
      error: action.payload,
    }),
    clearSalesSowData: () => initialState,
  },
});

export const {
  clearSalesSowData,
  getSalesSowRequest,
  getSalesSowSuccess,
  getSalesSowFailure,
} = SalesSowSlice.actions;

export const getSalesSowReducer = SalesSowSlice.reducer;

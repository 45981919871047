import React from 'react';
import { defaultDualSidebarWidth, defaultWidthToReduce } from 'shared/ContentShrink/constants';
import { Button } from '@mui/material';
import CommentIcon from '@mui/icons-material/Comment';
import { TableRowMode } from 'shared/Table/Table.models';

export const getOpenCommentsButton = (
  setSecondarySidebarMode: (mode: TableRowMode | null) => void,
) => (
  <Button
    startIcon={<CommentIcon color="primary" />}
    variant="outlined"
    onClick={() => setSecondarySidebarMode(TableRowMode.COMMENT)}
    className="edit-row-sidebar__comments"
  >
    Order Comments
  </Button>
);

export const getDrawerStyle = (secondarySidebarMode: boolean) => ({
  '& .MuiDrawer-paper': {
    right: secondarySidebarMode ? '500px' : '0',
    zIndex: 1300,
  },
});

export const getWidthToReduce = (secondarySidebarMode: boolean) => (
  secondarySidebarMode ? defaultDualSidebarWidth : defaultWidthToReduce
);

import React, { useState, useEffect } from 'react';
import { useAppDispatch } from 'core/store';
import { HTTPService } from 'core/services';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import FiltersByPeriod from 'pages/Dashboard/shared/FiltersByPeriod';
import { useActionsPermissions } from 'core/hooks/useActionsPermissions';
import { defaultPeriod } from '@dashboardWirelinePMDepartment/constants';
import BookingInfo from '@dashboardWirelineContractSpecialistDepartment/BookingInfo';
import ServiceTypeChart from '@dashboardWirelineContractSpecialistDepartment/ServiceTypeChart';
import OpenRequestsChart from '@dashboardWirelineContractSpecialistDepartment/OpenRequestsChart';
import { ACTIONS_DASHBOARD_WIRELINE_CONTRACT_SPECIALIST_PATHS } from 'core/models/actionsPermissions';
import { getOpenRequestsAction } from '@dashboardWirelineContractSpecialistDepartment/GetOpenRequests';
import { getTransactionalOrdersAction } from '@dashboardWirelineContractSpecialistDepartment/GetTransactionalOrders';
import { notifierMessage, TransactionalOrdersProducts } from '@dashboardWirelineContractSpecialistDepartment/constants';
import TransactionalOrdersChart
  from '@dashboardWirelineContractSpecialistDepartment/TransactionalOrdersChart';

import './ContractSpecialistDepartment.scss';

const {
  BOOKING_TABLE_GET,
  SERVICE_TYPE_CHART_GET,
  OPEN_REQUESTS_CHART_GET,
  TRANSACTIONAL_ORDERS_CHART_GET,
} = ACTIONS_DASHBOARD_WIRELINE_CONTRACT_SPECIALIST_PATHS;

const actionsPaths = {
  isAvailableBookingTable: BOOKING_TABLE_GET,
  isAvailableServiceTypeChart: SERVICE_TYPE_CHART_GET,
  isAvailableOpenRequestChart: OPEN_REQUESTS_CHART_GET,
  isAvailableTransactionalOrdersChart: TRANSACTIONAL_ORDERS_CHART_GET,
};

let transactionalOrdersController = HTTPService.getController();
let openRequestsController = HTTPService.getController();

const ContractSpecialistDepartment = () => {
  const { showErrorNotifier } = useNotifiers();

  const dispatch = useAppDispatch();

  const {
    isAvailableBookingTable,
    isAvailableServiceTypeChart,
    isAvailableOpenRequestChart,
    isAvailableTransactionalOrdersChart,
  } = useActionsPermissions(actionsPaths);

  const [period, setPeriod] = useState(defaultPeriod);

  const cancelTransactionalOrdersRequest = () => {
    HTTPService.cancelRequest(transactionalOrdersController);
  };

  const cancelOpenRequestsRequest = () => {
    HTTPService.cancelRequest(openRequestsController);
  };

  const getTransactionalOrders = (newPeriod) => {
    if (!isAvailableTransactionalOrdersChart) return;

    cancelTransactionalOrdersRequest();

    transactionalOrdersController = HTTPService.getController();

    const params = {
      products: TransactionalOrdersProducts.join(','),
      ...newPeriod,
    };

    dispatch(getTransactionalOrdersAction({
      controller: transactionalOrdersController, params,
    }))
      .unwrap()
      .catch((e) => {
        showErrorNotifier(notifierMessage.transactionalOrders.error, e);
      });
  };

  const getOpenRequestsOrders = (newPeriod) => {
    if (!isAvailableOpenRequestChart) return;

    cancelOpenRequestsRequest();

    openRequestsController = HTTPService.getController();

    dispatch(getOpenRequestsAction({
      controller: openRequestsController, params: newPeriod,
    }))
      .unwrap()
      .catch((e) => {
        showErrorNotifier(notifierMessage.openRequests.error, e);
      });
  };

  const updateCharts = (newPeriod) => {
    getTransactionalOrders(newPeriod);
    getOpenRequestsOrders(newPeriod);

    setPeriod(newPeriod);
  };

  const cancelRequests = () => {
    cancelTransactionalOrdersRequest();
    cancelOpenRequestsRequest();
  };

  useEffect(() => {
    updateCharts(period);

    return () => cancelRequests();
  }, []);

  return (
    <div className="contract-specialist">
      <div className="contract-specialist__filters">
        <FiltersByPeriod
          period={period}
          onChange={updateCharts}
        />
      </div>
      <div className="contract-specialist__charts">
        {isAvailableTransactionalOrdersChart && <TransactionalOrdersChart />}
        {isAvailableServiceTypeChart && <ServiceTypeChart />}
        {isAvailableOpenRequestChart && <OpenRequestsChart />}
      </div>
      {
        isAvailableBookingTable && (
          <div className="contract-specialist__boking-info">
            <BookingInfo />
          </div>
        )
      }
    </div>
  );
};

export default ContractSpecialistDepartment;

// eslint-disable-next-line no-unused-vars
import React, { SyntheticEvent } from 'react';
import {
  Box,
  Button,
  TextField,
} from '@mui/material';

interface ICommentsTextAreaProps {
    newCommentText: string
    error: string
    editCommentId: string | number
    onChangeComment: (e: SyntheticEvent) => void
    onCreateNewComment: (e: SyntheticEvent) => void
    onUpdateComment: (e: SyntheticEvent) => void
}

const CommentsTextArea: React.FC<ICommentsTextAreaProps> = ({
  newCommentText,
  error,
  editCommentId,
  onChangeComment,
  onCreateNewComment,
  onUpdateComment,
}) => (
  <Box component="form" className="widget-comments__textarea">
    <Box m={2}>
      <TextField
        placeholder={
          editCommentId
            ? 'Please confirm changes'
            : 'Enter comments here'
        }
        variant="outlined"
        disabled={Boolean(editCommentId)}
        multiline
        rows={2}
        fullWidth
        error={!!error}
        helperText={error && error}
        inputProps={{ className: 'widget__comment' }}
        required
        value={newCommentText}
        onChange={onChangeComment}
      />
    </Box>
    {!editCommentId && (
    <Button
      className="widget-comments__confirm"
      onClick={onCreateNewComment}
      disabled={!newCommentText.length}
    >
      Add comment
    </Button>
    )}
    {editCommentId && (
    <Button
      className="widget-comments__confirm"
      onClick={onUpdateComment}
    >
      Confirm
    </Button>
    )}
  </Box>
);

export default CommentsTextArea;

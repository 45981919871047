export type IOpenapiSchemaType = 'string' | 'object';

export enum OPENAPI_SCHEMA_FORMATS {
  DATE_TIME = 'date-time',
  DATE = 'date',
  PHONE_NUMBER = 'phone-number',
  EMAIL = 'email',
}

export interface IOpenapiSchemaProperty {
  allOf?: {
    0: {
      $ref: string
    };
  },
  items?: {
    $ref: string
  },
  title: string;
  type: IOpenapiSchemaType;
  enum?: string[];
  description?: string;
  format?: OPENAPI_SCHEMA_FORMATS;
  readOnly?: boolean;
  nullable?: boolean;
}

export interface IOpenapiSchemaProperties {
  [key: string]: IOpenapiSchemaProperty
}

export interface IOpenapiSchema {
  [key: string]: {
    type: IOpenapiSchemaType;
    required: string[];
    properties: IOpenapiSchemaProperties;
    enum?: string[];
    description?: string;
    format?: OPENAPI_SCHEMA_FORMATS;
    readOnly?: boolean;
    nullable?: boolean;
    oneOf?: { $ref: string }[];
  }
}

export interface IOpenapiSchemasParsedItem {
  fieldName: string;
  type: IOpenapiSchemaType;
  required?: string[];
  properties: IOpenapiSchemasParsedItem[] | null;
  title: string;
  enum?: string[];
  description?: string;
  format?: OPENAPI_SCHEMA_FORMATS;
  readOnly?: boolean;
  nullable?: boolean;
  writeOnly?: boolean;
}

export interface IOpenapiSchemasPaths {
  [key: string]: {
    get: {
      responses: {
        200: {
          content: {
            'application/json': {
              schema: {
                $ref: string
              }
            }
          },
          'description': string
        }
      }
    }
  }
}

export interface IOpenapiSchemaRequest {
  components: {
    schemas: IOpenapiSchema
  }
  paths: IOpenapiSchemasPaths
}

// eslint-disable-next-line no-unused-vars
import React, { FC } from 'react';
import { TextField } from '@mui/material';
import InputMask from 'react-input-mask';
import { useField } from 'formik';
import FormikFieldWrapper from '../FormikFieldWrapper';
import { phoneMask } from '../../widgets/Locations/LocationForm/constants';

import '../FormikField.scss';

interface IFormikMobileMaskFieldProps {
  label: string
  fieldName: string
  disabled?: boolean
  required?: boolean
  fullWidth?: boolean
}

const FormikMobileMaskField: FC<IFormikMobileMaskFieldProps> = ({
  fieldName, label, disabled, required, fullWidth, ...rest
}) => {
  const [field, error] = useField(fieldName);

  const isError = Boolean(error.error && error.touched);
  const errorText = isError ? error.error : ' ';

  return (
    <FormikFieldWrapper
      fieldName={fieldName}
      label={label}
      required={required as boolean}
    >
      <InputMask
        mask={phoneMask}
        disabled={disabled as boolean}
        {...field}
      >
        {() => (
          <TextField
            id={fieldName}
            type="tel"
            {...field}
            error={isError}
            disabled={disabled as boolean}
            required={required as boolean}
            fullWidth={fullWidth as boolean}
            helperText={errorText}
            {...rest}
          />
        )}
      </InputMask>
    </FormikFieldWrapper>

  );
};

FormikMobileMaskField.defaultProps = {
  disabled: false,
  required: false,
  fullWidth: false,
};

export default FormikMobileMaskField;

import {
  ITeamRole, IUser, IUserExtended, IUserListItem,
} from 'pages/UserManagement/UserManagement.models';
import { netsparkAxios } from 'core/services/HTTPService';
import { IRequestPaging } from 'core/services/HTTPService.models';
import { teamRolesPath, usersPath, getUpdateCustomerSellerUrl } from 'core/api/constants';
// eslint-disable-next-line no-unused-vars
import { IReassignSellerEdit } from 'pages/Customers/CustomersList/ReassignSellerModal/ReassignSellerModal.models';

export const getUsers = (params) => netsparkAxios.get<
  IRequestPaging<IUserListItem>
>(usersPath, { params });

export const getUserById = (id) => netsparkAxios.get<IUserExtended>(`${usersPath}${id}/`);

export const createUser = (params) => netsparkAxios.post<IUser>(usersPath, params);

export const updateUserById = (id, params) => netsparkAxios.put<IUser>(`${usersPath}${id}/`, params);

export const createTeamRole = (params: ITeamRole) => (
  netsparkAxios.post<ITeamRole>(teamRolesPath, params)
);

export const updateTeamRoleById = (id: string | number, params: ITeamRole) => (
  netsparkAxios.put<ITeamRole>(`${teamRolesPath}${id}/`, params)
);

export const updateCustomerSeller = (id: number, params: IReassignSellerEdit) => netsparkAxios.put(
  getUpdateCustomerSellerUrl(id),
  params,
);

import styles from 'assets/styles/_resources.scss';

const themeConfig = {
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: styles.redColor500 as string,
      },
      root: {
        color: styles.darkColor500 as string,
      },
    },
    MuiButton: {
      root: {
        minHeight: '37px',
        boxShadow: 'none',
      },
      contained: {
        boxShadow: 'inherit',
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$disabled': {
          background: styles.backgroundColor as string,
        },
        '&$disabled $notchedOutline': {
          borderColor: 'transparent',
        },
      },
    },
    MuiDrawer: {
      paper: {
        border: 'none',
      },
    },
    MuiPaper: {
      root: {
        border: `1px solid ${styles.borderColor}`,
      },
      elevation1: {
        boxShadow: 'none',
      },
    },
  },
  typography: {
    fontFamily: [
      'Montserrat',
      'Roboto',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: styles.primaryColor as string,
      50: styles.primaryColor50 as string,
      contrastText: '#fff',
    },
    secondary: {
      main: styles.secondaryColor as string,
      100: styles.secondaryColor100 as string,
      50: styles.secondaryColor50 as string,
      contrastText: styles.darkColor500 as string,
    },
    success: {
      main: styles.greenColor500 as string,
    },
    error: {
      main: styles.redColor500 as string,
    },
    action: {
      active: styles.textGrayColor as string,
    },
    text: {
      primary: styles.darkColor500 as string,
      secondary: styles.textGrayColor as string,
    },
    borderColor: {
      main: styles.borderColor as string,
    },
    info: {
      main: styles.darkColor500 as string,
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: ' 10px 20px',
          fontSize: '12px',
        },
      },
    },
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave',
      },
    },
  },
  spacing: 8,
};

export default themeConfig;

/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { postSalesOrderActions } from 'core/api/sales.api/sales.api';

const initialState = {
  isLoading: false,
};

export const requestOrdersAction = createAsyncThunk(
  'requestOrdersAction',
  async (params: { orderId: number }[], { rejectWithValue }) => {
    try {
      await postSalesOrderActions(params);
    } catch (error) {
      return rejectWithValue(error);
    }
  });

const requestOrdersSlice = createSlice({
  name: 'requestOrders',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        requestOrdersAction.pending,
        (state) => {
          state.isLoading = true;
        })
      .addMatcher(
        isAnyOf(requestOrdersAction.rejected, requestOrdersAction.fulfilled),
        (state) => {
          state.isLoading = false;
        },
      );
  },
});

export const requestOrdersReducer = requestOrdersSlice.reducer;

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FormikSelectField from 'shared/FormikField/FormikSelectField';
import { IOption } from 'shared/FormikField/FormikField.model';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { getUsers } from 'core/api/user.api';
import { getOptionsFromUser } from 'core/utilities/getOptionsFromUser';
import {
  UserTeamRoleName,
  UserTeamRoleTeamAlignment,
  UserTeamRoleTeamDepartment,
} from 'pages/UserManagement/UserManagement.models';
import {
  useWirelineEngagementsCreateContext,
} from 'pages/WirelineEngagements/WirelineEngagementsCreate/WirelineEngagementsCreateContext';

const userParams = {
  alignment: UserTeamRoleTeamAlignment.WIRELINE,
  department: UserTeamRoleTeamDepartment.SELLER,
  role: UserTeamRoleName.TECHNICAL_OVERLAY,
};

const EngagementsFormGeneralTechnicalOverlay = () => {
  const { wirelineEngagementId } = useParams();
  const { isEditModeGeneral } = useWirelineEngagementsCreateContext();

  const { showErrorNotifier } = useNotifiers();

  const [isLoading, setIsLoading] = React.useState(true);
  const [options, setOptions] = React.useState<IOption[]>([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const { data: { results } } = await getUsers(userParams);
        setOptions(getOptionsFromUser(results));
      } catch (e) {
        showErrorNotifier('Error while loading technical overlays', e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const isCreateMode = !wirelineEngagementId;
  const isFieldsDisabled = !isEditModeGeneral && !isCreateMode;

  return (
    <FormikSelectField
      fullWidth
      options={options}
      isLoading={isLoading}
      label="Technical Overlay"
      fieldName="technicalOverlay"
      disabled={isFieldsDisabled}
    />
  );
};

export default EngagementsFormGeneralTechnicalOverlay;

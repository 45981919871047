import React, {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react';
import { compact, debounce, map } from 'lodash';
import {
  MRT_ColumnDef as ColumnDef,
  MRT_ColumnFiltersState as ColumnFiltersState,
  MRT_ColumnOrderState as ColumnOrderState,
  MRT_PaginationState as PaginationState,
  MRT_SortingState as SortingState,
  MRT_RowSelectionState as RowSelectionState,
  // eslint-disable-next-line no-unused-vars
  MRT_Row as Row,
} from 'material-react-table';
import { useDispatch } from 'react-redux';
import {
  firstPage, rowActionsName, rowSelectName,
} from 'shared/Table/constants';
import useTableSearchParams from 'shared/Table/hooks/useTableSearchParams';
import {
  // eslint-disable-next-line no-unused-vars
  ColumnSort, ITableFilters, ParamTypes, TableFiltersState, TRow,
} from 'shared/Table/Table.models';
import { OnChangeStateFn } from 'core/models/general.models';
import { HTTPService } from 'core/services';

interface IMuiTableProviderProps<T> {
  id: string
  columns: ColumnDef<TRow>[]
  defaultSort?: ColumnSort | undefined
  getDataAction: (
    HttpController: AbortController,
    requestParams: {
      page: number;
      page_size: number;
      search: string;
      ordering?: string | undefined;
    },
    additionalParams: T | undefined
  ) => (dispatch: any) => Promise<void>
  requestParams?: T
  children: React.ReactNode
  enableRowSelection?: boolean | ((row: Row<TRow>) => boolean)
  enableSelectAll?: boolean
  enableEditing?: boolean
}

interface ITableContext {
  getTableData: () => void
  cancelGetTableDataRequest: () => void
  columns: ColumnDef<TRow>[]
  setParamsToUrlAndLocalStorage: (
    pageIndex: number, pageSize: string | number,
    sort?: ColumnSort,
    globalFilter?: string,
    columnFilters? : ColumnFiltersState,
    columnOrder? :ColumnOrderState,
  ) => void,
  columnOrder: ColumnOrderState
  setColumnOrder: OnChangeStateFn<ColumnOrderState>
  setColumnFilters : OnChangeStateFn<ColumnFiltersState>
  setGlobalFilter: OnChangeStateFn<string>
  setSorting: OnChangeStateFn<SortingState>
  setPagination: OnChangeStateFn<PaginationState>
  enableRowSelection: boolean | ((row: Row<TRow>) => boolean)
  enableEditing: boolean
  rowSelection: RowSelectionState
  setRowSelection: OnChangeStateFn<RowSelectionState>
  tableFilters: ITableFilters,
  updateTableFilters: (
      paramType: ParamTypes, updaterFunction: (prevValue: TableFiltersState) => TableFiltersState
  )=> void;
}

export const TableContext = createContext<ITableContext>({} as ITableContext);

let tableController = HTTPService.getController();
const regularDebounceTime = 500;
const initialDebounceTime = 0;

export const TableProvider = <T extends unknown> ({
  id, getDataAction, requestParams,
  children, defaultSort, enableRowSelection, enableSelectAll, enableEditing, columns,
}: IMuiTableProviderProps<T>) => {
  const {
    setParamsToUrlAndLocalStorage,
    defaultPagination,
    initialSort,
    initialSearchValue,
    initialFilters,
    initialOrder,
  } = useTableSearchParams(id, defaultSort);

  const defaultColumnOrder = compact([
    enableRowSelection && rowSelectName,
    enableEditing && rowActionsName,
    ...map(columns, 'accessorKey'),
  ]);

  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(
    initialOrder || defaultColumnOrder,
  );

  const [debounceTime, setDebounceTime] = useState(initialDebounceTime);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const [tableFilters, setTableFilters] = useState({
    [ParamTypes.PAGINATION]: defaultPagination,
    [ParamTypes.SORTING]: initialSort?.id ? [initialSort] as SortingState : [],
    [ParamTypes.SEARCH]: initialSearchValue,
    [ParamTypes.FILTERS]: initialFilters || [],
  });

  useEffect(() => {
    const {
      pagination, sorting, filters, search,
    } = tableFilters;

    setParamsToUrlAndLocalStorage(
      pagination.pageIndex, pagination.pageSize,
      sorting[0], search, filters, columnOrder,
    );
  }, [columnOrder]);

  const updateTableFilters = (
    paramType: ParamTypes,
    updaterFunction: (prevValue: TableFiltersState) => TableFiltersState,
  ) => {
    setTableFilters((prevParams) => {
      const updatedValue = updaterFunction(prevParams[paramType]);

      let newPagination;
      if (paramType === ParamTypes.PAGINATION) {
        newPagination = updatedValue;
      } else {
        newPagination = { ...prevParams.pagination, pageIndex: firstPage };
      }

      return {
        ...prevParams,
        [paramType]: updatedValue,
        [ParamTypes.PAGINATION]: newPagination,
      };
    });
  };

  const setPagination = (updater) => {
    updateTableFilters(ParamTypes.PAGINATION, updater);
  };

  const setSorting = (updater) => {
    updateTableFilters(ParamTypes.SORTING, updater);
  };

  const setColumnFilters = (updater) => {
    updateTableFilters(ParamTypes.FILTERS, updater);
  };

  const setGlobalFilter = (newGlobalFilterValue) => {
    updateTableFilters(ParamTypes.SEARCH, () => newGlobalFilterValue);
  };

  const dispatch: (dispatch: any) => Promise<void> = useDispatch();

  const cancelGetTableDataRequest = () => {
    HTTPService.cancelRequest(tableController);
  };

  const getTableData = debounce(async () => {
    // the first data fetching must be with zero debounce time
    if (!debounceTime) {
      setDebounceTime(regularDebounceTime);
    }

    cancelGetTableDataRequest();

    const {
      pagination, sorting, filters, search,
    } = tableFilters;

    const orderDir = sorting[0]?.desc ? '-' : '';
    const ordering = sorting[0]?.id ? orderDir + sorting[0].id : undefined;
    const filtersObject = filters.reduce(
      (result, { id: filterId, value }) => ({ ...result, [filterId]: `${value}` }), {});

    const data = {
      page: pagination.pageIndex + 1,
      page_size: pagination.pageSize,
      search,
      ordering,
      ...filtersObject,
    };

    tableController = HTTPService.getController();

    await dispatch(getDataAction(tableController, data, requestParams as T | undefined));

    setParamsToUrlAndLocalStorage(
      pagination.pageIndex, pagination.pageSize,
      sorting[0], search, filters, columnOrder,
    );
  }, debounceTime);

  const value = useMemo(() => ({
    getTableData,
    cancelGetTableDataRequest,
    columns,
    setParamsToUrlAndLocalStorage,
    columnOrder,
    setColumnOrder,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    rowSelection,
    setRowSelection,
    enableRowSelection: enableRowSelection as boolean,
    enableSelectAll: enableSelectAll as boolean,
    enableEditing: enableEditing as boolean,
    tableFilters,
    updateTableFilters,
  }), [
    setParamsToUrlAndLocalStorage,
    columnOrder,
    rowSelection,
    tableFilters,
  ]);

  return (
    <TableContext.Provider value={value}>
      {children}
    </TableContext.Provider>
  );
};

TableProvider.defaultProps = {
  defaultSort: undefined,
  enableRowSelection: false,
  enableSelectAll: false,
  enableEditing: false,
  requestParams: null,
};

export const useTableContext = () => useContext(TableContext);

import { getCustomerAttachments } from 'core/api/customer.api';
import {
  getCustomersAttachmentsRequest,
  getCustomersAttachmentsSuccess,
  getCustomersAttachmentsFailure,
} from './actionsCreators';

const getCustomerAttachmentsAction = (
  controller: AbortController,
  customerId: number | string,
) => async (dispatch) => {
  try {
    const { signal } = controller;
    dispatch(getCustomersAttachmentsRequest());

    const { data } = await getCustomerAttachments(signal, customerId);

    dispatch(getCustomersAttachmentsSuccess(data));
  } catch (error) {
    dispatch(getCustomersAttachmentsFailure(error));
  }
};

export default getCustomerAttachmentsAction;

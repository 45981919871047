import {
  getEngagementsLisRequest,
  getEngagementsLisSuccess,
  getEngagementsLisFailure,
} from './actionsCreators';
import getEngagementsLis from './api.service';

const getEngagementsLisAction = (controller, params) => async (dispatch) => {
  try {
    const { signal } = controller;
    dispatch(getEngagementsLisRequest());
    const { data } = await getEngagementsLis(signal, params);
    dispatch(getEngagementsLisSuccess(data));
  } catch (error) {
    dispatch(getEngagementsLisFailure(error));

    throw error;
  }
};

export default getEngagementsLisAction;

import React, { useEffect } from 'react';
import Comments from 'shared/widgets/Comments';
import { HTTPService } from 'core/services';
import { useAppDispatch, useAppSelector } from 'core/store';
import removeCommentAction from 'pages/Customers/CustomersComments/RemoveComment/actions';
import editCommentAction from 'pages/Customers/CustomersComments/EditComment/actions';
import createCommentAction from 'pages/Customers/CustomersComments/CreateComment/actions';
import getCommentsAction from 'pages/Customers/CustomersComments/GetComments/actions';
import clearCommentsErrorsAction from 'pages/Customers/CustomersComments/ClearErrorsComments/actions';

let customersCommentsController = HTTPService.getController();

interface ICustomersCommentsProps {
  customerId: number
}

const CustomersComments: React.FC<ICustomersCommentsProps> = ({ customerId }) => {
  const dispatch = useAppDispatch();
  const comments = useAppSelector((state) => state.customers.comments.data.results);
  const isLoading = useAppSelector((state) => state.customers.comments.isLoading);
  const commentsError = useAppSelector((state) => state.customers.comments.error);

  const cancelGetCustomerCommentsRequest = () => {
    HTTPService.cancelRequest(customersCommentsController);
  };

  const clearCustomerCommentsErrors = () => {
    dispatch(clearCommentsErrorsAction(comments));
  };

  const deleteComment = (commentId) => {
    dispatch(removeCommentAction(customerId, commentId));
  };

  const editComment = (commentId, data) => {
    const formData = { text: data };
    dispatch(editCommentAction(customerId, commentId, formData));
  };

  const createComment = (data) => {
    const formData = { text: data };
    dispatch(createCommentAction(customerId, formData));
  };

  const getComments = async () => {
    cancelGetCustomerCommentsRequest();

    customersCommentsController = HTTPService.getController();
    await dispatch(getCommentsAction(customersCommentsController, customerId));
  };

  useEffect(() => {
    getComments();

    return () => cancelGetCustomerCommentsRequest();
  }, []);

  return (
    <Comments
      isLoading={isLoading}
      comments={comments}
      deleteComment={deleteComment}
      addNewComment={createComment}
      error={commentsError}
      updateComment={editComment}
      clearCommentsErrors={clearCustomerCommentsErrors}
    />
  );
};

export default CustomersComments;

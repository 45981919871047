import { useMemo } from 'react';
import { useAppSelector } from 'core/store';

export const useActionsPermissions = <T = Record<string, boolean>>(
  actionsPaths: Record<string, string>,
): T => {
  const actionsPermissionsData = useAppSelector((state) => state.userProfile.permissions);

  return useMemo(() => Object.fromEntries(
    Object.entries(actionsPaths)
      .map(([key, value]) => ([key, Boolean(actionsPermissionsData?.[value])])),
  ),
  [actionsPaths, actionsPermissionsData]) as T;
};

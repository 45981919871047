import React from 'react';
import { tableIds } from 'shared/Table/constants';
import { useSearchParams } from 'react-router-dom';
import { TableProvider } from 'shared/Table/TableContext';
import { useActionsPermissions } from 'core/hooks/useActionsPermissions';
import { TableActiveRowContextProvider } from 'shared/Table/TableActiveRowContext';
import { ACTIONS_DASHBOARD_ESCALATION_SUPPORT_PATHS } from 'core/models/actionsPermissions';
import EscalationSupportTable from '@dashboardEscalationSupport/EscalationSupportTable/EscalationSupportTable';
import { columns, productUrlParam, customerCompanyNameUrlParam } from '@dashboardEscalationSupport/EscalationSupportTable/constants';
import getEscalationSupportDataAction from '@dashboardEscalationSupport/EscalationSupportTable/GetEscalationSupport/actions';
import EditRowEscalationSupportForm from '@dashboardEscalationSupport/EscalationSupportTable/EditRowEscalationSupportForm';

const { ESCALATION_SUPPORT_TABLE_GET, REQUEST_EDIT } = ACTIONS_DASHBOARD_ESCALATION_SUPPORT_PATHS;
const actionsPaths = {
  isAvailableEditRowTable: REQUEST_EDIT,
  isAvailableGetTable: ESCALATION_SUPPORT_TABLE_GET,
};

const EscalationSupportTableContainer = () => {
  const [searchParams] = useSearchParams();

  const {
    isAvailableGetTable,
    isAvailableEditRowTable,
  } = useActionsPermissions(actionsPaths);

  const product = searchParams.get(productUrlParam)?.split(',') || [];
  const customerCompanyName = searchParams.get(customerCompanyNameUrlParam)?.split(',') || [];

  const requestParams = {
    product: product[0],
    customerCompanyName: customerCompanyName[0],
  };

  if (!isAvailableGetTable) return null;

  return (
    <TableActiveRowContextProvider>
      <TableProvider
        id={tableIds.escalationSupport}
        columns={columns}
        requestParams={requestParams}
        enableEditing={isAvailableEditRowTable}
        getDataAction={getEscalationSupportDataAction}
      >
        <EscalationSupportTable isAvailableEditRow={isAvailableEditRowTable} />
        {isAvailableEditRowTable && <EditRowEscalationSupportForm />}
      </TableProvider>
    </TableActiveRowContextProvider>
  );
};

export default EscalationSupportTableContainer;

import get from 'lodash/get';
import { removeEngagementComment } from 'core/api/wirelineEngagements.api';
import {
  removeCommentRequest,
  removeCommentSuccess,
  removeCommentFailure,
} from 'pages/WirelineEngagements/WirelineEngagementsComments/RemoveComment/actionCreators';

export const removeCommentAction = (
  engagementId: string | number,
  commentId: string | number,
) => async (dispatch) => {
  try {
    dispatch(removeCommentRequest(commentId));
    await removeEngagementComment(engagementId, commentId);
    dispatch(removeCommentSuccess(commentId));
  } catch (error) {
    const detail = get(error, 'response.data.detail', '') || get(error, 'error.detail', '');

    dispatch(removeCommentFailure(commentId, detail));
    throw error;
  }
};

export default removeCommentAction;

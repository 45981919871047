import React, { useState, useEffect } from 'react';
import Table from 'shared/Table';
import paths from 'core/routing/paths';
import { useNavigate } from 'react-router-dom';
import CreateButton from 'shared/CreateButton';
import { TRow } from 'shared/Table/Table.models';
import { TableProvider } from 'shared/Table/TableContext';
import { useAppDispatch, useAppSelector } from 'core/store';
import { ICustomer } from 'pages/Customers/Customers.models';
import { MRT_ColumnDef as ColumnDef } from 'material-react-table';
import { columns, createCustomerButtonText } from 'pages/Customers/constans';
import ReassignSellerModal from 'pages/Customers/CustomersList/ReassignSellerModal';
import { clearCustomersListAction } from 'pages/Customers/CustomersList/GetCustomersList';
import getCustomersListAction from 'pages/Customers/CustomersList/GetCustomersList/actions';
import { useCustomersPermissions } from 'pages/Customers/CustomersList/useCustomersPermissions';
import CustomersListTableRowActions from 'pages/Customers/CustomersList/CustomersListTableRowActions';
import { tableIds, colDefOptionActionsSingleIcon, colDefOptionActionsTwoIcons } from 'shared/Table/constants';

const CustomersList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const listData = useAppSelector((state) => state.customers.list);
  const isLoading = useAppSelector((state) => state.customers.list.isLoading);

  const {
    isAvailableEditCustomer,
    isAvailableAllRowActions,
    isAvailableSomeRowAction,
    isAvailableCreateCustomer,
    isAvailableEditCustomerSeller,
  } = useCustomersPermissions();

  const [activeCustomerData, setActiveCustomerData] = useState<ICustomer | null>(null);

  const openCustomerCreatePage = () => navigate(paths.customersCreate);

  const createCustomerAction = isAvailableCreateCustomer ? (
    <CreateButton
      className="customer-create-btn"
      text={createCustomerButtonText}
      onClick={openCustomerCreatePage}
    />
  ) : undefined;

  const renderRowActions = isAvailableSomeRowAction
    ? ({ row: { original } }) => (
      <CustomersListTableRowActions
        row={original as unknown as ICustomer}
        activeCustomerData={activeCustomerData}
        setActiveCustomerData={setActiveCustomerData}
        permissions={{
          isAvailableEditCustomer,
          isAvailableEditCustomerSeller,
        }}
      />
    ) : undefined;

  useEffect(() => () => {
    dispatch(clearCustomersListAction());
  }, []);

  return (
    <div className="customers-list">
      <TableProvider
        id={tableIds.customers}
        getDataAction={getCustomersListAction}
        columns={columns as ColumnDef<TRow>[]}
        enableEditing={isAvailableSomeRowAction}
      >
        <Table
          showGlobalFilter
          showColumnFilters
          isLoading={isLoading}
          rowCount={listData.count}
          renderRowActions={renderRowActions}
          toolBarAction={createCustomerAction}
          rows={listData.results as unknown as TRow[]}
          displayColumnDefOptions={{
            ...(isAvailableSomeRowAction && colDefOptionActionsSingleIcon),
            ...(isAvailableAllRowActions && colDefOptionActionsTwoIcons),
          }}
        />
        <ReassignSellerModal
          activeCustomerData={activeCustomerData}
          setActiveCustomerData={setActiveCustomerData}
        />
      </TableProvider>

    </div>
  );
};

export default CustomersList;

import React from 'react';
import map from 'lodash/map';
import { useAppSelector } from 'core/store';
import CustomSelect from 'shared/CustomSelect';

interface IProductsSelectProps {
  setSelectedProducts: (products: string[]) => void
  selectedProducts: string[]
  activeProducts?: string[] | undefined
}

const ProductsSelect: React.FC<
  IProductsSelectProps
> = ({ setSelectedProducts, selectedProducts, activeProducts }) => {
  const productsData = useAppSelector((state) => state.products.data);

  const productOptions = map(productsData, ({ name }) => name);

  return (
    <CustomSelect
      label="Select Products"
      setSelectedOptions={setSelectedProducts}
      selectedOptions={selectedProducts}
      options={productOptions}
      activeOptions={activeProducts}
    />
  );
};

ProductsSelect.defaultProps = {
  activeProducts: undefined,
};

export default ProductsSelect;

import { netsparkAxios } from 'core/services/HTTPService';
import { IEscalationSupportDataItem } from 'pages/Dashboard/Dashboard.models';
import { getEscalationSupportDataUrl } from 'core/api/sales.api/constants';
import { IRequestPaging } from 'core/services/HTTPService.models';

const getEscalationSupportData = (signal: AbortSignal, params: object) => netsparkAxios
  .get<IRequestPaging<IEscalationSupportDataItem[]>>(
    getEscalationSupportDataUrl, { signal, params },
  );

export default getEscalationSupportData;

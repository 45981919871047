import React from 'react';
import renewalSowEngagementAcceptedAction
  from 'core/actions/Sales/RenewalSowEngagementAccepted/RenewalSowEngagementAccepted';
import { useAppDispatch } from 'core/store';
import OrderModalItem from 'shared/OrderModalItem';
import CustomModal from 'shared/CustomModal';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useTableContext } from 'shared/Table/TableContext';
import { IRenewalSowDataItem } from '@EngagementsRenewalSowData/renewalSowData.models';
import { engagementAcceptedNotifierMessage } from '@EngagementsActiveNumbers/ActiveNumbersEngagementAccepted/constants';

interface IEngagementAcceptedModalProps {
  selectedRenewalSowData: IRenewalSowDataItem[]
  isModalOpen: boolean
  setIsModalOpen: (isModalOpen: boolean) => void
}

const EngagementAcceptedModal: React.FC<IEngagementAcceptedModalProps> = ({
  selectedRenewalSowData,
  isModalOpen,
  setIsModalOpen,
}) => {
  const { getTableData, setRowSelection } = useTableContext();
  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();

  const dispatch = useAppDispatch();

  const onEngagementAccepted = async () => {
    await Promise.all(selectedRenewalSowData.map(
      ({ id }) => dispatch(renewalSowEngagementAcceptedAction(id))
        .unwrap()
        .then(() => {
          showSuccessNotifier(engagementAcceptedNotifierMessage.success);
        })
        .catch(() => {
          showErrorNotifier(engagementAcceptedNotifierMessage.error);
        })),
    );

    setRowSelection({});
    getTableData();
    setIsModalOpen(false);
  };

  return (
    <CustomModal
      title="Are you sure you want to accept engagement for the selected order/s?"
      isModalOpen={isModalOpen}
      confirmButtonText="Yes"
      cancelButtonText="No"
      onClickConfirm={onEngagementAccepted}
      onCloseModal={setIsModalOpen}
      areButtonsVisible
    >
      <>
        {
          selectedRenewalSowData.map(({ id, location, product }) => (
            <OrderModalItem
              key={id}
              location={location}
              product={product}
            />
          ))
        }
      </>
    </CustomModal>
  );
};

export default EngagementAcceptedModal;

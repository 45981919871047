import { netsparkAxios } from 'core/services/HTTPService';
import downloadFile from 'core/utilities/downloadFile';
import { downloadBulkLocationUrl } from './constants';

const downloadBulkLocation = () => netsparkAxios.get(downloadBulkLocationUrl)
  .then((response) => {
    downloadFile(response.data.url, 'Bulk_Address_Upload_Template.xlsx');
  });

export default downloadBulkLocation;

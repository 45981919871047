import {
  REMOVE_CUSTOMERS_ATTACHMENT_FAILURE,
  REMOVE_CUSTOMERS_ATTACHMENT_REQUEST,
  REMOVE_CUSTOMERS_ATTACHMENT_SUCCESS,
} from 'pages/Customers/CustomersAttachments/RemoveCustomersAttachment/actionTypes';

export const removeCustomersAttachmentRequest = (attachmentId) => ({
  type: REMOVE_CUSTOMERS_ATTACHMENT_REQUEST,
  attachmentId,
});

export const removeCustomersAttachmentSuccess = (attachmentId) => ({
  type: REMOVE_CUSTOMERS_ATTACHMENT_SUCCESS,
  attachmentId,
});

export const removeCustomersAttachmentFailure = (attachmentId, error) => ({
  type: REMOVE_CUSTOMERS_ATTACHMENT_FAILURE,
  attachmentId,
  error,
});

import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useFormikContext } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { getSowDataPath } from 'core/routing/paths';
import ConfirmDialog from 'shared/ConfirmDialog';

interface IContractFormButtonsProps {
  setIsActionsModalOpen: (isOpen: boolean) => void;
  isDisabled: boolean;
}

const ContractFormButtons: React.FC<IContractFormButtonsProps> = ({
  setIsActionsModalOpen, isDisabled,
}) => {
  const navigate = useNavigate();
  const { isValid } = useFormikContext();
  const { wirelineEngagementId, customerId } = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onCancel = () => {
    navigate(getSowDataPath(wirelineEngagementId, customerId));
  };

  return (
    <div className="contract-form__buttons">
      <Button
        className="button confirm"
        onClick={() => setIsActionsModalOpen(true)}
        variant="contained"
        disabled={!isValid || isDisabled}
      >
        Send
      </Button>
      <Button
        className="button"
        onClick={() => setIsModalVisible(true)}
        variant="outlined"
        disabled={isDisabled}
      >
        Cancel
      </Button>
      <ConfirmDialog
        confirmLabel="Leave"
        onConfirm={onCancel}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
      />
    </div>
  );
};

export default ContractFormButtons;

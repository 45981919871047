/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getSalesTSDList } from 'core/api/sales.api';
import { ISalesTSDItem } from 'pages/WirelineEngagements/shared/AddOrderModalForm/product.model';

export interface IState {
    data: ISalesTSDItem[];
    isLoading: boolean;
    error: object | null;
}

const initialState: IState = {
  data: [],
  error: null,
  isLoading: false,
};

export const getTSDsAction = createAsyncThunk(
  'getTSDAction',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getSalesTSDList();
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const TSDsSlice = createSlice({
  name: 'getTSDs',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getTSDsAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTSDsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(getTSDsAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
        state.data = [];
      });
  },
});

export const { clear: clearTSDsAction } = TSDsSlice.actions;
export const getTSDsReducer = TSDsSlice.reducer;

import { useIsUserType } from 'core/hooks/useIsUserType';
import useIsUserTeamRoleName from 'core/hooks/useIsUserTeamRoleName';
import useIsTeamRolesDepartmentMember from 'core/hooks/useIsTeamRolesDepartmentMember';
import { UserTeamRoleName, UserTeamRoleTeamDepartment, UserType } from 'pages/UserManagement/UserManagement.models';

const { DEPARTMENT_HEAD } = UserTeamRoleName;

const {
  SELLER, CONTRACT_SPECIALIST, BILLING_SPECIALIST, PROJECT_MANAGER,
} = UserTeamRoleTeamDepartment;

export const useSowDataActionsButtonPermissions = () => {
  const isAdmin = useIsUserType(UserType.ADMIN);

  const isDepartmentHead = useIsUserTeamRoleName(DEPARTMENT_HEAD);

  const isProjectManager = useIsTeamRolesDepartmentMember(PROJECT_MANAGER);
  const isContractSpecialist = useIsTeamRolesDepartmentMember(CONTRACT_SPECIALIST);
  const isBillingSpecialist = useIsTeamRolesDepartmentMember(BILLING_SPECIALIST);
  const isSeller = useIsTeamRolesDepartmentMember(SELLER);

  const isProjectManagerDepartmentHead = isProjectManager && isDepartmentHead;
  const isProjectManagerNotDepartmentHead = isProjectManager && !isDepartmentHead;

  const isEngagementAcceptedVisible = isAdmin
  || isContractSpecialist
  || isProjectManagerDepartmentHead;
  const isRequestActionsHidden = isContractSpecialist;
  const isProvideActionsHidden = isSeller;
  const isUnavailableActionsBuntton = isBillingSpecialist
  || isProjectManagerNotDepartmentHead;

  return {
    isProvideActionsHidden,
    isRequestActionsHidden,
    isEngagementAcceptedVisible,
    isUnavailableActionsBuntton,
  };
};

import React from 'react';
import { useFormikContext } from 'formik';
import { Box } from '@mui/material';
import Loader from 'shared/Loader';
import EngagementsFormGeneral from './EngagementsFormGeneral';

const EngagementsFormFields = () => {
  const { isSubmitting } = useFormikContext();

  return (
    <Box className="engagements-form__wrapper">
      {isSubmitting && <Loader />}
      <EngagementsFormGeneral />
    </Box>
  );
};

export default EngagementsFormFields;

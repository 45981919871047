import React from 'react';
import { IPayload } from 'core/models/Recharts.models';
import { Box } from '@mui/material';
import styles from 'assets/styles/_resources.scss';
import { others } from 'pages/Dashboard/shared/constants';
import './ChartTooltip.scss';

interface IChartTooltip {
  payload?: IPayload[] | null;
  unit?: string;
  isVerticalTooltip?: boolean;
}

const getBackgroundColor = (item) => {
  if (item.payload.name === others) {
    return styles.darkColor100;
  }
  return item?.color || item?.payload?.fill;
};

const ChartTooltip: React.FC<IChartTooltip> = ({ payload, unit, isVerticalTooltip }) => {
  if (!payload || !payload.length) {
    return null;
  }

  const payloadData = isVerticalTooltip ? payload.slice(0, -1) : payload;

  return (
    <Box className="chart-tooltip" data-testid="chart-tooltip">
      {
        isVerticalTooltip && (
          <Box className="chart-tooltip__name">
            {` ${payload[0]?.payload?.name} : `}
          </Box>
        )
      }
      {payloadData.map((item) => (
        <Box
          key={item.name}
          className="chart-tooltip__wrap"
          data-testid="tooltip"
        >
          <Box
            data-testid="tooltip-bullet"
            className="chart-tooltip__bullet"
            style={{ backgroundColor: getBackgroundColor(item) }}
          />
          <Box className="chart-tooltip__title">
            {` ${isVerticalTooltip ? item?.name : item?.payload?.name || item?.name} : `}
          </Box>
          <Box className="chart-tooltip__value">
            <span>{item?.value}</span>
            {unit}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

ChartTooltip.defaultProps = {
  payload: null,
  unit: '',
  isVerticalTooltip: false,
};

export default ChartTooltip;

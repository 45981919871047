import React from 'react';
import { useFormikContext } from 'formik';
import {
  Grid, Typography, useTheme, useMediaQuery,
} from '@mui/material';
import { dateFormatWithTime } from 'core/constants';
import EditSaveControls from 'shared/EditSaveControls';
import FormikTextareaField from 'shared/FormikField/FormikTextareaField';
import formatDatetime from 'core/utilities/dateUtilities/formatDatetime';
import { IScopeDescription, IScopeDescriptionForm } from 'pages/WirelineEngagements/WirelineEngagementsCreate/EngagementFormsWrapper/ScopeDescription/ScopeDescription.model';
import EngagementsFormScopeDescriptionActions from 'pages/WirelineEngagements/WirelineEngagementsCreate/EngagementFormsWrapper/ScopeDescription/EngagementsFormScopeDescription/EngagementsFormScopeDescriptionActions';

interface EngagementsFormScopeDescriptionParams {
  isEditMode: boolean
  isVisibleActionsButton: boolean
  scopeDescription: IScopeDescription
  toggleEditMode: (isEditMode: boolean) => void
}

const EngagementsFormScopeDescription: React.FC<EngagementsFormScopeDescriptionParams> = ({
  isEditMode,
  toggleEditMode,
  scopeDescription,
  isVisibleActionsButton,
}) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const { submitForm, resetForm } = useFormikContext<IScopeDescriptionForm>();

  const formattedModifiedDatetime = formatDatetime(
    scopeDescription?.modifiedDatetime,
    dateFormatWithTime,
  );

  return (
    <Grid
      className="engagements-forms__section"
      container
      direction="row"
      columnSpacing={5}
    >
      <Grid
        item
        container
        xs={12}
        pb={3}
      >
        <Grid
          item
          container
          direction="row"
          xs={12}
        >
          <Grid
            item
            container
            direction="row"
            md={4}
            xs={12}
          >
            <Grid
              item
              container
              direction="row"
              xs={12}
            >
              <Typography
                className="engagements-forms__section-title"
                variant="body1"
                component="p"
              >
                Scope Description
              </Typography>
              {isVisibleActionsButton
                && (
                <EditSaveControls
                  onSave={submitForm}
                  isEditMode={isEditMode}
                  onCancelEdit={resetForm}
                  setIsEditMode={toggleEditMode}
                />
                )}
            </Grid>
            <Grid
              item
              xs={12}
              mb={1}
            >
              <Typography
                className="engagements-forms__section-subtitle"
                variant="body1"
                component="p"
              >
                {formattedModifiedDatetime && `Edited: ${formattedModifiedDatetime}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent="end"
            alignItems="center"
            xs={8}
          >
            {!isTablet && <EngagementsFormScopeDescriptionActions />}
          </Grid>
          <Grid item mt={1} xs={12}>
            <FormikTextareaField
              fieldName="text"
              disabled={!isEditMode}
            />
          </Grid>
          <Grid
            item
            container
            direction="row"
          >
            {isTablet && <EngagementsFormScopeDescriptionActions />}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EngagementsFormScopeDescription;

import React from 'react';
import { useAppSelector } from 'core/store';
import SimpleBarChart from 'pages/Dashboard/shared/SimpleBarChart';
import styles from 'assets/styles/_resources.scss';

const OpenRequestsChart = () => {
  const openRequestsData = useAppSelector((state) => state.dashboard.openRequests.data);
  const isLoading = useAppSelector((state) => state.dashboard.openRequests.isLoading);

  return (
    <SimpleBarChart
      data={openRequestsData}
      color={styles.secondaryColor}
      isLoading={isLoading}
      title="Open Requests"
    />
  );
};

export default OpenRequestsChart;

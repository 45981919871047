import get from 'lodash/get';
import find from 'lodash/find';
import { getOpenapiSchemaNameFromEndpoint, parseOpenapiSchema, getSchemaNameFromRef } from 'core/utilities/openapiSchema';
// eslint-disable-next-line no-unused-vars
import { IOpenapiSchema, IOpenapiSchemasPaths } from 'core/models/openapi.models';
import { MRT_ColumnDef as ColumnDef } from 'material-react-table';
// eslint-disable-next-line no-unused-vars
import { ITableColumn, TRow } from 'shared/Table/Table.models';

export const getColumnsFromOpenapiSchema = (
  schema: IOpenapiSchema,
  openApiPaths: IOpenapiSchemasPaths,
  endpointToGetSchemaName: string,
  columns: ITableColumn[],
) => {
  const paginatedSchemaName = getOpenapiSchemaNameFromEndpoint(
    openApiPaths, endpointToGetSchemaName,
  );

  const schemaNameRef = get(schema, `${paginatedSchemaName}.properties.results.items.$ref`, '') as string;
  const schemaName = getSchemaNameFromRef(schemaNameRef);

  const tableSchema = parseOpenapiSchema(schema, schemaName);

  if (!tableSchema?.length || !tableSchema) {
    return [];
  }

  return tableSchema.map((item) => {
    const matchingColumn = find(columns, { accessorKey: item.fieldName });

    return {
      header: item.title,
      accessorKey: item.fieldName,
      enableColumnFilter: false,
      sortDescFirst: false,
      minSize: 100,
      ...matchingColumn,
    };
  }) as ColumnDef<TRow>[];
};

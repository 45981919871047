import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'core/store';
import { Outlet } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { HTTPService } from 'core/services/HTTPService';
import { getOpenapiSchemaAction } from 'core/actions/Openapi/GetOpenapiSchema';
import KeycloakService from 'core/services/KeycloakService';
import getStatesAction from 'layouts/BaseLayout/GetStates/actions';
import Header from 'layouts/Header';
import Sidebar from 'layouts/Sidebar';
import useNotifier from 'shared/Notifier';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import WelcomePage from 'pages/WelcomePage';
import { SidebarProvider } from 'layouts/Sidebar/SidebarContext';
import { getOpenapiSchemaMessage } from 'layouts/BaseLayout/constants';

const BaseLayout = () => {
  const { showErrorNotifier } = useNotifiers();
  const dispatch = useAppDispatch();
  const userProfileData = useAppSelector((state) => state.userProfile.data);
  const isUserHaveAnyAccess = !isEmpty(userProfileData.authorizedModules);
  const isLoggedIn = KeycloakService.isLoggedIn();

  const cancelRequests = (controller) => {
    HTTPService.cancelRequest(controller);
  };

  useNotifier();

  useEffect(() => {
    const controller = HTTPService.getController();

    dispatch(getStatesAction(controller));

    return () => cancelRequests(controller);
  }, []);

  useEffect(() => {
    if (!isLoggedIn || !isUserHaveAnyAccess) return;

    dispatch(getOpenapiSchemaAction())
      .unwrap()
      .catch((e) => showErrorNotifier(getOpenapiSchemaMessage.error, e));
  }, [isLoggedIn, isUserHaveAnyAccess]);

  return (
    <SidebarProvider>
      <div className="base-layout">
        <Header />
        {isUserHaveAnyAccess ? (
          <>
            <Sidebar />
            <div className="base-layout__content" data-testid="base-layout__content">
              <Outlet />
            </div>
          </>
        ) : <WelcomePage />}
      </div>
    </SidebarProvider>
  );
};

export default BaseLayout;

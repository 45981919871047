import React, { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ClearIcon from '@mui/icons-material/Clear';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { requestFormat } from 'core/constants';
import dayjs from 'dayjs';

interface FilterDatePickerProps {
  getFilterValue: () => string | null;
  setFilterValue: (value: string | null) => void;
}

const FilterDatePicker: React.FC<FilterDatePickerProps> = (
  { getFilterValue, setFilterValue }) => {
  const selectedDate = getFilterValue();
  const formattedDateValue = selectedDate ? dayjs(selectedDate) : null;
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const openCalendar = () => setIsCalendarOpen(true);
  const closeCalendar = () => setIsCalendarOpen(false);

  const onCalendarClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    openCalendar();
  };

  const onClearClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setFilterValue(null);
    closeCalendar();
  };

  const onDateInputClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (!isCalendarOpen) {
      openCalendar();
    }
  };

  const onDateChange = (newValue: any) => {
    setFilterValue(newValue ? newValue.format(requestFormat) : null);
    closeCalendar();
  };

  const datePickerInputProps = formattedDateValue ? {
    endAdornment: (
      <Tooltip title="Clear date" placement="right" arrow>
        <IconButton
          onClick={onClearClick}
          size="small"
          edge="end"
        >
          <ClearIcon />
        </IconButton>
      </Tooltip>
    ),
  } : {
    endAdornment: (
      <Tooltip title="Select date" placement="right" arrow>
        <IconButton
          onClick={onCalendarClick}
          size="small"
          edge="end"
        >
          <CalendarTodayIcon />
        </IconButton>
      </Tooltip>
    ),
  };

  const datePickerStyles = {
    maxWidth: '170px',
    margin: '0.5rem',
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
        <DatePicker
          open={isCalendarOpen}
          onOpen={openCalendar}
          onClose={closeCalendar}
          onChange={onDateChange}
          slotProps={{
            textField: {
              sx: datePickerStyles,
              variant: 'outlined',
              onClick: onDateInputClick,
              InputProps: datePickerInputProps,
            },
          }}
          value={formattedDateValue}
        />
      </div>
    </LocalizationProvider>
  );
};

export default FilterDatePicker;

export enum RenewalPeriodFilter {
    DAYS_OUT_120 = 120,
    DAYS_OUT_90 = 90,
    DAYS_OUT_60 = 60
}

export type RenewalPeriod = {
    daysToRenewalFrom: number,
    daysToRenewalTo : number,
}

// eslint-disable-next-line no-unused-vars
import { TPeriodInsideSalesBooking } from 'shared/CustomRangePicker/CustomRangePicker.models';
import {
  getInsideSalesBookingDataRequest,
  getInsideSalesBookingDataSuccess,
  getInsideSalesBookingDataFailure,
} from '@dashboardWirelineInsideSalesDepartment/GetInsideSalesTableData/actionsCreators';

import getInsideSalesBookingData from '@dashboardWirelineInsideSalesDepartment/GetInsideSalesTableData/api.service';

const getInsideSalesDataAction = (
  controller,
  params: {
      page: number;
      page_size: number;
      search: string;
      ordering?: string | undefined;
    },
  period: TPeriodInsideSalesBooking | undefined) => async (dispatch) => {
  const { signal } = controller;

  try {
    dispatch(getInsideSalesBookingDataRequest());

    const combinedParams = {
      ...params,
      ...period,
    };

    const { data } = await getInsideSalesBookingData(signal, combinedParams);
    dispatch(getInsideSalesBookingDataSuccess(data));
  } catch (error) {
    dispatch(getInsideSalesBookingDataFailure(error));
  }
};

export default getInsideSalesDataAction;

import { find, filter, compact } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { dashboardRoutes, dashboardWirelineRoutes } from 'pages/Dashboard/routes';
import { DASHBOARD_TABS } from 'pages/Dashboard/Dashboard.models';
// eslint-disable-next-line no-unused-vars
import { IRoutingConfigItem } from 'core/routing/models';
import routes from 'core/routing/routesConfig';
import accessNames from 'core/routing/accessRoutesNames';
import { useAppSelector } from 'core/store';

export const useAvailableSubRoutes = (subRoutes: IRoutingConfigItem[], keys: string[]) => {
  const userProfileData = useAppSelector((state) => state.userProfile.data);

  const tabs = keys.reduce(
    (currentModules, key) => currentModules.find((item) => item.name === key)?.tabs || [],
    userProfileData?.authorizedModules || [],
  ).map((item) => item.name);

  return subRoutes.filter(({ id }) => !!id && tabs.includes(id));
};

export const useAvailableRoutes = () => {
  const availableDashboardRoutes = useAvailableSubRoutes(dashboardRoutes, [accessNames.dashboard]);
  const availableDashboardWirelineRoutes = useAvailableSubRoutes(
    dashboardWirelineRoutes, [accessNames.dashboard, DASHBOARD_TABS.WIRELINE],
  );

  const userProfileData = useAppSelector((state) => state.userProfile.data);
  const isUserProfileData = !isEmpty(userProfileData.authorizedModules);

  if (!isUserProfileData) {
    return [];
  }

  const isAvailableRoute = (route: IRoutingConfigItem) => userProfileData.authorizedModules?.some(
    (item) => item.name === route.accessName,
  );

  const availableGeneralRoutes = routes.filter(isAvailableRoute);

  if (isEmpty(availableGeneralRoutes)) {
    return [];
  }

  const dashboardRoute = find(availableGeneralRoutes, { accessName: accessNames.dashboard });

  // getting child routes for dashboard
  if (!isEmpty(availableDashboardRoutes) && dashboardRoute) {
    const wirelineRoute = find(availableDashboardRoutes, { id: DASHBOARD_TABS.WIRELINE });

    // getting child routes for dashboard -> wireline
    if (!isEmpty(availableDashboardWirelineRoutes) && wirelineRoute) {
      wirelineRoute.child = availableDashboardWirelineRoutes;
      wirelineRoute.redirect = availableDashboardWirelineRoutes[0].path;
    }

    dashboardRoute.child = compact([
      wirelineRoute,
      ...filter(availableDashboardRoutes, ({ id }) => id !== DASHBOARD_TABS.WIRELINE),
    ]);
    dashboardRoute.redirect = wirelineRoute?.path;
  }

  return compact([
    dashboardRoute,
    ...filter(availableGeneralRoutes, ({ accessName }) => accessName !== accessNames.dashboard),
  ]);
};

import React, { useMemo } from 'react';
import { useField } from 'formik';
import { useAppSelector } from 'core/store';
import { GRID_DIRECTION } from 'shared/FormikField/FormikField.model';
import { getOptionsFromLocations } from 'core/utilities/getOptionsFromLocations';
import FormikAutocompleteField from 'shared/FormikField/FormikAutocompleteField';
import { fields } from 'pages/WirelineEngagements/shared/AddOrderModalForm/constants';

interface IAddProductLocationsFieldProps {
  itemCount: number
}

const AddProductLocationsField: React.FC<IAddProductLocationsFieldProps> = (
  { itemCount },
) => {
  const fieldName = `${fields.orders}[${itemCount}][${fields.locationIds}]`;
  const [field] = useField(fieldName);
  const locations = useAppSelector((state) => state.customers.locationsByCustomerId.data);

  const locationsOptions = useMemo(() => getOptionsFromLocations(locations), [locations]);

  const defaultValue = locationsOptions.filter((item) => field.value.includes(item.value));

  return (
    <FormikAutocompleteField
      multiple
      required
      disableCloseOnSelect
      label="Location"
      fieldName={fieldName}
      options={locationsOptions}
      defaultValue={defaultValue}
      gridDirection={GRID_DIRECTION.COLUMN}
    />
  );
};

export default AddProductLocationsField;

import { editEngagementComment } from 'core/api/wirelineEngagements.api';
// eslint-disable-next-line no-unused-vars
import { IEditCommentError, IDraftComment } from 'shared/widgets/Comments/Comments.models';
import {
  editCommentFailure, editCommentRequest, editCommentSuccess,
} from 'pages/WirelineEngagements/WirelineEngagementsComments/EditComment/actionCreators';

export const editCommentAction = (
  engagementId: string | number,
  commentId: string | number,
  formData: IDraftComment,
) => async (dispatch) => {
  try {
    dispatch(editCommentRequest(commentId));
    const { data } = await editEngagementComment(engagementId, commentId, formData);
    dispatch(editCommentSuccess(data));
  } catch (error) {
    const { response: { data: { text, detail } } } = error as IEditCommentError;
    dispatch(editCommentFailure(commentId, detail || text));
    throw error;
  }
};

export default editCommentAction;

import React from 'react';
import { uniqueId } from 'lodash';
import { useFormikContext } from 'formik';
import Dropzone from 'shared/widgets/Attachments/Dropzone';
import { IInsideSalesCreateRequestForm } from 'pages/InsideSales/model';
import InsideSalesAttachmentsList from 'pages/InsideSales/InsideSalesList/InsideSalesListTable/InsideSalesListTableModals/InsideSalesCreateModalForm/InsideSalesAttachments/InsideSalesAttachmentsList';

const InsideSalesAttachments = () => {
  const { values, setFieldValue } = useFormikContext<IInsideSalesCreateRequestForm>();
  const { attachments } = values;

  const onDrop = (acceptedFiles: File[]) => {
    const atachmentsData = acceptedFiles.map((file) => ({
      file,
      id: uniqueId(),
    }));

    setFieldValue('attachments', [...atachmentsData, ...attachments]);
  };

  const removeAttachment = (attachmentId: string) => {
    const validAttachments = attachments
      .filter((attachment) => attachment.id !== attachmentId);

    setFieldValue('attachments', validAttachments);
  };

  return (
    <div className="inside-sales__create-form__attachments">
      <Dropzone
        onDrop={onDrop}
        isAddButton={false}
      />
      <InsideSalesAttachmentsList
        attachments={attachments}
        removeAttachment={removeAttachment}
      />
    </div>

  );
};

export default InsideSalesAttachments;

import React from 'react';
import useInsideSalesListPermissions from 'pages/InsideSales/InsideSalesList/useInsideSalesListPermissions';
import CancelRequestModalForm from 'pages/InsideSales/InsideSalesList/InsideSalesListTable/InsideSalesListTableModals/CancelRequestModalForm';
import InsideSalesAcceptedModal from 'pages/InsideSales/InsideSalesList/InsideSalesListTable/InsideSalesListTableModals/InsideSalesAcceptedModal';
import InsideSalesCreateModalForm from 'pages/InsideSales/InsideSalesList/InsideSalesListTable/InsideSalesListTableModals/InsideSalesCreateModalForm';
import InsideSalesEditRowSidebarForm from 'pages/InsideSales/InsideSalesList/InsideSalesListTable/InsideSalesListTableModals/InsideSalesEditRowSidebarForm';

interface IInsideSalesListTableModalsProps {
  isCreateRequestModalOpen: boolean
  setIsCreateRequestModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  isInsideSalesAcceptedModalOpen: boolean
  setIsInsideSalesAcceptedModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const InsideSalesListTableModals: React.FC<
  IInsideSalesListTableModalsProps
> = ({
  isCreateRequestModalOpen,
  setIsCreateRequestModalOpen,
  isInsideSalesAcceptedModalOpen,
  setIsInsideSalesAcceptedModalOpen,
}) => {
  const {
    isAvailableEditSidebar,
    isAvailableCreateRequest,
    isAvailableCancelRequest,
    isAvailableRequestAccepted,
  } = useInsideSalesListPermissions();

  return (
    <>
      {
        isAvailableCreateRequest && (
          <InsideSalesCreateModalForm
            isOpen={isCreateRequestModalOpen}
            setIsOpen={setIsCreateRequestModalOpen}
          />
        )
      }
      {
        isAvailableRequestAccepted && (
          <InsideSalesAcceptedModal
            isInsideSalesAcceptedModalOpen={isInsideSalesAcceptedModalOpen}
            setIsInsideSalesAcceptedModalOpen={setIsInsideSalesAcceptedModalOpen}
          />
        )
      }
      {isAvailableEditSidebar && <InsideSalesEditRowSidebarForm />}
      {isAvailableCancelRequest && <CancelRequestModalForm />}
    </>
  );
};

export default InsideSalesListTableModals;

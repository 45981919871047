/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import getUserProfile from 'layouts/BaseLayout/GetUserProfile/api.service';
import { getPermissionsData } from 'layouts/BaseLayout/GetUserProfile/utilites';
import { IUserProfileData, IUserProfilePermissions } from 'layouts/BaseLayout/GetUserProfile/GetUserProfile.models';

export interface IState {
  isLoading: boolean;
  error: object | null;
  data: IUserProfileData;
  permissions: IUserProfilePermissions;
}

const initialState: IState = {
  data: {} as IUserProfileData,
  isLoading: false,
  error: null,
  permissions: {} as IUserProfilePermissions,
};

export const getUserProfileAction = createAsyncThunk(
  'getUserProfileAction',
  async (controller: AbortController, { rejectWithValue }) => {
    const { signal } = controller;

    try {
      const { data } = await getUserProfile(signal);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const UserProfileSlice = createSlice({
  name: 'getUserProfileReducer',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getUserProfileAction.pending, (state) => {
        state.data = initialState.data;
        state.isLoading = true;
        state.permissions = initialState.permissions;
      })
      .addCase(getUserProfileAction.fulfilled, (state, action) => {
        state.error = null;
        state.isLoading = false;
        state.data = action.payload;
        state.permissions = getPermissionsData(action.payload);
      })
      .addCase(getUserProfileAction.rejected, (state, action) => {
        state.data = initialState.data;
        state.isLoading = false;
        state.error = action.error;
        state.permissions = initialState.permissions;
      });
  },
});

export const { clear: clearUserProfileDataAction } = UserProfileSlice.actions;

export const getUserProfileReducer = UserProfileSlice.reducer;

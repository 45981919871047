import React from 'react';
import times from 'lodash/times';
import { Skeleton } from '@mui/material';

interface IDashboardPieChartSkeletonProps {
  isMobile: boolean
}

const DashboardPieChartSkeleton: React.FC<
  IDashboardPieChartSkeletonProps
> = ({ isMobile }) => (
  <div
    className="dashboard-pie-chart__skeleton-wrap"
    data-testid="dashboard-pie-chart__skeleton-wrap"
  >
    <div className="dashboard-pie-chart__skeleton-wrap__circle">
      <Skeleton
        variant="circular"
        width={isMobile ? 150 : 160}
        height={isMobile ? 150 : 160}
      />
    </div>

    <div className="dashboard-pie-chart__skeleton-wrap__legend">
      {
        times(3, (i) => (
          <div
            key={i}
            className="dashboard-pie-chart__skeleton-wrap__legend-item"
          >
            <Skeleton variant="text" sx={{ fontSize: '14px' }} />
            <Skeleton variant="text" sx={{ fontSize: '18px' }} />
          </div>
        ))
      }
    </div>
  </div>
);

export default DashboardPieChartSkeleton;

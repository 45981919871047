import { createSlice } from '@reduxjs/toolkit';
import { ICustomer } from 'pages/Customers/Customers.models';

export interface IGetCustomersListState {
  count: number
  isLoading: boolean
  error: object | null
  results: ICustomer[]
}

const initialState: IGetCustomersListState = {
  isLoading: true,
  count: 0,
  results: [],
  error: null,
};

const getCustomersListSlice = createSlice({
  name: 'getCustomersList',
  initialState,
  reducers: {
    getCustomersListRequest: (state) => ({
      ...state,
      isLoading: true,
      results: [],
    }),
    getCustomersListSuccess: (state, action) => ({
      ...state,
      ...action.payload,
      isLoading: false,
    }),
    getCustomersListFailure: (state, action) => ({
      ...state,
      isLoading: false,
      error: action.payload,
      results: [],
    }),
    clearCustomersListAction: () => initialState,
  },
});

export const {
  getCustomersListRequest,
  getCustomersListSuccess,
  getCustomersListFailure,
  clearCustomersListAction,
} = getCustomersListSlice.actions;

export const getCustomersListReducer = getCustomersListSlice.reducer;

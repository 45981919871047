import React, {
  // eslint-disable-next-line no-unused-vars
  createContext, FC, useCallback, useContext, useMemo, useState,
} from 'react';

interface ISidebarContextProps {
    isToggled: boolean;
    isCollapsed: boolean;
    switchIsSidebarCollapsed: () => void;
    switchIsSidebarToggled: () => void;
    switchIsSidebarToggledMobile: ()=> void;
}

interface ISidebarProviderProps {
    children: React.ReactNode,
}

const SidebarContext = createContext<ISidebarContextProps >({
  isToggled: false,
  isCollapsed: true,
  switchIsSidebarCollapsed: () => {},
  switchIsSidebarToggled: () => {},
  switchIsSidebarToggledMobile: () => {},
});

export const SidebarProvider: FC<ISidebarProviderProps> = ({ children }) => {
  const [isToggled, setIsToggled] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const switchIsSidebarCollapsed = useCallback(
    () => setIsCollapsed((collapsed) => !collapsed),
    [],
  );

  const switchIsSidebarToggled = useCallback(
    () => setIsToggled((toggled) => !toggled),
    [],
  );

  const switchIsSidebarToggledMobile = useCallback(() => {
    if (isCollapsed) {
      switchIsSidebarCollapsed();
    }
    switchIsSidebarToggled();
  }, [isCollapsed, switchIsSidebarCollapsed, switchIsSidebarToggled]);

  const value = useMemo(() => ({
    isToggled,
    isCollapsed,
    switchIsSidebarCollapsed,
    switchIsSidebarToggled,
    switchIsSidebarToggledMobile,
  }), [isToggled, isCollapsed]);

  return (
    <SidebarContext.Provider value={value}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebarContext = () => (useContext(SidebarContext));

import React from 'react';
import { Grid } from '@mui/material';
import {
  Form, useFormik, FormikProvider,
} from 'formik';
import { CustomDialogContent } from 'shared/CustomDialog';
import { useUpdateEffect } from 'core/hooks/useUpdateEffect';
import FormikSelectField from 'shared/FormikField/FormikSelectField';
import CalendarIcon from '@mui/icons-material/CalendarTodayOutlined';
import { GRID_DIRECTION } from 'shared/FormikField/FormikField.model';
import { getReportPeriodOptions } from '@dashboardWirelineSellerDepartment/BookingInfo/BookingTableTopToolbar/utilites';
// eslint-disable-next-line no-unused-vars
import { IBookedOrderForm } from '@dashboardWirelineSellerDepartment/BookingInfo/BookingTableTopToolbar/BookingActionButton/BookedOrder/bookedOrder.model';
import { bookedAcceptedSchema } from '@dashboardWirelineSellerDepartment/BookingInfo/BookingTableTopToolbar/BookingActionButton/BookedOrder/BookedOrderFormSchema';

const reportPeriodOptions = getReportPeriodOptions({ months: 2, withCurrentMonth: true });
const initialValues = {
  bookingReportPeriod: reportPeriodOptions[0].value,
};

interface IBookedOrderFormProps {
  onClose: () => void;
  setWithLeaveConfirmation: (value: boolean) => void;
  onSubmitHandler: (values: IBookedOrderForm) => void;
}

const BookedOrderForm: React.FC<IBookedOrderFormProps> = ({
  onClose,
  onSubmitHandler,
  setWithLeaveConfirmation,
}) => {
  const formikData = useFormik({
    initialValues,
    onSubmit: onSubmitHandler,
    validationSchema: bookedAcceptedSchema,
  });

  const {
    dirty, submitForm, isSubmitting,
  } = formikData;

  useUpdateEffect(() => {
    setWithLeaveConfirmation(dirty && !isSubmitting);
  }, [dirty, isSubmitting]);

  return (
    <FormikProvider
      value={formikData}
    >
      <Form>
        <CustomDialogContent
          areButtonsVisible
          onClose={onClose}
          cancelButtonText="No"
          confirmButtonText="Yes"
          isLoading={isSubmitting}
          onClickConfirm={submitForm}
          title="Are you sure you want to book the selected order/s?"
        >
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <FormikSelectField
                required
                StartIcon={CalendarIcon}
                label="Select report period"
                options={reportPeriodOptions}
                fieldName="bookingReportPeriod"
                gridDirection={GRID_DIRECTION.COLUMN}
              />
            </Grid>
          </Grid>
        </CustomDialogContent>
      </Form>
    </FormikProvider>
  );
};

export default BookedOrderForm;

import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useAppDispatch } from 'core/store';
import { getSowDataPath } from 'core/routing/paths';
import Loader from 'shared/Loader';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import RouterFormConfirmDialogFormik from 'shared/RouterFormConfirmDialog/RouterFormConfirmDialogFormik';
import schema from '@EngagementsSowData/ContractForm/ContractFormSchema';
import ContractFormInfo from '@EngagementsSowData/ContractForm/ContractFormInfo';
import ContractFormSettings from '@EngagementsSowData/ContractForm/ContractFormSettings';
import contractBundlesAction from '@EngagementsSowData/ContractForm/contractBundles';
import ContractFormButtons from '@EngagementsSowData/ContractForm/ContractFormButtons';
import ContractDetails from '@EngagementsSowData/ContractForm/ContractDetails';
import { IServerValidationError } from 'core/services/HTTPService.models';
import { requestContractsNotifierMessage } from '@EngagementsSowData/ContractForm/constants';
import RequestContractModal from '@EngagementsSowData/ContractForm/RequestContractModal';
import { ISowDataListItem, OrderActions } from '@EngagementsSowData/sowData.model';
import { IContractDetailsOrders } from '@EngagementsSowData/ContractForm/contractForm.model';
import { IContractFormInitialValues, IContractBundlesById } from '@EngagementsSowData/salesSow.model';

import './ContractForm.scss';

interface IContractFormProps {
  selectedOrders?: ISowDataListItem[],
  numberOfOrders: number,
  contractDetailsOrders: IContractDetailsOrders[],
  isViewMode?: boolean,
  orderIds?: string[],
  initialValues: IContractFormInitialValues | IContractBundlesById,
  isLoading: boolean,
  subAccountId: number | string,
}

const ContractForm: React.FC<IContractFormProps> = ({
  selectedOrders,
  numberOfOrders,
  contractDetailsOrders,
  isViewMode,
  orderIds,
  initialValues,
  isLoading,
  subAccountId,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { wirelineEngagementId, customerId } = useParams();
  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();

  const [isActionsModalOpen, setIsActionsModalOpen] = useState(false);

  const onSubmit = async (params, { setErrors }) => {
    const paramsWithId = {
      orderIds,
      ...params,
      additionalContacts: params.additionalContacts ? params.additionalContacts.split(' ') : [],
    };

    await dispatch(contractBundlesAction(paramsWithId)).unwrap()
      .then(() => {
        showSuccessNotifier(requestContractsNotifierMessage.success);
        navigate(getSowDataPath(wirelineEngagementId, customerId));
      })
      .catch((error) => {
        const definedError = error as IServerValidationError;
        setErrors(definedError?.response?.data);

        showErrorNotifier(requestContractsNotifierMessage.error, error);
      });
  };

  if (isLoading) {
    return (
      <Loader />
    );
  }

  return (
    <div>
      <ContractDetails
        orders={contractDetailsOrders}
        numberOfOrders={numberOfOrders}
        isViewMode={Boolean(isViewMode)}
      />
      <Formik
        validationSchema={schema}
        onSubmit={onSubmit}
        initialValues={initialValues}
        validateOnBlur
        validateOnChange
      >
        <Form className="contract-form">
          <ContractFormInfo
            subAccountId={String(subAccountId)}
            isDisabled={Boolean(isViewMode)}
          />
          <ContractFormSettings
            isDisabled={Boolean(isViewMode)}
          />
          <ContractFormButtons
            setIsActionsModalOpen={setIsActionsModalOpen}
            isDisabled={Boolean(isViewMode)}
          />
          {!isViewMode && (
            <RequestContractModal
              isModalOpen={isActionsModalOpen}
              setIsModalOpen={setIsActionsModalOpen}
              orderAction={OrderActions.REQUEST_CONTRACTS}
              selectedOrders={selectedOrders as ISowDataListItem[]}
            />
          )}
          <RouterFormConfirmDialogFormik />
        </Form>
      </Formik>
    </div>
  );
};

ContractForm.defaultProps = {
  selectedOrders: [],
  isViewMode: false,
  orderIds: [],
};

export default ContractForm;

import { createEngagementComment } from 'core/api/wirelineEngagements.api';
// eslint-disable-next-line no-unused-vars
import { IEditCommentError, IDraftComment } from 'shared/widgets/Comments/Comments.models';
import {
  createCommentFailure,
  createCommentRequest,
  createCommentSuccess,
} from 'pages/WirelineEngagements/WirelineEngagementsComments/CreateComment/actionCreators';

const createCommentAction = (
  engagementId: string | number,
  formData: IDraftComment,
) => async (dispatch) => {
  try {
    dispatch(createCommentRequest());
    const { data } = await createEngagementComment(engagementId, formData);

    dispatch(createCommentSuccess(data));
  } catch (error) {
    const { response: { data: { text, detail } } } = error as IEditCommentError;
    dispatch(createCommentFailure(detail || text));
    throw error;
  }
};

export default createCommentAction;

import React from 'react';
import {
  Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, Brush,
} from 'recharts';
import { useMediaQuery, useTheme } from '@mui/material';
import { chartNoData, cursorColor } from 'pages/Dashboard/shared/constants';
import styles from 'assets/styles/_resources.scss';
import ChartTooltip from 'pages/Dashboard/shared/ChartTooltip';
import { IChartItem } from 'core/models/Recharts.models';
import SimpleBarChartXAxisTick from 'pages/Dashboard/shared/SimpleBarChart/SimpleBarChartContent/SimpleBarChartXAxisTick';
import SimpleBarChartSkeleton from 'pages/Dashboard/shared/SimpleBarChart/SimpleBarChartContent/SimpleBarChartSkeleton';

const dataStartScrollLength = 11;

interface ISimpleBarChartContent {
  data: IChartItem[],
  color: string,
  barSize: number,
  isNeedSeparateLine: boolean | undefined
  isBrush?: boolean
  isLoading: boolean
}

const SimpleBarChartContent: React.FC<ISimpleBarChartContent> = ({
  data, color, barSize, isNeedSeparateLine, isBrush, isLoading,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isLoading) {
    return <SimpleBarChartSkeleton />;
  }

  if (!data?.length) {
    return chartNoData;
  }

  // brush end index should be smaller than data length at least by 1
  const brushEndIndex = data.length > dataStartScrollLength
    ? dataStartScrollLength - 1
    : data.length - 1;

  const CustomXAxisTick = (props) => (
    <SimpleBarChartXAxisTick
      data={data}
      barSize={barSize}
      isNeedSeparateLine={isNeedSeparateLine}
      {...props}
    />
  );

  return (
    <ResponsiveContainer width="99%" height={300}>
      <BarChart
        data={data}
        margin={{
          top: 5,
          right: isMobile ? 15 : 20,
          left: isMobile ? -15 : 10,
          bottom: 5,
        }}
      >
        <CartesianGrid vertical={false} strokeDasharray="4 4" />
        <XAxis
          tick={CustomXAxisTick}
          tickLine={false}
          stroke={styles.lightColor500}
          dataKey="name"
          type="category"
          interval={0}
          height={60}
        />

        <YAxis
          axisLine={false}
          tickLine={false}
          tick={{ fill: styles.darkColor500 }}
        />
        <Tooltip
          cursor={{ fill: cursorColor }}
          content={<ChartTooltip />}
        />
        <Bar
          dataKey="value"
          fill={color}
          barSize={barSize}
        />
        {
          isBrush && (
            <Brush
              dataKey="value"
              stroke={color}
              startIndex={0}
              endIndex={brushEndIndex}
              height={20}
            />
          )
        }
      </BarChart>
    </ResponsiveContainer>
  );
};

SimpleBarChartContent.defaultProps = {
  isBrush: false,
};

export default SimpleBarChartContent;

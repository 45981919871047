import {
  GET_PROJECT_ACCEPTANCE_REQUEST,
  GET_PROJECT_ACCEPTANCE_SUCCESS,
  GET_PROJECT_ACCEPTANCE_FAILURE,
} from '@dashboardWirelinePMDepartment/GetProjectAcceptance/actionsTypes';

export const getProjectAcceptanceRequest = () => (
  { type: GET_PROJECT_ACCEPTANCE_REQUEST }
);

export const getProjectAcceptanceSuccess = (data) => ({
  type: GET_PROJECT_ACCEPTANCE_SUCCESS,
  data,
});

export const getProjectAcceptanceFailure = (error) => ({
  type: GET_PROJECT_ACCEPTANCE_FAILURE,
  error,
});

import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useAppDispatch, useAppSelector } from 'core/store';
import { useTableContext } from 'shared/Table/TableContext';
import {
  getEscalationSupportItemAction,
  putEscalationSupportAction,
} from 'core/actions/Sales/EscalationSupportItem/EscalationSupportItem';
import {
  editEscalationSupportTitle, getEscalationSupportItemNotifierMessage,
  putEscalationSupportNotifierMessage,
} from '@dashboardEscalationSupport/EscalationSupportTable/constants';
import EditRowEscalationSupportSidebar from '@dashboardEscalationSupport/EscalationSupportTable/EditRowEscalationSupportSidebar';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { IEscalationSupportItem } from '@dashboardEscalationSupport/EscalationSupportTable/model';

const EditRowEscalationSupportForm = () => {
  const { activeRowId, cleanActiveRow } = useTableActiveRowContext<IEscalationSupportItem>();

  const { showErrorNotifier, showSuccessNotifier } = useNotifiers();

  const dispatch = useAppDispatch();

  const { getTableData } = useTableContext();

  const escalationSupportItem = useAppSelector(
    (state) => state.dashboard.escalationSupportItem.result,
  );

  const isLoadingEscalationSupportItem = useAppSelector(
    (state) => state.dashboard.escalationSupportItem.isLoading,
  );

  useEffect(() => {
    if (!activeRowId) return;

    dispatch(getEscalationSupportItemAction(activeRowId))
      .unwrap()
      .catch((e) => showErrorNotifier(getEscalationSupportItemNotifierMessage.error, e));
  }, [activeRowId]);

  if (!activeRowId || isLoadingEscalationSupportItem) {
    return null;
  }

  const onSubmit = (params, { setErrors, resetForm }) => {
    dispatch(putEscalationSupportAction({ id: activeRowId, params }))
      .unwrap()
      .then(() => {
        cleanActiveRow();
        showSuccessNotifier(putEscalationSupportNotifierMessage.success);
        getTableData();
        resetForm();
      })
      .catch((e) => {
        setErrors(e?.response?.data);
        showErrorNotifier(putEscalationSupportNotifierMessage.error, e);
      });
  };

  return (
    <Formik
      initialValues={escalationSupportItem}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <Form>
        <EditRowEscalationSupportSidebar
          title={editEscalationSupportTitle}
          isLoadingItem={isLoadingEscalationSupportItem}
        />
      </Form>
    </Formik>
  );
};

export default EditRowEscalationSupportForm;

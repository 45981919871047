import React from 'react';
import { Box, CircularProgress, circularProgressClasses } from '@mui/material';
import styles from 'assets/styles/_resources.scss';

interface ICustomCircularProgressProps {
    size?: number | undefined;
    thickness?: number | undefined;
}

const CustomCircularProgress = ({
  size, thickness, ...props
} : ICustomCircularProgressProps) => (
  <Box sx={{ position: 'relative' }}>
    <CircularProgress
      variant="determinate"
      sx={{
        color: styles.primaryColor50,
      }}
      size={size as number}
      thickness={thickness as number}
      {...props}
      value={100}
    />
    <CircularProgress
      data-testid="circular-progress"
      variant="indeterminate"
      disableShrink
      sx={{
        animationDuration: '1000ms',
        color: styles.primaryColor,
        position: 'absolute',
        left: 0,
        top: '3px',
        [`& .${circularProgressClasses.circle}`]: {
          strokeLinecap: 'round',
        },
      }}
      size={size as number}
      thickness={thickness as number}
      {...props}
    />
  </Box>
);

CustomCircularProgress.defaultProps = {
  size: 25,
  thickness: 3,
};

export default CustomCircularProgress;

import React from 'react';
import cx from 'classnames';
import dayjs from 'dayjs';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { getSowDataPath } from 'core/routing/paths';
import { dateFormatWithTime } from 'core/constants';
import { useNavigate, useParams } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import './RequestProvidedDate.scss';

interface IRequestProvidedDateProps {
  requestProvidedDate: string,
  viewPath?: string,
  isRequest?: boolean,
}

const RequestProvidedDate: React.FC<IRequestProvidedDateProps> = ({
  requestProvidedDate, isRequest, viewPath,
}) => {
  const navigate = useNavigate();
  const { wirelineEngagementId, customerId } = useParams();

  const sowDataPath = getSowDataPath(wirelineEngagementId, customerId);

  const iconClassName = cx({
    'request-icon': isRequest,
    'provided-icon': !isRequest,
  });

  const onClickView = () => {
    if (!viewPath) return;

    navigate(sowDataPath + viewPath);
  };

  return (
    <div>
      <div className="actions-menu__icon_line">
        <CheckCircleOutlineIcon
          className={iconClassName}
          data-testid={iconClassName}
        />
        {
          viewPath && (
          <Tooltip
            title="View"
            arrow
            placement="top"
          >
            <span>
              <IconButton
                onClick={onClickView}
                data-testid="view-button"
              >
                <VisibilityOutlinedIcon />
              </IconButton>
            </span>
          </Tooltip>
          )
        }
      </div>
      <div>{ dayjs(requestProvidedDate).format(dateFormatWithTime) }</div>
    </div>
  );
};

RequestProvidedDate.defaultProps = {
  isRequest: false,
  viewPath: '',
};

export default RequestProvidedDate;

// eslint-disable-next-line no-unused-vars
import React, { FC } from 'react';
import noop from 'lodash/noop';
import {
  RadioGroup, Radio, FormControlLabel, FormControl, FormHelperText,
} from '@mui/material';
import { useField } from 'formik';
import { IOption } from 'shared/FormikField/FormikField.model';
import FormikFieldWrapper from '../FormikFieldWrapper';

import '../FormikField.scss';

interface IFormikRadioGroupProps {
  label: string
  fieldName: string
  options: IOption[]
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, value: string) => void
  required?: boolean
  disabled?: boolean
}

const FormikRadioGroup: FC<IFormikRadioGroupProps> = ({
  label,
  fieldName,
  onChange,
  required,
  options,
  disabled,
}) => {
  const [field, error] = useField(fieldName);

  const isError = Boolean(error.error && error.touched);
  const errorText = isError ? error.error : ' ';

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, value) => {
    onChange?.(event, value);

    field.onChange(event);
  };

  return (
    <FormikFieldWrapper
      fieldName={fieldName}
      label={label}
      required={required as boolean}
    >
      <FormControl error={isError}>
        <RadioGroup
          {...field}
          onChange={onChangeHandler}
          row
        >
          {options.map(({
            value: optionValue,
            label: optionLabel,
            disabled: optionDisabled,
          }) => (
            <FormControlLabel
              key={String(optionValue)}
              value={optionValue}
              control={<Radio />}
              label={optionLabel}
              disabled={(disabled || optionDisabled) as boolean}
            />
          ))}
        </RadioGroup>
        <FormHelperText error={isError}>
          {errorText}
        </FormHelperText>
      </FormControl>
    </FormikFieldWrapper>
  );
};

FormikRadioGroup.defaultProps = {
  onChange: noop,
  required: false,
  disabled: false,
};

export default FormikRadioGroup;

import React from 'react';
import cx from 'classnames';
import LinerPreloader from 'shared/LinerPreloader';
import { IAttachmentInEditMode, Attachment } from 'shared/widgets/Attachments/Attachments.model';
import AttachmentListView from 'shared/widgets/Attachments/AttachmentList/AttachmentListView';

interface IAttachmentListProps {
  attachments: Attachment[]
  attachmentInEditMode: IAttachmentInEditMode | null
  focusAttachment: (attachment: Attachment) => void
  changeAttachmentName: (e: React.SyntheticEvent) => void
  editAttachmentName: (attachmentId: number) => void
  removeAttachment: (attachmentId: number) => void
  numberDownloadFiles: number | null
  isLoadingAttachments: boolean
  isViewMode?: boolean | undefined
}

const AttachmentList: React.FC<IAttachmentListProps> = ({
  attachments,
  attachmentInEditMode,
  focusAttachment,
  changeAttachmentName,
  editAttachmentName,
  removeAttachment,
  numberDownloadFiles,
  isLoadingAttachments,
  isViewMode,
}) => (
  <>
    <div
      className={cx({
        'widget-attachments__list': true,
        'widget-attachments__list__view': isViewMode,
      })}
    >
      <AttachmentListView
        attachments={attachments}
        attachmentInEditMode={attachmentInEditMode}
        focusAttachment={focusAttachment}
        changeAttachmentName={changeAttachmentName}
        editAttachmentName={editAttachmentName}
        removeAttachment={removeAttachment}
        isViewMode={isViewMode as boolean}
      />
    </div>
    {isLoadingAttachments && (
    <div className="widget-attachments__preloader">
      <span className="widget-attachments__preloader-files">{numberDownloadFiles}</span>
      <span className="widget-attachments__preloader-text">files are uploading...</span>
      <LinerPreloader />
    </div>
    )}
  </>
);

AttachmentList.defaultProps = {
  isViewMode: false,
};

export default AttachmentList;

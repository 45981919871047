import { netsparkAxios } from 'core/services/HTTPService';
import { getOrderCommentsItemUrl, getOrderCommentsListUrl } from 'core/api/sales.api/constants';
import { IComment } from 'shared/widgets/Comments/Comments.models';

export const getOrderCommentsList = (signal: AbortSignal, orderId: number | string) => (
  netsparkAxios.get<IComment[]>(getOrderCommentsListUrl(orderId), { signal })
);

export const createOrderComment = (orderId: number | string, params: Partial<IComment>) => (
  netsparkAxios.post<IComment>(getOrderCommentsListUrl(orderId), params)
);

export const updateOrderCommentItem = (
  orderId: number | string, commentId: number | string, params: Partial<IComment>,
) => (
  netsparkAxios.patch<IComment>(getOrderCommentsItemUrl(orderId, commentId), params)
);

export const deleteOrderCommentItem = (orderId: number | string, commentId: number | string) => (
  netsparkAxios.delete(getOrderCommentsItemUrl(orderId, commentId))
);

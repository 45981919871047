import { createAsyncThunk } from '@reduxjs/toolkit';
import { quoteProvide } from 'core/api/sales.api/sales.api';

const provideQuoteAction = createAsyncThunk(
  'provideQuoteAction',
  async (quoteId: number | string, { rejectWithValue }) => {
    try {
      await quoteProvide(quoteId);
    } catch (error) {
      return rejectWithValue(error);
    }
  });

export default provideQuoteAction;

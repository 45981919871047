import { createAsyncThunk } from '@reduxjs/toolkit';
import { postEscalationSupport } from 'core/api/sales.api/sales.api';
// eslint-disable-next-line no-unused-vars
import { IEscalationSupportParams } from '@EngagementsSowData/salesSow.model';

const postEscalationSupportAction = createAsyncThunk(
  'postEscalationSupportAction',
  async ({ id, params }: { id: string | number, params: IEscalationSupportParams },
    { rejectWithValue }) => {
    try {
      await postEscalationSupport(id, params);
    } catch (error) {
      return rejectWithValue(error);
    }
  });

export default postEscalationSupportAction;

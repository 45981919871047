import React, { useState } from 'react';
import { Button } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useAppSelector } from 'core/store';
import { useTableSelectedRows } from 'shared/Table/hooks/useTableSelectedRows';
import { IRenewalSowDataItem } from '@EngagementsRenewalSowData/renewalSowData.models';
import EngagementAcceptedModal from '@EngagementsRenewalSowData/EngagementAccepted/EngagementAcceptedModal';

const EngagementAcceptedButton = () => {
  const renewalSowData = useAppSelector(
    (state) => state.wirelineEngagements.renewalSow.results,
  );

  const selectedRenewalSowData = useTableSelectedRows<IRenewalSowDataItem>(
    { rows: renewalSowData },
  );

  const [isEngagementAcceptedModalOpen, setIsEngagementAcceptedModalOpen] = useState(false);

  const onOpenEngagementAcceptedModal = () => {
    setIsEngagementAcceptedModalOpen(true);
  };

  return (
    <>
      <Button
        className="engagement-accepted__button"
        disabled={isEmpty(selectedRenewalSowData)}
        onClick={onOpenEngagementAcceptedModal}
        data-testid="engagement-accepted-button-test-id"
      >
        <CheckCircleOutlineIcon className="engagement-accepted__button__icon" />
        <div className="engagement-accepted__button__text">
          engagement accepted
        </div>
      </Button>
      <EngagementAcceptedModal
        selectedRenewalSowData={selectedRenewalSowData}
        isModalOpen={isEngagementAcceptedModalOpen}
        setIsModalOpen={setIsEngagementAcceptedModalOpen}
      />
    </>
  );
};

export default EngagementAcceptedButton;

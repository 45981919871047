import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import formatDatetime from 'core/utilities/dateUtilities/formatDatetime';
import FormikSelectField from 'shared/FormikField/FormikSelectField/FormikSelectField';
import FormikMobileMaskField from 'shared/FormikField/FormikMobileMaskField/FormikMobileMaskField';
import FormikTextField from 'shared/FormikField/FormikTextField/FormikTextField';
import { effectivePeriodOptions } from 'pages/Customers/constans';
import { ICustomer } from 'pages/Customers/Customers.models';
import FormSection from 'pages/Customers/CustomersCreate/CustomersCreateForm/CustomersCreateFormSections/FormSection';
import LoaInformationButtons from 'pages/Customers/CustomersCreate/CustomersCreateForm/CustomersCreateFormSections/LOAInformationButtons';

const CustomersCreateFormLoaInformation = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { values } = useFormikContext<ICustomer>();

  const isBlockDisabled = !!values?.loaInfoProfile?.signOn || isLoading;

  return (
    <FormSection title="LOA information">
      <Grid
        container
        item
        rowSpacing={3}
      >
        <FormikTextField
          label="First name"
          fieldName="loaInfoProfile.firstName"
          disabled={isBlockDisabled}
          fullWidth
        />
        <FormikTextField
          label="Last name"
          fieldName="loaInfoProfile.lastName"
          disabled={isBlockDisabled}
          fullWidth
        />
        <FormikTextField
          label="Title"
          fieldName="loaInfoProfile.title"
          disabled={isBlockDisabled}
          fullWidth
        />
        <FormikTextField
          label="Email"
          fieldName="loaInfoProfile.email"
          disabled={isBlockDisabled}
          fullWidth
        />
        <FormikMobileMaskField
          label="Phone"
          fieldName="loaInfoProfile.phoneNumber"
          disabled={isBlockDisabled}
          fullWidth
        />
        <FormikSelectField
          label="Effective Period"
          fieldName="loaInfoProfile.effectivePeriod"
          options={effectivePeriodOptions}
          disabled={isBlockDisabled}
          fullWidth
          required
        />
        <FormikTextField
          label="LOA Sent On"
          fieldName="loaInfoProfile.sendOn"
          formatValue={formatDatetime}
          fullWidth
          disabled
        />
        <FormikTextField
          label="LOA Signed On"
          fieldName="loaInfoProfile.signOn"
          formatValue={formatDatetime}
          fullWidth
          disabled
        />
        <LoaInformationButtons
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          isBlockDisabled={isBlockDisabled}
        />
      </Grid>
    </FormSection>
  );
};

export default CustomersCreateFormLoaInformation;

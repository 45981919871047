import {
  REMOVE_CUSTOMERS_LOCATION_REQUEST,
  REMOVE_CUSTOMERS_LOCATION_SUCCESS,
  REMOVE_CUSTOMERS_LOCATION_FAILURE,
} from './RemoveLocation/actionTypes';
import {
  UPLOAD_BULK_CUSTOMERS_LOCATION_REQUEST,
  UPLOAD_BULK_CUSTOMERS_LOCATION_SUCCESS,
  UPLOAD_BULK_CUSTOMERS_LOCATION_FAILURE,
} from './UploadBulkLocation/actionTypes';
import {
  CREATE_CUSTOMERS_LOCATION_REQUEST,
  CREATE_CUSTOMERS_LOCATION_SUCCESS,
  CREATE_CUSTOMERS_LOCATION_FAILURE,
} from './CreateLocation/actionTypes';
import {
  EDIT_CUSTOMERS_LOCATION_REQUEST,
  EDIT_CUSTOMERS_LOCATION_SUCCESS,
  EDIT_CUSTOMERS_LOCATION_FAILURE,
} from './EditLocation/actionTypes';
import {
  GET_CUSTOMERS_LOCATIONS_REQUEST,
  GET_CUSTOMERS_LOCATIONS_SUCCESS,
  GET_CUSTOMERS_LOCATIONS_FAILURE,
} from './GetLocations/actionTypes';
import { CLEAR_ERRORS_CUSTOMERS_LOCATIONS } from './ClearErrorsLocation/actionTypes';

const locationsReducer = (state = {}, {
  type, locations, location, errors, bulkErrors,
}) => {
  switch (type) {
    case GET_CUSTOMERS_LOCATIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CUSTOMERS_LOCATIONS_SUCCESS:
      return {
        ...state,
        data: locations,
        isLoading: false,
        bulkErrors: '',
      };
    case GET_CUSTOMERS_LOCATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors,
      };
    case UPLOAD_BULK_CUSTOMERS_LOCATION_REQUEST:
    case CREATE_CUSTOMERS_LOCATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_CUSTOMERS_LOCATION_SUCCESS:
      return {
        ...state,
        data: {
          results: [
            location,
          ],
        },
        isLoading: false,
        errors: [],
      };
    case UPLOAD_BULK_CUSTOMERS_LOCATION_SUCCESS:
      return {
        ...state,
        bulkErrors: '',
        data: {
          results: [
            location,
          ],
        },
        isLoading: false,
        errors: [],
      };
    case CREATE_CUSTOMERS_LOCATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors,
      };
    case UPLOAD_BULK_CUSTOMERS_LOCATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        bulkErrors,
      };
    case REMOVE_CUSTOMERS_LOCATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case REMOVE_CUSTOMERS_LOCATION_SUCCESS:
      return {
        ...state,
        bulkErrors: '',
        data: {
          results: [
            location,
          ],
        },
        isLoading: false,
        errors: [],
      };
    case REMOVE_CUSTOMERS_LOCATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors,
      };
    case EDIT_CUSTOMERS_LOCATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_CUSTOMERS_LOCATION_SUCCESS:
      return {
        ...state,
        data: {
          results: [
            location,
          ],
        },
        isLoading: false,
        errors: [],
      };
    case EDIT_CUSTOMERS_LOCATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors,
      };
    case CLEAR_ERRORS_CUSTOMERS_LOCATIONS:
      return {
        ...state,
        errors,
        bulkErrors: '',
      };
    default:
      return state;
  }
};

export default locationsReducer;

import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import React from 'react';
import { useField } from 'formik';

interface IFormikSelectClearAdornmentProps {
  fieldName: string
  required?: boolean
  disabled?: boolean
  nullable?: boolean
}

const FormikSelectClearAdornment: React.FC<
  IFormikSelectClearAdornmentProps
> = ({
  fieldName, required, disabled, nullable,
}) => {
  const [field,, helpers] = useField(fieldName);
  const { value } = field;
  const { setValue } = helpers;

  const onClear = () => {
    if (nullable) {
      setValue(null);
    } else {
      setValue('');
    }
  };

  if (required || disabled || !value) {
    return null;
  }

  return (
    <IconButton
      className="formik-select-field__clear-adornment"
      size="small"
      onClick={onClear}
      data-testid="clear-button"
    >
      <ClearIcon fontSize="small" />
    </IconButton>
  );
};

FormikSelectClearAdornment.defaultProps = {
  required: false,
  disabled: false,
  nullable: false,
};

export default FormikSelectClearAdornment;

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import { useAppDispatch, useAppSelector } from 'core/store';
import ContractForm from '@EngagementsSowData/ContractForm';
import { getContractBundlesAction } from '@EngagementsSowData/GetContractBundles';
import getCustomerAction from 'core/actions/Customers/GetCustomerById/actions';

const ContractFormView = () => {
  const dispatch = useAppDispatch();
  const { contractBundleId, customerId } = useParams();

  const requestedContractData = useAppSelector(
    (state) => state.wirelineEngagements.contractBundles.data);

  const isLoading = useAppSelector(
    (state) => state.wirelineEngagements.contractBundles.isLoading);

  const subAccountId = useAppSelector(
    (state) => state.customers.customer.customerData.subAccountId);

  const uniqueRequestedOrdersByLocation = groupBy(
    requestedContractData.contracts, ({ order }) => order.location);

  const numberOfOrders = requestedContractData.contracts.length;
  const contractDetailsOrders = map(uniqueRequestedOrdersByLocation, (value, key) => ({
    location: [key],
    product: value.map((order) => ({
      name: order.order.product,
      id: order.id,
    })),
  }));

  useEffect(() => {
    dispatch(getContractBundlesAction(String(contractBundleId)));
    dispatch(getCustomerAction(customerId));
  }, []);

  return (
    <ContractForm
      numberOfOrders={numberOfOrders}
      contractDetailsOrders={contractDetailsOrders}
      isViewMode
      initialValues={requestedContractData}
      subAccountId={subAccountId}
      isLoading={isLoading}
    />
  );
};

export default ContractFormView;

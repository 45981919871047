import useIsUserTeamRoleName from 'core/hooks/useIsUserTeamRoleName';
import useIsTeamRolesDepartmentMember from 'core/hooks/useIsTeamRolesDepartmentMember';
import { UserTeamRoleName, UserTeamRoleTeamDepartment } from 'pages/UserManagement/UserManagement.models';
import { useActionsPermissions } from 'core/hooks/useActionsPermissions';
import { ACTIONS_WIRELINE_ENGAGEMENTS_PATHS } from 'core/models/actionsPermissions';

const {
  ENGAGEMENT_EDIT,
  ENGAGEMENT_CREATE,
} = ACTIONS_WIRELINE_ENGAGEMENTS_PATHS;

const actionsPaths = {
  isAvailableEditEngagement: ENGAGEMENT_EDIT,
  isAvailableCreateEngagement: ENGAGEMENT_CREATE,
};

const { DEPARTMENT_HEAD } = UserTeamRoleName;
const {
  PROJECT_MANAGER, CONTRACT_SPECIALIST, BILLING_SPECIALIST, RETENTION_MANAGER,
} = UserTeamRoleTeamDepartment;

export const useEngagementsFormPermission = () => {
  const isDepartmentHead = useIsUserTeamRoleName(DEPARTMENT_HEAD);

  const isContractSpecialist = useIsTeamRolesDepartmentMember(CONTRACT_SPECIALIST);
  const isBillingSpecialist = useIsTeamRolesDepartmentMember(BILLING_SPECIALIST);
  const isRetentionManager = useIsTeamRolesDepartmentMember(RETENTION_MANAGER);
  const isProjectManager = useIsTeamRolesDepartmentMember(PROJECT_MANAGER);

  const isProjectManagerNotDepartmentHead = isProjectManager && !isDepartmentHead;

  const isAvailableAddOrder = !isRetentionManager
   && !isContractSpecialist
   && !isBillingSpecialist
   && !isProjectManagerNotDepartmentHead;

  const {
    isAvailableEditEngagement,
    isAvailableCreateEngagement,
  } = useActionsPermissions(actionsPaths);

  return {
    isAvailableAddOrder,
    isAvailableEditEngagement,
    isAvailableCreateEngagement,
  };
};

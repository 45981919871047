import uniqBy from 'lodash/uniqBy';
import { status } from 'shared/constants';
import {
  UserType,
  UserTeamRoleName,
  UserTeamRoleTeamAlignment,
  UserTeamRoleTeamDepartment,
} from 'pages/UserManagement/UserManagement.models';

export const editModeTeamRoleFalseIndex = -1;

export const UserTypeLabels = {
  [UserType.TEAM_USER]: 'NetSpark User',
  [UserType.ADMIN]: 'Admin',
  [UserType.CUSTOMER]: 'Customer',
};

export const UserTypeOptions = [
  {
    value: UserType.TEAM_USER,
    label: UserTypeLabels[UserType.TEAM_USER],
  },
  {
    value: UserType.ADMIN,
    label: UserTypeLabels[UserType.ADMIN],
  },
  {
    value: UserType.CUSTOMER,
    label: UserTypeLabels[UserType.CUSTOMER],
    disabled: true,
  },
];

export const userStatusOptions = [
  { value: true, label: status.active },
  { value: false, label: status.inactive },
];

export const userTeamRolesNamesLabels = {
  [UserTeamRoleName.INDIVIDUAL_SUPPORTER]: 'Individual Supporter',
  [UserTeamRoleName.DEPARTMENT_HEAD]: 'Department Head',
  [UserTeamRoleName.TEAM_LEAD]: 'Team Lead',
  [UserTeamRoleName.TECHNICAL_OVERLAY]: 'Technical Overlay',
  [UserTeamRoleName.BACKOFFICE_OVERLAY_SUPPORT]: 'Backoffice Overlay Support',
};

export const userTeamRolesNamesOptions = [
  {
    value: UserTeamRoleName.INDIVIDUAL_SUPPORTER,
    label: userTeamRolesNamesLabels[UserTeamRoleName.INDIVIDUAL_SUPPORTER],
  },
  {
    value: UserTeamRoleName.DEPARTMENT_HEAD,
    label: userTeamRolesNamesLabels[UserTeamRoleName.DEPARTMENT_HEAD],
  },
  {
    value: UserTeamRoleName.TEAM_LEAD,
    label: userTeamRolesNamesLabels[UserTeamRoleName.TEAM_LEAD],
  },
  {
    value: UserTeamRoleName.TECHNICAL_OVERLAY,
    label: userTeamRolesNamesLabels[UserTeamRoleName.TECHNICAL_OVERLAY],
  },
  {
    value: UserTeamRoleName.BACKOFFICE_OVERLAY_SUPPORT,
    label: userTeamRolesNamesLabels[UserTeamRoleName.BACKOFFICE_OVERLAY_SUPPORT],
  },
];

export const userTeamRolesTeamAlignmentsLabels = {
  [UserTeamRoleTeamAlignment.WIRELINE]: 'Wireline',
  [UserTeamRoleTeamAlignment.MOBILITY]: 'Mobility',
};

export const userTeamRolesTeamAlignmentsOptions = [
  {
    value: UserTeamRoleTeamAlignment.WIRELINE,
    label: userTeamRolesTeamAlignmentsLabels[UserTeamRoleTeamAlignment.WIRELINE],
  },
  {
    value: UserTeamRoleTeamAlignment.MOBILITY,
    label: userTeamRolesTeamAlignmentsLabels[UserTeamRoleTeamAlignment.MOBILITY],
  },
];

export const userTeamRolesTeamDepartmentsLabels = {
  [UserTeamRoleTeamDepartment.SELLER]: 'Seller',
  [UserTeamRoleTeamDepartment.CONTRACT_SPECIALIST]: 'Contract Specialist',
  [UserTeamRoleTeamDepartment.PROJECT_MANAGER]: 'Project Manager',
  [UserTeamRoleTeamDepartment.BILLING_SPECIALIST]: 'Billing Specialist',
  [UserTeamRoleTeamDepartment.DISCONNECT_SPECIALIST]: 'Disconnect Specialist',
  [UserTeamRoleTeamDepartment.INSIDE_SALES]: 'Inside Sales',
  [UserTeamRoleTeamDepartment.RETENTION_MANAGER]: 'Retention Manager',
  [UserTeamRoleTeamDepartment.MOBILITY_PROJECT_MANAGER]: 'Mobility Project Manager',
};

const userDepartmentSellerOption = {
  value: UserTeamRoleTeamDepartment.SELLER,
  label: userTeamRolesTeamDepartmentsLabels[UserTeamRoleTeamDepartment.SELLER],
};

const userDepartmentContractSpecialistOption = {
  value: UserTeamRoleTeamDepartment.CONTRACT_SPECIALIST,
  label: userTeamRolesTeamDepartmentsLabels[UserTeamRoleTeamDepartment.CONTRACT_SPECIALIST],
};

const userDepartmentProjectManagerOption = {
  value: UserTeamRoleTeamDepartment.PROJECT_MANAGER,
  label: userTeamRolesTeamDepartmentsLabels[UserTeamRoleTeamDepartment.PROJECT_MANAGER],
};

const userDepartmentBillingSpecialistOption = {
  value: UserTeamRoleTeamDepartment.BILLING_SPECIALIST,
  label: userTeamRolesTeamDepartmentsLabels[UserTeamRoleTeamDepartment.BILLING_SPECIALIST],
};

const userDepartmentDisconnectSpecialistOption = {
  value: UserTeamRoleTeamDepartment.DISCONNECT_SPECIALIST,
  label: userTeamRolesTeamDepartmentsLabels[UserTeamRoleTeamDepartment.DISCONNECT_SPECIALIST],
};

const userDepartmentInsideSalesOption = {
  value: UserTeamRoleTeamDepartment.INSIDE_SALES,
  label: userTeamRolesTeamDepartmentsLabels[UserTeamRoleTeamDepartment.INSIDE_SALES],
};

const userDepartmentRetentionManagerOption = {
  value: UserTeamRoleTeamDepartment.RETENTION_MANAGER,
  label: userTeamRolesTeamDepartmentsLabels[UserTeamRoleTeamDepartment.RETENTION_MANAGER],
};

const userDepartmentMobilityProjectManagerOption = {
  value: UserTeamRoleTeamDepartment.MOBILITY_PROJECT_MANAGER,
  label: (
    userTeamRolesTeamDepartmentsLabels[UserTeamRoleTeamDepartment.MOBILITY_PROJECT_MANAGER]
  ),
};

export const userTeamRolesTeamDepartmentsOptionsWireline = [
  userDepartmentSellerOption,
  userDepartmentContractSpecialistOption,
  userDepartmentProjectManagerOption,
  userDepartmentBillingSpecialistOption,
  userDepartmentDisconnectSpecialistOption,
  userDepartmentInsideSalesOption,
  userDepartmentRetentionManagerOption,
];

export const userTeamRolesTeamDepartmentsOptionsMobility = [
  userDepartmentSellerOption,
  userDepartmentMobilityProjectManagerOption,
];

export const userTeamRolesTeamDepartmentsOptions = uniqBy(
  [
    ...userTeamRolesTeamDepartmentsOptionsWireline,
    ...userTeamRolesTeamDepartmentsOptionsMobility,
  ],
  'value',
);

export const emptyTeamRole = {
  name: '',
  team: {
    alignment: '',
    department: '',
  },
  associates: [],
};

export const relatedToUsersGetMessages = {
  error: 'Failed to load users for related to',
};

export const relatedToByTeamRoleGetMessages = {
  error: 'Failed to load related to rules',
};

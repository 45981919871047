import React from 'react';
import { useSellerDepartmentContext } from '@dashboardWirelineSellerDepartment/SellerDepartmentContext';
import BookedOrderButton from '@dashboardWirelineSellerDepartment/BookingInfo/BookingTableTopToolbar/BookingActionButton/BookedOrder/BookedOrderButton';

const BookingActionButton = () => {
  const { actionsPermissions } = useSellerDepartmentContext();

  if (!actionsPermissions.isAvailableBookOrder) return null;

  return <BookedOrderButton />;
};

export default BookingActionButton;

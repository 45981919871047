import React from 'react';
import DashboardPieChart from 'pages/Dashboard/shared/DashboardPieChart';
import { colorsOfEscalationSupportCharts } from '@dashboardEscalationSupport/EscalationSupportCharts/constants';
import { map, startCase, toLower } from 'lodash';
import { useAppSelector } from 'core/store';

const EscalationSupportChartsTotal = () => {
  const escalationSupportTotalData = useAppSelector(
    (state) => state.dashboard.escalationSupportTotal.data,
  );

  const isLoading = useAppSelector(
    (state) => state.dashboard.escalationSupportTotal.isLoading,
  );

  const formattedData = map(escalationSupportTotalData, (item) => ({
    ...item,
    name: startCase(toLower(item.name)),
  }));

  return (
    <DashboardPieChart
      data={formattedData}
      colors={colorsOfEscalationSupportCharts}
      isLoading={isLoading}
      innerRadius={60}
      paddingAngle={5}
      title="Total"
    />
  );
};

export default EscalationSupportChartsTotal;

import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useAppDispatch, useAppSelector } from 'core/store';
import { useTableContext } from 'shared/Table/TableContext';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import EditRowRenewalSowForm
  from '@EngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableModals/EditRowRenewalSowSidebar/EditRowRenewalSowForm';
import {
  getSalesRenewalSowItemAction,
  patchRenewalSowItemAction,
} from 'core/actions/Sales/SalesRenewalSowItem';
import {
  getRenewalSowMessage,
  patchRenewalSowMessage,
} from '@EngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableModals/EditRowRenewalSowSidebar/constants';
import { IRenewalSowDataItem } from '@EngagementsRenewalSowData/renewalSowData.models';

const EditRowRenewalSowSidebar = () => {
  const {
    activeRowMode, activeRowId, cleanActiveRow,
  } = useTableActiveRowContext<IRenewalSowDataItem>();
  const { isEditMode } = getTableRowModeBoolean(activeRowMode);

  const { showErrorNotifier, showSuccessNotifier } = useNotifiers();

  const dispatch = useAppDispatch();

  const { getTableData } = useTableContext();

  const renewalSowItem = useAppSelector(
    (state) => state.wirelineEngagements.renewalSowItem.result,
  );

  useEffect(() => {
    if (!isEditMode || !activeRowId) return;

    dispatch(getSalesRenewalSowItemAction(activeRowId))
      .unwrap()
      .catch((e) => showErrorNotifier(getRenewalSowMessage.error, e));
  }, [activeRowId]);

  const onSubmit = (params, { setErrors, resetForm }) => {
    if (!activeRowId) return;

    dispatch(patchRenewalSowItemAction({ id: activeRowId, params }))
      .unwrap()
      .then(() => {
        cleanActiveRow();
        showSuccessNotifier(patchRenewalSowMessage.success);
        getTableData();
        resetForm();
      })
      .catch((e) => {
        setErrors(e?.response?.data);
        showErrorNotifier(patchRenewalSowMessage.error, e);
      });
  };

  if (!isEditMode || !activeRowId) {
    return null;
  }

  return (
    <Formik
      initialValues={renewalSowItem}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <Form>
        <EditRowRenewalSowForm />
      </Form>
    </Formik>
  );
};

export default EditRowRenewalSowSidebar;

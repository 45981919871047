import React from 'react';
import { useAppSelector } from 'core/store';

import EngagementsByPeriodChart from '@dashboardWirelineDepartments/shared/EngagementsByPeriodChart';

const TOEngagementsByPeriodChart = () => {
  const engagementsByPeriod = useAppSelector((state) => state.dashboard.engagementsTOByPeriod.data);
  const isLoading = useAppSelector((state) => state.dashboard.engagementsTOByPeriod.isLoading);

  return (
    <EngagementsByPeriodChart
      isLoading={isLoading}
      list={engagementsByPeriod}
    />
  );
};

export default TOEngagementsByPeriodChart;

import React, { useEffect, useMemo } from 'react';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useAppDispatch, useAppSelector } from 'core/store';
import { getSowItemMessage } from '@EngagementsSowData/constants';
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import OrderDataSidebar from 'pages/WirelineEngagements/shared/OrderDataSidebar';
import { getParsedRowData } from 'pages/WirelineEngagements/WirelineEngagementsTables/utilities';
import { getSalesSowItemAction, clearGetSalsesSowItemData } from 'core/actions/Sales/GetSalesSowItem';
import { dynamicProductSchemaName } from 'pages/WirelineEngagements/WirelineEngagementsTables/WirelineEngagementsSowData/constants';

const DataRowSidebar = () => {
  const dispatch = useAppDispatch();
  const { showErrorNotifier } = useNotifiers();

  const {
    activeRowId, activeRowMode, cleanActiveRow,
    setSecondarySidebarMode, secondarySidebarMode,
  } = useTableActiveRowContext<ISowDataListItem>();

  const salesSowItem = useAppSelector((state) => state.wirelineEngagements.salesSowItem.result);
  const isLoadingSowItem = useAppSelector(
    (state) => state.wirelineEngagements.salesSowItem.isLoading,
  );
  const isLoadingSchemas = useAppSelector((state) => state.openapiSchemas.isLoading);

  const isLoading = isLoadingSowItem || isLoadingSchemas;

  const { isDataMode } = getTableRowModeBoolean(activeRowMode);
  const { isDataMode: isDataInsideEditMode } = getTableRowModeBoolean(secondarySidebarMode);

  const isOpen = !!activeRowId && (isDataMode || isDataInsideEditMode);
  const needFetchData = !!activeRowId && isDataMode;

  const orderData = useMemo(() => getParsedRowData({
    rowData: salesSowItem, isEditable: false,
  }), [salesSowItem]);

  const onCloseHandler = () => (secondarySidebarMode
    ? setSecondarySidebarMode(null)
    : cleanActiveRow());

  const getSalesSowItemData = async () => {
    try {
      await dispatch(getSalesSowItemAction(activeRowId as number))
        .unwrap();
    } catch (error) {
      showErrorNotifier(getSowItemMessage.error, error);
    }
  };

  useEffect(() => {
    if (!needFetchData) return;
    getSalesSowItemData();

    return () => {
      dispatch(clearGetSalsesSowItemData());
    };
  }, [activeRowId]);

  return (
    <OrderDataSidebar
      isOpen={isOpen}
      isLoading={isLoading}
      orderData={orderData}
      onClose={onCloseHandler}
      schemaName={dynamicProductSchemaName}
    />
  );
};

export default DataRowSidebar;

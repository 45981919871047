import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import find from 'lodash/find';
import { getOptionsFromUser } from 'core/utilities/getOptionsFromUser';
import { getUsers } from 'core/api/user.api';
import { IOption } from 'shared/FormikField/FormikField.model';
import { firstPage, maxPageSize } from 'shared/Table/constants';
import FormikSelectField from 'shared/FormikField/FormikSelectField/FormikSelectField';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { IAssociationsConfigItem } from 'pages/UserManagement/UserManagementCreate/UserManagementGeneralForm/UserManagementGeneralForm.models';
import { ITeamRole } from 'pages/UserManagement/UserManagement.models';
import { relatedToUsersGetMessages } from 'pages/UserManagement/constants';

interface IUserManagementCreateFormUserTypeAssociatesItemProps {
  config: IAssociationsConfigItem
  isDisabled: boolean
  associateItemFieldName: string
}

const UserManagementCreateFormTeamRoleItemAssociationsItem: React.FC<
  IUserManagementCreateFormUserTypeAssociatesItemProps
> = ({ config, isDisabled, associateItemFieldName }) => {
  const { values, setFieldValue } = useFormikContext<ITeamRole[]>();

  const {
    fieldName: label, alignment, department, roles,
  } = config;

  const [isLoading, setIsLoading] = useState(true);
  const [availableOptions, setAvailableOptions] = useState<IOption[]>([]);
  const { showErrorNotifier } = useNotifiers();

  const updateAssociate = (options: IOption[]) => {
    const associate = get(values, associateItemFieldName);

    const isSameAlignment = associate?.team?.alignment === alignment;
    const isSameDepartment = associate?.team?.department === department;
    const isAssociateAvailable = !!find(options, { value: associate?.user });

    if (!isSameAlignment || !isSameDepartment || !isAssociateAvailable) {
      const emptyAssociate = {
        user: '',
        team: { alignment, department },
      };

      setFieldValue(associateItemFieldName, emptyAssociate);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const params = {
          alignment,
          department,
          role: roles.join(','),
          page: firstPage + 1,
          page_size: maxPageSize,
        };

        const { data: { results } } = await getUsers(params);

        const options = getOptionsFromUser(results);

        setAvailableOptions(options);

        updateAssociate(options);
      } catch (e) {
        showErrorNotifier(relatedToUsersGetMessages.error, e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [alignment, department, roles]);

  return (
    <FormikSelectField
      fieldName={`${associateItemFieldName}.user`}
      label={label}
      options={availableOptions}
      isLoading={isLoading}
      disabled={isDisabled}
      fullWidth
      required
    />
  );
};

export default UserManagementCreateFormTeamRoleItemAssociationsItem;

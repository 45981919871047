import React from 'react';
import noop from 'lodash/noop';
import {
  Grid, Typography,
} from '@mui/material';
import EditSaveControls from 'shared/EditSaveControls';
import FormikSelectField from 'shared/FormikField/FormikSelectField';

const UserManagementCreateFormTeamRoleItemPlaceholder = ({ onAddUserType, isDisabled }) => (
  <Grid
    className="user-management-create-form__user-type user-management-create-form__section"
    container
    direction="row"
    columnSpacing={5}
    pt={2}
  >
    <Grid
      item
      container
      direction="column"
      rowSpacing={3}
      md={6}
      xl={4}
    >
      <Grid item className="user-management-create-form__section-header">
        <Typography
          className="user-management-create-form__section-title"
          variant="body1"
          component="p"
        >
          User Type
        </Typography>
        <EditSaveControls
          onCancelEdit={noop}
          onSave={noop}
          setIsEditMode={onAddUserType}
          isDisabled={isDisabled}
          isEditMode={false}
        />
      </Grid>
      <FormikSelectField
        fieldName="a"
        label="Team Alignment"
        options={[]}
        disabled
        required
        fullWidth
      />
      <FormikSelectField
        fieldName="b"
        label="Department"
        options={[]}
        required
        fullWidth
        disabled
      />
      <FormikSelectField
        fieldName="c"
        label="User Role"
        options={[]}
        required
        fullWidth
        disabled
      />
    </Grid>
  </Grid>
);

export default UserManagementCreateFormTeamRoleItemPlaceholder;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import paths from 'core/routing/paths';
import { filtersList } from 'shared/Table/constants';
import { productUrlParam, customerCompanyNameUrlParam } from '@dashboardEscalationSupport/EscalationSupportTable/constants';

interface IEscalationSupportViewProps {
  hasEscalationRequests: boolean,
  product: string,
  customerCompanyName: string,
}

const EscalationSupportView: React.FC<IEscalationSupportViewProps> = ({
  hasEscalationRequests, product, customerCompanyName,
}) => {
  const navigate = useNavigate();

  const navigateToEscalationSupportTable = () => {
    const productFilter = `{"id":"${productUrlParam}","value":"${product}"}`;
    const customerCompanyNameFilter = `{"id":"${customerCompanyNameUrlParam}","value":"${customerCompanyName}"}`;
    const filters = `[{"id":"status","value":[]},{"id":"type","value":[]},${productFilter},${customerCompanyNameFilter}]`;
    const searchParams = new URLSearchParams();
    searchParams.set(filtersList, filters);

    navigate({
      pathname: paths.dashboardEscalationSupportPath,
      search: searchParams.toString(),
    });
  };

  if (!hasEscalationRequests) {
    return null;
  }

  return (
    <Tooltip
      title="Escalation Support Table"
      arrow
      placement="top"
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <InsertLinkIcon onClick={navigateToEscalationSupportTable} />
      </Box>
    </Tooltip>
  );
};

export default EscalationSupportView;

import {
  getProjectStatusRequest,
  getProjectStatusSuccess,
  getProjectStatusFailure,
} from '@dashboardWirelinePMDepartment/GetProjectStatus/actionsCreators';
import getProjectStatus from '@dashboardWirelinePMDepartment/GetProjectStatus/api.service';
// eslint-disable-next-line no-unused-vars
import { TPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';

const getProjectStatusAction = (
  controller: AbortController,
  params: TPeriod,
) => async (dispatch) => {
  try {
    const { signal } = controller;
    dispatch(getProjectStatusRequest());
    const { data } = await getProjectStatus(signal, params);
    dispatch(getProjectStatusSuccess(data));
  } catch (error) {
    dispatch(getProjectStatusFailure(error));
    throw error;
  }
};

export default getProjectStatusAction;

// eslint-disable-next-line no-unused-vars
import { ITableFiltersParams } from 'core/models/general.models';
import {
  getBookingSellerDataRequest,
  getBookingSellerDataSuccess,
  getBookingSellerDataFailure,
} from './GetBookingSellerData';

import getBookingData from './api.service';

const getBookingSellerDataAction = (
  controller,
  params: ITableFiltersParams,
) => async (dispatch) => {
  const { signal } = controller;

  try {
    dispatch(getBookingSellerDataRequest(params));

    const { data } = await getBookingData(signal, params);
    dispatch(getBookingSellerDataSuccess(data));
  } catch (error) {
    dispatch(getBookingSellerDataFailure(error));
  }
};

export default getBookingSellerDataAction;

import React, { useState } from 'react';
import noop from 'lodash/noop';
import CustomDialogWrapper from 'shared/CustomDialog';
import { TAddOrderType } from 'pages/WirelineEngagements/WirelineEngagements.models';
import AddOrderModal from 'pages/WirelineEngagements/shared/AddOrderModalForm/AddOrderModal';

import './AddOrderModalForm.scss';

interface IAddOrderModalFormProps {
  customerId: number
  orderModalType: TAddOrderType
  onSubmitSuccessCallback?: () => void
  setAddOrderModalType: (value: TAddOrderType) => void
}

const AddOrderModalForm: React.FC<IAddOrderModalFormProps> = ({
  customerId, orderModalType, setAddOrderModalType, onSubmitSuccessCallback,
}) => {
  const [withLeaveConfirmation, setWithLeaveConfirmation] = useState(false);

  const onCloseModal = () => setAddOrderModalType(null);

  return (
    <CustomDialogWrapper
      onCloseModal={onCloseModal}
      isModalOpen={!!orderModalType}
      isConfirmDialog={withLeaveConfirmation}
      paperProps={{ sx: { width: '80%', maxWidth: '1162px', height: '656px' } }}
      renderChildren={(onClose) => (
        <AddOrderModal
          onClose={onClose}
          customerId={customerId}
          orderModalType={orderModalType}
          onSubmitSuccessCallback={onSubmitSuccessCallback}
          setWithLeaveConfirmation={setWithLeaveConfirmation}
        />
      )}
    />
  );
};

AddOrderModalForm.defaultProps = {
  onSubmitSuccessCallback: noop,
};

export default AddOrderModalForm;

import { netsparkAxios } from 'core/services/HTTPService';
import { getRetentionTotalDataUrl } from 'pages/Dashboard/api/apiUrls';

export interface IGetTotalEngagementsChartItem {
  // eslint-disable-next-line no-restricted-globals
  name: string;
  total: number;
}

export const getTotalEngagements = (signal: AbortSignal, params: unknown) => netsparkAxios
  .get<IGetTotalEngagementsChartItem[]>(getRetentionTotalDataUrl, { signal, params });

// eslint-disable-next-line no-unused-vars
import React, { FC, useState } from 'react';
import {
  Grid, IconButton, Typography, useMediaQuery, useTheme, Hidden, Collapse,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import './FormSection.scss';

interface IFormSectionProps {
  title: string
  children: React.ReactNode
}

const FormSection: FC<IFormSectionProps> = ({ title, children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [isExpanded, setIsExpanded] = useState(true);

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Grid
      item
      className="form-section"
      rowSpacing={3}
      sm={12}
      md={6}
      lg={4}
      xl={4}
      mb={2}
    >
      <div className="form-section__title-wrapper">
        <Typography
          variant="body1"
          component="h3"
          className="form-section__title"
        >
          {title}
        </Typography>
        <Hidden mdUp>
          <IconButton
            onClick={toggleIsExpanded}
            aria-selected={isExpanded}
            className="form-section__toggle"
            data-testid="title-toggle-button"
          >
            <ExpandMoreIcon />
          </IconButton>
        </Hidden>
      </div>
      <Collapse
        className={(!isMobile || isExpanded) ? 'form-section__content' : ''}
        in={!isMobile || isExpanded}
      >
        {children}
      </Collapse>
    </Grid>
  );
};

export default FormSection;

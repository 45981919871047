import React, { useEffect } from 'react';
import get from 'lodash/get';
import { useAppDispatch, useAppSelector } from 'core/store';
import { HTTPService } from 'core/services';
import downloadBulkLocation from 'core/api/downloadBulkLocation.api';
import getLocationsAction from 'pages/Customers/CustomersLocations/GetLocations/actions';
import editLocationAction from 'pages/Customers/CustomersLocations/EditLocation/actions';
import createLocationAction from 'pages/Customers/CustomersLocations/CreateLocation/actions';
import uploadBulkLocationAction from 'pages/Customers/CustomersLocations/UploadBulkLocation/actions';
import removeLocationAction from 'pages/Customers/CustomersLocations/RemoveLocation/actions';
import clearErrorsLocationAction from 'pages/Customers/CustomersLocations/ClearErrorsLocation/actions';
import Locations from 'shared/widgets/Locations';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { notifierMessage } from 'pages/Customers/CustomersLocations/constants';

let customersLocationsController = HTTPService.getController();

interface ICustomersLocationsProps {
  customerId: number
}

const CustomersLocations: React.FC<ICustomersLocationsProps> = ({ customerId }) => {
  const locations = useAppSelector((state) => state.customers.locations.data);
  const dispatch = useAppDispatch();

  const bulkErrors = useAppSelector(
    (state) => state.customers.locations.bulkErrors?.response?.data,
  );

  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();

  const cancelGetCustomersLocationsRequest = () => {
    HTTPService.cancelRequest(customersLocationsController);
  };

  const getLocations = () => {
    cancelGetCustomersLocationsRequest();
    customersLocationsController = HTTPService.getController();

    dispatch(getLocationsAction(customersLocationsController, customerId));
  };

  const bulkDownloadLocation = () => {
    try {
      downloadBulkLocation();
    } catch (error) {
      // @ts-ignore
      throw new Error(error);
    }
  };

  const editLocation = async (locationId, formData) => {
    await dispatch(editLocationAction(customerId, locationId, formData));

    getLocations();
  };

  const addLocation = async (location) => {
    try {
      await dispatch(createLocationAction(customerId, location));
      showSuccessNotifier(notifierMessage.addLocation.success);

      getLocations();
    } catch (e) {
      showErrorNotifier(notifierMessage.addLocation.error, e);
      throw e;
    }
  };

  const bulkUploadLocation = async (locationFile) => {
    try {
      await dispatch(uploadBulkLocationAction(customerId, locationFile));
      showSuccessNotifier(notifierMessage.bulkUpdate.success);

      getLocations();
    } catch (e) {
      showErrorNotifier(notifierMessage.bulkUpdate.error, e);
    }
  };

  const removeLocation = async (locationId) => {
    try {
      await dispatch(removeLocationAction(customerId, locationId));
    } catch (e) {
      const detail = get(e, 'response.data.error', '');

      if (detail) {
        showErrorNotifier(detail, e);
      }
    }

    getLocations();
  };

  const cancelEditor = () => { dispatch(clearErrorsLocationAction()); };

  useEffect(() => {
    getLocations();

    return () => cancelGetCustomersLocationsRequest();
  }, []);

  return (
    <Locations
      bulkDownloadLocation={bulkDownloadLocation}
      editLocation={editLocation}
      addLocation={addLocation}
      bulkUploadLocation={bulkUploadLocation}
      removeLocation={removeLocation}
      cancelEditor={cancelEditor}
      initialLocations={locations}
      bulkErrors={bulkErrors}
    />
  );
};

export default CustomersLocations;

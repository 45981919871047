import React from 'react';
import { useAppSelector } from 'core/store';

import EngagementsByPeriodChart from '@dashboardWirelineDepartments/shared/EngagementsByPeriodChart';

const SellerEngagementsByPeriodChart = () => {
  const engagementsByPeriod = useAppSelector((state) => state.dashboard.engagementsByPeriod.data);
  const isLoading = useAppSelector((state) => state.dashboard.engagementsByPeriod.isLoading);

  return (
    <EngagementsByPeriodChart
      isLoading={isLoading}
      list={engagementsByPeriod}
    />
  );
};

export default SellerEngagementsByPeriodChart;

import React from 'react';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { dateFormatWithSeconds } from 'core/constants';
import { Attachment } from 'shared/widgets/Attachments/Attachments.model';

interface IAttachmentSecondaryContentProps {
  attachment: Attachment
}

const AttachmentSecondaryContent: React.FC<IAttachmentSecondaryContentProps> = (
  { attachment },
) => {
  const {
    creatorFirstName, creatorLastName, createdDatetime, error,
  } = attachment;

  return (
    <div>
      <Typography component="time" className="widget-attachments__time">
        {dayjs(createdDatetime).format(dateFormatWithSeconds)}
      </Typography>
      <span className="widget-attachments__author">{creatorFirstName}</span>
      <span className="widget-attachments__author">{creatorLastName}</span>
      {error && (
        <Typography variant="body2" color="error">{error}</Typography>
      )}
    </div>
  );
};

export default AttachmentSecondaryContent;

import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useAppDispatch } from 'core/store';
import PeriodAutocomplete from 'shared/PeriodAutocomplete';
// eslint-disable-next-line no-unused-vars
import { TPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';
import {
  defaultPeriod,
  defaultPeriodOption, notifierMessage,
  periodOptions,
  titles,
} from '@dashboardWirelineRetentionDepartment/constants';
import TotalEngagementsChart from '@dashboardWirelineRetentionDepartment/TotalEngagements/TotalEngagementsChart';
import { HTTPService } from 'core/services';
import { getTotalEngagementsAction } from '@dashboardWirelineRetentionDepartment/GetTotalEngagements';
import useNotifiers from 'shared/Notifier/UseNotifiers';

let totalEngagementsController = HTTPService.getController();

const TotalEngagements = () => {
  const [period, setPeriod] = useState(defaultPeriod);

  const dispatch = useAppDispatch();

  const { showErrorNotifier } = useNotifiers();

  const cancelGetTotalEngagementsRequest = () => {
    HTTPService.cancelRequest(totalEngagementsController);
  };

  const getTotalEngagements = (newPeriod: TPeriod) => {
    cancelGetTotalEngagementsRequest();

    setPeriod(newPeriod);

    totalEngagementsController = HTTPService.getController();

    dispatch(getTotalEngagementsAction({
      controller: totalEngagementsController,
      params: newPeriod,
    }))
      .unwrap()
      .catch((e) => {
        showErrorNotifier(notifierMessage.total.error, e);
      });
  };

  useEffect(() => {
    getTotalEngagements(period);

    return () => cancelGetTotalEngagementsRequest();
  }, []);

  return (
    <div>
      <Typography variant="h3" className="retention-department__title">
        {titles.total}
      </Typography>
      <div className="retention-department__filters">
        <PeriodAutocomplete
          onChange={getTotalEngagements}
          options={periodOptions}
          defaultOption={defaultPeriodOption}
        />
      </div>
      <TotalEngagementsChart />
    </div>
  );
};

export default TotalEngagements;

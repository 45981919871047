/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IChartItem } from 'core/models/Recharts.models';
import { getTotalEngagements } from '@dashboardWirelineRetentionDepartment/GetTotalEngagements/api.service';
// eslint-disable-next-line no-unused-vars
import { TPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';

export interface IState {
  data: IChartItem[];
  isLoading: boolean;
  error: object | null;
}

const initialState: IState = {
  data: [],
  isLoading: false,
  error: null,
};

export const getTotalEngagementsAction = createAsyncThunk(
  'getTotalEngagementsAction',
  async ({ controller, params }: { controller: AbortController, params: TPeriod },
    { rejectWithValue }) => {
    try {
      const { signal } = controller;
      const { data } = await getTotalEngagements(signal, params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const TotalEngagementsSlice = createSlice({
  name: 'getTotalEngagements',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getTotalEngagementsAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTotalEngagementsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;

        state.data = action.payload.map(
          ({ name, total }) => ({ name, value: total }),
        );
      })
      .addCase(getTotalEngagementsAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
        state.data = [];
      });
  },
});

export const getTotalEngagementsReducer = TotalEngagementsSlice.reducer;

import {
  REMOVE_CUSTOMERS_COMMENT_FAILURE,
  REMOVE_CUSTOMERS_COMMENT_REQUEST,
  REMOVE_CUSTOMERS_COMMENT_SUCCESS,
} from 'pages/Customers/CustomersComments/RemoveComment/actionTypes';

export const removeCommentRequest = (commentId) => (
  { type: REMOVE_CUSTOMERS_COMMENT_REQUEST, commentId });

export const removeCommentSuccess = (commentId) => (
  { type: REMOVE_CUSTOMERS_COMMENT_SUCCESS, commentId });

export const removeCommentFailure = (commentId, error) => ({
  type: REMOVE_CUSTOMERS_COMMENT_FAILURE,
  commentId,
  error,
});

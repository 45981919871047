import React from 'react';
import { Grid } from '@mui/material';
import { Form } from 'formik';
import CompanyNameSearch from 'pages/InsideSales/InsideSalesList/InsideSalesListTable/InsideSalesListTableModals/InsideSalesCreateModalForm/CompanyNameSearch';
import FormikSelectField from 'shared/FormikField/FormikSelectField';
import FormikTextareaField from 'shared/FormikField/FormikTextareaField';
import FormikTextField from 'shared/FormikField/FormikTextField/FormikTextField';
import { GRID_DIRECTION } from 'shared/FormikField/FormikField.model';
import { requestTypeOptions } from 'pages/InsideSales/constants';

const InsideSalesCreateForm = () => (
  <Form>
    <Grid
      className="inside-sales__create-form"
      item
      container
      spacing={3}
    >
      <FormikTextField
        label="Support request"
        fieldName="supportRequest"
        variant="standard"
        disabled
      />
      <CompanyNameSearch />
      <FormikSelectField
        label="Request type"
        fieldName="requestType"
        options={requestTypeOptions}
        required
      />
      <FormikTextareaField
        label="Notes"
        fieldName="sellerNote"
        gridDirection={GRID_DIRECTION.ROW}
        required
      />
    </Grid>
  </Form>
);

export default InsideSalesCreateForm;

import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import { useTableContext } from '../TableContext';

interface IUseTableSelectedRowsProps<T> {
  rows: T[];
  selectedRowIdName?: string;
}

export const useTableSelectedRows = <T extends unknown> (
  { rows = [], selectedRowIdName = 'id' }: IUseTableSelectedRowsProps<T>,
) => {
  const { rowSelection } = useTableContext();

  const [selectedRows, setSelectedRows] = useState<T[]>([]);

  useEffect(() => {
    setSelectedRows((prevSelectedRows) => {
      if (isEmpty(rowSelection)) {
        return [];
      }

      const selectedRowIds = Object.keys(rowSelection);
      const isNewItem = selectedRowIds.length > prevSelectedRows.length;

      if (isNewItem) {
        const availableSelectedOrders = rows.filter((row) => rowSelection[row[selectedRowIdName]]);

        return (
          uniqBy(
            [...prevSelectedRows, ...availableSelectedOrders],
            selectedRowIdName,
          )
        );
      }

      return prevSelectedRows.filter((row) => rowSelection[row[selectedRowIdName]]);
    });
  }, [rowSelection]);

  return selectedRows;
};

// eslint-disable-next-line no-unused-vars
import React, { FC, ReactElement, SyntheticEvent } from 'react';
import { useField } from 'formik';
import { Autocomplete, Grid, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { MuiPaletteColors } from 'core/models/MUI.models';
import FormikFieldWrapper from '../FormikFieldWrapper';
import { IOption, TOnInputChange, GRID_DIRECTION } from '../FormikField.model';

import '../FormikField.scss';
import './FormikAutocompleteField.scss';

const ChipProps = {
  color: 'primary' as MuiPaletteColors,
  className: 'formik-autocomplete-field__chip',
  deleteIcon: <ClearIcon />,
};

const getOptionLabel = (option: IOption | string) => (typeof option === 'string' ? option : `${option.label}`);

interface IFormikAutocompleteFieldProps {
  label: string
  fieldName: string
  options: IOption[]
  inputValue?: string
  onInputChange?: TOnInputChange
  disabled?: boolean
  required?: boolean
  fullWidth?: boolean
  isLoading?: boolean
  freeSolo?: boolean
  disableCloseOnSelect?: boolean
  gridDirection?: GRID_DIRECTION
  Action ? : ReactElement | null
}

interface IFormikAutocompleteFieldPropsMultiple extends IFormikAutocompleteFieldProps {
  multiple: true
  onChange?: (option: IOption[] | null) => void
  defaultValue?: IOption[] | undefined

}

interface IFormikAutocompleteFieldPropsSingle extends IFormikAutocompleteFieldProps {
  multiple?: false
  onChange?: (option: IOption | null) => void
  defaultValue?: IOption | undefined
}

const FormikAutocompleteField: FC<
  IFormikAutocompleteFieldPropsMultiple | IFormikAutocompleteFieldPropsSingle
> = ({
  label,
  fieldName,
  inputValue,
  onChange,
  onInputChange,
  defaultValue,
  options,
  disabled,
  fullWidth,
  required,
  isLoading,
  multiple,
  freeSolo,
  disableCloseOnSelect,
  gridDirection,
  Action,
}) => {
  const [, error, helper] = useField(fieldName);

  const onChangeHandler = async (
    event: SyntheticEvent, selectedValue: string | IOption | (string | IOption)[] | null,
  ) => {
    if (Array.isArray(selectedValue)) {
      await helper.setValue(selectedValue?.map((option) => (option as IOption).value));
    } else {
      await helper.setValue((selectedValue as IOption)?.value);
    }

    if (multiple) {
      onChange?.(selectedValue as IOption[] | null);
    } else {
      onChange?.(selectedValue as IOption | null);
    }
  };

  const isError = Boolean(error.error && error.touched);
  const errorText = isError ? error.error : ' ';

  const renderInput = (params) => (
    <TextField
      {...params}
      error={isError}
      helperText={errorText}
    />
  );

  return (
    <FormikFieldWrapper
      fieldName={fieldName}
      label={label}
      gridDirection={gridDirection as GRID_DIRECTION}
      required={required as boolean}
    >
      <Grid
        item
        container
        spacing={Action ? 1 : 0}
        alignItems="center"
      >
        <Grid
          item
          xs={Action ? 10 : 12}
        >
          <Autocomplete
            onChange={onChangeHandler}
            defaultValue={defaultValue as IOption[] | IOption | null}
            inputValue={inputValue as string}
            onInputChange={onInputChange as TOnInputChange}
            options={options}
            renderInput={renderInput}
            getOptionLabel={getOptionLabel}
            fullWidth={fullWidth as boolean}
            disabled={disabled as boolean}
            loading={isLoading as boolean}
            multiple={multiple as boolean}
            disableCloseOnSelect={disableCloseOnSelect as boolean}
            ChipProps={ChipProps}
            freeSolo={!!freeSolo}
          />
        </Grid>
        {Action && (
          <Grid item xs={2}>
            {Action}
          </Grid>
        )}
      </Grid>
    </FormikFieldWrapper>
  );
};

/* eslint-disable react/default-props-match-prop-types */
FormikAutocompleteField.defaultProps = {
  onChange: () => {},
  defaultValue: undefined,
  inputValue: '',
  onInputChange: () => {},
  required: false,
  disabled: false,
  fullWidth: false,
  isLoading: false,
  disableCloseOnSelect: false,
  multiple: false,
  gridDirection: GRID_DIRECTION.ROW,
  Action: null,
  freeSolo: false,
};
/* eslint-enable react/default-props-match-prop-types */

export default FormikAutocompleteField;

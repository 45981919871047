import React from 'react';
import { useField } from 'formik';
import Switch from '@mui/material/Switch';
import FormikFieldWrapper from 'shared/FormikField/FormikFieldWrapper';

interface IFormikSwitchFieldProps {
  fieldName: string
  label: string
  disabled?: boolean
  required?: boolean
}

const FormikSwitchField: React.FC<IFormikSwitchFieldProps> = (
  {
    fieldName, label, disabled, required,
  },
) => {
  const [field] = useField(fieldName);

  return (
    <FormikFieldWrapper
      fieldName={fieldName}
      label={label}
      required={required as boolean}
    >
      <Switch
        {...field}
        checked={field.value}
        disabled={disabled as boolean}
      />
    </FormikFieldWrapper>
  );
};

FormikSwitchField.defaultProps = {
  disabled: false,
  required: false,
};

export default FormikSwitchField;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { contractProvide } from 'core/api/sales.api/sales.api';

const provideContractAction = createAsyncThunk(
  'provideContractAction',
  async (contractId: number | string, { rejectWithValue }) => {
    try {
      await contractProvide(contractId);
    } catch (error) {
      return rejectWithValue(error);
    }
  });

export default provideContractAction;

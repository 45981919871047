import React, { useEffect, useState } from 'react';
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import xor from 'lodash/xor';
import { useAppDispatch, useAppSelector } from 'core/store';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import PeriodAutocomplete from 'shared/PeriodAutocomplete';
import {
  defaultPeriod, defaultPeriodOption, periodOptions, titles, notifierMessage,
} from '@dashboardWirelineRetentionDepartment/constants';
import ProductsSelect from '@dashboardWirelineRetentionDepartment/ProductsSelect';
import { HTTPService } from 'core/services';
import DeclinedEngagementsChart from '@dashboardWirelineRetentionDepartment/DeclinedEngagements/DeclinedEngagementsChart';
import { Typography } from '@mui/material';
import { EngagementTypeForChart } from '@dashboardWirelineRetentionDepartment/RetentionManagerDepartment.models';
import { getDeclinedEngagementsAction } from '@dashboardWirelineRetentionDepartment/GetDeclinedEngagements';

let declinedEngagementsController = HTTPService.getController();

const DeclinedEngagements = () => {
  const declinedEngagementsData = useAppSelector(
    (state) => state.dashboard.declinedEngagements.data,
  );

  const productsWithValue = keys(declinedEngagementsData);

  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [period, setPeriod] = useState(defaultPeriod);

  const { showErrorNotifier } = useNotifiers();

  const dispatch = useAppDispatch();

  const cancelGetDeclinedEngagementsRequest = () => {
    HTTPService.cancelRequest(declinedEngagementsController);
  };

  const getDeclinedEngagements = async (newPeriod) => {
    cancelGetDeclinedEngagementsRequest();

    setPeriod(newPeriod);

    declinedEngagementsController = HTTPService.getController();

    const params = {
      engagementType: EngagementTypeForChart.DECLINED,
      ...newPeriod,
    };

    const isAllProductsWithValueSelected = (
      !isEmpty(productsWithValue) && xor(selectedProducts, productsWithValue).length === 0
    );

    dispatch(getDeclinedEngagementsAction({
      controller: declinedEngagementsController, params,
    }))
      .unwrap()
      .then((newDeclinedEngagementsData) => {
        if (!isAllProductsWithValueSelected) return;

        const newProductsWithValue = keys(newDeclinedEngagementsData);
        setSelectedProducts(newProductsWithValue);
      })
      .catch((e) => {
        showErrorNotifier(notifierMessage.declined.error, e);
      });
  };

  useEffect(() => {
    getDeclinedEngagements(period);

    return () => cancelGetDeclinedEngagementsRequest();
  }, []);

  return (
    <div className="declined-engagements">
      <Typography variant="h3" className="retention-department__title">
        {titles.declined}
      </Typography>
      <div className="retention-department__filters">
        <PeriodAutocomplete
          onChange={getDeclinedEngagements}
          options={periodOptions}
          defaultOption={defaultPeriodOption}
        />
        <ProductsSelect
          setSelectedProducts={setSelectedProducts}
          selectedProducts={selectedProducts}
          activeProducts={productsWithValue}
        />
      </div>
      <DeclinedEngagementsChart selectedProducts={selectedProducts} />
    </div>
  );
};

export default DeclinedEngagements;

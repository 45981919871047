/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getContractBundles } from 'core/api/sales.api/sales.api';
import { IContractBundlesById } from '@EngagementsSowData/salesSow.model';

export interface IState {
  data: IContractBundlesById;
  isLoading: boolean;
  error: object | null;
}

const initialState: IState = {
  data: {
    contracts: [],
    orderIds: [],
    generateContractPerServiceAddress: false,
    esign: false,
    mergeEsignForMultipleContracts: false,
    signerName: '',
    signerPhone: '',
    signerEmail: '',
    additionalContactName: '',
    additionalContactEmail: '',
    additionalContacts: [],
  },
  isLoading: false,
  error: null,
};

export const getContractBundlesAction = createAsyncThunk(
  'getContractBundlesAction',
  async (bundleId: string | number, { rejectWithValue }) => {
    try {
      const { data } = await getContractBundles(bundleId);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const contractBundlesActionSlice = createSlice({
  name: 'getContractBundles',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getContractBundlesAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getContractBundlesAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(getContractBundlesAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export const getContractBundlesReducer = contractBundlesActionSlice.reducer;

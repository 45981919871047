/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import getRequestStatus from '@dashboardWirelineInsideSalesDepartment/GetRequestStatus/api.service';
// eslint-disable-next-line no-unused-vars
import { TPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';
import { IChartItem } from 'core/models/Recharts.models';

export interface IState {
  data: IChartItem[];
  isLoading: boolean;
  error: object | null;
}

const initialState: IState = {
  data: [],
  isLoading: false,
  error: null,
};

export const getRequestStatusAction = createAsyncThunk(
  'getRequestStatusAction',
  async ({ controller, params }: { controller: AbortController, params: TPeriod },
    { rejectWithValue }) => {
    try {
      const { signal } = controller;
      const { data } = await getRequestStatus(signal, params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const RequestStatusSlice = createSlice({
  name: 'getRequestStatus',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getRequestStatusAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRequestStatusAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(getRequestStatusAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
        state.data = [];
      });
  },
});

export const getRequestStatusReducer = RequestStatusSlice.reducer;

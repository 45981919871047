import React, { ReactElement } from 'react';
import cx from 'classnames';
import { noop } from 'lodash';
import Loader from 'shared/Loader';
import { DialogContent } from '@mui/material';
import CustomDialogButtons, { IButtonVariant } from './CustomDialogButtons';

import './CustomDialog.scss';

interface ICustomDialogSteperContentProps {
  children: ReactElement;
  classNameContent?: string;
  onClickSecondButton: () => void;
  secondButtonText?: string | undefined;
  firstButtonText?: string | undefined;
  onClickFirstButton?: (() => void) | undefined;
  areButtonsVisible?: boolean | undefined;
  firstButtonDisable?: boolean | undefined;
  buttonWidth?: string;
  isSecondButtonVisible?: boolean | undefined;
  firstButtonVariant?: IButtonVariant;
  secondButtonVariant?: IButtonVariant;
  isLoading?: boolean;
}

const CustomDialogStep = ({
  children,
  isLoading,
  buttonWidth,
  firstButtonText,
  classNameContent,
  secondButtonText,
  areButtonsVisible,
  firstButtonDisable,
  firstButtonVariant,
  onClickFirstButton,
  secondButtonVariant,
  onClickSecondButton,
  isSecondButtonVisible,
}: ICustomDialogSteperContentProps) => (
  <>
    <DialogContent className={cx(classNameContent, 'custom-dialog-wrapper__content')}>
      {isLoading && <Loader className="custom-dialog-wrapper__loader" />}
      {children}
    </DialogContent>

    <CustomDialogButtons
      buttonWidth={buttonWidth}
      firstButtonText={firstButtonText}
      secondButtonText={secondButtonText}
      areButtonsVisible={areButtonsVisible}
      onClickFirstButton={onClickFirstButton}
      firstButtonDisable={firstButtonDisable}
      firstButtonVariant={firstButtonVariant}
      secondButtonVariant={secondButtonVariant}
      onClickSecondButton={onClickSecondButton}
      isSecondButtonVisible={isSecondButtonVisible}
    />
  </>
);

CustomDialogStep.defaultProps = {
  isLoading: false,
  classNameContent: '',
  buttonWidth: '157px',
  onClickFirstButton: noop,
  areButtonsVisible: true,
  secondButtonText: 'Cancel',
  firstButtonDisable: false,
  firstButtonText: 'Create',
  isSecondButtonVisible: true,
  firstButtonVariant: 'contained',
  secondButtonVariant: 'outlined',
};
export default CustomDialogStep;

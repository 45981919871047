import React from 'react';
import compact from 'lodash/compact';
import ClearIcon from '@mui/icons-material/Clear';
import Actions from 'shared/Table/Actions';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
// eslint-disable-next-line no-unused-vars
import { EngagementType, IInsideSalesListItem } from 'pages/InsideSales/model';
import useInsideSalesListPermissions from 'pages/InsideSales/InsideSalesList/useInsideSalesListPermissions';

const InsideSalesListTableRowActions = ({ row: { original } }) => {
  const { engagementType, id } = original;
  const {
    activeRowId,
    activeRowMode,
    setActiveRowEdit,
    setActiveRowView,
    setActiveRowCancelOrder,
  } = useTableActiveRowContext();

  const {
    isAvailableEditRequest,
    isAvailableEditSidebar,
    isAvailableCancelRequest,
  } = useInsideSalesListPermissions();

  const {
    isCancelOrderMode,
  } = getTableRowModeBoolean(activeRowMode);

  const isPending = engagementType === EngagementType.PENDING;
  const isCompleted = engagementType === EngagementType.COMPLETED;
  const isCanceled = engagementType === EngagementType.CANCELED;

  const isActiveButtonDisabled = isAvailableEditRequest && isPending;

  const isCancelButtonSelected = isCancelOrderMode && id === activeRowId;
  const isCancelButtonDisabled = (
    (!!activeRowId && !isCancelButtonSelected)
    || isCanceled
    || isCompleted
  );

  const isViewButton = isCanceled || isCompleted;

  const moreButtonConfig = compact([
    isAvailableCancelRequest && {
      onClick: () => setActiveRowCancelOrder(original),
      isDisabled: isCancelButtonDisabled,
      icon: <ClearIcon />,
      text: 'Cancel Request',
    },
  ]);

  if (!isAvailableEditSidebar && !isAvailableCancelRequest) {
    return null;
  }

  return (
    <Actions<IInsideSalesListItem>
      row={original}
      activeRowId={activeRowId}
      isViewButton={isViewButton}
      activeRowMode={activeRowMode}
      setActiveRowEdit={setActiveRowEdit}
      setActiveRowView={setActiveRowView}
      moreButtonConfig={moreButtonConfig}
      moreButtonTooltipTitle="Request actions"
      isMoreButtonDisabled={isCancelButtonDisabled}
      isActionButtonVisible={isAvailableEditSidebar}
      isActiveButtonDisabled={isActiveButtonDisabled}
    />
  );
};

export default InsideSalesListTableRowActions;

import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTableContext } from 'shared/Table/TableContext';
import { exportActiveNumbersData } from 'core/api/sales.api';
import ExportTableXlsxFile from 'shared/ExportTableXlsxFile';
import { activeNumberExportFileName } from '@EngagementsActiveNumbers/constants';
// eslint-disable-next-line no-unused-vars
import { IActiveNumbersItem, IExportActiveNumbersRequestParams } from '@EngagementsActiveNumbers/activeNumbers.model';

interface IExportActiveNumbersTable {
  activeNumbersItem?: IActiveNumbersItem
}

const ExportActiveNumbersTable: React.FC<IExportActiveNumbersTable> = ({ activeNumbersItem }) => {
  const { tableFilters } = useTableContext();
  const { customerId } = useParams();
  const { pagination: { pageSize, pageIndex } } = tableFilters;

  const { customerCompanyName } = activeNumbersItem || {};

  const exportFileName = useMemo(() => (customerCompanyName ? `${customerCompanyName}_${activeNumberExportFileName}.xlsx` : `${activeNumberExportFileName}.xlsx`), [customerCompanyName]);

  const exportParams = {
    requestParams: {
      customerId: customerId!,
    },
    filtersParams: {
      page_size: pageSize,
      page: pageIndex + 1,
    },

  };

  return (
    <ExportTableXlsxFile<IExportActiveNumbersRequestParams>
      params={exportParams}
      fileName={exportFileName}
      exportAction={exportActiveNumbersData}
    />
  );
};

ExportActiveNumbersTable.defaultProps = {
  activeNumbersItem: {} as IActiveNumbersItem,
};

export default ExportActiveNumbersTable;

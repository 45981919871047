/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCarriersList } from 'core/api/sales.api';
// eslint-disable-next-line no-unused-vars
import { ICarrier, ICarriersParams } from 'pages/WirelineEngagements/shared/AddOrderModalForm/product.model';

export interface IState {
    data: ICarrier[];
    isLoading: boolean;
    error: object | null;
}

const initialState: IState = {
  data: [],
  error: null,
  isLoading: false,
};

export const getCarriersAction = createAsyncThunk(
  'getCarriersAction',
  async (params: ICarriersParams, { rejectWithValue }) => {
    try {
      const { data } = await getCarriersList(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const CarriersSlice = createSlice({
  name: 'getCarriers',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getCarriersAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCarriersAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(getCarriersAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
        state.data = [];
      });
  },
});

export const { clear: clearCarriersAction } = CarriersSlice.actions;

export const getCarriersReducer = CarriersSlice.reducer;

import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'core/store';
import { clearProductsAction } from 'core/actions/Sales/GetProducts';
import {
  AddOrderProductsList,
  AddOrderSearchProductField,
  AddOrderProductCategoriesField,
} from 'pages/WirelineEngagements/shared/AddOrderModalForm/EngagementsOrderForm/EngagementOrderFormFields';

interface IAddOrderProductsATTFilter {
  itemCount: number
  getProducts: (itemCount: number) => void
}

const AddOrderProductsATTFilter:React.FC<IAddOrderProductsATTFilter> = ({
  itemCount, getProducts,
}) => {
  const dispatch = useAppDispatch();

  const products = useAppSelector((state) => state.products.data);
  const isLoading = useAppSelector((state) => state.products.isLoading);

  useEffect(() => {
    getProducts(itemCount);

    return () => {
      dispatch(clearProductsAction());
    };
  }, []);

  return (
    <>
      <Grid container item columnSpacing={3} xs={12} direction="row">
        <Grid item xs={4}>
          <AddOrderSearchProductField itemCount={itemCount} getProducts={getProducts} />
        </Grid>
        <Grid item xs={4}>
          <AddOrderProductCategoriesField itemCount={itemCount} getProducts={getProducts} />
        </Grid>
      </Grid>
      <AddOrderProductsList
        products={products}
        isLoading={isLoading}
        itemCount={itemCount}
        title="Select products"
      />
    </>
  );
};

export default AddOrderProductsATTFilter;

import { TPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';
import { ITableCell } from 'shared/Table/Table.models';
import {
  RenewalPeriod,
} from 'shared/RenewalPeriodAutocomplete/RenewalPeriodAutocomplete.models';
import { EngagementType } from 'pages/InsideSales/model';

export enum EngagementTypeForChart {
  DECLINED = 'DECLINED',
  RENEWED = 'RENEWED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export interface IRetentionDataItem {
  [key: string]: number
}

export interface IRetentionParams {
  engagementType: EngagementType,
  period: TPeriod | RenewalPeriod,
}

export interface IRetentionBookingItem {
  sellerFullname: string,
  customerName: string,
  dataBooked: string,
  engagementType: EngagementType,
  contractId: string,
  newContractId: string,
  term: number,
  newTerm: number,
  circuitId: string,
  accountNumber: string,
  newAccountNumber: string,
  mrc: string,
  newMrc: string,
  newNrc: string,
  renewalSigned: number,
  renewalNotes: string
}

export type ITableCellRetentionBookingItem = ITableCell<IRetentionBookingItem>

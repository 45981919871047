import {
  EDIT_ENGAGEMENTS_ATTACHMENT_NAME_REQUEST,
  EDIT_ENGAGEMENTS_ATTACHMENT_NAME_SUCCESS,
  EDIT_ENGAGEMENTS_ATTACHMENT_NAME_FAILURE,
} from 'pages/WirelineEngagements/WirelineEngagementsAttachments/EditEngagementsAttachmentName/actionTypes';

export const editEngagementsAttachmentNameRequest = (attachmentId, editedAttachmentName) => ({
  type: EDIT_ENGAGEMENTS_ATTACHMENT_NAME_REQUEST,
  attachmentId,
  editedAttachmentName,
});

export const editEngagementsAttachmentNameSuccess = (editedAttachmentName) => ({
  type: EDIT_ENGAGEMENTS_ATTACHMENT_NAME_SUCCESS,
  editedAttachmentName,
});

export const editEngagementsAttachmentNameFailure = (attachmentId, error) => ({
  type: EDIT_ENGAGEMENTS_ATTACHMENT_NAME_FAILURE,
  attachmentId,
  error,
});

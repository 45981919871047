import React from 'react';
import xor from 'lodash/xor';
import isEmpty from 'lodash/isEmpty';
import {
  Select, OutlinedInput, InputLabel, FormControl, IconButton, ListItemText, Checkbox, MenuItem,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { selectAllOption, selectActiveOption, SelectMenuProps } from 'shared/CustomSelect/constants';

import './CustomSelect.scss';

interface IProductsSelectProps {
  className?: string
  label: string
  setSelectedOptions: (Products: string[]) => void
  selectedOptions: string[]
  options: string[]
  activeOptions?: string[] | undefined
}

const CustomSelect: React.FC<IProductsSelectProps> = ({
  className, label, setSelectedOptions, selectedOptions, options, activeOptions,
}) => {
  const isAllSelected = selectedOptions.length === options.length;
  const isActiveSelected = (
    !isEmpty(activeOptions) && xor(selectedOptions, activeOptions).length === 0
  );

  const onSelectAll = () => {
    setSelectedOptions(isAllSelected ? [] : options);
  };

  const onSelectActive = () => {
    if (!activeOptions) return;

    setSelectedOptions(isActiveSelected ? [] : activeOptions);
  };

  const onChangeSelectedProducts = (event) => {
    const { target: { value } } = event;

    const isSelectAll = value.includes(selectAllOption);
    const isSelectActive = value.includes(selectActiveOption);

    if (isSelectAll || isSelectActive) {
      return;
    }

    setSelectedOptions(typeof value === 'string' ? value.split(',') : value);
  };

  const clearSelection = () => {
    setSelectedOptions([]);
  };

  const getSelectValue = (selected) => {
    if (isActiveSelected) return selectActiveOption;
    if (isAllSelected) return selectAllOption;

    return selected.join(', ');
  };

  const endAdornment = selectedOptions.length ? (
    <IconButton
      onClick={clearSelection}
      edge="end"
    >
      <ClearIcon fontSize="small" />
    </IconButton>
  ) : null;

  return (
    <FormControl className={className as string}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={selectedOptions}
        onChange={onChangeSelectedProducts}
        MenuProps={SelectMenuProps}
        data-testid="custom-select"
        input={(<OutlinedInput endAdornment={endAdornment} />)}
        renderValue={getSelectValue}
      >
        <MenuItem
          value={selectAllOption}
          onClick={onSelectAll}
        >
          <Checkbox checked={isAllSelected} />
          <ListItemText primary={selectAllOption} />
        </MenuItem>
        {
          !!activeOptions && (
            <MenuItem
              value={selectActiveOption}
              onClick={onSelectActive}
              disabled={isEmpty(activeOptions)}
            >
              <Checkbox checked={isActiveSelected} />
              <ListItemText primary={selectActiveOption} />
            </MenuItem>
          )
        }
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={selectedOptions.includes(option)} />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

CustomSelect.defaultProps = {
  className: 'custom-select',
  activeOptions: undefined,
};

export default CustomSelect;

import React from 'react';
import { Formik } from 'formik';
import {
  useWirelineEngagementsCreateContext,
} from 'pages/WirelineEngagements/WirelineEngagementsCreate/WirelineEngagementsCreateContext';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useAppDispatch } from 'core/store';
import { IServerValidationError } from 'core/services/HTTPService.models';
import { createCustomerFromDraftAction } from 'core/actions/Customers/CreateCustomerFromDraft/actions';
import useInitialFormData from 'pages/Customers/CustomersCreate/useInitialFormData';
import { createCustomerNotifierMessage } from 'pages/Customers/CustomersCreate/constants';
import CustomersCreateQuickFormModal from
  'pages/Customers/CustomersCreate/CustomersCreateQuickForm/CustomersCreateQuickFormModal';
import schema from 'pages/Customers/CustomersCreate/CustomersCreateForm/CustomersCreateFormSchema';

const CustomersCreateQuickFormWithModal = () => {
  const {
    setIsCreateCustomerModalOpen,
    setNewCompanyName,
  } = useWirelineEngagementsCreateContext();

  const {
    id,
    isLoading,
    initialValues,
  } = useInitialFormData();

  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();

  const dispatch = useAppDispatch();

  const onSubmit = async (params, { setErrors }) => {
    try {
      await dispatch(createCustomerFromDraftAction(id, params));

      setNewCompanyName(params.companyName);

      showSuccessNotifier(createCustomerNotifierMessage.sucsess);

      setIsCreateCustomerModalOpen(false);
    } catch (error) {
      const definedError = error as IServerValidationError;

      setErrors(definedError?.response?.data);

      showErrorNotifier(createCustomerNotifierMessage.error, error);
    }
  };

  const isShowForm = !isLoading && id;
  if (!isShowForm) {
    return null;
  }

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validateOnBlur
      validateOnChange
    >
      <CustomersCreateQuickFormModal />
    </Formik>
  );
};

export default CustomersCreateQuickFormWithModal;

import React, { useEffect, useMemo } from 'react';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useAppDispatch, useAppSelector } from 'core/store';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import OrderDataSidebar from 'pages/WirelineEngagements/shared/OrderDataSidebar';
import { getParsedRowData } from 'pages/WirelineEngagements/WirelineEngagementsTables/utilities';
import { getSalesActiveNumbersItemAction, clearGetActiveNumbersItemData } from 'core/actions/Sales/GetSalesActiveNumbersItem';
import {
  getActiveNumbersItemNotifierMessage,
  dynamicActiveNumbersProductSchemaName,
} from '@EngagementsActiveNumbers/constants';

const DataRowSidebar = () => {
  const dispatch = useAppDispatch();
  const { showErrorNotifier } = useNotifiers();

  const {
    activeRowId, activeRowMode, cleanActiveRow,
    setSecondarySidebarMode, secondarySidebarMode,
  } = useTableActiveRowContext();

  const salesActiveNumbersItem = useAppSelector(
    (state) => state.wirelineEngagements.salesActiveNumbersItem.result,
  );
  const isLoadingActiveNumbersItem = useAppSelector(
    (state) => state.wirelineEngagements.salesActiveNumbersItem.isLoading,
  );
  const isLoadingSchemas = useAppSelector((state) => state.openapiSchemas.isLoading);

  const isLoading = isLoadingActiveNumbersItem || isLoadingSchemas;

  const { isDataMode } = getTableRowModeBoolean(activeRowMode);
  const { isDataMode: isDataInsideEditMode } = getTableRowModeBoolean(secondarySidebarMode);

  const isOpen = !!activeRowId && (isDataMode || isDataInsideEditMode);
  const needFetchData = !!activeRowId && isDataMode;

  const orderData = useMemo(() => getParsedRowData({
    rowData: salesActiveNumbersItem, isEditable: false,
  }), [salesActiveNumbersItem]);

  const onCloseHandler = () => (secondarySidebarMode
    ? setSecondarySidebarMode(null)
    : cleanActiveRow());

  const getSalesActiveNumbersItemData = async () => {
    try {
      await dispatch(getSalesActiveNumbersItemAction(activeRowId as number))
        .unwrap();
    } catch (error) {
      showErrorNotifier(getActiveNumbersItemNotifierMessage.error, error);
    }
  };

  useEffect(() => {
    if (!needFetchData) return;
    getSalesActiveNumbersItemData();

    return () => {
      dispatch(clearGetActiveNumbersItemData());
    };
  }, [activeRowId]);

  return (
    <OrderDataSidebar
      isOpen={isOpen}
      isLoading={isLoading}
      orderData={orderData}
      onClose={onCloseHandler}
      schemaName={dynamicActiveNumbersProductSchemaName}
    />
  );
};

export default DataRowSidebar;

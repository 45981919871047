import React, { useEffect } from 'react';
import Attachments from 'shared/widgets/Attachments';
import { HTTPService } from 'core/services';
import { useAppDispatch, useAppSelector } from 'core/store';
// eslint-disable-next-line no-unused-vars
import { IDraftAttachment } from 'shared/widgets/Attachments/Attachments.model';
import getEngagementAttachmentsAction from 'pages/WirelineEngagements/WirelineEngagementsAttachments/GetEngagementsAttachments/actions';
import createEngagementsAttachmentAction from 'pages/WirelineEngagements/WirelineEngagementsAttachments/CreateEngagementsAttachment/actions';
import editEngagementsAttachmentNameAction from 'pages/WirelineEngagements/WirelineEngagementsAttachments/EditEngagementsAttachmentName/actions';
import removeEngagementsAttachmentAction from 'pages/WirelineEngagements/WirelineEngagementsAttachments/RemoveEngagementsAttachment/actions';
import clearAttachmentsErrorsAction from 'pages/WirelineEngagements/WirelineEngagementsAttachments/ClearErrorsAttachments/actions';
import { wirelineAttachmentsConfig } from 'pages/WirelineEngagements/WirelineEngagementsAttachments/constants';

let engagementsAttachmentsController = new AbortController();

interface IEngagementsAttachmentsProps {
  isAvailableCreate: boolean
  engagementId: number | string
}

const WirelineEngagementsAttachments: React.FC<IEngagementsAttachmentsProps> = ({
  engagementId, isAvailableCreate,
}) => {
  const dispatch = useAppDispatch();
  const attachments = useAppSelector(
    (state) => state.wirelineEngagements.attachments.data.results,
  );
  const isLoading = useAppSelector((state) => state.wirelineEngagements.attachments.isLoading);

  const cancelGetEngagementAttachmentsRequest = () => {
    HTTPService.cancelRequest(engagementsAttachmentsController);
  };

  const clearEngagementAttachmentsErrors = () => {
    dispatch(clearAttachmentsErrorsAction(attachments));
  };

  const getEngagementAttachments = () => {
    cancelGetEngagementAttachmentsRequest();
    engagementsAttachmentsController = HTTPService.getController();

    dispatch(getEngagementAttachmentsAction(engagementsAttachmentsController, engagementId));
  };

  const editAttachment = (attachmentId: string | number, newAttachment: IDraftAttachment) => {
    dispatch(editEngagementsAttachmentNameAction(engagementId, attachmentId, newAttachment));
  };

  const createAttachment = (attachmentFile) => {
    dispatch(createEngagementsAttachmentAction(engagementId, attachmentFile));
  };

  const removeAttachment = (attachmentId) => {
    dispatch(removeEngagementsAttachmentAction(engagementId, attachmentId));
  };

  useEffect(() => {
    getEngagementAttachments();

    return () => cancelGetEngagementAttachmentsRequest();
  }, []);

  return (
    <Attachments
      attachments={attachments}
      isLoading={isLoading}
      editAttachment={editAttachment}
      createAttachment={createAttachment}
      removeAttachment={removeAttachment}
      clearAttachmentsErrors={clearEngagementAttachmentsErrors}
      config={wirelineAttachmentsConfig}
      isAvailableAddAttachments={isAvailableCreate}
    />
  );
};

export default WirelineEngagementsAttachments;

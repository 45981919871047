import React, { useMemo, useRef } from 'react';
import omit from 'lodash/omit';
import { Formik, Form, FormikProps } from 'formik';
import schema from '@EngagementsForm/EngagementsSchema';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useNavigate, useParams } from 'react-router-dom';
import { getEngagementsEditPath } from 'core/routing/paths';
import { IServerValidationError } from 'core/services/HTTPService.models';
import EngagementsFormFields from '@EngagementsForm/EngagementsFormFields';
import { getFormInitialDataFromEngagement } from '@EngagementsForm/utilities';
import { IEngagement } from 'pages/WirelineEngagements/WirelineEngagements.models';
import { createEngagement, updateEngagementById } from 'core/api/wirelineEngagements.api';
import RouterFormConfirmDialogFormik from 'shared/RouterFormConfirmDialog/RouterFormConfirmDialogFormik';
import { useWirelineEngagementsCreateContext } from 'pages/WirelineEngagements/WirelineEngagementsCreate/WirelineEngagementsCreateContext';
import {
  createEngagementNotifications,
  updateEngagementNotifications,
} from 'pages/WirelineEngagements/WirelineEngagementsCreate/constants';

const EngagementsForm = () => {
  const ref = useRef<FormikProps<IEngagement>>(null);

  const formValues = ref.current?.values;

  const { wirelineEngagementId } = useParams();
  const { showErrorNotifier, showSuccessNotifier } = useNotifiers();
  const {
    newCompanyName,
    isEditModeGeneral,
    engagementsInitialValues,
    getEngagement,
    setIsEditModeGeneral,
  } = useWirelineEngagementsCreateContext();

  const navigate = useNavigate();

  const reinitializeForm = async (engagementId: number | string | undefined | null) => {
    if (!engagementId) return;

    setIsEditModeGeneral(false);
    await getEngagement(engagementId);
  };

  const handleErrorSaveData = (error, { setErrors }, errorMessage) => {
    const definedError = error as IServerValidationError;
    setErrors(definedError?.response?.data);

    showErrorNotifier(errorMessage, error);
  };

  const onUpdateEngagementGeneral = async (params, formikHelpers) => {
    const engagementParams = omit(params, ['selectedCustomer']);

    try {
      await updateEngagementById(wirelineEngagementId, engagementParams);
      showSuccessNotifier(updateEngagementNotifications.success);

      await reinitializeForm(wirelineEngagementId);
    } catch (error) {
      handleErrorSaveData(error, formikHelpers, updateEngagementNotifications.error);
    }
  };

  const onCreateEngagement = async (params, formikHelpers) => {
    const engagementParams = omit(params, ['selectedCustomer']);

    try {
      const { data } = await createEngagement(engagementParams);
      showSuccessNotifier(createEngagementNotifications.success);

      await reinitializeForm(data.id);
      navigate(getEngagementsEditPath(data.id));
    } catch (error) {
      // @ts-ignore
      const errorMsg = error.response?.data?.nonFieldErrors?.[0]
        || createEngagementNotifications.error;

      handleErrorSaveData(error, formikHelpers, errorMsg);
    }
  };

  const onSubmit = async (params, formikHelpers) => {
    if (wirelineEngagementId && isEditModeGeneral) {
      await onUpdateEngagementGeneral(params, formikHelpers);
    } else {
      await onCreateEngagement(params, formikHelpers);
    }
  };

  const initialValues = useMemo(() => (
    getFormInitialDataFromEngagement(engagementsInitialValues, newCompanyName, formValues)
  ), [engagementsInitialValues, newCompanyName]);

  return (
    <Formik
      validateOnBlur
      validateOnChange
      enableReinitialize
      innerRef={ref}
      onSubmit={onSubmit}
      validationSchema={schema}
      initialValues={initialValues}
    >
      <Form>
        <EngagementsFormFields />
        <RouterFormConfirmDialogFormik />
      </Form>
    </Formik>
  );
};

export default EngagementsForm;

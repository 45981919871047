import React from 'react';
import { useField } from 'formik';
import { Checkbox, FormControlLabel } from '@mui/material';
import FormikFieldWrapper from 'shared/FormikField/FormikFieldWrapper';

interface IFormikSingleChackboxFieldProps {
  label: string
  fieldName: string
  disabled?: boolean
  required?: boolean
  additionalLabel?: string
}

const FormikSingleChackboxField: React.FC<IFormikSingleChackboxFieldProps> = ({
  label,
  disabled,
  required,
  fieldName,
  additionalLabel,
}) => {
  const [field,, helpers] = useField(fieldName);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
    helpers.setValue(value);
  };

  return (
    <FormikFieldWrapper
      label={label}
      fieldName={fieldName}
      required={required as boolean}
    >
      <FormControlLabel
        control={(
          <Checkbox
            {...field}
            checked={!!field.value}
            onChange={onChangeHandler}
            disabled={disabled as boolean}
          />
        )}
        label={additionalLabel}
      />
    </FormikFieldWrapper>
  );
};

FormikSingleChackboxField.defaultProps = {
  disabled: false,
  required: false,
  additionalLabel: '',
};

export default FormikSingleChackboxField;

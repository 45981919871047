import React, { useEffect } from 'react';
import { Paper } from '@mui/material';
import { HTTPService } from 'core/services';
import Attachments from 'shared/widgets/Attachments';
import { FileCategory } from 'pages/InsideSales/model';
import { useAppDispatch, useAppSelector } from 'core/store';
import { useActionsPermissions } from 'core/hooks/useActionsPermissions';
import { ACTIONS_INSIDE_SALES_PATHS } from 'core/models/actionsPermissions';
// eslint-disable-next-line no-unused-vars
import { IDraftAttachment } from 'shared/widgets/Attachments/Attachments.model';
import { getInsideSalesAttachmentsConfig } from 'pages/InsideSales/InsideSalesList/InsideSalesListTable/InsideSalesListTableModals/InsideSalesEditRowSidebarForm/InsideSalesEditAttachments/utilites';
import {
  clearAttachmentsAction,
  clearAttachmentsErrorsAction,
  createInsideSalesAttachmentAction,
  getInsideSalesAttachmentListAction,
  editInsideSalesAttachmentItemAction,
  deleteInsideSalesAttachmentItemAction,
} from 'core/actions/crm/InsideSales/InsideSalesAttachments';

const {
  PROPOSALS_GET,
  PROPOSAL_CREATE,
  ATTACHMENTS_GET,
  ATTACHMENT_CREATE,
} = ACTIONS_INSIDE_SALES_PATHS;

const actionsPaths = {
  isAvailableGetProposals: PROPOSALS_GET,
  isAvailableCreateProposal: PROPOSAL_CREATE,
  isAvailableGetAttachments: ATTACHMENTS_GET,
  isAvailableCreateAttachment: ATTACHMENT_CREATE,
};

let attachmentsController = HTTPService.getController();
const cancelGetInsideSalesAttachmentList = () => HTTPService.cancelRequest(attachmentsController);

interface IInsideSalesEditAttachmentsProps {
  editRowId: number | string | undefined | null
  isViewMode?: boolean
}

const InsideSalesEditAttachments: React.FC<
  IInsideSalesEditAttachmentsProps
> = ({ editRowId, isViewMode }) => {
  const dispatch = useAppDispatch();

  const attachments = useAppSelector((state) => state.insideSales.attachmentsList.results);
  const isLoading = useAppSelector((state) => state.insideSales.attachmentsList.isLoading);

  const {
    isAvailableGetProposals,
    isAvailableCreateProposal,
    isAvailableGetAttachments,
    isAvailableCreateAttachment,
  } = useActionsPermissions(actionsPaths);

  const clearAllAttachments = () => {
    cancelGetInsideSalesAttachmentList();
    dispatch(clearAttachmentsAction());
  };

  const getAttachmentsList = () => {
    if (!editRowId) return;

    dispatch(getInsideSalesAttachmentListAction({
      insideSalesRequestId: editRowId,
      controller: attachmentsController,
    }));
  };

  useEffect(() => {
    clearAllAttachments();
    attachmentsController = HTTPService.getController();

    if (!editRowId) {
      return;
    }

    getAttachmentsList();

    return clearAllAttachments;
  }, [editRowId]);

  if (!editRowId) {
    return null;
  }

  const clearAttachmentsErrors = () => {
    dispatch(clearAttachmentsErrorsAction());
  };

  const editAttachment = (attachmentId: string | number, newAttachment: IDraftAttachment) => {
    dispatch(editInsideSalesAttachmentItemAction({
      insideSalesRequestId: editRowId,
      attachmentId,
      attachmentData: newAttachment,
    }));
  };

  const createAttachment = (attachmentFile) => {
    dispatch(createInsideSalesAttachmentAction({
      insideSalesRequestId: editRowId,
      attachmentFile,
    }));
  };

  const removeAttachment = (attachmentId: number) => {
    dispatch(deleteInsideSalesAttachmentItemAction({
      insideSalesRequestId: editRowId,
      attachmentId,
    }));
  };

  const attachmentsConfigurations = {
    [FileCategory.ATTACHMENT]: {
      isVisible: isAvailableGetAttachments,
      isForbiddenCreate: isViewMode || !isAvailableCreateAttachment,
    },
    [FileCategory.PROPOSAL]: {
      isVisible: isAvailableGetProposals,
      isForbiddenCreate: isViewMode || !isAvailableCreateProposal,
    },
  };

  const insideSalesAttachmentsConfig = getInsideSalesAttachmentsConfig(attachmentsConfigurations);

  return (
    <Paper
      className="inside-sales-edit-row-sidebar__attachments"
      elevation={3}
    >
      <Attachments
        isLoading={isLoading}
        attachments={attachments}
        editAttachment={editAttachment}
        createAttachment={createAttachment}
        removeAttachment={removeAttachment}
        config={insideSalesAttachmentsConfig}
        clearAttachmentsErrors={clearAttachmentsErrors}
      />
    </Paper>
  );
};

InsideSalesEditAttachments.defaultProps = {
  isViewMode: false,
};

export default InsideSalesEditAttachments;

import React, { useEffect, useState } from 'react';
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import xor from 'lodash/xor';
import { Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'core/store';
import { HTTPService } from 'core/services';
import PeriodAutocomplete from 'shared/PeriodAutocomplete';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import {
  defaultPeriod, defaultPeriodOption, periodOptions, titles, notifierMessage,
} from '@dashboardWirelineRetentionDepartment/constants';
import ProductsSelect from '@dashboardWirelineRetentionDepartment/ProductsSelect';
import RenewedEngagementsChart from '@dashboardWirelineRetentionDepartment/RenewedEngagements/RenewedEngagementsChart';
import { EngagementTypeForChart } from '@dashboardWirelineRetentionDepartment/RetentionManagerDepartment.models';
import { getRenewedEngagementsAction } from '@dashboardWirelineRetentionDepartment/GetRenewedEngagements';

let renewedEngagementsController = HTTPService.getController();

const RenewedEngagements = () => {
  const renewedEngagementsData = useAppSelector((state) => state.dashboard.renewedEngagements.data);

  const productsWithValue = keys(renewedEngagementsData);

  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [period, setPeriod] = useState(defaultPeriod);

  const { showErrorNotifier } = useNotifiers();

  const dispatch = useAppDispatch();

  const cancelGetRenewedEngagementsRequest = () => {
    HTTPService.cancelRequest(renewedEngagementsController);
  };

  const getRenewedEngagements = async (newPeriod) => {
    cancelGetRenewedEngagementsRequest();

    setPeriod(newPeriod);

    renewedEngagementsController = HTTPService.getController();

    const params = {
      engagementType: EngagementTypeForChart.RENEWED,
      ...newPeriod,
    };

    const isAllProductsWithValueSelected = (
      !isEmpty(productsWithValue) && xor(selectedProducts, productsWithValue).length === 0
    );

    dispatch(getRenewedEngagementsAction({
      controller: renewedEngagementsController, params,
    }))
      .unwrap()
      .then((mewRenewedEngagementsData) => {
        if (!isAllProductsWithValueSelected) return;

        const newProductsWithValue = keys(mewRenewedEngagementsData);
        setSelectedProducts(newProductsWithValue);
      })
      .catch((e) => {
        showErrorNotifier(notifierMessage.renewed.error, e);
      });
  };

  useEffect(() => {
    getRenewedEngagements(period);

    return () => cancelGetRenewedEngagementsRequest();
  }, []);

  return (
    <div className="renewed-engagements">
      <Typography variant="h3" className="retention-department__title">
        {titles.renewed}
      </Typography>
      <div className="retention-department__filters">
        <PeriodAutocomplete
          onChange={getRenewedEngagements}
          options={periodOptions}
          defaultOption={defaultPeriodOption}
        />
        <ProductsSelect
          setSelectedProducts={setSelectedProducts}
          selectedProducts={selectedProducts}
          activeProducts={productsWithValue}
        />
      </div>
      <RenewedEngagementsChart selectedProducts={selectedProducts} />
    </div>
  );
};

export default RenewedEngagements;

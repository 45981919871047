import React, { useState } from 'react';
import {
  TextField, InputLabel, Typography, Grid,
} from '@mui/material';
import { useFormikContext } from 'formik';
import FormikTextField from 'shared/FormikField/FormikTextField/FormikTextField';
import FormikMobileMaskField from 'shared/FormikField/FormikMobileMaskField/FormikMobileMaskField';
import FormikRadioGroup from 'shared/FormikField/FormikRadioGroup/FormikRadioGroup';
import { contractFormLabels, contractFormInfoOptions } from '@EngagementsSowData/ContractForm/constants';

interface IContractFormInfoProps {
  subAccountId: string | null
  isDisabled: boolean
}

const ContractFormInfo: React.FC<IContractFormInfoProps> = ({
  subAccountId, isDisabled,
}) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [isAdditionalCustomer, setIsAdditionalCustomer] = useState(false);

  const onChangeAdditionalCustomer = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    if (value === contractFormLabels.yes) {
      setIsAdditionalCustomer(true);
    } else {
      setIsAdditionalCustomer(false);
      setFieldValue('additionalContactName', '');
      setFieldValue('additionalContactEmail', '');
      setFieldTouched('additionalContactEmail', false);
      setFieldValue('additionalContacts', '');
    }
  };

  return (
    <div className="contract-form__info">
      <div className="contract-form__info-id">
        <InputLabel className="contract-form__info-id-label">
          Subaccount ID
        </InputLabel>
        <TextField
          className="contract-form__info-id-input"
          value={subAccountId}
          variant="outlined"
          disabled
        />
      </div>
      <Grid
        container
        item
        rowSpacing={3}
      >
        <Typography
          variant="body1"
          component="h3"
          className="contract-form__info-title"
        >
          Authorized Contract signer
        </Typography>
        <FormikTextField
          label="Name"
          fieldName="signerName"
          required
          fullWidth
          disabled={isDisabled}
        />
        <FormikMobileMaskField
          label="Phone number"
          fieldName="signerPhone"
          required
          fullWidth
          disabled={isDisabled}
        />
        <FormikTextField
          label="Email Address"
          fieldName="signerEmail"
          required
          fullWidth
          disabled={isDisabled}
        />
        <div className="contract-form__info-additional">
          <FormikRadioGroup
            label="Additional Customer contacts that need to receive the contract"
            fieldName="isAdditionalCustomer"
            options={contractFormInfoOptions}
            onChange={onChangeAdditionalCustomer}
            disabled={isDisabled}
          />
        </div>
        <FormikTextField
          label="Name"
          fieldName="additionalContactName"
          disabled={!isAdditionalCustomer}
          fullWidth
        />
        <FormikTextField
          label="Email Address"
          fieldName="additionalContactEmail"
          disabled={!isAdditionalCustomer}
          fullWidth
        />
        <FormikTextField
          label="Additional contacts"
          fieldName="additionalContacts"
          disabled={!isAdditionalCustomer}
          fullWidth
        />
      </Grid>
    </div>
  );
};

export default ContractFormInfo;

import React from 'react';
import SellerDepartmentContent from '@dashboardWirelineSellerDepartment/SellerDepartmentContent';
import SellerDepartmentProvider from '@dashboardWirelineSellerDepartment/SellerDepartmentContext';

import './SellerDepartment.scss';

const SellerDepartment = () => (
  <SellerDepartmentProvider>
    <SellerDepartmentContent />
  </SellerDepartmentProvider>
);

export default SellerDepartment;

import React, { useState } from 'react';
import { Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTableContext } from 'shared/Table/TableContext';
import EngagementAcceptedModal from '@EngagementsSowData/EngagementAccepted/EngagementAcceptedModal';
import isEmpty from 'lodash/isEmpty';
import { engagementAcceptedTitle } from '@EngagementsSowData/EngagementAccepted/constants';
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import { useAppSelector } from 'core/store';
import { useTableSelectedRows } from 'shared/Table/hooks/useTableSelectedRows';
import { checkIfOrdersIsAttTSD, checkIfOrdersRequestTypeIsNonCotractSupport } from '@EngagementsSowData/utilities';

const EngagementAccepted = () => {
  const { setRowSelection } = useTableContext();

  const [isEngagementAcceptedModalOpen, setIsEngagementAcceptedModalOpen] = useState(false);

  const sowData = useAppSelector(
    (state) => state.wirelineEngagements.salesSow.results,
  );

  const selectedSowData = useTableSelectedRows<ISowDataListItem>(
    { rows: sowData },
  );

  const hasAcceptedEngagement = selectedSowData.every(
    (item) => item.contractEngagementAcceptedDate !== undefined
    && item.contractEngagementAcceptedDate !== null,
  );

  const hasRowNonAttTSD = checkIfOrdersIsAttTSD(selectedSowData);
  const hasRowRequestTypeIsNonContractOrder = checkIfOrdersRequestTypeIsNonCotractSupport(
    selectedSowData,
  );

  const isDisabled = hasAcceptedEngagement
   || hasRowNonAttTSD
   || hasRowRequestTypeIsNonContractOrder
   || isEmpty(selectedSowData);

  const onOpenEngagementAcceptedModal = () => {
    setIsEngagementAcceptedModalOpen(true);
  };

  const onCloseEngagementAcceptedModal = () => {
    setIsEngagementAcceptedModalOpen(false);
    setRowSelection({});
  };

  return (
    <span className="sow-data__engagement-acceptance">
      <Button
        startIcon={<CheckCircleOutlineIcon />}
        disabled={isDisabled}
        onClick={onOpenEngagementAcceptedModal}
      >
        {engagementAcceptedTitle}
      </Button>
      <EngagementAcceptedModal
        isModalOpen={isEngagementAcceptedModalOpen}
        onCloseModal={onCloseEngagementAcceptedModal}
        selectedSowData={selectedSowData}
      />
    </span>
  );
};

export default EngagementAccepted;

// eslint-disable-next-line no-unused-vars
import React, { FC, useMemo } from 'react';
import merge from 'lodash/merge';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { Formik, Form } from 'formik';
import { Grid, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import Loader from 'shared/Loader';
import paths from 'core/routing/paths';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useAppDispatch, useAppSelector } from 'core/store';
import { ICustomer } from 'pages/Customers/Customers.models';
import { IOption } from 'shared/FormikField/FormikField.model';
import CustomersWidgets from 'pages/Customers/CustomersWidgets';
import { IServerValidationError } from 'core/services/HTTPService.models';
import NavigateBackToolbar from 'shared/NavigateBackToolbar';
import { createCustomerNotifierMessage } from 'pages/Customers/CustomersCreate/constants';
import schema from 'pages/Customers/CustomersCreate/CustomersCreateForm/CustomersCreateFormSchema';
import { createCustomerFromDraftAction } from 'core/actions/Customers/CreateCustomerFromDraft/actions';
import RouterFormConfirmDialogFormik from 'shared/RouterFormConfirmDialog/RouterFormConfirmDialogFormik';
import CustomersCreateFormButtons from 'pages/Customers/CustomersCreate/CustomersCreateForm/CustomersCreateFormButtons';
import CustomersCreateFormProfile from 'pages/Customers/CustomersCreate/CustomersCreateForm/CustomersCreateFormSections/CustomersCreateFormProfile';
import CustomersCreateFormLOAInformation from 'pages/Customers/CustomersCreate/CustomersCreateForm/CustomersCreateFormSections/CustomersCreateFormLOAInformation';
import CustomersCreateFormBillingInformation from 'pages/Customers/CustomersCreate/CustomersCreateForm/CustomersCreateFormSections/CustomersCreateFormBillingInformation';
import CustomersCreateFormCustomerInformation from 'pages/Customers/CustomersCreate/CustomersCreateForm/CustomersCreateFormSections/CustomersCreateFormCustomerInformation';

import './CustomersCreateForm.scss';

interface ICustomersCreateFormProps {
  id: number
  initialSeller?: string
  sellersOptions: IOption[]
  customer?: ICustomer | null
}

const CustomersCreateForm: FC<ICustomersCreateFormProps> = ({
  id, sellersOptions, customer, initialSeller,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();

  const isLoadingCustomer = useAppSelector((state) => state.customers.createCustomer.isLoading);
  const isLoadingUsers = useAppSelector((state) => state.customers.users.isLoading);

  const isLoading = isLoadingCustomer || isLoadingUsers;
  const isEditMode = !!customer;
  const toolbarTitle = isEditMode ? 'Edit customer' : 'Create customer';

  const initialValues = useMemo(() => {
    const defaultValues = schema.getDefault();

    const existingCustomerData = omitBy(customer, isNil);

    return merge(
      defaultValues,
      existingCustomerData,
      { id, ...(initialSeller && { seller: initialSeller }) },
    );
  }, []);

  const onSubmit = async (params, { setErrors }) => {
    try {
      await dispatch(createCustomerFromDraftAction(id, params));
      showSuccessNotifier(createCustomerNotifierMessage.sucsess);
      navigate(paths.customersList);
    } catch (error) {
      const definedError = error as IServerValidationError;
      setErrors(definedError?.response?.data);

      showErrorNotifier(createCustomerNotifierMessage.error, error);
    }
  };

  return (
    <Formik
      validateOnBlur
      validateOnChange
      onSubmit={onSubmit}
      validationSchema={schema}
      initialValues={initialValues}
    >
      <Form>
        <Box className="customers-create-form__wrapper">
          <NavigateBackToolbar
            title={toolbarTitle}
            path={paths.customersList}
          />
          <Grid
            className="customers-create-form__section"
            container
            columnSpacing={5}
          >
            {isLoading && <Loader />}
            <CustomersCreateFormCustomerInformation
              isEditMode={isEditMode}
              sellersOptions={sellersOptions}
            />
            <CustomersCreateFormBillingInformation />
            <CustomersCreateFormLOAInformation />
            <CustomersCreateFormProfile
              title="contract signer"
              sectionFieldName="contractSignerProfile"
            />
            <CustomersCreateFormProfile
              title="primary it"
              sectionFieldName="primaryItProfile"
            />
            <CustomersCreateFormProfile
              title="alternative contact"
              sectionFieldName="alternativeContactProfile"
            />
          </Grid>
        </Box>
        <CustomersWidgets id={id} isLoading={isLoading} />
        <CustomersCreateFormButtons isEditMode={isEditMode} />
        <RouterFormConfirmDialogFormik />
      </Form>
    </Formik>
  );
};

CustomersCreateForm.defaultProps = {
  customer: null,
  initialSeller: '',
};

export default CustomersCreateForm;

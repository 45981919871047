import {
  GET_USER_MANAGEMENT_LIST_REQUEST,
  GET_USER_MANAGEMENT_LIST_SUCCESS,
  GET_USER_MANAGEMENT_LIST_FAILURE,
} from './actionsTypes';

export const getUserManagementLisRequest = () => ({ type: GET_USER_MANAGEMENT_LIST_REQUEST });
export const getUserManagementLisSuccess = (data) => ({
  type: GET_USER_MANAGEMENT_LIST_SUCCESS,
  data,
});
export const getUserManagementLisFailure = (error) => ({
  type: GET_USER_MANAGEMENT_LIST_FAILURE,
  error,
});

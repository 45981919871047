/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// eslint-disable-next-line no-unused-vars
import { IProduct, ICreateProduct } from 'pages/WirelineEngagements/shared/AddOrderModalForm/product.model';
import { createProduct } from 'core/api/sales.api';

export interface IState {
  isLoading: boolean
  data: IProduct | null
  error: object | null
}

const initialState: IState = {
  data: null,
  isLoading: false,
  error: null,
};

export const createProductAction = createAsyncThunk(
  'createProductAction',
  async (params: ICreateProduct, { rejectWithValue }) => {
    try {
      const { data } = await createProduct(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const ProductSlice = createSlice({
  name: 'createProducts',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(createProductAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createProductAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(createProductAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
        state.data = null;
      });
  },
});

export const { clear: clearCreateProductAction } = ProductSlice.actions;

export const createProductsReducer = ProductSlice.reducer;

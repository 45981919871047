import React from 'react';
import FormikRadioGroup from 'shared/FormikField/FormikRadioGroup/FormikRadioGroup';
import { contractFormSettingsOptions } from '@EngagementsSowData/ContractForm/constants';

interface IContractFormSettingsProps {
    isDisabled: boolean
}

const ContractFormSettings: React.FC<IContractFormSettingsProps> = ({ isDisabled }) => (
  <div className="contract-form__settings">
    <FormikRadioGroup
      label="Do you want a new contract per service address? "
      fieldName="generateContractPerServiceAddress"
      options={contractFormSettingsOptions}
      disabled={isDisabled}
    />
    <FormikRadioGroup
      label="Do you want contract to be sent to customer via esign?"
      fieldName="esign"
      options={contractFormSettingsOptions}
      disabled={isDisabled}
    />
    <FormikRadioGroup
      label="Do you want to merge contracts in esign if multiple contracts required?"
      fieldName="mergeEsignForMultipleContracts"
      options={contractFormSettingsOptions}
      disabled={isDisabled}
    />
    <FormikRadioGroup
      label="Are there multiple products/services included in this request?"
      fieldName="multipleProductsServicesIncluded"
      options={contractFormSettingsOptions}
      disabled={isDisabled}
    />
  </div>
);

export default ContractFormSettings;

import React, { useEffect, useMemo } from 'react';
import Loader from 'shared/Loader';
import { useParams } from 'react-router-dom';
import ContentShrink from 'shared/ContentShrink';
import { tableIds } from 'shared/Table/constants';
import { TableProvider } from 'shared/Table/TableContext';
import { useAppDispatch, useAppSelector } from 'core/store';
import { parseOpenapiSchema } from 'core/utilities/openapiSchema';
import { clearSalesSowData } from 'core/actions/Sales/GetSalesSow';
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import getSalesSowAction from 'core/actions/Sales/GetSalesSow/actions';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useUserPermissions } from '@EngagementsSowData/useUserPermissions';
import { getWidthToReduce } from 'pages/WirelineEngagements/shared/utilites';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { columns, dynamicProductSchemaName } from '@EngagementsSowData/constants';
import { getColumnsFromMetaAndShchema } from 'core/utilities/getColumnsFromMetaAndShchema';
import { getSalesSowMetaAction, clearSalesSowMeta } from 'core/actions/Sales/GetSalesSowMeta';
import WirelineEngagementsSowDataTable from '@EngagementsSowData/WirelineEngagementsSowDataTable';
import BackToEditEngagementButton from 'pages/WirelineEngagements/shared/BackToEditEngagementButton';
import { checkIfOrderNonAttTSD, isActiveNumbersItemCanceled } from 'pages/WirelineEngagements/WirelineEngagementsTables/utilities';

import 'pages/WirelineEngagements/WirelineEngagementsTables/WirelineEngagementsTables.scss';
import './WirelineEngagementsSowData.scss';

const WirelineEngagementsSowDataContainer = () => {
  const { customerId } = useParams();
  const dispatch = useAppDispatch();

  const { isActionsUnavailable, isRequiredATTRoles } = useUserPermissions();

  const openApiSchemas = useAppSelector((state) => state.openapiSchemas.schemas);
  const metaColumns = useAppSelector((state) => state.wirelineEngagements.salesSowMeta.meta);
  const isLoadingMeta = useAppSelector((state) => state.wirelineEngagements.salesSowMeta.isLoading);
  const isLoadingSchemas = useAppSelector((state) => state.openapiSchemas.isLoading);

  const retrieveSchema = useMemo(() => parseOpenapiSchema(
    openApiSchemas,
    dynamicProductSchemaName,
  ), [openApiSchemas],
  );

  const availableColumns = useMemo(
    () => getColumnsFromMetaAndShchema({ metaColumns, columns, retrieveSchema }),
    [metaColumns, retrieveSchema],
  );

  const enableRowSelection = (
    !isActionsUnavailable && ((row) => {
      const isCancaled = isActiveNumbersItemCanceled(row.original);
      const isRowNonAttTSD = checkIfOrderNonAttTSD(row.original);
      const isAttTSDForRoles = isRequiredATTRoles && isRowNonAttTSD;

      return !isCancaled
        && !isAttTSDForRoles;
    })
  );

  const { activeRowMode, secondarySidebarMode } = useTableActiveRowContext<ISowDataListItem>();
  const { isEditMode, isViewMode, isCommentMode } = getTableRowModeBoolean(activeRowMode);

  const isTableShrank = isEditMode || isViewMode || isCommentMode;

  const getSalesSowMetaData = async () => { await dispatch(getSalesSowMetaAction()); };

  useEffect(() => {
    getSalesSowMetaData();

    return () => {
      dispatch(clearSalesSowMeta());
      dispatch(clearSalesSowData());
    };
  }, []);

  if (!customerId) {
    return null;
  }

  if (isLoadingMeta || isLoadingSchemas) {
    return <Loader />;
  }

  return (
    <div className="sow-data">
      <BackToEditEngagementButton
        title="SOW Data"
      />
      <ContentShrink
        isShrinked={isTableShrank}
        widthToReduce={getWidthToReduce(!!secondarySidebarMode)}
      >
        <TableProvider<string[]>
          id={tableIds.salesSow}
          columns={availableColumns}
          requestParams={[customerId]}
          getDataAction={getSalesSowAction}
          enableEditing={!isActionsUnavailable}
          enableRowSelection={enableRowSelection}
        >
          <WirelineEngagementsSowDataTable />
        </TableProvider>
      </ContentShrink>
    </div>
  );
};

export default WirelineEngagementsSowDataContainer;

import React, { useState } from 'react';
import { IComment } from 'shared/widgets/Comments/Comments.models';
import CommentsList from 'shared/widgets/Comments/CommentsList';
import CommentsTextArea from 'shared/widgets/Comments/CommentsTextArea';
import WidgetTemplate from 'shared/widgets/WidgetTemplate';

interface ICommentsProps {
  error: string
  addNewComment: (data: string) => void
  updateComment: (commentId: number, data: string) => void
  deleteComment: (commentId: number) => void
  isLoading: boolean
  comments: IComment[]
  clearCommentsErrors: () => void
  isAvailableAddComment?: boolean
}

const Comments: React.FC<ICommentsProps> = ({
  error,
  addNewComment,
  updateComment,
  deleteComment,
  isLoading,
  comments,
  clearCommentsErrors,
  isAvailableAddComment,
}) => {
  const [newCommentText, setNewCommentText] = useState('');
  const [editCommentId, setEditCommentId] = useState('');
  const [editCommentText, setEditCommentText] = useState('');

  const onChangeComment = (e) => {
    setNewCommentText(e.target.value);
    clearCommentsErrors();
  };

  const onEditComment = (e) => {
    setEditCommentText(e.target.value);
  };

  const onFocusEditComment = (e, comment) => {
    e.preventDefault();
    const { id } = comment;
    setEditCommentId(id);
    setEditCommentText(e.target.value);
    clearCommentsErrors();
  };

  const onCreateNewComment = (e) => {
    e.preventDefault();
    addNewComment(newCommentText);
    setNewCommentText('');
  };

  const onUpdateComment = (e) => {
    e.preventDefault();
    updateComment(Number(editCommentId), editCommentText);
    setEditCommentId('');
    setEditCommentText('');
  };

  const onRemoveComment = (e, commentId) => {
    e.preventDefault();
    deleteComment(commentId);
  };

  const commentsList = (
    <CommentsList
      comments={comments}
      editCommentId={editCommentId}
      editCommentText={editCommentText}
      onFocusEditComment={onFocusEditComment}
      onEditComment={onEditComment}
      onRemoveComment={onRemoveComment}
    />
  );

  const textAreaBlock = isAvailableAddComment ? (
    <CommentsTextArea
      newCommentText={newCommentText}
      error={error}
      editCommentId={editCommentId}
      onChangeComment={onChangeComment}
      onCreateNewComment={onCreateNewComment}
      onUpdateComment={onUpdateComment}
    />
  ) : undefined;

  return (
    <WidgetTemplate
      title="Comments"
      isLoading={isLoading}
      buttonBlock={textAreaBlock}
      className="widget-comments"
      content={commentsList}
    />
  );
};

Comments.defaultProps = {
  isAvailableAddComment: true,
};

export default Comments;

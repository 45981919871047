import React, { useEffect } from 'react';
import { useAppDispatch } from 'core/store';
import { getProductsAction } from 'core/actions/Sales/GetProducts';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import TotalEngagements from '@dashboardWirelineRetentionDepartment/TotalEngagements';
import RenewedEngagements from '@dashboardWirelineRetentionDepartment/RenewedEngagements';
import { notifierMessage } from '@dashboardWirelineRetentionDepartment/constants';
import DeclinedEngagements from '@dashboardWirelineRetentionDepartment/DeclinedEngagements';
import InProgressEngagements from '@dashboardWirelineRetentionDepartment/InProgressEngagements';
import RetentionBookingList from '@dashboardWirelineRetentionDepartment/RetentionBookingList';
import './RetentionManagerDepartment.scss';

const RetentionManagerDepartment = () => {
  const dispatch = useAppDispatch();

  const { showErrorNotifier } = useNotifiers();

  const getProducts = async () => {
    dispatch(getProductsAction())
      .unwrap()
      .catch((e) => {
        showErrorNotifier(notifierMessage.product.error, e);
      });
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <div className="retention-department">
      <div className="retention-department__charts">
        <TotalEngagements />
        <InProgressEngagements />
        <RenewedEngagements />
        <DeclinedEngagements />
      </div>
      <div className="retention-department__retention-boking-info">
        <RetentionBookingList />
      </div>
    </div>
  );
};

export default RetentionManagerDepartment;

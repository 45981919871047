import { Button } from '@mui/material';
import React, { memo } from 'react';
import CommentIcon from '@mui/icons-material/Comment';
import DatadIcon from '@mui/icons-material/Description';
import { TableRowMode } from 'shared/Table/Table.models';
import './HeaderActions.scss';

interface IHeaderActionsProps {
  isVisibleOrderDataButton: boolean
  secondarySidebarMode: TableRowMode | null
  setSecondarySidebarMode: (mode: TableRowMode | null) => void
}

const HeaderActions: React.FC<IHeaderActionsProps> = ({
  secondarySidebarMode,
  setSecondarySidebarMode,
  isVisibleOrderDataButton,
}) => {
  const disabledData = secondarySidebarMode === TableRowMode.COMMENT;
  const disabledComment = secondarySidebarMode === TableRowMode.DATA;

  const setSecondarySidebarInDataMode = () => setSecondarySidebarMode(TableRowMode.DATA);
  const setSecondarySidebarInComentMode = () => setSecondarySidebarMode(TableRowMode.COMMENT);

  return (
    <div className="header-actions">
      <Button
        variant="outlined"
        disabled={disabledComment}
        className="header-actions__button"
        onClick={setSecondarySidebarInComentMode}
        startIcon={<CommentIcon />}
      >
        Comments
      </Button>
      {isVisibleOrderDataButton
        && (
        <Button
          variant="outlined"
          disabled={disabledData}
          className="header-actions__button"
          onClick={setSecondarySidebarInDataMode}
          startIcon={<DatadIcon />}
        >
          Data
        </Button>
        )}
    </div>
  );
};

export default memo(HeaderActions);

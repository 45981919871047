import React from 'react';
import { List } from '@mui/material';
import CommentItem from 'shared/widgets/Comments/CommentsList/CommentItem';
import { IComment } from 'shared/widgets/Comments/Comments.models';
import CommentsEmpty from 'shared/widgets/Comments/CommentsList/CommentsEmpty';

interface ICommentsListView {
    comments: IComment[];
    editCommentId: string | number;
    editCommentText: string;
    onFocusEditComment: (e: React.SyntheticEvent, comment: IComment) => void;
    onEditComment: (e: React.SyntheticEvent) => void;
    onRemoveComment: (e: React.SyntheticEvent, commentId: number) => void;
}

const CommentsListView: React.FC<ICommentsListView> = ({
  comments,
  editCommentId,
  editCommentText,
  onFocusEditComment,
  onEditComment,
  onRemoveComment,
}) => {
  const hasComments = comments.length > 0;

  if (!hasComments) {
    return <CommentsEmpty />;
  }

  return (
    <List>
      {comments.map((comment) => (
        <CommentItem
          key={comment.id}
          comment={comment}
          isDisabledTextField={editCommentId !== String(comment.id)}
          isEditMode={Number(editCommentId) !== comment.id}
          editCommentText={editCommentText}
          onFocusEditComment={onFocusEditComment}
          onEditComment={onEditComment}
          onRemoveComment={onRemoveComment}
          editCommentId={editCommentId}
        />
      ))}
    </List>
  );
};

export default CommentsListView;

import React, { useMemo, useEffect } from 'react';
import { omit } from 'lodash';
import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';

import Loader from 'shared/Loader';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useAppDispatch, useAppSelector } from 'core/store';
import { IServerValidationError } from 'core/services/HTTPService.models';
import { getOrderFormData } from 'pages/WirelineEngagements/WirelineEngagementsTables/utilities';
import RequestOrderFormButtons from '@EngagementsSowData/RequestOrderForm/RequestOrderFormButtons';
import RouterFormConfirmDialogFormik from 'shared/RouterFormConfirmDialog/RouterFormConfirmDialogFormik';
import RequestOrderFormFormGeneral from '@EngagementsSowData/RequestOrderForm/RequestOrderFormFormGeneral';
import { getSalesSowOrderFormAction, putSalesSowOrderFormAction } from 'core/actions/Sales/SalesSowOrderForm';
import RequestOrderFormContacts from '@EngagementsSowData/RequestOrderForm/RequestOrderFormContacts/RequestOrderFormContacts';
import {
  includedFields,
  saveRequestOrderNotifierMessage,
  loadRequestOrderDataNotifierMessage,
} from '@EngagementsSowData/RequestOrderForm/constants';

import './RequestOrderForm.scss';

interface IRequestOrderFormProps {
  orderId: string,
  isViewMode?: boolean,
  isEditMode?: boolean,
  setIsEditMode?: ((value: boolean) => void) | undefined,
}

const RequestOrderForm: React.FC<
  IRequestOrderFormProps
> = ({
  orderId, isViewMode, isEditMode, setIsEditMode,
}) => {
  const dispatch = useAppDispatch();
  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();

  const salesSowOrderForm = useAppSelector(
    (state) => state.wirelineEngagements.salesSowOrderForm.result,
  );

  const isLoadingOrderForm = useAppSelector(
    (state) => state.wirelineEngagements.salesSowOrderForm.isLoading,
  );
  const isLoadingRequestOrders = useAppSelector(
    (state) => state.wirelineEngagements.requestOrders.isLoading,
  );
  const isLoadingSchemas = useAppSelector((state) => state.openapiSchemas.isLoading);
  const isLoadingAction = isLoadingOrderForm || isLoadingRequestOrders;

  const { editableOrderData, orderDataKeys } = useMemo(() => {
    const parsedOrderData = getOrderFormData(salesSowOrderForm);
    const dataKeys = Object.keys(parsedOrderData);

    return { editableOrderData: parsedOrderData, orderDataKeys: dataKeys };
  }, [salesSowOrderForm]);

  const loadSalesSowOrderForm = async () => {
    try {
      await dispatch(getSalesSowOrderFormAction(orderId))
        .unwrap();
    } catch (error) {
      showErrorNotifier(loadRequestOrderDataNotifierMessage.error, error);
    }
  };

  const clearParamsFromAdditionalData = (params) => omit(params, includedFields);

  const onSubmit = async (values, { setErrors }) => {
    const formatedParams = clearParamsFromAdditionalData(values);

    try {
      await dispatch(putSalesSowOrderFormAction({
        id: orderId,
        params: formatedParams,
      }))
        .unwrap();

      setIsEditMode?.(false);
      showSuccessNotifier(saveRequestOrderNotifierMessage.success);
    } catch (error) {
      const definedError = error as IServerValidationError;
      setErrors(definedError?.response?.data);
      showErrorNotifier(saveRequestOrderNotifierMessage.error, error);
    }
  };

  useEffect(() => {
    loadSalesSowOrderForm();
  }, [orderId]);

  if (isLoadingSchemas) {
    return <Loader />;
  }

  return (
    <Formik
      validateOnBlur
      validateOnChange
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={editableOrderData}
    >
      <Form data-testid="request-order-form">
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          columnSpacing={3}
        >
          {isLoadingAction && <Loader />}
          {!isViewMode && setIsEditMode && (
            <RequestOrderFormButtons
              orderId={orderId}
              isEditMode={isEditMode as boolean}
              setIsEditMode={setIsEditMode}
            />
          )}
          <RequestOrderFormFormGeneral
            orderDataKeys={orderDataKeys}
            isEditMode={isEditMode as boolean}
          />
          <RequestOrderFormContacts
            isEditMode={isEditMode as boolean}
          />
        </Grid>
        <RouterFormConfirmDialogFormik />
      </Form>
    </Formik>
  );
};

RequestOrderForm.defaultProps = {
  isViewMode: false,
  isEditMode: false,
  setIsEditMode: undefined,
};

export default RequestOrderForm;

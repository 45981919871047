import isEmpty from 'lodash/isEmpty';
import {
  // eslint-disable-next-line no-unused-vars
  ITeamRole, IUserWithTeamRoles, IUserExtended, UserType,
} from 'pages/UserManagement/UserManagement.models';

export const getUserEditFromUserList = (extendedUser: IUserExtended): IUserWithTeamRoles => {
  const { teamRoles, type } = extendedUser;

  let regularTeamRoles;

  if (type === UserType.ADMIN || isEmpty(teamRoles)) {
    regularTeamRoles = [];
  } else {
    regularTeamRoles = teamRoles.map((teamRole) => ({
      ...teamRole,
      associates: teamRole.associates.map(({ user: { email }, team }) => ({ user: email, team })),
    }) as ITeamRole);
  }

  return {
    ...extendedUser,
    teamRoles: regularTeamRoles,
  };
};

import React from 'react';
import get from 'lodash/get';
import ActionButtonMore from 'shared/Table/Actions/ActionButtonMore/ActionButtonMore';
import { TableRowMode } from 'shared/Table/Table.models';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ActionButton from 'shared/Table/Actions/ActionButton';
import { IMoreButtonConfigItem } from 'shared/Table/Actions/model';

interface IActionsProps<T> {
  row: T
  activeRowId: string | number | null | undefined
  activeRowMode: TableRowMode | null
  isActiveButtonDisabled?: boolean

  setActiveRowEdit: (row: T | null) => void
  setActiveRowView?: (row: T | null) => void | undefined

  moreButtonConfig?: IMoreButtonConfigItem[] | undefined,
  moreButtonTooltipTitle: string
  isMoreButtonDisabled?: boolean,
  isViewButton?: boolean,
  viewButtonTooltipTitle?: string | undefined,
  isActionButtonVisible?: boolean,
}

const Actions = <T extends unknown> ({
  row,
  activeRowId,
  activeRowMode,
  isActiveButtonDisabled,

  setActiveRowEdit,
  setActiveRowView,

  moreButtonConfig,
  moreButtonTooltipTitle,
  isMoreButtonDisabled,
  isViewButton,
  viewButtonTooltipTitle,
  isActionButtonVisible,
}: IActionsProps<T>) => {
  const rowId = get(row, 'id');

  const { isEditMode, isViewMode } = getTableRowModeBoolean(activeRowMode);

  const isViewButtonSelected = isViewMode && rowId === activeRowId;
  const isViewButtonDisabled = (!!activeRowId && !isViewButtonSelected) || !!isActiveButtonDisabled;

  const onClickViewButton = () => setActiveRowView?.(row);

  const isEditButtonSelected = isEditMode && rowId === activeRowId;
  const isEditButtonDisabled = (!!activeRowId && !isEditButtonSelected) || !!isActiveButtonDisabled;

  const onClickEditButton = () => setActiveRowEdit(row);

  const actionButtonProps = isViewButton
    ? {
      onClick: onClickViewButton,
      tooltipTitle: viewButtonTooltipTitle as string,
      icon: <VisibilityIcon />,
      isDisabled: isViewButtonDisabled,
      isSelected: isViewButtonSelected,
    }
    : {
      onClick: onClickEditButton,
      tooltipTitle: 'Edit',
      icon: <EditOutlinedIcon />,
      isDisabled: isEditButtonDisabled,
      isSelected: isEditButtonSelected,
    };

  return (
    <div className="table__actions-wrapper">
      {isActionButtonVisible && (
        <ActionButton
          {...actionButtonProps}
        />
      )}
      <ActionButtonMore
        config={moreButtonConfig}
        tooltipTitle={moreButtonTooltipTitle}
        isDisabled={isMoreButtonDisabled as boolean}
      />
    </div>
  );
};

Actions.defaultProps = {
  isActiveButtonDisabled: false,
  isMoreButtonDisabled: false,
  isViewButton: false,
  viewButtonTooltipTitle: 'View',
  isActionButtonVisible: true,
  setActiveRowView: undefined,
  moreButtonConfig: undefined,
};

export default Actions;

import {
  GET_USERS_REQUEST,
  GET_USERS_FAILURE,
  GET_USERS_SUCCESS,
} from './actionsTypes';

const initialState = {
  isLoading: false,
  count: 0,
  results: [],
  errors: {},
};

const getUsersReducer = (state = initialState, {
  type, data, error,
}) => {
  if (type === GET_USERS_REQUEST) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (type === GET_USERS_SUCCESS) {
    return {
      ...state,
      ...data,
      isLoading: false,
    };
  }

  if (type === GET_USERS_FAILURE) {
    return {
      ...state,
      isLoading: false,
      error,
    };
  }

  return state;
};
export default getUsersReducer;

import React, { useEffect, useMemo } from 'react';
import { useField } from 'formik';
import { Grid } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'core/store';
import { clearProductsAction } from 'core/actions/Sales/GetProducts';
import { fields } from 'pages/WirelineEngagements/shared/AddOrderModalForm/constants';
// eslint-disable-next-line no-unused-vars
import { IFormikOrder, IProductParams, IOrderProduct } from 'pages/WirelineEngagements/shared/AddOrderModalForm/product.model';
import { AddOrderProductField, AddOrderProductCarrierField, AddOrderProductsList } from 'pages/WirelineEngagements/shared/AddOrderModalForm/EngagementsOrderForm/EngagementOrderFormFields';

interface IAddOrderProductsNonATTFilter {
  itemCount: number
  nonATTCategoriesIds: number[],
  getProducts: (itemCount: number) => void;
}

const AddOrderProductsNonATTFilter:React.FC<IAddOrderProductsNonATTFilter> = ({
  itemCount,
  getProducts,
  nonATTCategoriesIds,
}) => {
  const dispatch = useAppDispatch();

  const createProductLoading = useAppSelector(
    (state) => state.wirelineEngagements.createProducts.isLoading,
  );
  const [{ value: productsData }] = useField<{IOrderProduct}>(`${fields.orders}[${itemCount}][${fields.products}]`);

  const products = useMemo(
    () => (Object.keys(productsData).length ? Object.entries(productsData)
      .map(([id, product]) => ({ name: product.name, id: Number(id) })) : []),
    [productsData],
  );

  useEffect(() => () => {
    dispatch(clearProductsAction());
  }, []);

  return (
    <>
      <Grid container item columnSpacing={3} xs={12} direction="row" alignItems="end">
        <Grid item xs={4}>
          <AddOrderProductCarrierField itemCount={itemCount} />
        </Grid>
        <Grid item xs={4}>
          <AddOrderProductField
            itemCount={itemCount}
            getProducts={getProducts}
            nonATTCategoriesIds={nonATTCategoriesIds}
          />
        </Grid>
      </Grid>
      <AddOrderProductsList
        products={products}
        itemCount={itemCount}
        title="Selected products"
        isLoading={createProductLoading}
      />
    </>
  );
};
export default AddOrderProductsNonATTFilter;

import React from 'react';
import { IAttachmentInEditMode, Attachment } from 'shared/widgets/Attachments/Attachments.model';
import { Link, TextField } from '@mui/material';

interface IAttachmentPrimaryContentProps {
  attachment: Attachment,
  attachmentInEditMode: IAttachmentInEditMode | null,
  changeAttachmentName: (e: React.SyntheticEvent) => void,
  editAttachmentName: (attachmentId: number) => void,
}
const AttachmentPrimaryContent: React.FC<IAttachmentPrimaryContentProps> = ({
  attachment, attachmentInEditMode, changeAttachmentName, editAttachmentName,
}) => {
  const { id, file, name } = attachment;

  const isAttachmentInEditMode = attachmentInEditMode?.id === id;

  if (isAttachmentInEditMode) {
    return (
      <TextField
        autoFocus
        className="widget-attachments-textfield"
        value={attachmentInEditMode?.name}
        onChange={changeAttachmentName}
        onBlur={() => editAttachmentName(id)}
        color="secondary"
      />
    );
  }

  return (
    <Link
      href={file}
      className="widget-attachments__link"
      download={name}
      target="_blank"
      rel="noopener"
    >
      {name}
    </Link>
  );
};
export default AttachmentPrimaryContent;

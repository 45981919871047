import * as Yup from 'yup';
import { RequestType } from 'pages/InsideSales/model';
import { getOneOfFromType } from 'core/utilities/Yup/YupHelpers';

export const InsideSalesCreateFormSchema = Yup.object().shape({
  supportRequest: Yup
    .string()
    .default('Wireline'),
  companyName: Yup
    .string()
    .required('Customer name is required')
    .default(''),
  requestType: Yup
    .string()
    .oneOf(getOneOfFromType(RequestType))
    .required('Request type is required')
    .default(''),
  sellerNote: Yup
    .string()
    .required('Seller notes are required')
    .default(''),
  attachments: Yup.array()
    .of(Yup.object().shape({
      id: Yup.string(),
      file: Yup.mixed(),
    }))
    .default([]),
});

export default InsideSalesCreateFormSchema;

import React from 'react';
import { MenuItem } from 'react-pro-sidebar';
import { NavLink, useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useAppSelector } from 'core/store';
import includes from 'lodash/includes';
import sidebarConfig from 'layouts/Sidebar/config';

const SidebarMenuItems = () => {
  const userProfileData = useAppSelector((state) => state.userProfile.data);
  const location = useLocation();

  const filteredConfig = sidebarConfig.filter(
    ({ accessName }) => userProfileData.authorizedModules?.some((item) => item.name === accessName),
  );

  return (
    <>
      {filteredConfig.map(
        ({
          icon, path, text, id,
        }) => {
          const isActive = includes(location.pathname, path);

          return (
            <MenuItem
              key={id}
              icon={icon}
              active={isActive}
              component={<NavLink to={path} />}
              className="sidebar__link"
            >
              <Typography
                className="sidebar__link-name"
                variant="body1"
                component="span"
              >
                {text}
              </Typography>
            </MenuItem>
          );
        },
      )}
    </>
  );
};

export default SidebarMenuItems;

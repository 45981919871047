import React from 'react';
import { Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import paths from 'core/routing/paths';
import { useWirelineEngagementsCreateContext } from 'pages/WirelineEngagements/WirelineEngagementsCreate/WirelineEngagementsCreateContext';

const WirelineEngagementsCreateBackButton = () => {
  const { isInitializing } = useWirelineEngagementsCreateContext();

  const navigate = useNavigate();

  const onGoBack = () => {
    navigate(paths.wirelineEngagementsList);
  };

  if (isInitializing) {
    return null;
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      mt={2}
      xs={12}
    >
      <Grid
        item
      >
        <Button
          onClick={onGoBack}
          variant="outlined"
        >
          Back to engagements
        </Button>
      </Grid>
    </Grid>
  );
};

export default WirelineEngagementsCreateBackButton;

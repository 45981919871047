import React from 'react';

import { useAppSelector } from 'core/store';
import EngagementsTotalsChart from '@dashboardWirelineDepartments/shared/EngagementsTotalsChart';

const TOEngagementsTotalsChart = () => {
  const engagementsTotalOrders = useAppSelector(
    (state) => state.dashboard.engagementsTOTotals.data,
  );
  const isLoading = useAppSelector((state) => state.dashboard.engagementsTOTotals.isLoading);

  return (
    <EngagementsTotalsChart
      isLoading={isLoading}
      list={engagementsTotalOrders}
    />
  );
};

export default TOEngagementsTotalsChart;

import { useAppSelector } from 'core/store';
// eslint-disable-next-line no-unused-vars
import { UserTeamRoleTeamDepartment } from 'pages/UserManagement/UserManagement.models';

const useIsTeamRolesDepartmentMember = (department: UserTeamRoleTeamDepartment) => {
  const userProfileData = useAppSelector((state) => state.userProfile.data);

  const isDepartmentMember = userProfileData.teamRoles.some(
    (teamRole) => (
      teamRole.team.department === department
    ),
  );

  return Boolean(isDepartmentMember);
};

export default useIsTeamRolesDepartmentMember;

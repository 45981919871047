import React from 'react';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';
import { Typography, Grid } from '@mui/material';
import EditSaveControls from 'shared/EditSaveControls';
import AddOrderButtonBlock from '@EngagementsForm/AddOrderButtonBlock';
import { useEngagementsFormPermission } from '@EngagementsForm/useEngagementsFormPermission';
import { useWirelineEngagementsCreateContext } from 'pages/WirelineEngagements/WirelineEngagementsCreate/WirelineEngagementsCreateContext';
import EngagementsFormGeneralEngagement from '@EngagementsForm/EngagementsFormGeneral/EngagementsFormGeneralEngagement';
import EngagementsFormGeneralCustomerInformation from '@EngagementsForm/EngagementsFormGeneral/EngagementsFormGeneralCustomerInformation';

const EngagementsFormGeneral = () => {
  const { wirelineEngagementId } = useParams();
  const { submitForm, resetForm } = useFormikContext();
  const {
    isEditModeGeneral, setIsEditModeGeneral,
  } = useWirelineEngagementsCreateContext();

  const {
    isAvailableAddOrder,
    isAvailableEditEngagement,
    isAvailableCreateEngagement,
  } = useEngagementsFormPermission();

  const isCreateMode = !wirelineEngagementId;

  const isVisibleActionButton = (isCreateMode && isAvailableCreateEngagement)
  || (!isCreateMode && isAvailableEditEngagement);

  const isVisibleAddOrderButton = !isCreateMode && isAvailableAddOrder;

  return (
    <Grid
      className="engagements-forms__section"
      container
      direction="row"
      columnSpacing={5}
    >
      <Grid
        item
        container
        xs={12}
        pb={3}
      >
        <Typography
          className="engagements-forms__section-title"
          variant="body1"
          component="p"
        >
          Customer Information
        </Typography>
        {isVisibleActionButton && (
          <EditSaveControls
            onSave={submitForm}
            onCancelEdit={resetForm}
            isCreateMode={isCreateMode}
            isEditMode={isEditModeGeneral}
            setIsEditMode={setIsEditModeGeneral}
          />
        )}
      </Grid>
      <EngagementsFormGeneralEngagement />
      <EngagementsFormGeneralCustomerInformation />
      {isVisibleAddOrderButton && <AddOrderButtonBlock />}
    </Grid>
  );
};

export default EngagementsFormGeneral;

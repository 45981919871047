import React, { useCallback, useMemo } from 'react';
import { useField } from 'formik';
import { debounce } from 'lodash';
import { debounceTime } from 'core/constants';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useAppDispatch, useAppSelector } from 'core/store';
// eslint-disable-next-line no-unused-vars
import { GRID_DIRECTION, IOption } from 'shared/FormikField/FormikField.model';
import FormikAutocompleteField from 'shared/FormikField/FormikAutocompleteField';
import { fields } from 'pages/WirelineEngagements/shared/AddOrderModalForm/constants';
import { getCarriersAction, clearCarriersAction } from 'core/actions/Sales/GetCarriers';
import { getOptionsList } from 'pages/WirelineEngagements/shared/AddOrderModalForm/utilites';

interface IAddOrderCarrierField {
  itemCount: number
}

const AddOrderCarrierField: React.FC<IAddOrderCarrierField> = ({ itemCount }) => {
  const dispatch = useAppDispatch();
  const { showErrorNotifier } = useNotifiers();

  const fieldName = `${fields.orders}[${itemCount}][${fields.carrier}]`;

  const [{ value }, , { setValue }] = useField(fieldName);

  const carriers = useAppSelector((state) => state.wirelineEngagements.carriers.data);
  const isLoading = useAppSelector((state) => state.wirelineEngagements.carriers.isLoading);

  const carriersOptions = useMemo(
    () => getOptionsList({ list: carriers, valueKey: 'name' }),
    [carriers]);

  const loadOptions = useCallback((carrierName) => {
    dispatch(getCarriersAction({ name: carrierName }))
      .unwrap()
      .catch((e) => {
        showErrorNotifier('Failed to load carriers', e);
      });
  }, []);

  const loadOptionsDebounce = useCallback(
    debounce(loadOptions, debounceTime),
    [loadOptions],
  );

  const onInputChange = (_, newInputValue) => {
    setValue(newInputValue);
    dispatch(clearCarriersAction());
    if (newInputValue) loadOptionsDebounce(newInputValue);
  };

  return (
    <FormikAutocompleteField
      freeSolo
      label="Carrier"
      inputValue={value}
      fieldName={fieldName}
      isLoading={isLoading}
      options={carriersOptions}
      onInputChange={onInputChange}
      gridDirection={GRID_DIRECTION.COLUMN}
    />
  );
};

export default AddOrderCarrierField;

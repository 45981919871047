import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useAppSelector } from 'core/store';
// eslint-disable-next-line no-unused-vars
import { SelectChangeEvent } from '@mui/material';
import FormikSelectField from 'shared/FormikField/FormikSelectField';
// eslint-disable-next-line no-unused-vars
import { GRID_DIRECTION } from 'shared/FormikField/FormikField.model';
import { fields } from 'pages/WirelineEngagements/shared/AddOrderModalForm/constants';
import { TAddOrderType, ADD_ORDER_TYPE } from 'pages/WirelineEngagements/WirelineEngagements.models';
import { IFormikProductValues } from 'pages/WirelineEngagements/shared/AddOrderModalForm/product.model';
import { getOptionsList, checkIfSelectedProducts } from 'pages/WirelineEngagements/shared/AddOrderModalForm/utilites';
import { orderDefaultData } from 'pages/WirelineEngagements/shared/AddOrderModalForm/EngagementsOrderForm/EngagementsOrderFormSchema';

interface IAddOrderTSDField {
  itemCount: number
  attTSDId: number | undefined
  orderModalType: TAddOrderType
}

const AddOrderTSDField: React.FC<IAddOrderTSDField> = ({
  itemCount, attTSDId, orderModalType,
}) => {
  const { setValues, values, setTouched } = useFormikContext<IFormikProductValues>();
  const TSDList = useAppSelector((state) => state.wirelineEngagements.TSDs.data);

  const TSDListsByType = useMemo(() => {
    if (orderModalType === ADD_ORDER_TYPE.RENEWAL_ORDER) {
      return TSDList.filter((item) => item.id === attTSDId);
    }

    return TSDList;
  }, [TSDList, attTSDId]);

  const TSDOptions = useMemo(() => getOptionsList({
    list: TSDListsByType,
  }),
  [TSDListsByType]);

  const onChangeHandler = (event: SelectChangeEvent<any>) => {
    const newTSD = event?.target?.value;
    const currentTSDData = values.orders[itemCount];

    const hasCurrentTSDDataProducts = checkIfSelectedProducts(currentTSDData.products);
    const alreadyExistTSD = values.orders.find((item) => item.tsdId === newTSD);

    const updatedExistTSDsData = hasCurrentTSDDataProducts
      ? values.orders
      : values.orders.filter((item) => item.tsdId !== currentTSDData.tsdId);

    const newTSDsData = alreadyExistTSD
      ? updatedExistTSDsData
      : [...updatedExistTSDsData, { ...structuredClone(orderDefaultData), [fields.tsdId]: newTSD }];

    setValues({
      currentTSD: newTSD,
      orders: newTSDsData,
    }, true);
    setTouched({});
  };

  return (
    <FormikSelectField
      required
      label="TSD"
      options={TSDOptions}
      onChange={onChangeHandler}
      fieldName={fields.currentTSD}
      gridDirection={GRID_DIRECTION.COLUMN}
    />
  );
};

export default AddOrderTSDField;

import React from 'react';
import { Tooltip } from '@mui/material';
import { TooltipPlacement } from 'shared/CustomTooltip/customTooltip.models';

interface IFormikTextFieldTooltipProps {
  title: string
  children: React.ReactElement
  isHidden?: boolean
  placement?: TooltipPlacement;
}

const CustomTooltip : React.FC<
  IFormikTextFieldTooltipProps
> = ({
  children, title, isHidden, placement,
}) => {
  if (isHidden) {
    return children;
  }

  const popperSxTop = placement === TooltipPlacement.BOTTOM && {
    top: ' -5px !important',
  };

  return (
    <Tooltip
      title={title}
      arrow
      placement={placement as TooltipPlacement}
      PopperProps={{
        sx: { ...popperSxTop },
      }}
    >
      <div>
        {children}
      </div>
    </Tooltip>
  );
};

CustomTooltip.defaultProps = {
  placement: TooltipPlacement.BOTTOM,
  isHidden: false,
};

export default CustomTooltip;

import React from 'react';
import classNames from 'classnames';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Button } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { maxAttachmentFilesAmount, maxAttachmentFileSize } from 'core/constants';

interface IDropzoneProps {
  onDrop: (acceptedFiles: File[]) => void
  isAddButton?: boolean
}

const Dropzone: React.FC<
  IDropzoneProps
> = ({ onDrop, isAddButton }) => {
  const {
    getRootProps, getInputProps, open, isDragActive, isDragAccept, isDragReject,
  } = useDropzone({
    noKeyboard: true,
    maxSize: maxAttachmentFileSize,
    maxFiles: maxAttachmentFilesAmount,
    onDrop,
  });

  const getClassNames = () => classNames('widget-attachments__dropzone', {
    reject: isDragReject,
    accept: isDragAccept,
    active: isDragActive,
  });

  return (
    <Box component="form">
      <section className="widget-attachments__dropzone-wrap">
        <div className={getClassNames()} {...getRootProps()}>
          <input data-testid="dropzone" {...getInputProps()} />
          <CloudUploadIcon fontSize="large" />
          <p className="widget-attachments__dropzone-text">
            Drag and drop files here to upload
          </p>
        </div>
        {isAddButton && (
          <Button className="widget-attachments__dropzone-btn" onClick={open}>
            Add attachment
          </Button>
        )}
      </section>
    </Box>
  );
};

Dropzone.defaultProps = {
  isAddButton: true,
};

export default Dropzone;

import React from 'react';
import { useAppSelector } from 'core/store';
import Table from 'shared/Table';

const RetentionBookingTable = () => {
  const {
    isLoading, count, results,
  } = useAppSelector((state) => state.dashboard.retentionBookingData);

  return (
    <Table
      isLoading={isLoading}
      rowCount={count}
      rows={results}
      showGlobalFilter={false}
      showColumnFilters={false}
      showToolbar={false}
    />
  );
};

export default RetentionBookingTable;

import styles from 'assets/styles/_resources.scss';
import { alpha } from '@mui/material';

const styleActive = {
  borderLeftWidth: 2,
  borderLeftStyle: 'solid',
  borderLeftColor: `${styles.primaryColor} !important`,
};

const menuItemBackgroundColor = alpha(styles.secondaryColor, 0.15);

export const menuItemStyles = {
  button: ({ active }) => (
    active ? {
      ...styleActive,
      backgroundColor: menuItemBackgroundColor,
      '&:hover': {
        backgroundColor: menuItemBackgroundColor,
      },
      '& path': {
        color: styles.whiteColor,
      },
    } : {
      borderLeftWidth: 2,
      borderLeftStyle: 'solid',
      borderLeftColor: 'transparent',
      fontWeight: 700,
      '&:hover': {
        backgroundColor: menuItemBackgroundColor,
        '&:hover path': {
          color: styles.whiteColor,
        },
      },
    }),
};

export default { menuItemStyles };

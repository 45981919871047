import React from 'react';
import { InputAdornment } from '@mui/material';
import { AssignmentReturnedOutlined } from '@mui/icons-material';
import AddButton from 'shared/AddButton';
import styles from 'assets/styles/_resources.scss';

interface ILocationsListButtonsProps {
    goToCreateLocation: ()=> void;
    addLocationFile: () => void;
}

const LocationsListButtons: React.FC<ILocationsListButtonsProps> = (
  { goToCreateLocation, addLocationFile },
) => (
  <div className="location-list__btn-wrap">
    <AddButton
      className="location-list__btn location-list__btn-add"
      color={styles.secondaryColor50}
      onClick={goToCreateLocation}
    >
      Add location
    </AddButton>
    <div>
      <input
        accept=".xlsx"
        id="contained-button-file"
        style={{ display: 'none' }}
        multiple
        value=""
        type="file"
        onChange={addLocationFile}
        data-testid="button-file"
      />
      <label htmlFor="contained-button-file">
        <AddButton
          className="location-list__btn location-list__btn-add"
          fullWidth
          variant="contained"
          component="span"
          color={styles.secondaryColor50}
        >
          <InputAdornment position="start">
            <AssignmentReturnedOutlined />
          </InputAdornment>
          Bulk update
        </AddButton>
      </label>
    </div>
  </div>
);

export default LocationsListButtons;

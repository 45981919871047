import {
  CREATE_CUSTOMERS_ATTACHMENT_FAILURE,
  CREATE_CUSTOMERS_ATTACHMENT_REQUEST,
  CREATE_CUSTOMERS_ATTACHMENT_SUCCESS,
} from 'pages/Customers/CustomersAttachments/CreateCustomersAttachment/actionTypes';

export const createCustomersAttachmentRequest = () => ({
  type: CREATE_CUSTOMERS_ATTACHMENT_REQUEST,
});

export const createCustomersAttachmentSuccess = (newAttachment) => ({
  type: CREATE_CUSTOMERS_ATTACHMENT_SUCCESS,
  newAttachment,
});

export const createCustomersAttachmentFailure = (error) => ({
  type: CREATE_CUSTOMERS_ATTACHMENT_FAILURE,
  error,
});

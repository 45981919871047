import {
  CREATE_CUSTOMERS_DRAFT_REQUEST,
  CREATE_CUSTOMERS_DRAFT_SUCCESS,
  CREATE_CUSTOMERS_DRAFT_FAILURE,
} from './actionTypes';

const initialState = {
  isLoading: true,
  draftFields: {},
};

const createCustomersDraftReducer = (state = initialState, {
  type, draftFields, error,
}) => {
  if (type === CREATE_CUSTOMERS_DRAFT_REQUEST) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (type === CREATE_CUSTOMERS_DRAFT_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      draftFields: {
        ...draftFields,
      },
    };
  }

  if (type === CREATE_CUSTOMERS_DRAFT_FAILURE) {
    return {
      ...state,
      isLoading: false,
      error,
    };
  }

  return state;
};

export default createCustomersDraftReducer;

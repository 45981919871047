import {
  GET_ENGAGEMENTS_LIST_REQUEST,
  GET_ENGAGEMENTS_LIST_SUCCESS,
  GET_ENGAGEMENTS_LIST_FAILURE,
} from './actionsTypes';

export const getEngagementsLisRequest = () => ({ type: GET_ENGAGEMENTS_LIST_REQUEST });
export const getEngagementsLisSuccess = (data) => ({
  type: GET_ENGAGEMENTS_LIST_SUCCESS,
  data,
});
export const getEngagementsLisFailure = (error) => ({
  type: GET_ENGAGEMENTS_LIST_FAILURE,
  error,
});

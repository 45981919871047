import React from 'react';
import { List } from '@mui/material';
import { IBulkError } from 'shared/widgets/Locations/Locations.models';
import ErrorItem from 'shared/widgets/Locations/LocationsList/BulkErrorsDisplay/ErrorList/ErrorItem';

interface ErrorListProps {
    bulkErrors: IBulkError[] | { error: string } | null;
}

const ErrorList: React.FC<ErrorListProps> = ({ bulkErrors }) => {
  if (!bulkErrors) {
    return null;
  }

  const errorsToRender = Array.isArray(bulkErrors)
    ? bulkErrors
    : [{ error: bulkErrors.error as string }];

  return (
    <List data-testid="error-list" className="bulk-errors-display">
      {errorsToRender.map((error, index) => (
        <ErrorItem
          error={error}
          key={`${error.rowNumber || index}`}
        />
      ))}
    </List>
  );
};

export default ErrorList;

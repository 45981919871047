import React from 'react';
import PinDropIcon from '@mui/icons-material/PinDrop';
import NoData from 'shared/NoData';

const LocationsEmpty = () => (
  <NoData
    icon={<PinDropIcon fontSize="large" />}
    description="Please add location using buttons below."
    title="No locations yet."
  />
);

export default LocationsEmpty;

import {
  CREATE_CUSTOMER_FROM_DRAFT_REQUEST,
  CREATE_CUSTOMER_FROM_DRAFT_SUCCESS,
  CREATE_CUSTOMER_FROM_DRAFT_FAILURE,
} from './actionTypes';

const initialState = {
  isLoading: false,
};

const createCustomerFromDraftReducer = (state = initialState, {
  type, error,
}) => {
  if (type === CREATE_CUSTOMER_FROM_DRAFT_REQUEST) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (type === CREATE_CUSTOMER_FROM_DRAFT_SUCCESS) {
    return {
      ...state,
      isLoading: false,
    };
  }

  if (type === CREATE_CUSTOMER_FROM_DRAFT_FAILURE) {
    return {
      ...state,
      isLoading: false,
      error,
    };
  }

  return state;
};

export default createCustomerFromDraftReducer;

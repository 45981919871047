import React, { useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import { IOption } from 'shared/FormikField/FormikField.model';
import { getRenewalPeriod } from './utilities';
import {
  // eslint-disable-next-line no-unused-vars
  RenewalPeriod,
  RenewalPeriodFilter,
} from './RenewalPeriodAutocomplete.models';

interface IRenewalAutocompleteProps {
    onChange: (period: RenewalPeriod)=> void;
    options: IOption<RenewalPeriodFilter>[];
    defaultValue: IOption<RenewalPeriodFilter>;
}

const RenewalPeriodAutocomplete: React.FC<IRenewalAutocompleteProps> = (
  { onChange, options, defaultValue },
) => {
  const [value, setValue] = useState(defaultValue);

  const onChangeRenewal = (event, newValue) => {
    setValue(newValue);

    const { daysToRenewalFrom, daysToRenewalTo } = getRenewalPeriod(newValue.value);

    onChange({ daysToRenewalFrom, daysToRenewalTo });
  };

  const renderInput = (params) => (
    <TextField
      {...params}
      label="Renewal Period"
      variant="outlined"
      fullWidth
      size="small"
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <EventIcon color="action" />
        ),
      }}
    />
  );

  return (
    <Autocomplete
      value={value}
      onChange={onChangeRenewal}
      options={options}
      disableClearable
      renderInput={renderInput}
      data-testid="renewal-autocomplete"
      sx={{
        width: 158,
        '.MuiAutocomplete-input': {
          height: '10px',
        },
      }}
    />
  );
};

export default RenewalPeriodAutocomplete;

import React from 'react';
import {
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import { FilePresentOutlined } from '@mui/icons-material';
import AttachmentSecondaryContent from 'shared/widgets/Attachments/AttachmentList/AttachmentListItem/AttachmentSecondaryContent';
import AttachmentPrimaryContent from 'shared/widgets/Attachments/AttachmentList/AttachmentListItem/AttachmentPrimaryContent';
import { IAttachmentInEditMode, Attachment } from 'shared/widgets/Attachments/Attachments.model';
import AttachmentListItemButtons from 'shared/widgets/Attachments/AttachmentList/AttachmentListItem/AttachmentListItemButtons';

interface IAttachmentListItemProps {
  attachment: Attachment
  attachmentInEditMode: IAttachmentInEditMode | null,
  focusAttachment: (attachment: Attachment) => void
  changeAttachmentName: (e: React.SyntheticEvent) => void,
  editAttachmentName: (attachmentId: number) => void,
  removeAttachment: (attachmentId: number) => void
  isViewMode: boolean
}

const AttachmentListItem: React.FC<IAttachmentListItemProps> = ({
  attachment,
  attachmentInEditMode,
  focusAttachment,
  changeAttachmentName,
  editAttachmentName,
  removeAttachment,
  isViewMode,
}) => {
  const { id } = attachment;

  return (
    <ListItem alignItems="flex-start" disableGutters dense key={id}>
      <ListItemAvatar>
        <FilePresentOutlined color="action" fontSize="large" />
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={(
          <AttachmentPrimaryContent
            attachment={attachment}
            attachmentInEditMode={attachmentInEditMode}
            changeAttachmentName={changeAttachmentName}
            editAttachmentName={editAttachmentName}
          />
        )}
        secondary={<AttachmentSecondaryContent attachment={attachment} />}
      />
      {!isViewMode && (
        <ListItemSecondaryAction>
          <AttachmentListItemButtons
            attachment={attachment}
            isEditMode={!!attachmentInEditMode}
            focusAttachment={focusAttachment}
            removeAttachment={removeAttachment}
          />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default AttachmentListItem;

import React from 'react';
import { Tooltip } from '@mui/material';
import KeycloakService from 'core/services/KeycloakService';

// @ts-ignore
import defaultImg from './img/avatar-placeholder.png';

const UserAccount = () => {
  const username = KeycloakService.getUsername();

  return (
    <div className="user-account">
      <div className="user-account__avatar-wrap">
        <Tooltip
          title={username}
          arrow
          placement="bottom"
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                padding: ' 10px 20px !important',
                fontSize: '14px !important',
              },
            },
          }}
        >
          <img
            className="user-account__avatar"
            src={defaultImg}
            alt={username}
            width={30}
            height={30}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default UserAccount;

import React from 'react';
import { useField } from 'formik';
import cx from 'classnames';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button, ButtonGroup } from '@mui/material';
import FormikCounterFieldWrapper from 'shared/FormikField/FormikCounterField/FormikCounterFieldWrapper';
import Show from 'shared/Show';

import './FormikCounterField.scss';

interface IFormikCounterFieldProps {
  label: string
  fieldName: string
  disabled?: boolean
}

const FormikCounterField: React.FC<
  IFormikCounterFieldProps
> = ({ label, fieldName, disabled }) => {
  const [field, , { setValue }] = useField(fieldName);

  const onIncrement = () => {
    if (!field?.value?.quantity) {
      setValue({ name: label, quantity: 1 });
      return;
    }
    setValue({ name: label, quantity: field.value.quantity + 1 });
  };

  const onDecrement = () => {
    setValue({ name: label, quantity: field.value.quantity - 1 });
  };

  const displayCounter = field?.value?.quantity > 0;

  return (
    <FormikCounterFieldWrapper
      fieldName={fieldName}
      label={label}
    >
      <ButtonGroup
        className="formik-counter-field__button-group"
        size="small"
        disabled={disabled as boolean}
      >
        <Button
          className={cx(displayCounter && 'hide-right-border')}
          onClick={onIncrement}
          color="info"
        >
          <AddIcon fontSize="small" />
        </Button>
        <Show on={displayCounter}>
          <Button
            className="hide-left-border hider-right-border transition"
            color="info"
            disabled
          >
            {field?.value?.quantity}
          </Button>
          <Button
            className="hide-left-border transition"
            onClick={onDecrement}
            color="info"
          >
            <RemoveIcon fontSize="small" />
          </Button>
        </Show>
      </ButtonGroup>
    </FormikCounterFieldWrapper>
  );
};

FormikCounterField.defaultProps = {
  disabled: false,
};

export default FormikCounterField;

// eslint-disable-next-line no-unused-vars
import React, { FC } from 'react';
import cx from 'classnames';
import CustomCircularProgress from 'shared/CustomCircularProgress';

import './Loader.scss';

interface ILoaderProps {
  size?: number
  type?: 'component' | 'icon'
  className?: string
}

const Loader: FC<ILoaderProps> = ({ size, type, className }) => (
  <div
    className={cx({
      'custom-loader': type === 'component',
      'custom-loader-icon': type === 'icon',
    }, className)}
    data-testid="preloader"
  >
    <CustomCircularProgress
      size={size}
      thickness={2}
    />
  </div>
);

Loader.defaultProps = {
  size: 73,
  type: 'component',
  className: '',
};

export default Loader;

// eslint-disable-next-line no-unused-vars
import React, { FC } from 'react';
import { Grid } from '@mui/material';
import FormikTextField from 'shared/FormikField/FormikTextField/FormikTextField';
import FormikMobileMaskField from 'shared/FormikField/FormikMobileMaskField/FormikMobileMaskField';
import FormSection from './FormSection/FormSection';

interface ICustomersCreateFormProfileProps {
  title: string
  sectionFieldName: string
}

const CustomersCreateFormProfile: FC<ICustomersCreateFormProfileProps> = (
  { title, sectionFieldName },
) => (
  <FormSection title={title}>
    <Grid
      container
      item
      rowSpacing={3}
    >
      <FormikTextField
        label="First name"
        fieldName={`${sectionFieldName}.firstName`}
        fullWidth
      />
      <FormikTextField
        label="Last name"
        fieldName={`${sectionFieldName}.lastName`}
        fullWidth
      />
      <FormikTextField
        label="Title"
        fieldName={`${sectionFieldName}.title`}
        fullWidth
      />
      <FormikTextField
        label="Email"
        fieldName={`${sectionFieldName}.email`}
        fullWidth
      />
      <FormikMobileMaskField
        label="Phone"
        fieldName={`${sectionFieldName}.phoneNumber`}
        fullWidth
      />
    </Grid>
  </FormSection>
);

export default CustomersCreateFormProfile;

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import styles from 'assets/styles/_resources.scss';

const AddButton = ({ color, children, ...props }) => {
  const stylesButton = {
    backgroundColor: `${color} !important`,
    color: `${styles.darkColor500} !important`,
    fontSize: '12px',
    fontWeight: '600',
    letterSpacing: '.04em',
    textTransform: 'uppercase',
    boxShadow: 'none !important',
    '&:hover': {
      backgroundColor: `${styles.secondaryColor100} !important`,
      boxShadow: `${styles.boxShadow4} !important`,
    },
  };
  return (
    <Button className="add-button" variant="contained" sx={{ ...stylesButton }} {...props}>
      {children}
    </Button>
  );
};
AddButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  color: PropTypes.string,
};

AddButton.defaultProps = {
  color: styles.secondaryColor50,
};

export default AddButton;

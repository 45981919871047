import {
  CREATE_ENGAGEMENTS_COMMENT_REQUEST,
  CREATE_ENGAGEMENTS_COMMENT_SUCCESS,
  CREATE_ENGAGEMENTS_COMMENT_FAILURE,
} from 'pages/WirelineEngagements/WirelineEngagementsComments/CreateComment/actionTypes';

export const createCommentRequest = () => ({ type: CREATE_ENGAGEMENTS_COMMENT_REQUEST });

export const createCommentSuccess = (newComment) => (
  { type: CREATE_ENGAGEMENTS_COMMENT_SUCCESS, newComment }
);

export const createCommentFailure = (error) => (
  { type: CREATE_ENGAGEMENTS_COMMENT_FAILURE, error }
);

import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Box, Tab, Tabs } from '@mui/material';
import { IRoutingConfigItem } from 'core/routing/models';

import './TabsRouting.scss';

interface ITabsRoutingProps {
    routes: IRoutingConfigItem[]
}

const TabsRouting: React.FC<ITabsRoutingProps> = ({ routes }) => {
  const { pathname } = useLocation();
  const value = routes.find(({ path }) => pathname.includes(path))?.path;

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs
        className="routing-tabs"
        value={value}
      >
        {routes.map((
          { path, label },
        ) => (
          <Tab
            key={path}
            className="routing-tabs-tab"
            value={path}
            to={path}
            component={NavLink}
            label={label}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default TabsRouting;

import { IEngagementsTotalItem } from 'pages/Dashboard/Dashboard.models';

import {
  GET_ENGAGEMENTS_TOTAL_ORDERS_REQUEST,
  GET_ENGAGEMENTS_TOTAL_ORDERS_SUCCESS,
  GET_ENGAGEMENTS_TOTAL_ORDERS_FAILURE,
} from './actionsTypes';

interface IGetEngagementsTotalOrdersState {
  data: IEngagementsTotalItem[];
  isLoading: boolean;
  error: object;
}

const initialState: IGetEngagementsTotalOrdersState = {
  isLoading: false,
  data: [],
  error: {},
};

const getEngagementsTotalOrdersReducer = (state = initialState, {
  type, data, error,
}) => {
  if (type === GET_ENGAGEMENTS_TOTAL_ORDERS_REQUEST) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (type === GET_ENGAGEMENTS_TOTAL_ORDERS_SUCCESS) {
    return {
      ...state,
      data,
      isLoading: false,
    };
  }

  if (type === GET_ENGAGEMENTS_TOTAL_ORDERS_FAILURE) {
    return {
      ...state,
      isLoading: false,
      data: [],
      error,
    };
  }

  return state;
};
export default getEngagementsTotalOrdersReducer;

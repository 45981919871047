import React from 'react';
import { useInsideSalesDepartmentContext } from '@dashboardWirelineInsideSalesDepartment/InsideSalesDepartmentContext';
import FiltersByPeriod from 'pages/Dashboard/shared/FiltersByPeriod';

const InsideSalesFiltersByPeriod = () => {
  const {
    getDashboardData,
    period,
  } = useInsideSalesDepartmentContext();

  return (
    <FiltersByPeriod
      period={period}
      onChange={getDashboardData}
    />
  );
};

export default InsideSalesFiltersByPeriod;

import NoData from 'shared/NoData';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import React from 'react';

export const monthNames = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
];

export const chartNoData = (
  <NoData
    icon={<BarChartRoundedIcon fontSize="large" color="primary" />}
  />
);

export const others = 'Others';

export const cursorColor = 'rgba(247,247,247,0.5)';

import React, { useEffect } from 'react';
import Attachments from 'shared/widgets/Attachments';
import { HTTPService } from 'core/services';
import { useAppDispatch, useAppSelector } from 'core/store';
// eslint-disable-next-line no-unused-vars
import { IDraftAttachment } from 'shared/widgets/Attachments/Attachments.model';
import getCustomerAttachmentsAction from 'pages/Customers/CustomersAttachments/GetCustomersAttachments/actions';
import createCustomersAttachmentAction from 'pages/Customers/CustomersAttachments/CreateCustomersAttachment/actions';
import removeCustomersAttachmentAction from 'pages/Customers/CustomersAttachments/RemoveCustomersAttachment/actions';
import editCustomersAttachmentNameAction from 'pages/Customers/CustomersAttachments/EditCustomersAttachmentName/actions';
import clearAttachmentsErrorsAction from 'pages/Customers/CustomersAttachments/ClearErrorsAttachments/actions';
import { customersAttachmentsConfig } from 'pages/Customers/CustomersAttachments//constants';

let customersAttachmentsController = new AbortController();

interface ICustomersAttachmentsProps {
  customerId: number
}

const CustomersAttachments: React.FC<ICustomersAttachmentsProps> = ({ customerId }) => {
  const dispatch = useAppDispatch();
  const attachments = useAppSelector(
    (state) => state.customers.attachments.data.results,
  );
  const isLoading = useAppSelector((state) => state.customers.attachments.isLoading);

  const cancelGetCustomerAttachmentsRequest = () => {
    HTTPService.cancelRequest(customersAttachmentsController);
  };

  const clearCustomerAttachmentsErrors = () => {
    dispatch(clearAttachmentsErrorsAction(attachments));
  };

  const getCustomerAttachments = () => {
    cancelGetCustomerAttachmentsRequest();
    customersAttachmentsController = HTTPService.getController();

    dispatch(getCustomerAttachmentsAction(customersAttachmentsController, customerId));
  };

  const editAttachment = (attachmentId: string | number, newAttachment: IDraftAttachment) => {
    dispatch(editCustomersAttachmentNameAction(customerId, attachmentId, newAttachment));
  };

  const createAttachment = (attachmentFile) => {
    dispatch(createCustomersAttachmentAction(customerId, attachmentFile));
  };

  const removeAttachment = (attachmentId) => {
    dispatch(removeCustomersAttachmentAction(customerId, attachmentId));
  };

  useEffect(() => {
    getCustomerAttachments();

    return () => cancelGetCustomerAttachmentsRequest();
  }, []);

  return (
    <Attachments
      attachments={attachments}
      isLoading={isLoading}
      editAttachment={editAttachment}
      createAttachment={createAttachment}
      removeAttachment={removeAttachment}
      clearAttachmentsErrors={clearCustomerAttachmentsErrors}
      config={customersAttachmentsConfig}
    />
  );
};

export default CustomersAttachments;

import { netsparkAxios } from 'core/services/HTTPService';
import { getEscalationSupportEscalationTypeUrl } from 'pages/Dashboard/api/apiUrls';
// eslint-disable-next-line no-unused-vars
import { TPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';
import { IEscalationSupportTypeItem } from '@dashboardEscalationSupport/EscalationSupportCharts/models';

const getEscalationSupportEscalationType = (signal: AbortSignal, params: TPeriod) => netsparkAxios
  .get<IEscalationSupportTypeItem[]>(getEscalationSupportEscalationTypeUrl, { signal, params });

export default getEscalationSupportEscalationType;

import React from 'react';
import { List } from '@mui/material';
import LocationsEmpty from 'shared/widgets/Locations/LocationsList/LocationsEmpty';
import LocationItem from 'shared/widgets/Locations/LocationsList/LocationItem';
import { ICustomerLocation } from 'pages/Customers/Customers.models';

interface ILocationsListViewProps {
  locations: ICustomerLocation[];
  goToEditLocation:(showLocation: ICustomerLocation)=> void;
  onRemoveLocation:(id) => void;
}

const LocationsListView: React.FC<ILocationsListViewProps> = (
  { locations, goToEditLocation, onRemoveLocation },
) => {
  const hasLocations = locations && locations.length > 0;

  if (!hasLocations) {
    return <LocationsEmpty />;
  }

  return (
    <div className="location-list__wrap">
      {locations.map((location : ICustomerLocation) => (
        <List key={location.id} disablePadding>
          <LocationItem
            location={location}
            onEdit={goToEditLocation}
            onRemove={onRemoveLocation}
          />
        </List>
      )).reverse()}
    </div>
  );
};

export default LocationsListView;

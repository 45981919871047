import React from 'react';
import { Formik } from 'formik';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useAppDispatch, useAppSelector } from 'core/store';
import { useTableContext } from 'shared/Table/TableContext';
import { IServerValidationError } from 'core/services/HTTPService.models';
import { createRequestMessages } from 'pages/InsideSales/InsideSalesList/constants';
// eslint-disable-next-line no-unused-vars
import { FileCategory, IInsideSalesCreateRequestForm, IInsideSalesCreateRequest } from 'pages/InsideSales/model';
import InsideSalesCreateModal from 'pages/InsideSales/InsideSalesList/InsideSalesListTable/InsideSalesListTableModals/InsideSalesCreateModalForm/InsideSalesCreateModal';
import InsideSalesCreateFormSchema from 'pages/InsideSales/InsideSalesList/InsideSalesListTable/InsideSalesListTableModals/InsideSalesCreateModalForm/InsideSalesCreateFormSchema';
import {
  insideSalesCreateRequestAction,
} from 'core/actions/crm/InsideSales/InsideSalesCreateRequest/InsideSalesCreateRequest';
import {
  clearAttachmentsAction,
  createInsideSalesAttachmentAction,
} from 'core/actions/crm/InsideSales/InsideSalesAttachments';

import './InsideSalesCreateModalForm.scss';

interface IInsideSalesCreateModalFormProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const initialValues = InsideSalesCreateFormSchema.getDefault();

const InsideSalesCreateModalForm: React.FC<
  IInsideSalesCreateModalFormProps
> = ({ isOpen, setIsOpen }) => {
  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();
  const { getTableData } = useTableContext();

  const dispatch = useAppDispatch();

  const isCreateRequestLoading = useAppSelector(
    (state) => state.insideSales.createReques.isLoading,
  );
  const isCreateAttachmentstLoading = useAppSelector(
    (state) => state.insideSales.attachmentsList.isLoading,
  );

  const isLoading = isCreateRequestLoading || isCreateAttachmentstLoading;

  const createAttachment = async (id, atachments) => {
    const attachments = atachments.map(({ file }) => {
      const attachmentFile = new FormData();
      attachmentFile.append('file', file);
      attachmentFile.append('category', FileCategory.ATTACHMENT);

      return attachmentFile;
    });

    await Promise.all(attachments.map((attachment) => dispatch(createInsideSalesAttachmentAction({
      insideSalesRequestId: id,
      attachmentFile: attachment,
    })),
    ));
  };

  const onCreateRequest = (params: IInsideSalesCreateRequest) => dispatch(
    insideSalesCreateRequestAction(params),
  ).unwrap();

  const onSubmit = async (params: IInsideSalesCreateRequestForm, { resetForm, setErrors }) => {
    const { attachments, ...createRequestParams } = params;
    try {
      const { id } = await onCreateRequest(createRequestParams);
      await createAttachment(id, attachments);

      getTableData();
      resetForm();
      dispatch(clearAttachmentsAction());
      setIsOpen(false);

      showSuccessNotifier(createRequestMessages.success);
    } catch (error) {
      const definedError = error as IServerValidationError;

      setErrors(definedError?.response?.data);
      showErrorNotifier(createRequestMessages.error, error);
    }
  };

  return (
    <Formik
      initialValues={initialValues as IInsideSalesCreateRequestForm}
      validationSchema={InsideSalesCreateFormSchema}
      onSubmit={onSubmit}
      validateOnBlur
      validateOnChange
    >
      <InsideSalesCreateModal
        isOpen={isOpen}
        isLoading={isLoading}
        setIsOpen={setIsOpen}
      />
    </Formik>
  );
};

export default InsideSalesCreateModalForm;

import React, { useState, useEffect } from 'react';
import ErrorDialog from 'shared/ErrorDialog';
import ErrorList from 'shared/widgets/Locations/LocationsList/BulkErrorsDisplay/ErrorList';
import { IBulkError } from 'shared/widgets/Locations/Locations.models';
import './BulkErrorsDisplay.scss';

interface BulkErrorsProps {
  bulkErrors: IBulkError[] | { error: string } | null;
  clearErrors: ()=> void
}

const BulkErrorsDisplay: React.FC<BulkErrorsProps> = (
  { bulkErrors, clearErrors },
) => {
  const [bulkUpdateErrors, setBulkUpdateErrors] = useState<
      IBulkError[] | { error: string } | null>(bulkErrors);

  useEffect(() => {
    setBulkUpdateErrors(bulkErrors);
  }, [bulkErrors]);

  const onCloseDialog = () => {
    setBulkUpdateErrors(null);
    clearErrors();
  };

  return (
    <ErrorDialog
      onCloseDialog={onCloseDialog}
      isOpen={!!bulkUpdateErrors}
      title="There was an error when loading locations list"
    >
      <ErrorList bulkErrors={bulkUpdateErrors} />
    </ErrorDialog>
  );
};

export default BulkErrorsDisplay;

import React, { useState } from 'react';
import { useAppSelector } from 'core/store';
import Table from 'shared/Table';
import CreateButton from 'shared/CreateButton';
import ContentShrink from 'shared/ContentShrink';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import useInsideSalesListPermissions from 'pages/InsideSales/InsideSalesList/useInsideSalesListPermissions';
import InsideSalesAcceptedButton from 'pages/InsideSales/InsideSalesList/InsideSalesListTable/InsideSalesAcceptedButton';
import InsideSalesListTableModals from 'pages/InsideSales/InsideSalesList/InsideSalesListTable/InsideSalesListTableModals';
import InsideSalesListTableRowActions from 'pages/InsideSales/InsideSalesList/InsideSalesListTable/InsideSalesListTableRowActions';
import {
  colDefOptionSelect,
  defaultColumnPinning,
  colDefOptionActionsTwoIcons,
  colDefOptionActionsSingleIcon,
} from 'shared/Table/constants';

import './InsideSalesListTable.scss';

const InsideSalesListTable = () => {
  const {
    isLoading, count, results,
  } = useAppSelector((state) => state.insideSales.list);

  const {
    isAvailableEditSidebar,
    isAvailableCreateRequest,
    isAvailableCancelRequest,
    isAvailableRequestAccepted,
  } = useInsideSalesListPermissions();

  const { activeRowId, activeRowMode } = useTableActiveRowContext();

  const { isEditMode, isViewMode } = getTableRowModeBoolean(activeRowMode);

  const [isCreateRequestModalOpen, setIsCreateRequestModalOpen] = useState(false);
  const [isInsideSalesAcceptedModalOpen, setIsInsideSalesAcceptedModalOpen] = useState(false);

  const onOpenCreateRequestModal = () => setIsCreateRequestModalOpen(true);

  const createRequestAction = isAvailableCreateRequest
    ? (
      <CreateButton
        text="create request"
        disabled={!!activeRowId}
        onClick={onOpenCreateRequestModal}
        className="inside-sales__create-request-button"
      />
    )
    : undefined;

  const actionsButton = isAvailableRequestAccepted
    ? (
      <InsideSalesAcceptedButton
        setIsInsideSalesAcceptedModalOpen={setIsInsideSalesAcceptedModalOpen}
      />
    )
    : undefined;

  const isSingleAction = isAvailableEditSidebar || isAvailableCancelRequest;
  const isTwoActions = isAvailableEditSidebar && isAvailableCancelRequest;

  return (
    <ContentShrink
      isShrinked={isEditMode || isViewMode}
    >
      <Table
        className="inside-sales-table-wrapper"
        isLoading={isLoading}
        rowCount={count}
        rows={results}
        toolBarAction={createRequestAction}
        showColumnFilters
        showGlobalFilter
        actionsButton={actionsButton}
        renderRowActions={InsideSalesListTableRowActions}
        initialColumnPinning={defaultColumnPinning}
        displayColumnDefOptions={{
          ...(isAvailableRequestAccepted ? colDefOptionSelect : undefined),
          ...(isSingleAction ? colDefOptionActionsSingleIcon : undefined),
          ...(isTwoActions ? colDefOptionActionsTwoIcons : undefined),
        }}
      />
      <InsideSalesListTableModals
        isCreateRequestModalOpen={isCreateRequestModalOpen}
        setIsCreateRequestModalOpen={setIsCreateRequestModalOpen}
        isInsideSalesAcceptedModalOpen={isInsideSalesAcceptedModalOpen}
        setIsInsideSalesAcceptedModalOpen={setIsInsideSalesAcceptedModalOpen}
      />
    </ContentShrink>

  );
};

export default InsideSalesListTable;

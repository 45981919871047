const accessNames = {
  dashboard: 'dashboard',
  wirelineEngagements: 'wireline_engagements',
  mobilityEngagements: 'mobility_engagements',
  insideSalesRequests: 'inside_sales_requests',
  mobilityRequests: 'mobility_requests',
  customerSupportRequests: 'customer_support_requests',
  customers: 'customers',
  userManagement: 'user_management',
  contractManagement: 'contract_management',
};

export default accessNames;

import {
  CREATE_CUSTOMER_FROM_DRAFT_REQUEST,
  CREATE_CUSTOMER_FROM_DRAFT_SUCCESS,
  CREATE_CUSTOMER_FROM_DRAFT_FAILURE,
} from './actionTypes';

export const createCustomerFromDraftRequest = () => ({ type: CREATE_CUSTOMER_FROM_DRAFT_REQUEST });
export const createCustomerFromDraftSuccess = (data) => (
  { type: CREATE_CUSTOMER_FROM_DRAFT_SUCCESS, data }
);
export const createCustomerFromDraftFailure = (error) => ({
  type: CREATE_CUSTOMER_FROM_DRAFT_FAILURE, error,
});

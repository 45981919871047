import { createCustomerAttachment } from 'core/api/customer.api';
import {
  createCustomersAttachmentRequest,
  createCustomersAttachmentSuccess,
  createCustomersAttachmentFailure,
} from './actionsCreators';

const createCustomersAttachmentAction = (
  customerId: number | string,
  attachmentFile: FormData,
) => async (dispatch) => {
  try {
    dispatch(createCustomersAttachmentRequest());
    const { data } = await createCustomerAttachment(customerId, attachmentFile);
    dispatch(createCustomersAttachmentSuccess(data));
  } catch (error) {
    dispatch(createCustomersAttachmentFailure(error));
    throw error;
  }
};

export default createCustomersAttachmentAction;

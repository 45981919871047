import { useActionsPermissions } from 'core/hooks/useActionsPermissions';
import { ACTIONS_CUSTOMERS_PATHS } from 'core/models/actionsPermissions';

const {
  SELLER_EDIT,
  CUSTOMER_EDIT,
  CUSTOMER_CREATE,
} = ACTIONS_CUSTOMERS_PATHS;

const actionsPaths = {
  isAvailableEditCustomer: CUSTOMER_EDIT,
  isAvailableCreateCustomer: CUSTOMER_CREATE,
  isAvailableEditCustomerSeller: SELLER_EDIT,
};

export const useCustomersPermissions = () => {
  const {
    isAvailableEditCustomer,
    isAvailableCreateCustomer,
    isAvailableEditCustomerSeller,
  } = useActionsPermissions(actionsPaths);

  const isAvailableSomeRowAction = isAvailableEditCustomer || isAvailableEditCustomerSeller;
  const isAvailableAllRowActions = isAvailableEditCustomer && isAvailableEditCustomerSeller;

  return {
    isAvailableEditCustomer,
    isAvailableSomeRowAction,
    isAvailableAllRowActions,
    isAvailableCreateCustomer,
    isAvailableEditCustomerSeller,
  };
};

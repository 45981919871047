/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// eslint-disable-next-line no-unused-vars
import { IProductCategory, IProductCategoriesParams } from 'pages/WirelineEngagements/shared/AddOrderModalForm/product.model';
import { getProductCategoriesList } from 'core/api/sales.api';

export interface IState {
    data: IProductCategory[];
    isLoading: boolean;
    error: object | null;
}

const initialState: IState = {
  data: [],
  error: null,
  isLoading: false,
};

export const getProductCategoriesAction = createAsyncThunk(
  'getProductCategoriesAction',
  async (params: IProductCategoriesParams | undefined, { rejectWithValue }) => {
    try {
      const { data } = await getProductCategoriesList(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const ProductCategoriesSlice = createSlice({
  name: 'getProductCategories',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getProductCategoriesAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProductCategoriesAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(getProductCategoriesAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
        state.data = [];
      });
  },
});

export const { clear: clearProductCategoriesAction } = ProductCategoriesSlice.actions;

export const getProductCategoriesReducer = ProductCategoriesSlice.reducer;

import {
  GET_CUSTOMERS_LOCATIONS_REQUEST,
  GET_CUSTOMERS_LOCATIONS_SUCCESS,
  GET_CUSTOMERS_LOCATIONS_FAILURE,
} from 'pages/Customers/CustomersLocations/GetLocations/actionTypes';

export const getLocationsRequest = () => ({ type: GET_CUSTOMERS_LOCATIONS_REQUEST });

export const getLocationsSuccess = (locations) => (
  { type: GET_CUSTOMERS_LOCATIONS_SUCCESS, locations }
);

export const getLocationsFailure = (errors) => (
  { type: GET_CUSTOMERS_LOCATIONS_FAILURE, errors });

import React, { useMemo } from 'react';
import filter from 'lodash/filter';
import { Grid, Typography } from '@mui/material';
import { useAppSelector } from 'core/store';
import { parseOpenapiSchema } from 'core/utilities/openapiSchema';
import { locationFields, profileFields } from '@EngagementsSowData/RequestOrderForm/constants';
import FormikFieldsFromSchema from 'pages/WirelineEngagements/shared/FormikFieldsFromSchema';
import { dynamicProductSchemaName } from '@EngagementsSowData/constants';

interface IRequestOrderFormFormGeneralProps {
  isEditMode: boolean
  orderDataKeys: string[]
}

const staticFields = [
  ...profileFields.map(({ fieldName }) => fieldName),
  ...locationFields.map(({ fieldName }) => fieldName),
];

const RequestOrderFormFormGeneral: React.FC<
  IRequestOrderFormFormGeneralProps
> = ({ isEditMode, orderDataKeys }) => {
  const openApiSchemas = useAppSelector((state) => state.openapiSchemas.schemas);

  const retrieveSchema = useMemo(() => parseOpenapiSchema(
    openApiSchemas,
    dynamicProductSchemaName,
  ), [openApiSchemas]);

  const filteredRetrieveSchema = useMemo(() => {
    if (!retrieveSchema?.length || !orderDataKeys?.length) return [];

    const allowedFields = orderDataKeys.filter((key) => !staticFields.includes(key));

    return filter(
      retrieveSchema,
      ({ fieldName }) => allowedFields.includes(fieldName),
    );
  }, [retrieveSchema, orderDataKeys]);

  return (
    <Grid
      className="request-order-form__section"
      item
      container
      md={6}
    >
      <Grid
        item
        container
        xs={12}
        pb={3}
      >
        <Typography
          className="request-order-form__section-title"
          variant="body1"
          component="p"
        >
          General
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        pb={3}
        rowSpacing={3}
      >
        <FormikFieldsFromSchema
          isTooltip
          isDisabled={!isEditMode}
          retrieveSchema={filteredRetrieveSchema}
        />
      </Grid>
    </Grid>
  );
};

export default RequestOrderFormFormGeneral;

import React, { Dispatch, SetStateAction, useState } from 'react';
import { useAppDispatch } from 'core/store';
import CustomDialogWrapper from 'shared/CustomDialog';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useTableContext } from 'shared/Table/TableContext';
import { IBookingDataItem } from '@dashboardWireline/WirelineDepartments/WIrelineDepartament.models';
import { bookedOrderNotifierMessage } from '@dashboardWirelineSellerDepartment/BookingInfo/BookingTableTopToolbar/constants';
import BookedOrderForm from '@dashboardWirelineSellerDepartment/BookingInfo/BookingTableTopToolbar/BookingActionButton/BookedOrder/BookedOrderForm';
// eslint-disable-next-line no-unused-vars
import { IBookedOrderForm } from '@dashboardWirelineSellerDepartment/BookingInfo/BookingTableTopToolbar/BookingActionButton/BookedOrder/bookedOrder.model';
import bookingInfoBookedOrderAction from '@dashboardWirelineSellerDepartment/BookingInfo/BookingTableTopToolbar/BookingActionButton/BookedOrder/BookedOrderAction';

interface IBookedOrderModalProps {
  selectedBookedOrder: IBookingDataItem[],
  isModalOpen: boolean
  setIsModalOpen: Dispatch<SetStateAction<boolean>>,
}

const BookedOrderModal: React.FC<IBookedOrderModalProps> = ({
  selectedBookedOrder,
  isModalOpen,
  setIsModalOpen,
}) => {
  const { getTableData, setRowSelection } = useTableContext();
  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();

  const [withLeaveConfirmation, setWithLeaveConfirmation] = useState(false);

  const dispatch = useAppDispatch();

  const onBookedAccepted = async (formValues: IBookedOrderForm) => {
    const orderIds = selectedBookedOrder.map((order) => order.id);
    const params = { ...formValues, orderIds };

    try {
      await dispatch(bookingInfoBookedOrderAction(params)).unwrap();

      showSuccessNotifier(bookedOrderNotifierMessage.success);
      setRowSelection({});
      getTableData();
      setIsModalOpen(false);
    } catch (error) {
      showErrorNotifier(bookedOrderNotifierMessage.error, error);
    }
  };

  return (
    <CustomDialogWrapper
      isModalOpen={isModalOpen}
      onCloseModal={setIsModalOpen}
      isConfirmDialog={withLeaveConfirmation}
      renderChildren={
        (onClose) => (
          <BookedOrderForm
            onClose={onClose}
            onSubmitHandler={onBookedAccepted}
            setWithLeaveConfirmation={setWithLeaveConfirmation}
          />
        )
      }
    />
  );
};

export default BookedOrderModal;

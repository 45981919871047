import React from 'react';
import map from 'lodash/map';

import { getMonthName } from 'pages/Dashboard/shared/utilities';
import DashboardBarChart from 'pages/Dashboard/shared/DashboardBarChart';
import { colorsOfTotalCharts, totalChartFields } from '@dashboardWirelineDepartments/shared/constants';
// eslint-disable-next-line no-unused-vars
import { IEngagementsOrdersByPeriodItem, IEngagementsOrdersByPeriodItemChart } from 'pages/Dashboard/Dashboard.models';

interface IEngagementsByPeriodChartProps {
  isLoading: boolean;
  list: IEngagementsOrdersByPeriodItem[];
}

const EngagementsByPeriodChart: React.FC<IEngagementsByPeriodChartProps> = ({
  list,
  isLoading,
}) => {
  const data = map(list, (item) => ({
    ...item,
    xDateKey: getMonthName(item?.month),
  }));

  return (
    <DashboardBarChart<IEngagementsOrdersByPeriodItemChart>
      data={data}
      isLoading={isLoading}
      colors={colorsOfTotalCharts}
      fields={totalChartFields}
      title="By Period"
      xAxisLabel="MON"
    />
  );
};

export default EngagementsByPeriodChart;

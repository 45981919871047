import {
  CREATE_ENGAGEMENTS_ATTACHMENT_REQUEST,
  CREATE_ENGAGEMENTS_ATTACHMENT_SUCCESS,
  CREATE_ENGAGEMENTS_ATTACHMENT_FAILURE,
} from 'pages/WirelineEngagements/WirelineEngagementsAttachments/CreateEngagementsAttachment/actionTypes';

export const createEngagementsAttachmentRequest = () => ({
  type: CREATE_ENGAGEMENTS_ATTACHMENT_REQUEST,
});

export const createEngagementsAttachmentSuccess = (newAttachment) => ({
  type: CREATE_ENGAGEMENTS_ATTACHMENT_SUCCESS,
  newAttachment,
});

export const createEngagementsAttachmentFailure = (error) => ({
  type: CREATE_ENGAGEMENTS_ATTACHMENT_FAILURE,
  error,
});

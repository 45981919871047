import {
  EDIT_ENGAGEMENTS_COMMENT_REQUEST,
  EDIT_ENGAGEMENTS_COMMENT_SUCCESS,
  EDIT_ENGAGEMENTS_COMMENT_FAILURE,
} from 'pages/WirelineEngagements/WirelineEngagementsComments/EditComment/actionTypes';

export const editCommentRequest = (commentId) => (
  { type: EDIT_ENGAGEMENTS_COMMENT_REQUEST, commentId });

export const editCommentSuccess = (editedComment) => (
  { type: EDIT_ENGAGEMENTS_COMMENT_SUCCESS, editedComment }
);

export const editCommentFailure = (commentId, error) => ({
  type: EDIT_ENGAGEMENTS_COMMENT_FAILURE,
  commentId,
  error,
});

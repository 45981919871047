import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import styles from 'assets/styles/_resources.scss';

const { backgroundColor, borderColor, lightBlueColor } = styles;

export const tableStyles = {
  muiTableHeadCellFilterTextFieldProps: {
    sx: { m: '0.5rem', maxWidth: '170px ' },
    variant: 'outlined',
  },
  positionGlobalFilter: 'left' as const,
  muiSearchTextFieldProps: {
    placeholder: 'Search',
    sx: { minWidth: '200px' },
    variant: 'outlined' as const,
  },
  muiTableBodyRowProps: {
    sx: (theme) => ({
      cursor: 'pointer',
      minHeight: '50px',
      '&.Mui-selected': {
        backgroundColor: `${theme.palette.action['50']} !important`,
      },
      '&.Mui-selected .MuiTableCell-root': {
        backgroundColor: `${theme.palette.primary['50']} !important`,
      },
    }),
  },
  muiTablePaperProps: {
    elevation: 0,
  },
  muiTableBodyProps: {
    sx: () => ({
      '& tr:nth-of-type(even)': {
        backgroundColor,
      },
    }),
  },
  muiTableBodyCellProps: {
    sx: {
      borderRight: `1px solid ${borderColor}`,
      fontSize: '14px',
    },
  },
  muiLinearProgressProps: ({ isTopToolbar }: { isTopToolbar: boolean }) => ({
    sx: {
      display: isTopToolbar ? 'block' : 'none',
    },
  }),
  muiTableHeadCellProps: {
    sx: {
      fontSize: '12px',
      textTransform: 'uppercase',
      minHeight: '60px',
      padding: 0,
      backgroundColor: lightBlueColor,
    },
  },

  layoutMode: 'grid' as const,

  icons: {
    ViewColumnIcon: FormatListBulletedRoundedIcon,
  },
};

export default { tableStyles };

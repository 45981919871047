import styles from 'assets/styles/_resources.scss';

export const colorsOfTotalCharts = [
  styles.primaryColor,
  styles.darkColor100,
  styles.blueColor,
  styles.tealColor500,
  styles.secondaryColor,
  styles.yellowColor500,
];

export const colorsOfTotalMrcChartFields = [
  styles.primaryColor,
  styles.blueColor,
  styles.tealColor500,
  styles.yellowColor500,
  styles.secondaryColor,
  styles.darkColor100,
];

export const totalChartFields = {
  REQUESTED: 'Requested',
  CANCELED: 'Canceled',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
};

export const totalMrcChartFields = {
  NEW: 'New',
  RE_ORDER: 'Re-order',
  RENEWAL: 'Renewal',
  DOWNGRADE: 'Downgrade',
  UPGRADE: 'Upgrade',
  CANCELED: 'Canceled',
};

export const notifierMessage = {
  engagementsTotals: {
    error: 'Failed to complete your request for Engagement Total Orders, please try again later.',
  },
  engagementsByPeriod: {
    error: 'Failed to complete your request for Engagements By Period Orders, please try again later.',
  },
  engagementsMrc: {
    error: 'Failed to complete your request for Engagement for MRC, please try again later.',
  },
  engagementsRevenue: {
    error: 'Failed to complete your request for Engagement Revenue, please try again later.',
  },
};

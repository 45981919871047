import React from 'react';
import map from 'lodash/map';
import Button from '@mui/material/Button';
import cx from 'classnames';
import { modifiers, stateFilterLabels } from '@dashboardWirelineSellerDepartment/TechnicalOverlay/constants';
import { StateFilter } from '@dashboardWirelineSellerDepartment/TechnicalOverlay/TechnicalOverlay.models';

interface IStateFilterButtonsProps {
  onChange: (state: StateFilter) => void;
  activeState: StateFilter;
}

const StateFilterButtons: React.FC<
  IStateFilterButtonsProps
> = ({ onChange, activeState }) => (
  <div className={modifiers.stateFilter}>
    {
        map(StateFilter, (state) => {
          const onClick = () => {
            onChange(state);
          };

          return (
            <Button
              key={state}
              onClick={onClick}
              className={cx(modifiers.stateFilterBtn, { active: activeState === state })}
            >
              {stateFilterLabels[state]}
            </Button>
          );
        })
      }
  </div>
);

export default StateFilterButtons;

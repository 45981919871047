// eslint-disable-next-line no-unused-vars
import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Box } from '@mui/material';

import paths from 'core/routing/paths';
import { useAppSelector } from 'core/store';
import { ICustomerListItem } from 'pages/Customers/Customers.models';
import CustomersCreateFormActivateButton from 'pages/Customers/CustomersCreate/CustomersCreateForm/CustomersCreateFormActivateButton';

interface ICustomersCreateFormButtonsProps {
  isEditMode: boolean
}

const CustomersCreateFormButtons: FC<ICustomersCreateFormButtonsProps> = ({ isEditMode }) => {
  const {
    submitForm,
    isValid,
  } = useFormikContext<ICustomerListItem>();

  const isLoading = useAppSelector((state) => state.customers.createCustomer.isLoading);

  const navigate = useNavigate();

  const onCancel = () => {
    navigate(paths.customersList);
  };

  return (
    <Grid
      sx={{ mt: 2 }}
      container
      direction="row"
      alignItems="center"
    >
      <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
        <Button
          sx={{ mr: 2 }}
          onClick={submitForm}
          disabled={isLoading || !isValid}
          variant="contained"
          type="submit"
        >
          {isEditMode ? 'Save' : 'Create'}
        </Button>
        <Button
          onClick={onCancel}
          disabled={isLoading}
          variant="outlined"
          type="button"
        >
          Cancel
        </Button>
      </Box>
      { isEditMode && (
        <CustomersCreateFormActivateButton
          isLoading={isLoading}
        />
      )}
    </Grid>
  );
};

export default CustomersCreateFormButtons;

import { ITableCell } from 'shared/Table/Table.models';

export enum UserType {
  ADMIN = 'ADMIN',
  TEAM_USER = 'TEAM_USER',
  CUSTOMER = 'CUSTOMER',
}

export enum UserTeamRoleName {
  INDIVIDUAL_SUPPORTER = 'INDIVIDUAL_SUPPORTER',
  DEPARTMENT_HEAD = 'DEPARTMENT_HEAD',
  TEAM_LEAD = 'TEAM_LEAD',
  TECHNICAL_OVERLAY = 'TECHNICAL_OVERLAY',
  BACKOFFICE_OVERLAY_SUPPORT = 'BACKOFFICE_OVERLAY_SUPPORT',
}

export enum UserTeamRoleTeamAlignment {
  WIRELINE = 'WIRELINE',
  MOBILITY = 'MOBILITY',
}

export enum UserTeamRoleTeamDepartment {
  SELLER = 'SELLER',
  CONTRACT_SPECIALIST = 'CONTRACT_SPECIALIST',
  PROJECT_MANAGER = 'PROJECT_MANAGER',
  BILLING_SPECIALIST = 'BILLING_SPECIALIST',
  DISCONNECT_SPECIALIST = 'DISCONNECT_SPECIALIST',
  INSIDE_SALES = 'INSIDE_SALES',
  RETENTION_MANAGER = 'RETENTION_MANAGER',
  MOBILITY_PROJECT_MANAGER = 'MOBILITY_PROJECT_MANAGER',
}

export interface ITeam {
  alignment: UserTeamRoleTeamAlignment
  department: UserTeamRoleTeamDepartment
}

export interface ITeamRoleAssociate {
  user: string
  team: ITeam
}

export interface ITeamRole {
  id?: number | undefined
  userId?: number | string
  // eslint-disable-next-line no-restricted-globals
  name: UserTeamRoleName
  team: ITeam,
  associates: ITeamRoleAssociate[]
}

export interface IUser {
  id?: number | string
  email: string
  firstName: string
  lastName: string
  isActive: boolean
  type: UserType
  allowDirectLogin: boolean
  phoneNumber: string
  customer?: null | string
}

export interface IUserWithTeamRoles extends IUser {
  teamRoles: ITeamRole[]
}

export interface IUserListItem extends IUser {
  id: number
  teamRoles: Omit<ITeamRole, 'associates'>[]
}

export type ITableCellUser = ITableCell<IUserListItem>;

export interface ITeamRoleAssociateExtended extends Omit<ITeamRole, 'user'> {
  id: number
  user: {
    id: number
    firstName: string
    lastName: string
    email: string
  }
}

export interface ITeamRoleExtended extends Omit<ITeamRole, 'associates'> {
  associates: ITeamRoleAssociateExtended[]
}

export interface IUserExtended extends IUser {
  id: number
  teamRoles: ITeamRoleExtended[]
}

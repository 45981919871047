import React from 'react';
import { Box } from '@mui/material';
import DashboardPieChart from 'pages/Dashboard/shared/DashboardPieChart';
import { colorsOfPMCharts } from '@dashboardWirelinePMDepartment/constants';
import { useAppSelector } from 'core/store';
import { mockProjectStatusData } from '@dashboardWirelinePMDepartment/ProjectStatusChart/mockProjectStatusData';

const ProjectStatusChart = () => {
  // TODO: uncomment when backend is ready
  // const projectStatusData = useAppSelector((state) => state.dashboard.projectStatus.data);
  const isLoading = useAppSelector((state) => state.dashboard.projectStatus.isLoading);

  return (
    <Box>
      <DashboardPieChart
        colors={colorsOfPMCharts}
        data={mockProjectStatusData}
        innerRadius={60}
        paddingAngle={2}
        title="Project Status"
        isLoading={isLoading}
      />
    </Box>
  );
};

export default ProjectStatusChart;

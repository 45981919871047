// eslint-disable-next-line no-unused-vars
import { IBookingReportPeriod } from '@dashboardWireline/WirelineDepartments/WIrelineDepartament.models';
// eslint-disable-next-line no-unused-vars
import { ITableFiltersParams } from 'core/models/general.models';
import {
  getBookingReportDataRequest,
  getBookingReportDataSuccess,
  getBookingReportDataFailure,
} from './GetBookingReportData';

import getBookingReportData from './api.service';

const getBookingReportDataAction = (
  controller,
  params: ITableFiltersParams,
  period?: IBookingReportPeriod,
) => async (dispatch) => {
  const { signal } = controller;

  try {
    const combinedParams = {
      ...params,
      ...period,
    };

    dispatch(getBookingReportDataRequest(combinedParams));

    const { data } = await getBookingReportData(signal, combinedParams);
    dispatch(getBookingReportDataSuccess(data));
  } catch (error) {
    dispatch(getBookingReportDataFailure(error));
  }
};

export default getBookingReportDataAction;

// eslint-disable-next-line no-unused-vars
import { ICustomerLocation } from 'pages/Customers/Customers.models';
// eslint-disable-next-line no-unused-vars
import { IOption } from 'shared/FormikField/FormikField.model';

export const getOptionsFromLocations = (
  locations: ICustomerLocation[]): IOption[] => locations.map(({
  id, address, city, state, zipCode,
}) => ({
  label: `${address}, ${city}, ${state}, ${zipCode}`,
  value: id,
}));

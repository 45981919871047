import { netsparkAxios } from 'core/services/HTTPService';
import { getEscalationSupportTotalUrl } from 'pages/Dashboard/api/apiUrls';
// eslint-disable-next-line no-unused-vars
import { TPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';
import { IChartItem } from 'core/models/Recharts.models';

const getEscalationSupportTotal = (signal: AbortSignal, params: TPeriod) => netsparkAxios
  .get<IChartItem[]>(getEscalationSupportTotalUrl, { signal, params });

export default getEscalationSupportTotal;

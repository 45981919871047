import {
  GET_APP_VARIABLES_REQUEST,
  GET_APP_VARIABLES_SUCCESS,
  GET_APP_VARIABLES_FAILURE,
} from './actionTypes';

const getAppVariablesReducer = (state = { data: {} }, {
  type, data, error,
}) => {
  switch (type) {
    case GET_APP_VARIABLES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_APP_VARIABLES_SUCCESS:
      return {
        ...state,
        data,
        isLoading: false,
      };
    case GET_APP_VARIABLES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error,
      };
    default:
      return state;
  }
};

export default getAppVariablesReducer;

import React from 'react';
import { useAppSelector } from 'core/store';
import { map } from 'lodash';
import {
  byUsersChartFields,
  colorsOfInsideSalesByUsersCharts,
} from '@dashboardWirelineInsideSalesDepartment/constants';
import DashboardBarChart from 'pages/Dashboard/shared/DashboardBarChart';
import { LabelPosition } from 'core/models/Recharts.models';

const ServiceTypeChart = () => {
  const serviceTypeData = useAppSelector((state) => state.dashboard.serviceType.data);
  const isLoading = useAppSelector((state) => state.dashboard.serviceType.isLoading);

  const formattedData = map(serviceTypeData, (item) => ({
    xDateKey: item.name,
    ...item.value,
  }));

  const barLabel = {
    label: { position: LabelPosition.TOP },
  };

  return (
    <DashboardBarChart
      data={formattedData}
      colors={colorsOfInsideSalesByUsersCharts}
      fields={byUsersChartFields}
      isLoading={isLoading}
      title="Service Type"
      barLabel={barLabel}
      needInsideLabel
    />
  );
};

export default ServiceTypeChart;

import React, { useEffect, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import merge from 'lodash/merge';
import { HTTPService } from 'core/services';
import { useAppSelector, useAppDispatch } from 'core/store';
import getSalesSowAction from 'core/actions/Sales/GetSalesSow/actions';
import ContractForm from '@EngagementsSowData/ContractForm';
import schema from '@EngagementsSowData/ContractForm/ContractFormSchema';
import getCustomerAction from 'core/actions/Customers/GetCustomerById/actions';

const ordersInfoController = HTTPService.getController();

const ContractFormCreate = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { customerId } = useParams();

  const selectedOrders = useAppSelector((state) => state.wirelineEngagements.salesSow.results);
  const isLoading = useAppSelector((state) => state.customers.customer.isLoading);
  const customerSignerProfile = useAppSelector(
    (state) => state.customers.customer.customerData.contractSignerProfile);
  const subAccountId = useAppSelector(
    (state) => state.customers.customer.customerData.subAccountId);

  const orderIds = searchParams.get('orderIds')?.split(',') || [];
  const uniqueOrdersByLocation = groupBy(selectedOrders, (order) => order.customerLocation);
  const numberOfOrders = selectedOrders.length;

  const contractDetailsOrders = map(uniqueOrdersByLocation, (value, key) => ({
    location: [key],
    product: value.map((order) => ({
      name: order.product,
      id: order.id,
    })),
  }));

  const orderIdsParams = {
    page: 1,
    page_size: orderIds.length,
    search: '',
    ordering: '',
    orderId: orderIds.toString(),
  };

  const initialValues = useMemo(() => {
    const defaultValues = schema.getDefault();
    const {
      firstName, lastName, phoneNumber, email,
    } = customerSignerProfile;

    const contractSignerProfile = {
      signerName: `${firstName} ${lastName}`,
      signerPhone: phoneNumber,
      signerEmail: email,
    };

    return merge(defaultValues, contractSignerProfile);
  }, [customerSignerProfile]);

  useEffect(() => {
    dispatch(getSalesSowAction(ordersInfoController, orderIdsParams));
    dispatch(getCustomerAction(customerId));
  }, []);

  return (
    <ContractForm
      selectedOrders={selectedOrders}
      numberOfOrders={numberOfOrders}
      contractDetailsOrders={contractDetailsOrders}
      orderIds={orderIds}
      initialValues={initialValues}
      isLoading={isLoading}
      subAccountId={subAccountId}
    />
  );
};

export default ContractFormCreate;

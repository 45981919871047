import { getInsideSales } from 'core/api/insideSales.api';
import {
  getInsideSalesRequest,
  getInsideSalesSuccess,
  getInsideSalesFailure,
} from 'core/actions/crm/InsideSales/GetInsideSalesList/actionsCreators';

const getInsideSalesAction = (
  controller: AbortController,
  params: {
    page: number;
    page_size: number;
    search: string;
    ordering?: string | undefined;
  },
) => async (dispatch) => {
  try {
    const { signal } = controller;

    dispatch(getInsideSalesRequest());

    const { data } = await getInsideSales(signal, params);

    dispatch(getInsideSalesSuccess(data));
  } catch (error) {
    dispatch(getInsideSalesFailure(error));

    throw error;
  }
};

export default getInsideSalesAction;

/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IEngagementsRevenueItem } from 'pages/Dashboard/Dashboard.models';
// eslint-disable-next-line no-unused-vars
import { TPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';
import { getEngagementsTORevenue } from './api.service';

export interface IState {
  data: IEngagementsRevenueItem[];
  isLoading: boolean;
  error: object | null;
}

const initialState: IState = {
  data: [],
  error: null,
  isLoading: false,
};

export const getEngagementsTORevenueAction = createAsyncThunk(
  'getEngagementsTORevenueAction',
  async (
    { controller, params }: {params: TPeriod, controller: AbortController},
    { rejectWithValue },
  ) => {
    const { signal } = controller;

    try {
      const { data } = await getEngagementsTORevenue(signal, params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const EngagementsTORevenueSlice = createSlice({
  name: 'getEngagementsTORevenueSlice',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getEngagementsTORevenueAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEngagementsTORevenueAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(getEngagementsTORevenueAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
        state.data = [];
      });
  },
});

export const { clear: clearEngagementsTORevenueAction } = EngagementsTORevenueSlice.actions;

export const getEngagementsTORevenueReducer = EngagementsTORevenueSlice.reducer;

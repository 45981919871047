import Yup from 'core/utilities/Yup';
import { UserType } from 'pages/UserManagement/UserManagement.models';

export const userManagementGeneralFormSchema = (
  Yup.object().shape({
    firstName: Yup
      .string()
      .trim()
      .required('First name is required')
      .default(''),
    lastName: Yup
      .string()
      .trim()
      .required('Last name is required')
      .default(''),
    email: Yup
      .string()
      .email('Please, enter correct email address')
      .required('Email is required')
      .default(''),
    allowDirectLogin: Yup
      .bool()
      .required()
      .default(false),
    phoneNumber: Yup
      .string()
      .required('Phone number is required')
      .default(''),
    type: Yup
      .string()
      .oneOf(Object.values(UserType))
      .required()
      .default(UserType.TEAM_USER),
    isActive: Yup
      .bool()
      .required()
      .default(true),
  })
);

import React from 'react';
import CustomModal from 'shared/CustomModal';
import { orderActionsTitle } from '@EngagementsSowData/constants';
import { ISowDataListItem, OrderActions } from '@EngagementsSowData/sowData.model';
import OrderItem from '@EngagementsSowData/WirelineEngagementsSowDataModals/ActionsModal/OrderItem';

interface IActionsModalRequestOrderProps {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  onConfirm: () => void;
  orders: ISowDataListItem[];
}

const ActionsModalRequestOrder: React.FC<
  IActionsModalRequestOrderProps
> = ({
  isModalOpen, setIsModalOpen, onConfirm, orders,
}) => (
  <CustomModal
    title={orderActionsTitle[OrderActions.REQUEST_ORDERS]}
    confirmButtonText="Yes"
    cancelButtonText="No"
    isModalOpen={isModalOpen}
    onClickConfirm={onConfirm}
    onCloseModal={setIsModalOpen}
    areButtonsVisible
  >
    <div>
      {orders.map((order) => (
        <OrderItem
          key={order.id}
          order={order}
          orderAction={OrderActions.REQUEST_ORDERS}
        />
      ))}
    </div>
  </CustomModal>
);

export default ActionsModalRequestOrder;

import { EngagementType, RequestType } from 'pages/InsideSales/model';

export const engagementTypeLabels = {
  [EngagementType.PENDING]: 'Pending',
  [EngagementType.IN_PROGRESS]: 'In Progress',
  [EngagementType.COMPLETED]: 'Completed',
  [EngagementType.CANCELED]: 'Canceled',
};

export const engagementTypesToShowStatusField = [
  EngagementType.IN_PROGRESS,
  EngagementType.COMPLETED,
];

export const engagementTypeOptions = [
  {
    value: EngagementType.PENDING,
    label: engagementTypeLabels[EngagementType.PENDING],
  },
  {
    value: EngagementType.IN_PROGRESS,
    label: engagementTypeLabels[EngagementType.IN_PROGRESS],
  },
  {
    value: EngagementType.COMPLETED,
    label: engagementTypeLabels[EngagementType.COMPLETED],
  },
  {
    value: EngagementType.CANCELED,
    label: engagementTypeLabels[EngagementType.CANCELED],
  },
];

export const requestTypeLabels = {
  [RequestType.QUOTE]: 'Quote',
  [RequestType.QUOTE_AND_PROPOSAL]: 'Quote and proposal',
};

export const requestTypeOptions = [
  {
    value: RequestType.QUOTE,
    label: requestTypeLabels[RequestType.QUOTE],
  },
  {
    value: RequestType.QUOTE_AND_PROPOSAL,
    label: requestTypeLabels[RequestType.QUOTE_AND_PROPOSAL],
  },
];

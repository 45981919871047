import {
  GET_STATES_REQUEST,
  GET_STATES_SUCCESS,
  GET_STATES_FAILURE,
} from './actionTypes';

export const getStatesRequest = () => (
  { type: GET_STATES_REQUEST });
export const getStatesSuccess = (data) => (
  { type: GET_STATES_SUCCESS, data });
export const getStatesFailure = (error) => (
  { type: GET_STATES_FAILURE, error });

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { dateFormat } from 'core/constants';

dayjs.extend(customParseFormat);

const formats = ['DD-MM-YYYY', 'YYYY-MM-DD', 'MM-DD-YYYY'];

const trimMilliseconds = (isoString: string) => {
  const datePart = isoString.split('T')[0];

  return datePart.replace(/\.\d{3}\d*/, '.000');
};

const formatDate = (dateString: string | null) => {
  if (!dateString) return '';

  const dateToFormat = trimMilliseconds(dateString);
  const parsedDate = formats
    .map((format) => dayjs(dateToFormat, format, true))
    .find((date) => date.isValid());

  if (!parsedDate) return '';

  return parsedDate.format(dateFormat);
};

export default formatDate;

import React from 'react';
import DashboardPieChart from 'pages/Dashboard/shared/DashboardPieChart';
import { colorsOfPMCharts } from '@dashboardWirelinePMDepartment/constants';
import { useAppSelector } from 'core/store';

const ProjectAcceptanceChart = () => {
  const projectAcceptanceData = useAppSelector((state) => state.dashboard.projectAcceptance.data);
  const isLoading = useAppSelector((state) => state.dashboard.projectAcceptance.isLoading);

  return (
    <DashboardPieChart
      colors={colorsOfPMCharts}
      data={projectAcceptanceData}
      innerRadius={60}
      paddingAngle={2}
      title="Project Acceptance"
      isLoading={isLoading}
    />
  );
};

export default ProjectAcceptanceChart;

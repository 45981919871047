import React from 'react';
import { Form, Formik } from 'formik';
import { useAppDispatch } from 'core/store';
import { CancelRequestAction } from 'core/actions/crm/InsideSales/CancelRequest';
import { useTableContext } from 'shared/Table/TableContext';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { TableRowMode } from 'shared/Table/Table.models';
// eslint-disable-next-line no-unused-vars
import { IInsideSalesListItem, IInsideSalesRequestCancelRequest } from 'pages/InsideSales/model';
import CancelOrderModal
  from 'pages/InsideSales/InsideSalesList/InsideSalesListTable/InsideSalesListTableModals/CancelRequestModalForm/CancelOrderModal';
import {
  CancelRequestFormSchema,
} from 'pages/InsideSales/InsideSalesList/InsideSalesListTable/InsideSalesListTableModals/CancelRequestModalForm/CancelRequestFormSchema';

const CancelRequestModalForm = () => {
  const {
    activeRowMode,
    activeRow,
    cleanActiveRow,
  } = useTableActiveRowContext<IInsideSalesListItem>();

  const dispatch = useAppDispatch();
  const { getTableData } = useTableContext();

  const cancelOrderRowId = activeRow?.id;
  const isCancelOrderMode = activeRowMode === TableRowMode.CANCEL_ORDER;

  const initialValues = CancelRequestFormSchema.getDefault();

  const onSubmit = async (params: IInsideSalesRequestCancelRequest, { setErrors, resetForm }) => {
    if (!cancelOrderRowId) return;

    try {
      await dispatch(CancelRequestAction({
        id: cancelOrderRowId,
        params,
      })).unwrap();

      cleanActiveRow();
      getTableData();
      resetForm();
    } catch (e) {
      // @ts-ignore
      setErrors(e?.response?.data);
    }
  };

  if (!cancelOrderRowId || !isCancelOrderMode) {
    return null;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={CancelRequestFormSchema}
    >
      <Form>
        <CancelOrderModal
          activeRow={activeRow}
          cleanActiveRow={cleanActiveRow}
        />
      </Form>
    </Formik>
  );
};

export default CancelRequestModalForm;

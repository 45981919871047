export enum EngagementTypeForChart {
    COMPLETED = 'COMPLETED',
    IN_PROGRESS = 'IN_PROGRESS',
}

export interface IInsideSalesUsersItem {
    // eslint-disable-next-line no-restricted-globals
    name: string,
    value: {
        [EngagementTypeForChart.COMPLETED]?: number,
        [EngagementTypeForChart.IN_PROGRESS]?: number,
    },
}

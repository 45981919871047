import React from 'react';
import TOEngagementsMrcChart from '@dashboardWirelineDepartments/TechnicalOverlayDepartment/TOEngagementsMrcChart';
import TOEngagementsTotalsChart from '@dashboardWirelineDepartments/TechnicalOverlayDepartment/TOEngagementsTotalsChart';
import TOEngagementsRevenueChart from '@dashboardWirelineDepartments/TechnicalOverlayDepartment/TOEngagementsRevenueChart';
import TOEngagementsByPeriodChart from '@dashboardWirelineDepartments/TechnicalOverlayDepartment/TOEngagementsByPeriodChart';
import TechnicalOverlayFiltersByPeriod from '@dashboardWirelineDepartments/TechnicalOverlayDepartment/TechnicalOverlayFiltersByPeriod';
import { useTechnicalOverlayDepartmentContext } from '@dashboardWirelineDepartments/TechnicalOverlayDepartment/TechnicalOverlayDepartmentContext';

import './TechnicalOverlayDepartment.scss';

const TechnicalOverlay = () => {
  const { actionsPermissions } = useTechnicalOverlayDepartmentContext();

  const {
    isAvailableGetMRCChart,
    isAvailableGetTotalChart,
    isAvailableGetByPeriodChart,
    isAvailableGetByRevenueChart,
  } = actionsPermissions;

  return (
    <div className="technical-overlay-department">
      <TechnicalOverlayFiltersByPeriod />
      <div className="technical-overlay-department__charts">
        {isAvailableGetTotalChart && <TOEngagementsTotalsChart />}
        {isAvailableGetByPeriodChart && <TOEngagementsByPeriodChart />}
        {isAvailableGetByRevenueChart && <TOEngagementsRevenueChart />}
        {isAvailableGetMRCChart && <TOEngagementsMrcChart />}
      </div>
    </div>
  );
};
export default TechnicalOverlay;

/* eslint-disable no-param-reassign */
import { createSlice, isAnyOf, AsyncThunk } from '@reduxjs/toolkit';

interface IError { detail: string, text: string }

interface IThunkError { rejectValue: IError }

type Id = string | number;

export interface IState<T> {
  error: string
  isLoading: boolean
  results: T[]
}

type CreateItemAction<T> = AsyncThunk<
  T, { parentId: Id, params: Partial<T> }, IThunkError
>;

type GetListAction<T> = AsyncThunk<
  T[], { parentId: Id, controller: AbortController }, IThunkError
>;

type EditItemAction<T> = AsyncThunk<
  T, { parentId: Id, id: Id, params: Partial<T> }, IThunkError
>;

type DeleteItemAction = AsyncThunk<
  void, { parentId: Id, id: Id }, IThunkError
>;

export interface ISliceParams<T> {
  // eslint-disable-next-line no-restricted-globals
  name: string
  initialState: IState<T>
  createItemAction: CreateItemAction<T>
  getListAction: GetListAction<T>
  editItemAction: EditItemAction<T>,
  deleteItemAction: DeleteItemAction
}

const createListSlice = <T extends { id: Id }>({
  name,
  initialState,
  createItemAction,
  getListAction,
  editItemAction,
  deleteItemAction,
}: ISliceParams<T>) => (
    createSlice({
      name,
      initialState,
      reducers: {
        clearErrorsAction: (state) => {
          state.error = '';
          state.results = state.results.map((item) => ({
            ...item,
            error: '',
          }));
        },
        clearListAction: (state) => {
          state.results = [];
        },
      },
      extraReducers(builder) {
        builder
          .addCase(
            createItemAction.fulfilled,
            (state, action) => {
              state.isLoading = false;
              state.results = [
                ...state.results,
                action.payload,
              ] as typeof state.results;
            },
          )
          .addCase(
            getListAction.fulfilled,
            (state, action) => {
              state.isLoading = false;
              state.results = action.payload as typeof state.results;
            },
          )
          .addCase(
            editItemAction.fulfilled,
            (state, action) => {
              state.results = state.results.map((item) => {
                if (item.id === action.payload.id) {
                  return action.payload;
                }
                return item;
              }) as typeof state.results;
            },
          )
          .addCase(
            deleteItemAction.fulfilled,
            (state, action) => {
              const { id } = action.meta.arg;

              state.results = state.results.filter((item) => item.id !== id);
            },
          )
          .addMatcher(
            isAnyOf(
              createItemAction.rejected,
              getListAction.rejected,
            ),
            (state, action) => {
              state.isLoading = false;

              const { detail, text } = action.payload || {};
              state.error = detail || text || '';
            },
          )
          .addMatcher(
            isAnyOf(
              createItemAction.pending,
              getListAction.pending,
            ),
            (state) => {
              state.isLoading = true;
            },
          )
          .addMatcher(
            isAnyOf(
              editItemAction.pending,
              deleteItemAction.pending,
            ),
            (state, action) => {
              const { id } = action.meta.arg;

              state.results = state.results.map((item) => {
                if (item.id === id) {
                  return {
                    ...item,
                    isLoading: true,
                  };
                }

                return item;
              });
            },
          )
          .addMatcher(
            isAnyOf(
              editItemAction.rejected,
              deleteItemAction.rejected,
            ),
            (state, action) => {
              const { id } = action.meta.arg;

              state.results = state.results.map((item) => {
                if (item.id === id) {
                  const { detail, text } = action.payload || {};

                  return {
                    ...item,
                    isLoading: false,
                    error: detail || text || '',
                  };
                }

                return item;
              });
            },
          );
      },
    })
  );

export default createListSlice;

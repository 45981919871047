import React from 'react';
import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { useAppSelector } from 'core/store';
import CustomModal from 'shared/CustomModal';
import FormikTextareaField from 'shared/FormikField/FormikTextareaField';

import './CancelOrderModal.scss';

const CancelOrderModal = ({ activeRow, cleanActiveRow }) => {
  const { companyName, sellerFullname } = activeRow;

  const isLoading = useAppSelector((state) => state.insideSales.cancelRequest.isLoading);
  const { dirty, isSubmitting, submitForm } = useFormikContext();

  const withLeaveConfirmation = dirty && !isSubmitting;

  const onClose = () => {
    cleanActiveRow();
  };

  return (
    <CustomModal
      title="Are you sure you want to cancel the selected request?"
      isModalOpen={!!activeRow}
      onClickConfirm={submitForm}
      onCloseModal={onClose}
      isLoading={isLoading}
      areButtonsVisible
      maxWidth="504px"
      confirmButtonText="Yes"
      cancelButtonText="No"
      isConfirmDialog={withLeaveConfirmation}
    >
      <Grid
        className="cancel-order__modal"
        container
        item
      >
        <div className="custom-modal__list__item">
          Request for
          {' '}
          {companyName}
          {' '}
          has been created by
          {' '}
          {sellerFullname}
          .
        </div>
        <FormikTextareaField
          fieldName="cancellationReason"
        />
      </Grid>
    </CustomModal>
  );
};

export default CancelOrderModal;

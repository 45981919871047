import { editCustomerLocation } from 'core/api/customer.api';
// eslint-disable-next-line no-unused-vars
import { IDraftLocation } from 'shared/widgets/Locations/Locations.models';
import {
  editLocationRequest,
  editLocationSuccess,
  editLocationFailure,
} from 'pages/Customers/CustomersLocations/EditLocation/actionCreators';

const editLocationAction = (
  customerId: number | string,
  locationId: number | string,
  formData: IDraftLocation,
) => async (dispatch) => {
  try {
    dispatch(editLocationRequest());
    const { data } = await editCustomerLocation(customerId, locationId, formData);
    dispatch(editLocationSuccess(data));
  } catch (error) {
    dispatch(editLocationFailure(error));
    throw error;
  }
};

export default editLocationAction;

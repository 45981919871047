import {
  GET_ENGAGEMENTS_ORDERS_BY_PERIOD_REQUEST,
  GET_ENGAGEMENTS_ORDERS_BY_PERIOD_SUCCESS,
  GET_ENGAGEMENTS_ORDERS_BY_PERIOD_FAILURE,
} from './actionsTypes';

export const getEngagementsOrdersByPeriodRequest = () => (
  { type: GET_ENGAGEMENTS_ORDERS_BY_PERIOD_REQUEST }
);

export const getEngagementsOrdersByPeriodSuccess = (data) => ({
  type: GET_ENGAGEMENTS_ORDERS_BY_PERIOD_SUCCESS,
  data,
});

export const getEngagementsOrdersByPeriodFailure = (error) => ({
  type: GET_ENGAGEMENTS_ORDERS_BY_PERIOD_FAILURE,
  error,
});

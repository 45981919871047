// eslint-disable-next-line no-unused-vars
import React, { FC } from 'react';
import { Grid, InputLabel } from '@mui/material';

interface IFormikCounterFieldWrapperProps {
  children: React.ReactNode
  fieldName: string,
  label: string
  required?: boolean
}

const FormikCounterFieldWrapper: FC<IFormikCounterFieldWrapperProps> = ({
  children, fieldName, required, label,
}) => (
  <Grid
    item
    container
    direction="row"
    flexWrap="nowrap"
    alignItems="center"
  >
    <Grid item xs="auto" mr={2}>
      {children}
    </Grid>
    <Grid
      item
      container
      direction="column"
      justifyContent="center"
      mr={2}
    >
      <InputLabel
        htmlFor={fieldName}
        required={required as boolean}
      >
        {label}
      </InputLabel>
    </Grid>
  </Grid>
);

FormikCounterFieldWrapper.defaultProps = {
  required: false,
};

export default FormikCounterFieldWrapper;

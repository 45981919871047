/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// eslint-disable-next-line no-unused-vars
import { IProduct, IProductParams } from 'pages/WirelineEngagements/shared/AddOrderModalForm/product.model';
import { getProducts } from 'core/api/sales.api';

export interface IState {
    data: IProduct[];
    isLoading: boolean;
    error: object | null;
}

const initialState: IState = {
  data: [],
  isLoading: false,
  error: null,
};

export const getProductsAction = createAsyncThunk(
  'getProductsAction',
  async (params: IProductParams, { rejectWithValue }) => {
    try {
      const { data } = await getProducts(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const ProductsSlice = createSlice({
  name: 'getProducts',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getProductsAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProductsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(getProductsAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
        state.data = [];
      });
  },
});

export const { clear: clearProductsAction } = ProductsSlice.actions;

export const getProductsReducer = ProductsSlice.reducer;

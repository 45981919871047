import React, { useEffect, useState } from 'react';
import { firstPage, maxPageSize } from 'shared/Table/constants';
import { getUsers } from 'core/api/user.api';
import { getOptionsFromUser } from 'core/utilities/getOptionsFromUser';
import { IOption } from 'shared/FormikField/FormikField.model';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import FormikSelectField from 'shared/FormikField/FormikSelectField';
import {
  UserTeamRoleName,
  UserTeamRoleTeamAlignment,
  UserTeamRoleTeamDepartment,
} from 'pages/UserManagement/UserManagement.models';

interface IFormikSelectFieldUserProps {
  fieldName: string
  label: string
  errorMessage: string
  selectValueField?: string
  department?: UserTeamRoleTeamDepartment | undefined
  alignment?: UserTeamRoleTeamAlignment | undefined
  role?: UserTeamRoleName | undefined
  disabled?: boolean
  required?: boolean
}

const FormikSelectFieldUser: React.FC<IFormikSelectFieldUserProps> = ({
  fieldName,
  label,
  department,
  alignment,
  role,
  errorMessage,
  selectValueField,
  disabled,
  required,
}) => {
  const { showErrorNotifier } = useNotifiers();

  const [isLoading, setIsLoading] = useState(true);
  const [availableOptions, setAvailableOptions] = useState<IOption[]>([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const params = {
          department,
          alignment,
          role,
          page: firstPage + 1,
          page_size: maxPageSize,
        };

        const { data: { results } } = await getUsers(params);

        const options = getOptionsFromUser(results, selectValueField);

        setAvailableOptions(options);
      } catch (e) {
        showErrorNotifier(errorMessage, e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [department]);

  return (
    <FormikSelectField
      fieldName={fieldName}
      label={label}
      options={availableOptions}
      isLoading={isLoading}
      disabled={disabled as boolean}
      required={required as boolean}
      fullWidth
    />
  );
};

FormikSelectFieldUser.defaultProps = {
  selectValueField: 'id',
  department: undefined,
  alignment: undefined,
  role: undefined,
  disabled: false,
  required: false,
};

export default FormikSelectFieldUser;

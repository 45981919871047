import React from 'react';
import {
  Grid, Button,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';
import { emptyTeamRole } from 'pages/UserManagement/constants';
import { ITeamRole } from 'pages/UserManagement/UserManagement.models';
import { useUserManagementCreateContext } from 'pages/UserManagement/UserManagementCreate/UserManagementCreateContext';
import UserManagementCreateFormTeamRoleItem from 'pages/UserManagement/UserManagementCreate/UserManagementTeamRoleForm/UserManagementCreateFormTeamRole/UserManagementCreateFormTeamRoleItem';
import UserManagementCreateFormTeamRoleItemPlaceholder from 'pages/UserManagement/UserManagementCreate/UserManagementTeamRoleForm/UserManagementCreateFormTeamRole/UserManagementCreateFormTeamRoleItemPlaceholder';

const UserManagementCreateFormTeamRole = () => {
  const { id } = useParams();
  const { setIndexEditModeTeamRole, isUserHasEmailOnly } = useUserManagementCreateContext();

  const { values, setFieldValue } = useFormikContext<ITeamRole[]>();

  const { isEditModeGeneral, isEditModeTeamRole } = useUserManagementCreateContext();

  const isCreateMode = isUserHasEmailOnly || !id;
  const isAddUserTypeDisabled = isEditModeGeneral || isEditModeTeamRole || isCreateMode;

  const onAddUserType = () => {
    const newTeamRoleIndex = values.length;

    setFieldValue(`[${newTeamRoleIndex}]`, emptyTeamRole);
    setIndexEditModeTeamRole(newTeamRoleIndex);
  };

  return (
    <>
      {values.length === 0 && (
        <UserManagementCreateFormTeamRoleItemPlaceholder
          onAddUserType={onAddUserType}
          isDisabled={isCreateMode}
        />
      )}
      {values.map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <UserManagementCreateFormTeamRoleItem key={index} index={index} />
      ))}
      <Grid
        className="user-management-create-form__section"
        item
        columnSpacing={5}
      >
        <Button
          onClick={onAddUserType}
          variant="outlined"
          disabled={isAddUserTypeDisabled}
        >
          + add user type
        </Button>
      </Grid>
    </>
  );
};

export default UserManagementCreateFormTeamRole;

import React from 'react';
import cx from 'classnames';

import styles from 'assets/styles/_resources.scss';
import StatusCell from 'shared/Table/shared/StatusCell';
import { EngagementType, RequestType } from 'pages/InsideSales/model';
import { StatusCellClasses } from 'shared/Table/shared/StatusCell/model';
import RequestProvidedDate from 'shared/Table/shared/RequestProvidedDate';
import convertDataFromFormToTable from 'core/utilities/convertDataFromFormToTable';
import {
  engagementTypeOptions, requestTypeOptions, engagementTypeLabels, requestTypeLabels,
} from 'pages/InsideSales/constants';

const { greenColor50, greenColor500 } = styles;

export const providedHeadCellStyle = {
  sx: {
    fontSize: '12px',
    paddingLeft: '0px',
    paddingRight: '0px',
    textTransform: 'uppercase',
    backgroundColor: greenColor50,
    borderTop: `2px solid ${greenColor500}`,
  },
};

export const providedBodyCellStyle = {
  sx: {
    backgroundColor: greenColor50,
    fontSize: '14px',
  },
};

export const columns = [
  {
    header: 'Request Accepted Date',
    accessorKey: 'acceptedDatetime',
    enableColumnFilter: false,
    muiTableHeadCellProps: providedHeadCellStyle,
    muiTableBodyCellProps: providedBodyCellStyle,
    Cell: ({ renderedCellValue }) => renderedCellValue && (
      <RequestProvidedDate requestProvidedDate={renderedCellValue as string} />
    ),
  },
  {
    header: 'Engagement Type',
    accessorKey: 'engagementType',
    filterVariant: 'select',
    filterFn: 'equals',
    filterSelectOptions: convertDataFromFormToTable(engagementTypeOptions),
    muiTableHeadCellFilterTextFieldProps: { placeholder: 'Select type' },
    Cell: ({ renderedCellValue }) => {
      if (!renderedCellValue) {
        return null;
      }

      const className = cx({
        [StatusCellClasses.RED]: renderedCellValue === EngagementType.PENDING,
        [StatusCellClasses.GREEN]: renderedCellValue === EngagementType.IN_PROGRESS,
        [StatusCellClasses.PRIMARY]: renderedCellValue === EngagementType.COMPLETED,
        [StatusCellClasses.DARK]: renderedCellValue === EngagementType.CANCELED,
      });

      return (
        <StatusCell className={className as StatusCellClasses}>
          {engagementTypeLabels[renderedCellValue as EngagementType]}
        </StatusCell>
      );
    },
  },
  {
    header: 'Customer name',
    accessorKey: 'companyName',
  },
  {
    header: 'Inside Sales Executive',
    accessorKey: 'insideSalesManagerFullname',
  },
  {
    header: 'Seller',
    accessorKey: 'sellerFullname',
  },
  {
    header: 'Request Type',
    accessorKey: 'requestType',
    filterVariant: 'select',
    filterFn: 'equals',
    filterSelectOptions: convertDataFromFormToTable(requestTypeOptions),
    muiTableHeadCellFilterTextFieldProps: { placeholder: 'Select type' },
    Cell: ({ renderedCellValue }) => renderedCellValue && (
      requestTypeLabels[renderedCellValue as RequestType]
    ),
  },
  {
    header: 'Request details',
    accessorKey: 'sellerNote',
  },
  {
    header: 'Inside Sales notes',
    accessorKey: 'managerNote',
  },
  {
    header: 'Cancellation reason',
    accessorKey: 'cancellationReason',
  },
];

export const createRequestMessages = {
  error: 'Failed to create inside sales request',
  success: 'Request has been created successfully',
};

export const createRequestDraftMessages = {
  error: 'Failed to create draft for inside sales request',
};

export const loadInsideSalesItemMessages = {
  error: 'Failed to load inside sales item data',
};

export const updateInsideSalesItemMessages = {
  error: 'Failed to update inside sales item data',
  success: 'Inside sales item has been updated successfully',
};

export const setEngagementTypeToCompletedMessages = {
  error: 'Failed to set engagement type to completed',
  success: 'Engagement type has been set to completed successfully',
};

export const editRequestTitle = 'Edit Request';
export const viewRequestTitle = 'View Request';

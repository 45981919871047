/* eslint-disable no-unused-vars */
import { IOption } from 'shared/FormikField/FormikField.model';
import { ITeam } from 'pages/UserManagement/UserManagementCreate/UserManagementGeneralForm/UserManagementGeneralForm.models';
import {
  UserTeamRoleName,
  UserTeamRoleTeamAlignment,
  UserTeamRoleTeamDepartment,
} from 'pages/UserManagement/UserManagement.models';
import { userTeamRolesNamesLabels } from 'pages/UserManagement/constants';

export const findTeamRoles = (
  selectedAlignment: UserTeamRoleTeamAlignment,
  selectedDepartment: UserTeamRoleTeamDepartment,
  teams: ITeam[],
) => {
  const team = teams.find(({
    alignment,
    department,
  }) => (
    alignment === selectedAlignment
    && department === selectedDepartment
  ));

  if (!team) {
    return [];
  }

  return team.roles;
};

export const getOptionsFromRoles = (roles: UserTeamRoleName[]) => roles.map(
  (role) => ({
    label: userTeamRolesNamesLabels[role],
    value: role,
  }) as IOption,
);

export const getUserTeamRolesNamesOptions = (
  selectedAlignment: UserTeamRoleTeamAlignment,
  selectedDepartment: UserTeamRoleTeamDepartment,
  teams: ITeam[],
) => {
  const roles = findTeamRoles(selectedAlignment, selectedDepartment, teams);

  return getOptionsFromRoles(roles);
};

export const getTeamRoleFieldNames = (index: number) => {
  const teamRolesItemFieldName = `[${index}]`;

  return ({
    teamRolesItemFieldName,
    teamRolesItemIdFieldName: `${teamRolesItemFieldName}.id`,
    teamRolesItemNameFieldName: `${teamRolesItemFieldName}.name`,
    teamRolesItemTeamAlignmentFieldName: `${teamRolesItemFieldName}.team.alignment`,
    teamRolesItemTeamDepartmentFieldName: `${teamRolesItemFieldName}.team.department`,
    teamRolesItemAssociatesFieldName: `${teamRolesItemFieldName}.associates`,
  });
};

import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { ITeamRole } from 'pages/UserManagement/UserManagement.models';
import { IAssociationsConfigItem } from 'pages/UserManagement/UserManagementCreate/UserManagementGeneralForm/UserManagementGeneralForm.models';
import { getRelatedToByTeamRole } from 'pages/UserManagement/UserManagementCreate/UserManagementGeneralForm/api.service';
import { relatedToByTeamRoleGetMessages } from 'pages/UserManagement/constants';
import { useUserManagementCreateContext } from 'pages/UserManagement/UserManagementCreate/UserManagementCreateContext';
import UserManagementCreateFormTeamRoleItemAssociationsHeader
  from 'pages/UserManagement/UserManagementCreate/UserManagementTeamRoleForm/UserManagementCreateFormTeamRole/UserManagementCreateFormTeamRoleItemAssociations/UserManagementCreateFormTeamRoleItemAssociationsHeader';
import UserManagementCreateFormTeamRoleItemAssociationsItem
  from 'pages/UserManagement/UserManagementCreate/UserManagementTeamRoleForm/UserManagementCreateFormTeamRole/UserManagementCreateFormTeamRoleItemAssociations/UserManagementCreateFormTeamRoleItemAssociationsItem';
import { getTeamRoleFieldNames } from 'pages/UserManagement/UserManagementCreate/UserManagementTeamRoleForm/UserManagementCreateFormTeamRole/utilities';

interface IUserManagementCreateFormUserTypeItemAssociationsProps {
  index: number
}

const UserManagementCreateFormTeamRoleItemAssociations: React.FC<
  IUserManagementCreateFormUserTypeItemAssociationsProps
> = ({ index }) => {
  const { getIsTeamRoleInEditMode } = useUserManagementCreateContext();
  const { showErrorNotifier } = useNotifiers();

  const { values, setFieldValue } = useFormikContext<ITeamRole[]>();

  const {
    teamRolesItemFieldName,
    teamRolesItemNameFieldName,
    teamRolesItemTeamAlignmentFieldName,
    teamRolesItemTeamDepartmentFieldName,
    teamRolesItemAssociatesFieldName,
  } = getTeamRoleFieldNames(index);

  const name = get(values, teamRolesItemNameFieldName);
  const alignment = get(values, teamRolesItemTeamAlignmentFieldName);
  const department = get(values, teamRolesItemTeamDepartmentFieldName);

  const isAssociationsAvailable = !!(name && alignment && department);

  const [isLoading, setIsLoading] = useState(true);
  const [associationsConfig, setAssociationsConfig] = useState<IAssociationsConfigItem[]>([]);

  const isEditMode = getIsTeamRoleInEditMode(index);

  useEffect(() => {
    if (!isAssociationsAvailable) {
      setIsLoading(false);
      setAssociationsConfig([]);
      return;
    }

    (async () => {
      try {
        setIsLoading(true);
        const teamRole = get(values, teamRolesItemFieldName);
        const associates = get(values, teamRolesItemAssociatesFieldName);

        const { data } = await getRelatedToByTeamRole(teamRole);

        const sortedAssociates = data.map(
          (configItem) => (
            associates.find((associate) => {
              const isSameAlignment = associate?.team?.alignment === configItem.alignment;
              const isSameDepartment = associate?.team?.department === configItem.department;

              return isSameAlignment && isSameDepartment;
            })
          ));

        setFieldValue(teamRolesItemAssociatesFieldName, sortedAssociates);

        setAssociationsConfig(data);
      } catch (e) {
        showErrorNotifier(relatedToByTeamRoleGetMessages.error, e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [name, alignment, department]);

  return (
    <Grid
      item
      container
      direction="column"
      rowSpacing={3}
      md={6}
      xl={4}
    >
      <UserManagementCreateFormTeamRoleItemAssociationsHeader
        isAssociationsAvailable={isAssociationsAvailable}
        isLoading={isLoading}
        associationsConfig={associationsConfig}
      />
      {associationsConfig.map((config, configIndex) => (
        <UserManagementCreateFormTeamRoleItemAssociationsItem
          key={config.fieldName}
          isDisabled={!isEditMode}
          associateItemFieldName={`${teamRolesItemAssociatesFieldName}.${configIndex}`}
          config={config}
        />
      ))}
    </Grid>
  );
};

export default UserManagementCreateFormTeamRoleItemAssociations;

import { ITableCell } from 'shared/Table/Table.models';
import { IUserListItem } from 'pages/UserManagement/UserManagement.models';
import { ICustomerListItem } from 'pages/Customers/Customers.models';
import { IAttachment } from 'shared/widgets/Attachments/Attachments.model';
import { IComment } from 'shared/widgets/Comments/Comments.models';

export interface IEngagementsListItem {
  engagementType: number
  companyName: string
  createdDatetime: string
  latestCommentText: string
  mrc: number
}

export type ITableCellEngagements = ITableCell<IEngagementsListItem>;

export interface IEngagement {
  engagementType: number
  subAgentName: string
  bdsSupport: string
  directSeller: string
  scopeDescription: {
    id: number
    text: string
    modifiedDatetime: string
  }
  technicalOverlay: IUserListItem
  customer: ICustomerListItem
}

export interface IEngagementAttachment extends IAttachment {
  engagementId: number
}

export interface IEngagementComment extends IComment {
  engagementId: number
}

export enum ADD_ORDER_TYPE {
  NEW_ORDER = 'NEW',
  RENEWAL_ORDER = 'RENEWAL',
}

export type TAddOrderType = ADD_ORDER_TYPE | null

import React, { useState } from 'react';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import {
  ListItemIcon, ListItemText, Menu, MenuItem,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ActionButton from 'shared/Table/Actions/ActionButton';
import { IMoreButtonConfigItem } from 'shared/Table/Actions/model';

interface IActionButtonMoreProps {
  tooltipTitle: string,
  isDisabled?: boolean,
  config?: IMoreButtonConfigItem[] | undefined
}

const ActionButtonMore: React.FC<IActionButtonMoreProps> = ({
  isDisabled, config, tooltipTitle,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl((element) => (element ? null : event.currentTarget));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ActionButton
        onClick={handleClick}
        icon={<MoreHorizIcon />}
        tooltipTitle={tooltipTitle}
        isDisabled={isDisabled as boolean || isEmpty(config)}
      />
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{
          'aria-labelledby': 'more-actions',
        }}
      >
        {map(config, ({
          onClick, isDisabled: isItemDisabled, icon, text,
        }) => (
          <MenuItem
            key={text}
            onClick={onClick}
            disabled={isItemDisabled}
          >
            <ListItemIcon>
              {icon}
            </ListItemIcon>
            <ListItemText>
              {text}
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

ActionButtonMore.defaultProps = {
  isDisabled: false,
  config: [],
};

export default ActionButtonMore;

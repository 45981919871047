// eslint-disable-next-line no-unused-vars
import { SyntheticEvent } from 'react';

export interface IOption<
  TValue = string | number | boolean,
  TLabel = string | number
> {
  value: TValue
  label: TLabel
  disabled?: boolean
}

export enum GRID_DIRECTION {
  ROW = 'row',
  COLUMN = 'column',
}

export type TOnInputChange = (e: SyntheticEvent, newInputValue: string) => void;

import {
  // eslint-disable-next-line no-unused-vars
  ITechnicalOverlayFilters, DateFilter,
  StateFilter,
} from '@dashboardWirelineSellerDepartment/TechnicalOverlay/TechnicalOverlay.models';

export const dateOptions = [
  {
    label: 'Current month',
    value: DateFilter.CURRENT_MONTH,
  },
  {
    label: 'Last month',
    value: DateFilter.LAST_MONTH,
  },
];

export const stateFilterLabels = {
  [StateFilter.OPEN]: 'Opened',
  [StateFilter.CLOSED]: 'Closed',
};

export const defaultFilters: ITechnicalOverlayFilters = {
  state: StateFilter.CLOSED,
  date: DateFilter.CURRENT_MONTH,
};

export const notifierMessage = {
  technicalOverlay: {
    error: 'Failed to complete your request for TechnicalOverlay, please try again later.',
  },
  users: {
    error: 'Failed to complete your request for Users list, please try again later.',
  },
};

export const technicalOverlayTitle = 'Technical overlay';

export const technicalOverlayModifier = 'technical-overlay';

export const modifiers = {
  title: `${technicalOverlayModifier}__title`,
  filters: `${technicalOverlayModifier}-filters`,
  dateFilter: `${technicalOverlayModifier}-filters__date`,
  usersFilter: `${technicalOverlayModifier}-filters__users`,
  stateFilter: `${technicalOverlayModifier}-filters__state`,
  stateFilterBtn: `${technicalOverlayModifier}-filters__state-btn`,
};

import React, { memo } from 'react';
import { Box, Button } from '@mui/material';

import './InsideModalConfirmation.scss';

interface InsideModalConfirmationProps {
  isVisible: boolean
  title?: string
  description?: string
  cancelLabel?: string
  confirmLabel?: string
  onCancel: () => void
  onConfirm: () => void
}

const InsideModalConfirmation: React.FC<InsideModalConfirmationProps> = ({
  title,
  isVisible,
  description,
  cancelLabel,
  confirmLabel,
  onCancel,
  onConfirm,
}) => {
  if (!isVisible) { return null; }

  return (
    <Box className="inside-modal-confirmation">
      <p className="inside-modal-confirmation__title">{title}</p>
      <p className="inside-modal-confirmation__description">{description}</p>
      <Box className="inside-modal-confirmation__actions">
        <Button
          type="submit"
          variant="contained"
          onClick={onConfirm}
          className="inside-modal-confirmation__actions-action"
        >
          {confirmLabel}
        </Button>
        <Button
          variant="outlined"
          onClick={onCancel}
          className="inside-modal-confirmation__actions-action"
        >
          {cancelLabel}
        </Button>
      </Box>
    </Box>
  );
};

InsideModalConfirmation.defaultProps = {
  confirmLabel: 'Leave',
  cancelLabel: 'Cancel',
  title: 'Are you sure you want to leave page?',
  description: 'Data you have entered would not be saved.',
};

export default memo(InsideModalConfirmation);

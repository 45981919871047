import Yup from 'core/utilities/Yup';
import { fields } from 'pages/WirelineEngagements/shared/AddOrderModalForm/constants';
// eslint-disable-next-line no-unused-vars
import { IOrderProduct } from 'pages/WirelineEngagements/shared/AddOrderModalForm/product.model';
import { checkIfSelectedProducts } from 'pages/WirelineEngagements/shared/AddOrderModalForm/utilites';

export const getOrderDataSchema = (ATAndTTSDId: string | null) => Yup.object().shape({
  [fields.productName]: Yup.string().default('').nullable(),
  [fields.categoryId]: Yup.number().default(null as unknown as number).nullable(),
  [fields.tsdId]: Yup.string().required('TSD field is required').default(''),
  [fields.locationIds]: Yup.array()
    .of(Yup.number())
    .required()
    .min(1, 'At least one location is required')
    .default([]),
  [fields.carrier]: Yup
    .string()
    .when(fields.tsdId, ([testId], shape) => (testId !== ATAndTTSDId ? shape.required('Carrier is required') : shape))
    .default(''),
  [fields.products]: Yup
    .object()
    .test(
      'products quantity',
      'At least one product is required',
      (products: IOrderProduct) => {
        const hasAddedProducts = checkIfSelectedProducts(products);

        return hasAddedProducts;
      },
    )
    .default({}),
});

const orderDefaultData = getOrderDataSchema(null).getDefault();

const getEngagementsOrderFormSchema = (ATAndTTSDId: string | null) => Yup.object().shape({
  [fields.currentTSD]: Yup.string().default(''),
  [fields.orders]: Yup.array()
    .of(getOrderDataSchema(ATAndTTSDId)).default([orderDefaultData]),
});

const engagementsOrderFormDefaultData = getEngagementsOrderFormSchema(null).getDefault();

export { orderDefaultData, engagementsOrderFormDefaultData };

export default getEngagementsOrderFormSchema;

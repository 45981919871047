import React from 'react';
import {
  colorsOfEscalationSupportCharts,
  escalationTypeChartFields, escalationTypeDateKeyMobile,
} from '@dashboardEscalationSupport/EscalationSupportCharts/constants';
import DashboardBarChart from 'pages/Dashboard/shared/DashboardBarChart';
import { map } from 'lodash';
import { useAppSelector } from 'core/store';
import { LabelPosition } from 'core/models/Recharts.models';

const EscalationSupportEscalationTypeChart = () => {
  const escalationTypeData = useAppSelector((state) => state.dashboard.escalationSupportType.data);
  const isLoading = useAppSelector((state) => state.dashboard.escalationSupportType.isLoading);

  const formattedData = map(escalationTypeData, (item) => ({
    xDateKey: item.name,
    xDateKeyMobile: escalationTypeDateKeyMobile[item.name],
    ...item.value,
  }));

  const barLabel = {
    label: { position: LabelPosition.TOP },
  };

  return (
    <DashboardBarChart
      data={formattedData}
      colors={colorsOfEscalationSupportCharts}
      fields={escalationTypeChartFields}
      isLoading={isLoading}
      title="Escalation Type"
      barLabel={barLabel}
      needInsideLabel
      enableShorthandXAxis
    />
  );
};
export default EscalationSupportEscalationTypeChart;

import {
  REMOVE_CUSTOMERS_LOCATION_REQUEST,
  REMOVE_CUSTOMERS_LOCATION_SUCCESS,
  REMOVE_CUSTOMERS_LOCATION_FAILURE,
} from 'pages/Customers/CustomersLocations/RemoveLocation/actionTypes';

export const removeLocationRequest = () => ({ type: REMOVE_CUSTOMERS_LOCATION_REQUEST });

export const removeLocationSuccess = (location) => (
  { type: REMOVE_CUSTOMERS_LOCATION_SUCCESS, location });

export const removeLocationFailure = (errors) => (
  { type: REMOVE_CUSTOMERS_LOCATION_FAILURE, errors });

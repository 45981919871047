import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useAppDispatch, useAppSelector } from 'core/store';
// eslint-disable-next-line camelcase
import { ThemeProvider, createTheme, unstable_createMuiStrictModeTheme } from '@mui/material/styles';
import { HTTPService, netsparkAxios } from 'core/services/HTTPService';
import getAppVariablesAction from 'core/state/GetAppVariables/actions';
import { getUserProfileAction } from 'layouts/BaseLayout/GetUserProfile';
import themeConfig from 'core/configs/themeConfig';
import RouterProvider from 'core/routing/RouterProvider';
import { SnackbarProvider } from 'notistack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import KeycloakService from 'core/services/KeycloakService';
import './App.scss';

const isDevelopment = process.env.NODE_ENV === 'development';
// eslint-disable-next-line camelcase
const createCustomTheme = isDevelopment ? unstable_createMuiStrictModeTheme : createTheme;

const theme = createCustomTheme(themeConfig);

const App = () => {
  const dispatch = useAppDispatch();
  const userProfileData = useAppSelector((state) => state.userProfile.data);
  const appVariablesData = useAppSelector((state) => state.appVariables.data);

  const setupInitialData = async () => {
    const appVariables = await dispatch(getAppVariablesAction());

    HTTPService.setDefaultGlobalConfig(netsparkAxios, appVariables.apiUrl);

    const userProfileController = HTTPService.getController();
    await dispatch(getUserProfileAction(userProfileController));
  };

  useEffect(() => {
    (async () => {
      await setupInitialData();
    })();
  }, []);

  if (!KeycloakService.isLoggedIn()) {
    KeycloakService.doLogin();
  }

  if (isEmpty(userProfileData) || isEmpty(appVariablesData)) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        preventDuplicate
        iconVariant={{
          success: <CheckCircleIcon color="success" />,
          error: <ErrorRoundedIcon color="error" />,
        }}
      >
        <RouterProvider />
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;

import React, { useEffect } from 'react';
import {
// eslint-disable-next-line no-unused-vars
  Form, useFormik, FormikProvider, FormikHelpers,
} from 'formik';
import { CustomDialogContent } from 'shared/CustomDialog';
import { useAppSelector, useAppDispatch } from 'core/store';
import { useUpdateEffect } from 'core/hooks/useUpdateEffect';
// eslint-disable-next-line no-unused-vars
import { IOption } from 'shared/FormikField/FormikField.model';
import FormikDatePicker from 'shared/FormikField/FormikDatePicker';
import FormikSelectField from 'shared/FormikField/FormikSelectField';
import getUsersAction from 'core/actions/Customers/GetUsers/actions';
import { getOptionsFromUser } from 'core/utilities/getOptionsFromUser';
import TeamMembersList from 'pages/Customers/CustomersList/ReassignSellerModal/TeamMembersList';
import FormFieldWrapper from 'pages/Customers/CustomersList/ReassignSellerModal/FormFieldWrapper';
// eslint-disable-next-line no-unused-vars
import { IReassignSellerEdit, IReassignSellerForm } from 'pages/Customers/CustomersList/ReassignSellerModal/ReassignSellerModal.models';
import { minDate, reassignSellerModalFormSchema } from 'pages/Customers/CustomersList/ReassignSellerModal/reassignSellerModalFormSchema';
import {
  sellersParams,
  dateFieldDescription,
  currentTeamUsersList,
  sellerFieldDescription,
  alignedBackOfficeUsers,
  alignedBackOfficeUsersList,
  reassignSellerModalSubtitle,
} from 'pages/Customers/CustomersList/contants';

interface IReassignSellerFormProps {
  sellerId: number;
  onClose: () => void;
  setWithLeaveConfirmation: (value: boolean) => void;
  onSubmitHandler: (
    values: IReassignSellerEdit,
    helper: FormikHelpers<IReassignSellerForm>
    ) => void;
}

const initialValues = reassignSellerModalFormSchema.getDefault();

const ReassignSellerForm: React.FC<IReassignSellerFormProps> = ({
  onClose,
  sellerId,
  onSubmitHandler,
  setWithLeaveConfirmation,
}) => {
  const dispatch = useAppDispatch();

  const isLoadingUsers = useAppSelector((state) => state.customers.users.isLoading);
  const sellersList = useAppSelector((state) => state.customers.users.results);
  const sellersOptions: IOption[] = getOptionsFromUser(sellersList, 'id');

  const onSubmit = (formValues: IReassignSellerForm, formikHandler) => {
    const newSeller = sellersList.find((item) => item.id === Number(formValues.seller));
    const params = { ...formValues, seller: newSeller.email };

    onSubmitHandler(params, formikHandler);
  };

  const formikData = useFormik({
    onSubmit,
    initialValues,
    validationSchema: reassignSellerModalFormSchema,
  });

  const {
    values, dirty, submitForm, isSubmitting,
  } = formikData;

  useUpdateEffect(() => {
    setWithLeaveConfirmation(dirty && !isSubmitting);
  }, [dirty, isSubmitting]);

  useEffect(() => {
    dispatch(getUsersAction(sellersParams));
  }, []);

  return (
    <FormikProvider value={formikData}>
      <CustomDialogContent
        areButtonsVisible
        onClose={onClose}
        title="Reassign Seller"
        confirmButtonText="Save"
        onClickConfirm={submitForm}
        isLoading={isSubmitting || isLoadingUsers}
      >
        <Form>
          <p className="reassign-seller-modal__title">
            {reassignSellerModalSubtitle}
          </p>
          <TeamMembersList
            sellerId={sellerId}
            title="Current Team"
            renderList={currentTeamUsersList}
          />
          <FormFieldWrapper
            title="New Seller"
            description={sellerFieldDescription}
          >
            <FormikSelectField
              required
              fullWidth
              label="Seller"
              fieldName="seller"
              options={sellersOptions}
            />
          </FormFieldWrapper>
          <TeamMembersList
            sellerId={values.seller}
            title="Aligned Back Office Users"
            description={alignedBackOfficeUsers}
            renderList={alignedBackOfficeUsersList}
          />
          <FormFieldWrapper
            title="Transition Period"
            description={dateFieldDescription}
          >
            <FormikDatePicker
              required
              label="Date"
              minDate={minDate}
              fieldName="expirationDate"
              classNameField="reassign-seller-modal__date-field"
            />
          </FormFieldWrapper>
        </Form>
      </CustomDialogContent>
    </FormikProvider>
  );
};

export default ReassignSellerForm;

import React from 'react';
import { useField } from 'formik';
// eslint-disable-next-line no-unused-vars
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FormControl, FormHelperText } from '@mui/material';
import FormikFieldWrapper from 'shared/FormikField/FormikFieldWrapper';
import { dateFormat } from 'pages/Dashboard/shared/FiltersByPeriod/constants';

interface IFormikDatePickerProps {
  label: string
  format?: string
  fieldName: string
  required?: boolean
  disabled?: boolean
  closeOnSelect?: boolean
  classNameField?: string
  minDate?: Dayjs | undefined
  maxDate?: Dayjs | undefined
}

const inputAdornment = {
  position: 'start' as 'start',
  sx: { marginRight: '0 !important' },
};

const actionBar = {
  actions: ['accept' as 'accept', 'clear' as 'clear'],
};

const FormikDatePicker: React.FC<IFormikDatePickerProps > = ({
  fieldName, minDate, maxDate, format, label, required, closeOnSelect, disabled, classNameField,
}) => {
  const [field, error, helpers] = useField(fieldName);

  const isError = Boolean(error.error && error.touched);
  const errorText = isError ? error.error : ' ';

  const selectedDate = field.value ? dayjs(field.value) : null;

  const onChange = (date: Dayjs | null = null) => {
    helpers.setValue(date ? date.format(format) : date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormikFieldWrapper
        label={label}
        fieldName={fieldName}
        required={required as boolean}
      >
        <FormControl error={isError}>
          <MUIDatePicker
            {...field}
            minDate={minDate}
            maxDate={maxDate}
            onChange={onChange}
            value={selectedDate}
            format={format as string}
            disabled={disabled as boolean}
            className={classNameField as string}
            closeOnSelect={closeOnSelect as boolean}
            slotProps={{
              inputAdornment,
              actionBar,
            }}
          />
          <FormHelperText error={isError}>
            {errorText}
          </FormHelperText>
        </FormControl>
      </FormikFieldWrapper>
    </LocalizationProvider>
  );
};

FormikDatePicker.defaultProps = {
  format: dateFormat,
  required: false,
  closeOnSelect: true,
  disabled: false,
  minDate: undefined,
  maxDate: undefined,
  classNameField: '',
};

export default FormikDatePicker;

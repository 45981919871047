import React, { useState } from 'react';
import noop from 'lodash/noop';
import Table from 'shared/Table';
import isEmpty from 'lodash/isEmpty';
import { useAppSelector } from 'core/store';
import CreateButton from 'shared/CreateButton';
import { TRow } from 'shared/Table/Table.models';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useTableContext } from 'shared/Table/TableContext';
import ExportSowTable from '@EngagementsSowData/ExportSowTable';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useUserPermissions } from '@EngagementsSowData/useUserPermissions';
import SowDataActionsButton from '@EngagementsSowData/SowDataActionsButton';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { useTableSelectedRows } from 'shared/Table/hooks/useTableSelectedRows';
import { ISowDataListItem, OrderActions } from '@EngagementsSowData/sowData.model';
import { colDefOptionActionsTwoIcons, colDefOptionSelect } from 'shared/Table/constants';
import WirelineEngagementsSowDataModals from '@EngagementsSowData/WirelineEngagementsSowDataModals';
import WirelineEngagementsSowDataTableRowActions from '@EngagementsSowData/WirelineEngagementsSowDataTableRowActions';
import { defaultColumnPinning, muiTableContainerProps } from 'pages/WirelineEngagements/WirelineEngagementsTables/constants';
import { TAddOrderType, ADD_ORDER_TYPE } from 'pages/WirelineEngagements/WirelineEngagements.models';

const WirelineEngagementsSowDataTable = () => {
  const { rowSelection } = useTableContext();

  const { activeRowMode, secondarySidebarMode } = useTableActiveRowContext<ISowDataListItem>();
  const { isEditMode, isViewMode } = getTableRowModeBoolean(activeRowMode);

  const {
    isActionsUnavailable,
    isUnavailableAddOrderButton,
  } = useUserPermissions();

  const results = useAppSelector((state) => state.wirelineEngagements.salesSow.results);
  const count = useAppSelector((state) => state.wirelineEngagements.salesSow.count);
  const isLoading = useAppSelector((state) => state.wirelineEngagements.salesSow.isLoading);

  const isAddProductButtonDisabled = isUnavailableAddOrderButton
    || isEditMode
    || isViewMode
    || !isEmpty(rowSelection);

  const [addOrderModalType, setAddOrderModalType] = useState<TAddOrderType>(null);

  const selectedOrders = useTableSelectedRows<ISowDataListItem>({ rows: results });

  const [isActionsModalOpen, setIsActionsModalOpen] = useState(false);
  const [orderAction, setOrderAction] = useState(OrderActions.REQUEST_QUOTES);

  const openAddProductModal = () => setAddOrderModalType(ADD_ORDER_TYPE.NEW_ORDER);

  const addProductActionComponent = secondarySidebarMode ? <MoreHorizIcon /> : 'add order';

  const addProductAction = (
    <CreateButton
      className="sow-data-btn"
      onClick={openAddProductModal}
      text={addProductActionComponent}
      disabled={isAddProductButtonDisabled}
    />
  );

  const renderBottomToolbarCustomActions = () => (
    <ExportSowTable sowDataItem={results[0]} />
  );

  return (
    <>
      <Table
        className="wireline-table"
        isLoading={isLoading}
        rowCount={count}
        toolBarAction={addProductAction}
        showColumnFilters
        showGlobalFilter
        handleRowClick={noop}
        enableSelectAll={false}
        rows={results as unknown as TRow[]}
        initialColumnPinning={defaultColumnPinning}
        muiTableContainerProps={muiTableContainerProps}
        enableStickyHeader
        actionsButton={(
          <SowDataActionsButton
            selectedOrders={selectedOrders}
            setIsActionsModalOpen={setIsActionsModalOpen}
            setOrderAction={setOrderAction}
          />
        )}
        renderRowActions={({ row: { original } }) => (
          <WirelineEngagementsSowDataTableRowActions
            row={original as unknown as ISowDataListItem}
          />
        )}
        displayColumnDefOptions={{
          ...colDefOptionSelect,
          ...(!isActionsUnavailable ? colDefOptionActionsTwoIcons : undefined),
        }}
        renderBottomToolbarCustomActions={renderBottomToolbarCustomActions}
      />
      <WirelineEngagementsSowDataModals
        addOrderModalType={addOrderModalType}
        setAddOrderModalType={setAddOrderModalType}
        isActionsModalOpen={isActionsModalOpen}
        setIsActionsModalOpen={setIsActionsModalOpen}
        orderAction={orderAction}
        selectedOrders={selectedOrders}
      />
    </>
  );
};

export default WirelineEngagementsSowDataTable;

import React from 'react';
import ReactRouterPrompt from 'react-router-prompt';
import ConfirmDialog from 'shared/ConfirmDialog';

interface IRouterFormConfirmDialogProps {
  when: boolean
}

const RouterFormConfirmDialog: React.FC<
  IRouterFormConfirmDialogProps
> = ({ when }) => (
  <ReactRouterPrompt when={when}>
    {({ isActive, onConfirm, onCancel }) => (
      <ConfirmDialog
        visible={isActive}
        confirmLabel="Leave"
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
    )}
  </ReactRouterPrompt>
);

export default RouterFormConfirmDialog;

// eslint-disable-next-line no-unused-vars
import { IOption } from 'shared/FormikField/FormikField.model';

export const getOneOfFromType = (type: object) => [...Object.values(type), ''];

export const getOneOfFromOption = <
  TypeToReturn extends IOption['value']
>(
    options: IOption[],
  ) => options.map(({ value }) => `${value}` as TypeToReturn);

export default {
  getOneOfFromType,
};

import React from 'react';
import InsideSalesDepartmentProvider from '@dashboardWirelineInsideSalesDepartment/InsideSalesDepartmentContext';
import InsideSalesDepartmentContent from '@dashboardWirelineInsideSalesDepartment/InsideSalesDepartmentContent';
import '@dashboardWirelineInsideSalesDepartment/InsideSalesDepartment.scss';

const InsideSalesDepartment = () => (
  <InsideSalesDepartmentProvider>
    <InsideSalesDepartmentContent />
  </InsideSalesDepartmentProvider>
);

export default InsideSalesDepartment;

import React from 'react';
import WirelineDepartmentsTabs from '@dashboardWireline/WirelineDepartments/WirelineDepartmentsTabs';
import { Box } from '@mui/material';

const WirelineDepartments = () => (
  <Box data-testid="departments-tabs" sx={{ m: '20px 0' }}>
    <WirelineDepartmentsTabs />
  </Box>
);

export default WirelineDepartments;

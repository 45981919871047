import React from 'react';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';

import DashboardPieChart from 'pages/Dashboard/shared/DashboardPieChart';
import { useAppSelector } from 'core/store';
import { colorsOfCharts } from '@dashboardWirelineRetentionDepartment/constants';

interface ITechnicalOverlayChartProps {
  selectedUsers: string[]
}

const TechnicalOverlayChart: React.FC<
  ITechnicalOverlayChartProps
> = ({ selectedUsers }) => {
  const technicalOverlayData = useAppSelector((state) => state.dashboard.technicalOverlay.data);
  const isLoading = useAppSelector((state) => state.dashboard.technicalOverlay.isLoading);

  const filteredData = (
    isEmpty(selectedUsers)
      ? [...technicalOverlayData].filter(({ potentialProfit }) => !!potentialProfit)
      : filter(
        technicalOverlayData,
        ({ sellerFullname }) => selectedUsers.includes(sellerFullname),
      )
  );

  const sortData = filteredData
    .sort((a, b) => b.potentialProfit - a.potentialProfit)
    .map(({ potentialProfit, sellerFullname }) => ({
      name: sellerFullname,
      value: potentialProfit,
    }));

  return (
    <DashboardPieChart
      colors={colorsOfCharts}
      data={sortData}
      isLoading={isLoading}
      innerRadius={60}
      paddingAngle={2}
    />
  );
};

export default TechnicalOverlayChart;

import React, {
  createContext, useContext, useMemo, useState,
} from 'react';
import { IUser, UserType } from 'pages/UserManagement/UserManagement.models';
import { editModeTeamRoleFalseIndex } from 'pages/UserManagement/constants';
import { ITeam } from 'pages/UserManagement/UserManagementCreate/UserManagementGeneralForm/UserManagementGeneralForm.models';

const UserManagementCreateContext = createContext<IUserManagementCreateContext>({
  teams: [],
  isUserHasEmailOnly: false,
  isUserFilled: false,
  isEditModeGeneral: false,
  setIsEditModeGeneral: () => {},
  isEditModeTeamRole: false,
  indexEditModeTeamRole: editModeTeamRoleFalseIndex,
  setIndexEditModeTeamRole: () => {},
  getIsTeamRoleInEditMode: () => false,
  onDisableTeamRoleEditMode: () => {},
  isGeneralFormDirty: false,
  setIsGeneralFormDirty: () => {},
  isTeamRoleFormDirty: false,
  setIsTeamRoleFormDirty: () => {},
  userType: undefined,
  setUserType: () => {},
});

interface IUserManagementCreateProviderProps {
  children: React.ReactNode
  teams: ITeam[]
  initialUserValues: IUser
}

interface IUserManagementCreateContext {
  teams: ITeam[]
  isUserHasEmailOnly: boolean
  isUserFilled: boolean
  isEditModeGeneral: boolean
  setIsEditModeGeneral: (value: boolean) => void
  isEditModeTeamRole: boolean
  indexEditModeTeamRole: number
  setIndexEditModeTeamRole: (value: number) => void
  getIsTeamRoleInEditMode: (index: number) => boolean
  onDisableTeamRoleEditMode: () => void
  isGeneralFormDirty: boolean
  setIsGeneralFormDirty: (value: boolean) => void
  isTeamRoleFormDirty: boolean
  setIsTeamRoleFormDirty: (value: boolean) => void
  userType: UserType | undefined
  setUserType: (value: UserType) => void
}

export const UserManagementCreateProvider: React.FC<
  IUserManagementCreateProviderProps
> = ({ children, teams, initialUserValues }) => {
  const [isEditModeGeneral, setIsEditModeGeneral] = useState(false);
  const [indexEditModeTeamRole, setIndexEditModeTeamRole] = useState(editModeTeamRoleFalseIndex);
  const [isGeneralFormDirty, setIsGeneralFormDirty] = useState(false);
  const [isTeamRoleFormDirty, setIsTeamRoleFormDirty] = useState(false);
  const [userType, setUserType] = useState<UserType | undefined>();

  const isHasEmail = !!initialUserValues.email;
  const isHasFirstName = !!initialUserValues.firstName;

  const isUserHasEmailOnly = isHasEmail && !isHasFirstName;
  const isUserFilled = isHasEmail && isHasFirstName;

  const getIsTeamRoleInEditMode = (index: number) => index === indexEditModeTeamRole;

  const onDisableTeamRoleEditMode = () => {
    setIndexEditModeTeamRole(editModeTeamRoleFalseIndex);
  };

  const value = useMemo(() => ({
    teams,
    isUserHasEmailOnly,
    isUserFilled,
    isEditModeGeneral,
    initialUserValues,
    setIsEditModeGeneral,
    isEditModeTeamRole: indexEditModeTeamRole !== editModeTeamRoleFalseIndex,
    indexEditModeTeamRole,
    setIndexEditModeTeamRole,
    getIsTeamRoleInEditMode,
    onDisableTeamRoleEditMode,
    isGeneralFormDirty,
    setIsGeneralFormDirty,
    isTeamRoleFormDirty,
    setIsTeamRoleFormDirty,
    userType,
    setUserType,
  }), [
    teams, isUserFilled, isUserHasEmailOnly, isEditModeGeneral, indexEditModeTeamRole,
    initialUserValues, isGeneralFormDirty, isTeamRoleFormDirty, userType,
  ]);

  return (
    <UserManagementCreateContext.Provider value={value}>
      {children}
    </UserManagementCreateContext.Provider>
  );
};

export const useUserManagementCreateContext = (): IUserManagementCreateContext => (
  useContext(UserManagementCreateContext)
);

export default UserManagementCreateProvider;

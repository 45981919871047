import React from 'react';
// import get from 'lodash/get';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LoopIcon from '@mui/icons-material/Loop';
import { getCustomersEditPath } from 'core/routing/paths';
import ActionButton from 'shared/Table/Actions/ActionButton';
import { ICustomer } from 'pages/Customers/Customers.models';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

interface ICustomersListTableRowActionsPermissions {
  isAvailableEditCustomer: boolean
    isAvailableEditCustomerSeller: boolean
}
interface ICustomersListTableRowActionsProps {
  row: ICustomer
  activeCustomerData: ICustomer | null
  permissions: ICustomersListTableRowActionsPermissions;
  setActiveCustomerData: (customerData: ICustomer | null) => void
}

const CustomersListTableRowActions: React.FC<
  ICustomersListTableRowActionsProps
> = ({
  row, permissions, activeCustomerData, setActiveCustomerData,
}) => {
  const navigate = useNavigate();

  const {
    isAvailableEditCustomer,
    isAvailableEditCustomerSeller,
  } = permissions;

  const isSelectedReassignSellerButton = activeCustomerData?.id === row.id;

  const onEdidCustomerHandler = () => {
    navigate(getCustomersEditPath(row.id));
  };

  const onReassignSellerHandler = () => setActiveCustomerData(row);

  return (
    <Grid container>
      {isAvailableEditCustomer
        && (
          <ActionButton
            tooltipTitle="Edit"
            icon={<EditOutlinedIcon />}
            onClick={onEdidCustomerHandler}
          />
        )}
      {isAvailableEditCustomerSeller
        && (
          <ActionButton
            icon={<LoopIcon />}
            tooltipTitle="Reassign seller"
            onClick={onReassignSellerHandler}
            isSelected={isSelectedReassignSellerButton}
          />
        )}
    </Grid>
  );
};

export default CustomersListTableRowActions;

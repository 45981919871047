import { AttachmentsCategory } from 'shared/widgets/Attachments/Attachments.model';
import { attachmentsCategoriesName } from 'shared/widgets/Attachments/constants';

export const wirelineAttachmentsConfig = [
  {
    tabName: attachmentsCategoriesName.attachments,
    category: AttachmentsCategory.ATTACHMENTS,
  },
  {
    tabName: attachmentsCategoriesName.quotes,
    category: AttachmentsCategory.QUOTES,
  },
  {
    tabName: attachmentsCategoriesName.contracts,
    category: AttachmentsCategory.CONTRACTS,
  },
  {
    tabName: attachmentsCategoriesName.networkDiagram,
    category: AttachmentsCategory.NETWORK_DIAGRAMS,
  },
];

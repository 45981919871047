import React from 'react';
import { ToggleButton } from '@mui/material';
import CustomTooltip from 'shared/CustomTooltip';
import { TooltipPlacement } from 'shared/CustomTooltip/customTooltip.models';

import './ActionButton.scss';

interface IActionEditProps {
  onClick: (e: React.MouseEvent<HTMLElement>) => void,
  tooltipTitle: string,
  icon: React.ReactNode,
  isDisabled?: boolean
  isSelected?: boolean
}

const ActionButton: React.FC<IActionEditProps> = ({
  onClick, tooltipTitle, icon, isDisabled, isSelected,
}) => (
  <CustomTooltip
    title={tooltipTitle}
    placement={TooltipPlacement.TOP}
  >
    <ToggleButton
      className="table__action-btn"
      data-testid="table__action-btn"
      onClick={onClick}
      disabled={isDisabled as boolean}
      selected={isSelected as boolean}
      value={tooltipTitle}
      aria-label={tooltipTitle}
    >
      {icon}
    </ToggleButton>
  </CustomTooltip>

);

ActionButton.defaultProps = {
  isDisabled: false,
  isSelected: false,
};

export default ActionButton;

// eslint-disable-next-line no-unused-vars
import { IUserExtended, UserTeamRoleTeamDepartment } from 'pages/UserManagement/UserManagement.models';
import { ITeamMembersData } from 'pages/Customers/CustomersList/ReassignSellerModal/ReassignSellerModal.models';

const { SELLER } = UserTeamRoleTeamDepartment;

export const getUsersData = (userData: IUserExtended) => {
  const { teamRoles, firstName, lastName } = userData;
  const teamRole = teamRoles.find((item) => item.team.department === SELLER);

  if (!teamRole) return {};

  const sellerData = { [SELLER]: `${firstName} ${lastName}` };

  const teamData = teamRole.associates.reduce<ITeamMembersData>((value, current) => ({
    ...value,
    [current.team.department]: `${current.user.firstName} ${current.user.lastName}`,
  }), {});

  return { ...teamData, ...sellerData };
};

import React from 'react';
import DateAutocomplete
  from '@dashboardWirelineSellerDepartment/TechnicalOverlay/TechnicalOverlayFilters/DateAutocomplete';
import { dateOptions, modifiers } from '@dashboardWirelineSellerDepartment/TechnicalOverlay/constants';

import StateFilterButtons from 'pages/Dashboard/BusinessDirections/Wireline/WirelineDepartments/SellerDepartment/TechnicalOverlay/TechnicalOverlayFilters/StateFilterButtons';
import UsersSelect from '@dashboardWirelineSellerDepartment/TechnicalOverlay/TechnicalOverlayFilters/UsersSelect';
import {
  // eslint-disable-next-line no-unused-vars
  ITechnicalOverlayFilters, DateFilter, StateFilter,
} from '@dashboardWirelineSellerDepartment/TechnicalOverlay/TechnicalOverlay.models';

interface ITechnicalOverlayFiltersProps {
  filters: ITechnicalOverlayFilters,
  setFilters: (
      updateFunction: (prevState: ITechnicalOverlayFilters) => ITechnicalOverlayFilters
  ) => void
  selectedUsers: string[]
  setSelectedUsers: (users: string[]) => void
}

const TechnicalOverlayFilters: React.FC<
  ITechnicalOverlayFiltersProps
> = ({
  filters, setFilters, selectedUsers, setSelectedUsers,
}) => {
  const onChangeDate = (date: DateFilter) => {
    setFilters((prevState) => ({
      ...prevState,
      date,
    }));
  };

  const onChangeStateFilter = (state: StateFilter) => {
    setFilters((prevState) => ({
      ...prevState,
      state,
    }));
  };

  return (
    <div className={modifiers.filters}>
      <DateAutocomplete
        options={dateOptions}
        onChange={onChangeDate}
        value={filters.date}
      />
      <UsersSelect
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
      />
      <StateFilterButtons
        onChange={onChangeStateFilter}
        activeState={filters.state}
      />
    </div>
  );
};

export default TechnicalOverlayFilters;

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { getEngagementById } from 'core/api/wirelineEngagements.api';
import { IEngagement } from 'pages/WirelineEngagements/WirelineEngagements.models';
import { reinitializeEngagementNotifications } from 'pages/WirelineEngagements/WirelineEngagementsCreate/constants';

const useEngagementsForm = () => {
  const { wirelineEngagementId } = useParams();
  const { showErrorNotifier } = useNotifiers();

  const [engagementsInitialValues, setEngagementsInitialValues] = useState<
    IEngagement | null
  >(null);
  const [isInitializing, setIsInitializing] = useState(true);
  const [isEditModeGeneral, setIsEditModeGeneral] = useState(false);

  const getEngagement = async (engagementId) => {
    try {
      const { data } = await getEngagementById(engagementId);
      setEngagementsInitialValues(data);
    } catch (e) {
      showErrorNotifier(reinitializeEngagementNotifications.error, e);
    }
  };

  const getInitialData = async () => {
    if (wirelineEngagementId) {
      await getEngagement(wirelineEngagementId);
    }

    setIsInitializing(false);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const initialCustomerId = engagementsInitialValues?.customer?.id;

  return {
    isInitializing,
    isEditModeGeneral,
    initialCustomerId,
    engagementsInitialValues,
    getEngagement,
    setIsEditModeGeneral,
  };
};

export default useEngagementsForm;

export const createEngagementNotifications = {
  success: 'Engagement created successfully',
  error: 'Error creation engagement',
};

export const updateEngagementNotifications = {
  success: 'Engagement updated successfully',
  error: 'Error update engagement',
};

export const updateEngagementScopeDescriptionNotifications = {
  success: 'Scope description updated successfully',
  error: 'Error update scope description',
};

export const reinitializeEngagementNotifications = {
  error: 'Error loading engagement',
};

import React from 'react';
import { useAppSelector } from 'core/store';
import DashboardPieChart from 'pages/Dashboard/shared/DashboardPieChart';
import { prepareChartData } from '@dashboardWirelineRetentionDepartment/utilities';

interface IRenewedEngagementsChart {
    selectedProducts: string[]
}

const RenewedEngagementsChart: React.FC <IRenewedEngagementsChart> = (
  { selectedProducts },
) => {
  const renewedEngagementsData = useAppSelector(
    (state) => state.dashboard.renewedEngagements.data,
  );
  const isLoading = useAppSelector(
    (state) => state.dashboard.renewedEngagements.isLoading,
  );

  const { chartData, colors } = prepareChartData(selectedProducts, renewedEngagementsData);

  return (
    <DashboardPieChart
      colors={colors}
      data={chartData}
      isLoading={isLoading}
      innerRadius={60}
      paddingAngle={2}
    />
  );
};

export default RenewedEngagementsChart;

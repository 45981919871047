import React, { useEffect, useState } from 'react';
import { HTTPService } from 'core/services';
import { useAppDispatch } from 'core/store';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import FiltersByPeriod from 'pages/Dashboard/shared/FiltersByPeriod';
import { useActionsPermissions } from 'core/hooks/useActionsPermissions';
import ProjectStatusChart from '@dashboardWirelinePMDepartment/ProjectStatusChart';
import { defaultPeriod, notifierMessage } from '@dashboardWirelinePMDepartment/constants';
import ProjectAcceptanceChart from '@dashboardWirelinePMDepartment/ProjectAcceptanceChart';
import getProjectStatusAction from '@dashboardWirelinePMDepartment/GetProjectStatus/actions';
import { ACTIONS_DASHBOARD_WIRELINE_PROJECT_MANAGER_PATHS } from 'core/models/actionsPermissions';
import getProjectAcceptanceAction from '@dashboardWirelinePMDepartment/GetProjectAcceptance/actions';

import './ProjectManagerDepartment.scss';

const {
  PROJECT_STATUS_CHART_GET,
  PROJECT_ACCEPTANCE_CHART_GET,
} = ACTIONS_DASHBOARD_WIRELINE_PROJECT_MANAGER_PATHS;

const actionsPaths = {
  isAvailableGetProjectStatus: PROJECT_STATUS_CHART_GET,
  isAvailableGetProjectAcceptance: PROJECT_ACCEPTANCE_CHART_GET,
};

let projectAcceptanceController = HTTPService.getController();
let projectStatusController = HTTPService.getController();

const ProjectManagerDepartment = () => {
  const { showErrorNotifier } = useNotifiers();

  const dispatch = useAppDispatch();

  const {
    isAvailableGetProjectStatus,
    isAvailableGetProjectAcceptance,
  } = useActionsPermissions(actionsPaths);

  const [period, setPeriod] = useState(defaultPeriod);

  const cancelGetProjectAcceptanceRequest = () => {
    HTTPService.cancelRequest(projectAcceptanceController);
  };

  const cancelGetProjectStatusRequest = () => {
    HTTPService.cancelRequest(projectStatusController);
  };

  const getProjectAcceptance = async (newPeriod) => {
    if (!isAvailableGetProjectAcceptance) return;
    cancelGetProjectAcceptanceRequest();

    try {
      projectAcceptanceController = HTTPService.getController();
      await dispatch(getProjectAcceptanceAction(projectAcceptanceController, newPeriod));
    } catch (e) {
      showErrorNotifier(notifierMessage.projectAcceptance.error, e);
    }
  };
  const getProjectStatus = async (newPeriod) => {
    if (!isAvailableGetProjectStatus) return;
    cancelGetProjectStatusRequest();

    try {
      projectStatusController = HTTPService.getController();
      await dispatch(getProjectStatusAction(projectStatusController, newPeriod));
    } catch (e) {
      showErrorNotifier(notifierMessage.projectStatus.error, e);
    }
  };

  const updateCharts = (newPeriod) => {
    getProjectAcceptance(newPeriod);
    getProjectStatus(newPeriod);

    setPeriod(newPeriod);
  };

  const cancelRequests = () => {
    cancelGetProjectAcceptanceRequest();
    cancelGetProjectStatusRequest();
  };

  useEffect(() => {
    updateCharts(period);

    return () => cancelRequests();
  }, []);

  return (
    <div className="project-manager">
      <div className="project-manager__filters">
        <FiltersByPeriod
          period={period}
          onChange={updateCharts}
        />
      </div>
      <div className="project-manager__pie-charts">
        {isAvailableGetProjectAcceptance && <ProjectAcceptanceChart />}
        {isAvailableGetProjectStatus && <ProjectStatusChart />}
      </div>
    </div>
  );
};

export default ProjectManagerDepartment;

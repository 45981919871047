import React from 'react';
import { TableActiveRowContextProvider } from 'shared/Table/TableActiveRowContext';
import WirelineEngagementsActiveNumbersContainer from '@EngagementsActiveNumbers/WirelineEngagementsActiveNumbersContainer';

const WirelineEngagementsActiveNumbers = () => (
  <TableActiveRowContextProvider>
    <WirelineEngagementsActiveNumbersContainer />
  </TableActiveRowContextProvider>
);

export default WirelineEngagementsActiveNumbers;

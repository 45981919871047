import {
  GET_PROJECT_STATUS_REQUEST,
  GET_PROJECT_STATUS_SUCCESS,
  GET_PROJECT_STATUS_FAILURE,
} from '@dashboardWirelinePMDepartment/GetProjectStatus/actionsTypes';

export const getProjectStatusRequest = () => (
  { type: GET_PROJECT_STATUS_REQUEST }
);

export const getProjectStatusSuccess = (data) => ({
  type: GET_PROJECT_STATUS_SUCCESS,
  data,
});

export const getProjectStatusFailure = (error) => ({
  type: GET_PROJECT_STATUS_FAILURE,
  error,
});

import React from 'react';
import { useField } from 'formik';
import {
  FormHelperText, Grid, InputLabel,
} from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import Loader from 'shared/Loader';
import NoData from 'shared/NoData';
import FormikCounterField from 'shared/FormikField/FormikCounterField';
import { fields } from 'pages/WirelineEngagements/shared/AddOrderModalForm/constants';
import { IProduct } from 'pages/WirelineEngagements/shared/AddOrderModalForm/product.model';

interface IAddOrderProductsField {
  title: string;
  itemCount: number;
  isLoading?: boolean;
  products: IProduct[];
}
const AddOrderProductsField:React.FC<IAddOrderProductsField> = ({
  title, products, itemCount, isLoading,
}) => {
  const [, { error, touched }] = useField(`${fields.orders}[${itemCount}][${fields.products}]`);

  const isEmptyProducts = !products || !products.length;

  const isError = Boolean(error && touched);
  const errorText = isError ? error : ' ';

  return (
    <Grid
      item
      container
      xs={12}
      flexDirection="column"
      className="add-order-modal-form__product-field-wrapper"
    >
      <Grid
        item
        xs={12}
        pb={3}
      >
        <InputLabel error={!!isError} required>
          {title}
        </InputLabel>
      </Grid>
      <Grid item container xs={12} rowSpacing={3} alignContent="flex-start" alignItems="center" className="add-order-modal-form__product-field-content">
        {isLoading && <Loader />}
        {!isLoading && isEmptyProducts && <NoData icon={<DescriptionIcon />} title="No products" />}
        {!isLoading && !isEmptyProducts && products.map((product) => (
          <Grid key={product.id} item xs={6}>
            <FormikCounterField
              label={product.name}
              fieldName={`${fields.orders}[${itemCount}].${fields.products}.${product.id}`}
            />
          </Grid>
        ))}
      </Grid>
      <FormHelperText error={!!isError}>
        {errorText}
      </FormHelperText>
    </Grid>
  );
};

AddOrderProductsField.defaultProps = {
  isLoading: false,
};

export default AddOrderProductsField;

export enum StateFilter {
    CLOSED = 'CLOSED',
    OPEN = 'OPEN'
}

export enum DateFilter {
    CURRENT_MONTH = 'CURRENT_MONTH',
    LAST_MONTH = 'LAST_MONTH'
}

export interface ITechnicalOverlayFilters {
    state: StateFilter;
    date: DateFilter;
}

export interface ITechnicalOverlayParams {
    state: StateFilter;
    month: number;
    year: number;
}

export interface ISelectOption {
    label: string
    value: string
}

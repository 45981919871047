import React, { useMemo, useState } from 'react';
import { useField } from 'formik';
import { useAppSelector } from 'core/store';
import { GRID_DIRECTION } from 'shared/FormikField/FormikField.model';
import FormikAutocompleteField from 'shared/FormikField/FormikAutocompleteField';
import { getOptionsList } from 'pages/WirelineEngagements/shared/AddOrderModalForm/utilites';
import { fields, nonATAndTCategoryLabel } from 'pages/WirelineEngagements/shared/AddOrderModalForm/constants';

interface IAddOrderProductCategoriesFieldProps {
  itemCount: number
  getProducts: (itemCount: number) => void
}

const AddOrderProductCategoriesField: React.FC<IAddOrderProductCategoriesFieldProps> = ({
  itemCount, getProducts,
}) => {
  const fieldName = `${fields.orders}[${itemCount}].${fields.categoryId}`;
  const categories = useAppSelector((state) => state.wirelineEngagements.productCategories.data);
  const [inputValue, setInputValue] = useState<string>('');
  const [field] = useField(fieldName);

  const categoriesOptions = useMemo(
    () => getOptionsList({
      list: categories,
      excludesLabels: [nonATAndTCategoryLabel],
    }),
    [categories]);

  const onInputChange = (_, newValue) => {
    setInputValue(newValue);
  };

  const defaultValue = categoriesOptions.find((item) => field.value === item.value);

  return (
    <FormikAutocompleteField
      fieldName={fieldName}
      inputValue={inputValue}
      options={categoriesOptions}
      defaultValue={defaultValue}
      onInputChange={onInputChange}
      label="Filter by Product Category"
      gridDirection={GRID_DIRECTION.COLUMN}
      onChange={() => getProducts(itemCount)}
    />
  );
};

export default AddOrderProductCategoriesField;

import React from 'react';
import { Box } from '@mui/material';

interface IFormFieldWrapperProps {
  title: string
  description: string
  children: React.ReactNode,
}

const FormFieldWrapper: React.FC<IFormFieldWrapperProps> = ({ title, description, children }) => (
  <Box className="reassign-seller-modal__field-wrapper">
    <p className="reassign-seller-modal__field-wrapper__title">{title}</p>
    <p className="reassign-seller-modal__field-wrapper__description">{description}</p>
    {children}
  </Box>
);

export default FormFieldWrapper;

// eslint-disable-next-line no-unused-vars
import { ICustomerComment } from 'pages/Customers/Customers.models';
import {
  clearErrorsComment,
} from 'pages/Customers/CustomersComments/ClearErrorsComments/actionCreators';

const clearCommentsErrorsAction = (
  comments: ICustomerComment[],
) => async (dispatch) => {
  dispatch(clearErrorsComment(comments));
};

export default clearCommentsErrorsAction;

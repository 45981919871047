import React from 'react';
import { status } from 'shared/constants';
// eslint-disable-next-line no-unused-vars
import { EFFECTIVE_PERIOD, ITableCellCustomer } from 'pages/Customers/Customers.models';

export const columns = [
  {
    header: 'Company name',
    accessorKey: 'companyName',
    minWidth: '100px',
    muiTableHeadCellFilterTextFieldProps: { placeholder: 'Company name' },
    sortDescFirst: false,
  },
  {
    header: 'Status',
    accessorKey: 'status',
    minWidth: '100px',
    Cell: ({ row }: ITableCellCustomer) => {
      const statusClass = row.original.status ? 'active' : 'inactive';
      const classes = `status ${statusClass}`;
      return (
        <div className={classes}>
          {row.original.status ? status.active : status.inactive }
        </div>
      );
    },
    filterVariant: 'multi-select',
    filterFn: 'equals',
    filterSelectOptions: [
      { text: status.active, value: '2' },
      { text: status.inactive, value: '0' },
    ],
    muiTableHeadCellFilterTextFieldProps: { placeholder: 'Status' },
    sortDescFirst: false,
  },
  {
    header: 'Billing Address',
    accessorKey: 'billingInfoAddress',
    enableColumnFilter: false,
    sortDescFirst: false,
  },
  {
    header: 'Primary contact',
    accessorKey: 'primaryContact',
    enableColumnFilter: false,
    sortDescFirst: false,
  },
  {
    header: 'Primary contact email',
    accessorKey: 'primaryContactEmail',
    enableColumnFilter: false,
    sortDescFirst: false,
  },
  {
    header: 'Seller',
    accessorKey: 'seller',
    minWidth: '100px',
    enableColumnFilter: false,
    sortDescFirst: false,
  },
];

export const createCustomerButtonText = 'Create new customer';

export const formEffectivePeriodDraftFieldsDEPRECATED = [
  { id: EFFECTIVE_PERIOD.THREE_YEARS, name: 'three (3) years' },
  { id: EFFECTIVE_PERIOD.TWO_YEARS, name: 'two (2) years' },
  { id: EFFECTIVE_PERIOD.ONE_YEAR, name: 'one (1) year' },
  { id: EFFECTIVE_PERIOD.SIX_MONTHS, name: 'six (6) months' },
  { id: EFFECTIVE_PERIOD.THREE_MONTHS, name: 'three (3) months' },
];

export const effectivePeriodOptions = [
  { value: EFFECTIVE_PERIOD.THREE_YEARS, label: 'three (3) years' },
  { value: EFFECTIVE_PERIOD.TWO_YEARS, label: 'two (2) years' },
  { value: EFFECTIVE_PERIOD.ONE_YEAR, label: 'one (1) year' },
  { value: EFFECTIVE_PERIOD.SIX_MONTHS, label: 'six (6) months' },
  { value: EFFECTIVE_PERIOD.THREE_MONTHS, label: 'three (3) months' },
];

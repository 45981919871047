import React from 'react';
import InsideSalesFiltersByPeriod from '@dashboardWirelineInsideSalesDepartment/InsideSalesFiltersByPeriod';
import RequestStatusChart from '@dashboardWirelineInsideSalesDepartment/RequestStatusChart';
import ByUsersChart from '@dashboardWirelineInsideSalesDepartment/ByUsersChart';
import InsideSalesBookingList from '@dashboardWirelineInsideSalesDepartment/InsideSalesBookingList/InsideSalesBookingList';
import { useInsideSalesDepartmentContext } from '@dashboardWirelineInsideSalesDepartment/InsideSalesDepartmentContext';

const InsideSalesDepartmentContent = () => {
  const { actionsPermissions } = useInsideSalesDepartmentContext();

  const {
    isAvailableByUsersChart,
    isAvailableRequestStatusChart,
    isAvailableRequestBookingTable,
  } = actionsPermissions;

  return (
    <div className="dashboard-inside-sales">
      <div className="dashboard-inside-sales__filters">
        <InsideSalesFiltersByPeriod />
      </div>
      <div className="dashboard-inside-sales__charts">
        {isAvailableRequestStatusChart && <RequestStatusChart />}
        {isAvailableByUsersChart && <ByUsersChart />}
      </div>
      {isAvailableRequestBookingTable && (
        <div className="dashboard-inside-sales__inside-sales-table" data-testid="inside-sales-table">
          <InsideSalesBookingList />
        </div>
      )}
    </div>
  );
};

export default InsideSalesDepartmentContent;

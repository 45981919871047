import { combineReducers } from '@reduxjs/toolkit';
import getInsideSalesListReducer from 'core/actions/crm/InsideSales/GetInsideSalesList/reducer';
import {
  insideSalesCreateRequesReducer,
} from 'core/actions/crm/InsideSales/InsideSalesCreateRequest/InsideSalesCreateRequest';
import {
  InsideSalesAttachmentsReducer,
} from 'core/actions/crm/InsideSales/InsideSalesAttachments';
import { insideSalesListItemReducer } from 'core/actions/crm/InsideSales/InsideSalesListItem';
import { setInsideSalesManagerReducer } from 'core/actions/crm/InsideSales/PostSetInsideSalesManager';
import { CancelRequestReducer } from 'core/actions/crm/InsideSales/CancelRequest';

const insideSalesReducer = combineReducers({
  list: getInsideSalesListReducer,
  createReques: insideSalesCreateRequesReducer,
  attachmentsList: InsideSalesAttachmentsReducer,
  item: insideSalesListItemReducer,
  accepted: setInsideSalesManagerReducer,
  cancelRequest: CancelRequestReducer,
});

export default insideSalesReducer;

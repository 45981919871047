import {
  getProjectAcceptanceRequest,
  getProjectAcceptanceSuccess,
  getProjectAcceptanceFailure,
} from '@dashboardWirelinePMDepartment/GetProjectAcceptance/actionsCreators';
import getProjectAcceptance from '@dashboardWirelinePMDepartment/GetProjectAcceptance/api.service';
// eslint-disable-next-line no-unused-vars
import { TPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';

const getProjectAcceptanceAction = (
  controller: AbortController,
  params:TPeriod,
) => async (dispatch) => {
  try {
    const { signal } = controller;
    dispatch(getProjectAcceptanceRequest());
    const { data } = await getProjectAcceptance(signal, params);
    dispatch(getProjectAcceptanceSuccess(data));
  } catch (error) {
    dispatch(getProjectAcceptanceFailure(error));
    throw error;
  }
};

export default getProjectAcceptanceAction;

import { useActionsPermissions } from 'core/hooks/useActionsPermissions';
import { ACTIONS_WIRELINE_ENGAGEMENTS_PATHS } from 'core/models/actionsPermissions';

const {
  ENGAGEMENT_GET,
  ENGAGEMENT_EDIT,
  ENGAGEMENT_CREATE,
} = ACTIONS_WIRELINE_ENGAGEMENTS_PATHS;

const actionsPaths = {
  isAvailableGetEngagement: ENGAGEMENT_GET,
  isAvailableEditEngagement: ENGAGEMENT_EDIT,
  isAvailableCreateEngagement: ENGAGEMENT_CREATE,
};

export const useWirelineEngagementsListPermission = () => {
  const {
    isAvailableGetEngagement,
    isAvailableEditEngagement,
    isAvailableCreateEngagement,
  } = useActionsPermissions(actionsPaths);

  return {
    isAvailableGetEngagement,
    isAvailableEditEngagement,
    isAvailableCreateEngagement,
  };
};

import styles from 'assets/styles/_resources.scss';

export const wirelineDepartmentsTabStyles = {
  '&:hover': {
    color: styles.primaryColor,
    backgroundColor: 'primary.50,',
  },
  '&.MuiButtonBase-root': {
    bgcolor: styles.backgroundColor,
    color: styles.darkColor500,
    borderRadius: '4px',
    minHeight: '36px',
    padding: '0 12px',
    textTransform: 'none !important',
    '&:hover': { bgcolor: 'secondary.100', color: styles.darkColor500 },
    mr: 1,
  },
  '&.active': {
    backgroundColor: styles.whiteColor,
    color: styles.darkColor500,
    border: `1px solid ${styles.primaryColor}`,
    '&:hover': { bgcolor: 'primary.50', color: styles.darkColor500 },
  },
};

export const wirelineDepartmentsTabsRootStyles = {
  alignItems: 'center !important',
  '.MuiTabs-indicator': {
    display: 'none',
  },
};

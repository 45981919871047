import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getSowDataPath, getRenewalSowPath } from 'core/routing/paths';
import AddOrderModalForm from 'pages/WirelineEngagements/shared/AddOrderModalForm';
import { useWirelineEngagementsCreateContext } from 'pages/WirelineEngagements/WirelineEngagementsCreate/WirelineEngagementsCreateContext';
import { ADD_ORDER_TYPE } from '../WirelineEngagements.models';

const AddOrderModalFormContextWrap = () => {
  const navigate = useNavigate();
  const {
    initialCustomerId,
    addOrderModalType,
    setAddOrderModalType,
  } = useWirelineEngagementsCreateContext();
  const { wirelineEngagementId } = useParams();

  const onSuccessCreateOrder = () => {
    let pageUrl = '';

    switch (addOrderModalType) {
      case ADD_ORDER_TYPE.NEW_ORDER: {
        pageUrl = getSowDataPath(wirelineEngagementId, initialCustomerId);
        break;
      }
      case ADD_ORDER_TYPE.RENEWAL_ORDER: {
        pageUrl = getRenewalSowPath(wirelineEngagementId, initialCustomerId);
        break;
      }
      default: {
        pageUrl = '';
      }
    }

    navigate(pageUrl);
  };

  if (!initialCustomerId) {
    return null;
  }

  return (
    <AddOrderModalForm
      orderModalType={addOrderModalType}
      customerId={Number(initialCustomerId)}
      setAddOrderModalType={setAddOrderModalType}
      onSubmitSuccessCallback={onSuccessCreateOrder}
    />
  );
};

export default AddOrderModalFormContextWrap;

import React from 'react';
import { ITickPayload } from 'core/models/Recharts.models';

interface ICustomizedXAxisTick {
  x: number;
  y: number;
  payload: ITickPayload;
}

const CustomizedPeriodXAxisTick: React.FC<ICustomizedXAxisTick> = (
  { x, y, payload },
) => (
  <text
    className="recharts-label"
    x={x}
    y={y}
    dy={16}
    textAnchor="middle"
  >
    {payload.value}
  </text>
);
export default CustomizedPeriodXAxisTick;

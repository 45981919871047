import React from 'react';
import convertDataFromFormToTable from 'core/utilities/convertDataFromFormToTable';
import { status } from 'shared/constants';
// eslint-disable-next-line no-unused-vars
import { ITableCellUser } from 'pages/UserManagement/UserManagement.models';

import {
  userStatusOptions, userTeamRolesNamesLabels,
  userTeamRolesNamesOptions, userTeamRolesTeamAlignmentsLabels,
  userTeamRolesTeamAlignmentsOptions, userTeamRolesTeamDepartmentsLabels,
  userTeamRolesTeamDepartmentsOptions, UserTypeLabels, UserTypeOptions,
} from 'pages/UserManagement/constants';

export const columns = [
  {
    header: 'First name',
    accessorKey: 'firstName',
    minWidth: '100px',
    muiTableHeadCellFilterTextFieldProps: { placeholder: 'Enter First Name' },
    sortDescFirst: false,
  },
  {
    header: 'Last name',
    accessorKey: 'lastName',
    minWidth: '100px',
    muiTableHeadCellFilterTextFieldProps: { placeholder: 'Enter Last Name' },
    sortDescFirst: false,
  },
  {
    header: 'email',
    accessorKey: 'email',
    minWidth: '100px',
    muiTableHeadCellFilterTextFieldProps: { placeholder: 'Enter Email' },
    sortDescFirst: false,
  },
  {
    header: 'User type',
    accessorKey: 'type',
    Cell: ({ row }: ITableCellUser) => (
      <span>{UserTypeLabels[row.original.type]}</span>
    ),
    filterVariant: 'multi-select',
    filterFn: 'equals',
    filterSelectOptions: convertDataFromFormToTable(UserTypeOptions),
    muiTableHeadCellFilterTextFieldProps: { placeholder: 'Select type' },
  },
  {
    header: 'Team alignment',
    accessorKey: 'alignment',
    Cell: ({ row }: ITableCellUser) => (
      <div className="multiRow">
        {
          row.original.teamRoles.map((item) => (
            <span>{userTeamRolesTeamAlignmentsLabels[item.team.alignment]}</span>
          ))
        }
      </div>
    ),
    filterVariant: 'multi-select',
    filterFn: 'equals',
    filterSelectOptions: convertDataFromFormToTable(userTeamRolesTeamAlignmentsOptions),
    muiTableHeadCellFilterTextFieldProps: { placeholder: 'Select team' },
  },
  {
    header: 'Department',
    accessorKey: 'department',
    Cell: ({ row }: ITableCellUser) => (
      <div className="multiRow">
        {
          row.original.teamRoles.map((item) => (
            <span>{userTeamRolesTeamDepartmentsLabels[item.team.department]}</span>
          ))
        }
      </div>
    ),
    filterVariant: 'multi-select',
    filterFn: 'equals',
    filterSelectOptions: convertDataFromFormToTable(userTeamRolesTeamDepartmentsOptions),
    muiTableHeadCellFilterTextFieldProps: { placeholder: 'Select Department' },
  },
  {
    header: 'user role',
    accessorKey: 'role',
    Cell: ({ row }: ITableCellUser) => (
      <div className="multiRow">
        {
          row.original.teamRoles.map((item) => (
            <span>{userTeamRolesNamesLabels[item.name]}</span>
          ))
        }
      </div>
    ),
    filterVariant: 'multi-select',
    filterFn: 'equals',
    filterSelectOptions: convertDataFromFormToTable(userTeamRolesNamesOptions),
    muiTableHeadCellFilterTextFieldProps: { placeholder: 'Select role' },
  },
  {
    header: 'Status',
    accessorKey: 'isActive',
    minWidth: '100px',
    Cell: ({ row }: ITableCellUser) => {
      const statusClass = row.original.isActive ? 'active' : 'inactive';
      const classes = `status ${statusClass}`;

      return (
        <div className={classes}>
          {row.original.isActive ? status.active : status.inactive}
        </div>
      );
    },
    filterVariant: 'multi-select',
    filterFn: 'equals',
    filterSelectOptions: convertDataFromFormToTable(userStatusOptions),
    muiTableHeadCellFilterTextFieldProps: { placeholder: 'Status' },
    sortDescFirst: false,
  },
];

export const createUserButtonText = 'create user';

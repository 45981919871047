export const contractFormLabels = {
  yes: 'Yes',
  no: 'No',
};

export const contractFormSettingsOptions = [
  {
    value: true,
    label: contractFormLabels.yes,
  },
  {
    value: false,
    label: contractFormLabels.no,
  },
];

export const contractFormInfoOptions = [
  {
    value: contractFormLabels.yes,
    label: contractFormLabels.yes,
  },
  {
    value: contractFormLabels.no,
    label: contractFormLabels.no,
  },
];

export const requestContractsNotifierMessage = {
  success: 'Request contract was added successfully.',
  error: 'Request contract was failed.',
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { quoteRequest } from 'core/api/sales.api/sales.api';
// eslint-disable-next-line no-unused-vars
import { IDraftQuote } from '@EngagementsSowData/salesSow.model';

const requestQuoteAction = createAsyncThunk(
  'requestQuoteAction',
  async (draftQuote: IDraftQuote, { rejectWithValue }) => {
    try {
      await quoteRequest(draftQuote);
    } catch (error) {
      return rejectWithValue(error);
    }
  });

export default requestQuoteAction;

import Keycloak from 'keycloak-js';

const _kc = new Keycloak('/settings/keycloak.json');

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = (): boolean => !!_kc.token;

const updateToken = (successCallback) => _kc.updateToken(5)
  .then(successCallback)
  .catch(doLogin);

const getGivenName = (): string | undefined => _kc.tokenParsed?.given_name;

const getFamilyName = (): string | undefined => _kc.tokenParsed?.family_name;

const getUsername = () => `${getGivenName()} ${getFamilyName()}`;

const initKeycloak = (onAuthenticatedCallback): void => {
  _kc.init({
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
    pkceMethod: 'S256',
  })
    .then((authenticated) => {
      if (!authenticated) {
        doLogin();
      } else {
        onAuthenticatedCallback();
      }
    })
    .catch(doLogin);
};

const KeycloakService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
};

export default KeycloakService;

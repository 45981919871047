import React from 'react';
import { Grid } from '@mui/material';
import UserManagementCreateFormTeamRoleItemTeam from 'pages/UserManagement/UserManagementCreate/UserManagementTeamRoleForm/UserManagementCreateFormTeamRole/UserManagementCreateFormTeamRoleItemTeam';
import UserManagementCreateFormTeamRoleItemAssociations from 'pages/UserManagement/UserManagementCreate/UserManagementTeamRoleForm/UserManagementCreateFormTeamRole/UserManagementCreateFormTeamRoleItemAssociations/UserManagementCreateFormTeamRoleItemAssociations';

interface IUserManagementCreateFormUserTypeItemProps {
  index: number;
}

const UserManagementCreateFormTeamRoleItem: React.FC<
  IUserManagementCreateFormUserTypeItemProps
> = ({
  index,
}) => (
  <Grid
    className="user-management-create-form__user-type user-management-create-form__section"
    container
    direction="row"
    columnSpacing={5}
    pt={2}
  >
    <UserManagementCreateFormTeamRoleItemTeam index={index} />
    <UserManagementCreateFormTeamRoleItemAssociations index={index} />
  </Grid>
);

export default UserManagementCreateFormTeamRoleItem;

import {
  GET_CUSTOMER_BY_ID_REQUEST,
  GET_CUSTOMER_BY_ID_SUCCESS,
  GET_CUSTOMER_BY_ID_FAILURE,
} from './actionTypes';

export const getCustomerRequest = () => ({ type: GET_CUSTOMER_BY_ID_REQUEST });
export const getCustomerSuccess = (customer) => (
  { type: GET_CUSTOMER_BY_ID_SUCCESS, customer });
export const getCustomerFailure = (error) => ({ type: GET_CUSTOMER_BY_ID_FAILURE, error });

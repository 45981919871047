import { combineReducers } from '@reduxjs/toolkit';

import postCustomersDraftReducer from 'core/actions/Customers/CreateCustomerDraft/reducer';
import getUsers from 'core/actions/Customers/GetUsers/reducer';
import putCustomers from 'core/actions/Customers/CreateCustomerFromDraft/reducer';
import getCustomerReducer from 'core/actions/Customers/GetCustomerById/reducer';
import { getLocationsByCustomerIdReducer } from 'core/actions/Customers/GetLocationsByCustomerId';
import attachmentsReducer from './CustomersAttachments/reducer';
import locationsReducer from './CustomersLocations/reducer';
import commentsReducer from './CustomersComments/reducer';
import { getCustomersListReducer } from './CustomersList/GetCustomersList';

const customersReducer = combineReducers({
  locations: locationsReducer,
  attachments: attachmentsReducer,
  comments: commentsReducer,
  createCustomer: putCustomers,
  draft: postCustomersDraftReducer,
  users: getUsers,
  list: getCustomersListReducer,
  customer: getCustomerReducer,
  locationsByCustomerId: getLocationsByCustomerIdReducer,
});

export default customersReducer;

import { getSalesRenewalSowMeta } from 'core/api/sales.api';
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ITableColumnMetaItem } from 'pages/WirelineEngagements/WirelineEngagementsTables/wirelineEngagementsTables.model';

export interface ISalesRenewalSowMetaState {
  isLoading: boolean;
  meta: ITableColumnMetaItem[];
  error: any;
}

const initialState: ISalesRenewalSowMetaState = {
  isLoading: true,
  meta: [],
  error: null,
};

export const getSalesRenewalSowMetaAction = createAsyncThunk(
  'getSalesRenewalSowMetaAction',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getSalesRenewalSowMeta();
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const SalesRenewalSowMeta = createSlice({
  name: 'getSalesRenewalSowMeta',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getSalesRenewalSowMetaAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSalesRenewalSowMetaAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.meta = action.payload;
        state.error = null;
      })
      .addCase(getSalesRenewalSowMetaAction.rejected, (state, action) => {
        state.meta = [];
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export const { clear: clearSalesRenewalSowMeta } = SalesRenewalSowMeta.actions;
export const getSalesRenewalSowMetaReducer = SalesRenewalSowMeta.reducer;

import React, { useEffect, useState } from 'react';
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import xor from 'lodash/xor';
import { useAppDispatch, useAppSelector } from 'core/store';
import {
  defaultRenewalPeriod, renewalOptions, titles, notifierMessage,
} from '@dashboardWirelineRetentionDepartment/constants';
import ProductsSelect from '@dashboardWirelineRetentionDepartment/ProductsSelect';
import { HTTPService } from 'core/services';
import InProgressEngagementsChart from '@dashboardWirelineRetentionDepartment/InProgressEngagements/InProgressEngagementsChart';
import { Typography } from '@mui/material';
import RenewalPeriodAutocomplete from 'shared/RenewalPeriodAutocomplete';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { EngagementTypeForChart } from '@dashboardWirelineRetentionDepartment/RetentionManagerDepartment.models';
import { getInProgressEngagementsAction } from '@dashboardWirelineRetentionDepartment/GetInProgressEngagements/GetInProgressEngagements';

let inProgressEngagementsController = HTTPService.getController();

const InProgressEngagements = () => {
  const inProgressEngagementsData = useAppSelector(
    (state) => state.dashboard.inProgressEngagements.data,
  );

  const productsWithValue = keys(inProgressEngagementsData);

  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [renewalPeriod, setRenewalPeriod] = useState(defaultRenewalPeriod);

  const { showErrorNotifier } = useNotifiers();

  const dispatch = useAppDispatch();

  const cancelGetInProgressEngagementsRequest = () => {
    HTTPService.cancelRequest(inProgressEngagementsController);
  };

  const getInProgressEngagements = async (period) => {
    cancelGetInProgressEngagementsRequest();

    setRenewalPeriod(period);

    inProgressEngagementsController = HTTPService.getController();

    const params = {
      engagementType: EngagementTypeForChart.IN_PROGRESS,
      ...period,
    };

    const isAllProductsWithValueSelected = (
      !isEmpty(productsWithValue) && xor(selectedProducts, productsWithValue).length === 0
    );

    dispatch(getInProgressEngagementsAction({
      controller: inProgressEngagementsController, params,
    }))
      .unwrap()
      .then((newInProgressEngagementsData) => {
        if (!isAllProductsWithValueSelected) return;

        const newProductsWithValue = keys(newInProgressEngagementsData);
        setSelectedProducts(newProductsWithValue);
      })
      .catch((e) => {
        showErrorNotifier(notifierMessage.inProgress.error, e);
      });
  };

  useEffect(() => {
    getInProgressEngagements(renewalPeriod);

    return () => cancelGetInProgressEngagementsRequest();
  }, []);

  return (
    <div className="inProgress-engagements">
      <Typography variant="h3" className="retention-department__title">
        {titles.inProgress}
      </Typography>
      <div className="retention-department__filters">
        <RenewalPeriodAutocomplete
          onChange={getInProgressEngagements}
          options={renewalOptions}
          defaultValue={renewalOptions[2]}
        />
        <ProductsSelect
          setSelectedProducts={setSelectedProducts}
          selectedProducts={selectedProducts}
          activeProducts={productsWithValue}
        />
      </div>
      <InProgressEngagementsChart selectedProducts={selectedProducts} />
    </div>
  );
};

export default InProgressEngagements;

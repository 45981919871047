import styles from 'assets/styles/_resources.scss';
import find from 'lodash/find';
import { PERIOD_SELECT } from 'shared/PeriodAutocomplete/PeriodAutocomplete.models';
import calculatePeriod from 'core/utilities/dateUtilities/calculatePeriod';
import periodMapping from 'shared/PeriodAutocomplete/constants';
import {
  getRenewalPeriod,
} from 'shared/RenewalPeriodAutocomplete/utilities';
import {
  RenewalPeriodFilter,
} from 'shared/RenewalPeriodAutocomplete/RenewalPeriodAutocomplete.models';

export const colorsOfCharts = [
  styles.primaryColor,
  styles.blueColor,
  styles.tealColor500,
  styles.secondaryColor,
  styles.darkColor100,
  styles.yellowColor500,
];

export const totalColor = [
  styles.lightColor500,
];

export const periodOptions = [
  {
    value: PERIOD_SELECT.ONE_WEEK,
    label: '1 week',
  },
  {
    value: PERIOD_SELECT.ONE_MONTH,
    label: '1 month',
  },
  {
    value: PERIOD_SELECT.SIX_MONTHS,
    label: '6 months',
  },
  {
    value: PERIOD_SELECT.ONE_YEAR,
    label: '1 year',
  },
];

export const renewalOptions = [
  { label: '120 days out', value: RenewalPeriodFilter.DAYS_OUT_120 },
  { label: '90 days out', value: RenewalPeriodFilter.DAYS_OUT_90 },
  { label: '60 days out', value: RenewalPeriodFilter.DAYS_OUT_60 },
];

export const defaultPeriodValue = PERIOD_SELECT.ONE_MONTH;
export const defaultPeriod = calculatePeriod(periodMapping, defaultPeriodValue);
export const defaultPeriodOption = find(periodOptions, { value: defaultPeriodValue });

export const defaultRenewalPeriod = getRenewalPeriod(RenewalPeriodFilter.DAYS_OUT_60);

export const titles = {
  total: 'Total',
  renewed: 'Renewed',
  declined: 'Declined',
  inProgress: 'In Progress',
};

export const notifierMessage = {
  product: {
    error: 'Failed to load product',
  },
  total: {
    error: 'Failed to complete your request for Total Engagements , please try again later.',
  },
  renewed: {
    error: 'Failed to complete your request for Renewed Engagements , please try again later.',
  },
  declined: {
    error: 'Failed to complete your request for Declined Engagements , please try again later.',
  },
  inProgress: {
    error: 'Failed to complete your request for in Progress Engagements , please try again later.',
  },
};

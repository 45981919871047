import axios from 'axios';

import KeycloakService from 'core/services/KeycloakService';

const netsparkAxios = axios.create();

netsparkAxios.interceptors.request.use((config) => {
  if (KeycloakService.isLoggedIn()) {
    const updateTokenCallback = () => {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${KeycloakService.getToken()}`;

      return Promise.resolve(config);
    };

    return KeycloakService.updateToken(updateTokenCallback);
  }

  return config;
});

class HTTPService {
  static setDefaultGlobalConfig(axiosInstance, apiUrl) {
    // eslint-disable-next-line no-param-reassign
    axiosInstance.defaults.baseURL = apiUrl;
    // eslint-disable-next-line no-param-reassign
    axiosInstance.defaults.withCredentials = true;
  }

  static getController() {
    return new AbortController();
  }

  static cancelRequest(controller) {
    controller.abort();
  }
}

export {
  HTTPService,
  netsparkAxios,
};

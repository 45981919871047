import { US_STATES } from './states.models';

export const USStatesOptions = [
  { value: US_STATES.AL, label: 'Alabama' },
  { value: US_STATES.AK, label: 'Alaska' },
  { value: US_STATES.AZ, label: 'Arizona' },
  { value: US_STATES.AR, label: 'Arkansas' },
  { value: US_STATES.CA, label: 'California' },
  { value: US_STATES.CO, label: 'Colorado' },
  { value: US_STATES.CT, label: 'Connecticut' },
  { value: US_STATES.DE, label: 'Delaware' },
  { value: US_STATES.FL, label: 'Florida' },
  { value: US_STATES.GA, label: 'Georgia' },
  { value: US_STATES.HI, label: 'Hawaii' },
  { value: US_STATES.ID, label: 'Idaho' },
  { value: US_STATES.IL, label: 'Illinois' },
  { value: US_STATES.IN, label: 'Indiana' },
  { value: US_STATES.IA, label: 'Iowa' },
  { value: US_STATES.KS, label: 'Kansas' },
  { value: US_STATES.KY, label: 'Kentucky' },
  { value: US_STATES.LA, label: 'Louisiana' },
  { value: US_STATES.ME, label: 'Maine' },
  { value: US_STATES.MD, label: 'Maryland' },
  { value: US_STATES.MA, label: 'Massachusetts' },
  { value: US_STATES.MI, label: 'Michigan' },
  { value: US_STATES.MN, label: 'Minnesota' },
  { value: US_STATES.MS, label: 'Mississippi' },
  { value: US_STATES.MO, label: 'Missouri' },
  { value: US_STATES.MT, label: 'Montana' },
  { value: US_STATES.NE, label: 'Nebraska' },
  { value: US_STATES.NV, label: 'Nevada' },
  { value: US_STATES.NH, label: 'New Hampshire' },
  { value: US_STATES.NJ, label: 'New Jersey' },
  { value: US_STATES.NM, label: 'New Mexico' },
  { value: US_STATES.NY, label: 'New York' },
  { value: US_STATES.NC, label: 'North Carolina' },
  { value: US_STATES.ND, label: 'North Dakota' },
  { value: US_STATES.OH, label: 'Ohio' },
  { value: US_STATES.OK, label: 'Oklahoma' },
  { value: US_STATES.OR, label: 'Oregon' },
  { value: US_STATES.PA, label: 'Pennsylvania' },
  { value: US_STATES.RI, label: 'Rhode Island' },
  { value: US_STATES.SC, label: 'South Carolina' },
  { value: US_STATES.SD, label: 'South Dakota' },
  { value: US_STATES.TN, label: 'Tennessee' },
  { value: US_STATES.TX, label: 'Texas' },
  { value: US_STATES.UT, label: 'Utah' },
  { value: US_STATES.VT, label: 'Vermont' },
  { value: US_STATES.VA, label: 'Virginia' },
  { value: US_STATES.WA, label: 'Washington' },
  { value: US_STATES.WV, label: 'West Virginia' },
  { value: US_STATES.WI, label: 'Wisconsin' },
  { value: US_STATES.WY, label: 'Wyoming' },
  { value: US_STATES.DC, label: 'District of Columbia' },
  { value: US_STATES.AS, label: 'American Samoa' },
  { value: US_STATES.GU, label: 'Guam' },
  { value: US_STATES.MP, label: 'Northern Mariana Islands' },
  { value: US_STATES.PR, label: 'Puerto Rico' },
  { value: US_STATES.UM, label: 'United States Minor Outlying Islands' },
  { value: US_STATES.VI, label: 'U.S. Virgin Islands' },
];

export default { USStatesOptions };

import { netsparkAxios } from 'core/services/HTTPService';
import { getRetentionDataUrl } from 'pages/Dashboard/api/apiUrls';
import {
  IRetentionDataItem,
  // eslint-disable-next-line no-unused-vars
  IRetentionParams,
} from '@dashboardWirelineRetentionDepartment/RetentionManagerDepartment.models';

const getRenewedEngagements = (signal: AbortSignal, params: IRetentionParams) => netsparkAxios
  .get<IRetentionDataItem>(getRetentionDataUrl, { signal, params });

export default getRenewedEngagements;

import React from 'react';
import { ICreateRequestAttachmentsItemForm } from 'pages/InsideSales/model';
import { DeleteOutlineOutlined, FilePresentOutlined } from '@mui/icons-material';
import {
  IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText,
} from '@mui/material';

interface IInsideSalesAttachmentsListProps {
  attachments: ICreateRequestAttachmentsItemForm[]
  removeAttachment: (attachmentId: string) => void
}

const InsideSalesAttachmentsList: React.FC<IInsideSalesAttachmentsListProps> = ({
  attachments,
  removeAttachment,
}) => (
  <div className="inside-sales__create-form__attachments__list">
    <List disablePadding>
      {
        attachments.map(({ file, id }) => (
          <ListItem
            dense
            key={id}
            disableGutters
          >
            <ListItemAvatar>
              <FilePresentOutlined
                color="action"
                fontSize="large"
              />
            </ListItemAvatar>
            <ListItemText
              disableTypography
              primary={file.name}
            />
            <ListItemSecondaryAction>
              <IconButton
                size="small"
                disableRipple
                aria-label="Remove attach"
                onClick={() => removeAttachment(id)}
              >
                <DeleteOutlineOutlined />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))
      }
    </List>
  </div>

);

export default InsideSalesAttachmentsList;

import { netsparkAxios } from 'core/services/HTTPService';
import { IEngagementsTotalItem } from 'pages/Dashboard/Dashboard.models';
import { getEngagementsRevenueUrl } from 'pages/Dashboard/api/apiUrls';
// eslint-disable-next-line no-unused-vars
import { TPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';

const getEngagementsRevenue = (
  signal: AbortSignal, params: TPeriod,
) => netsparkAxios
  .get<IEngagementsTotalItem[]>(getEngagementsRevenueUrl, { signal, params });

export default getEngagementsRevenue;

import Yup from 'core/utilities/Yup';
import {
  fields, sellerRequestTypeValues, projectManagerSupportValues, billingSupportValues,
} from 'pages/WirelineEngagements/shared/AddOrderModalForm/constants';

const requestTypeSchema = Yup.object().shape({
  products: Yup.array()
    .of(Yup.object().shape({
      [fields.sellerRequestType]: Yup.string().required('Request type is required'),
      params: Yup.object().shape({
        [fields.contractSupport]: Yup
          .string()
          // eslint-disable-next-line func-names
          .test('match', 'This is a required field', function (value) {
            const sellerRequestType = this?.from?.[1]?.value?.sellerRequestType;
            return (sellerRequestType === sellerRequestTypeValues.contractSupport && !!value)
             || sellerRequestType !== sellerRequestTypeValues.contractSupport;
          }),
        [fields.projectManagementSupport]: Yup
          .string()
          // eslint-disable-next-line func-names
          .test('match', 'This is a required field', function (value) {
            const sellerRequestType = this?.from?.[1]?.value?.sellerRequestType;
            return (sellerRequestType === sellerRequestTypeValues.projectManagerSupport && !!value)
             || sellerRequestType !== sellerRequestTypeValues.projectManagerSupport;
          }),
        [fields.billingSupport]: Yup
          .string()
          // eslint-disable-next-line func-names
          .test('match', 'This is a required field', function (value) {
            const sellerRequestType = this?.from?.[1]?.value?.sellerRequestType;
            return (sellerRequestType === sellerRequestTypeValues.billingSuport && !!value)
             || sellerRequestType !== sellerRequestTypeValues.billingSuport;
          }),
        [fields.customerAssistPm]: Yup
          .string()
          .when(fields.projectManagementSupport, {
            is: (value) => value === projectManagerSupportValues.customerAssistPM,
            then: (schema) => schema.required('This is a required field'),
          }),
        [fields.customerAssistBilling]: Yup
          .string()
          .when(fields.billingSupport, {
            is: (value) => value === billingSupportValues.customerAssistBilling,
            then: (schema) => schema.required('This is a required field'),
          }),
      }),
    })),
});

export default requestTypeSchema;

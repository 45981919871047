import {
  UPLOAD_BULK_CUSTOMERS_LOCATION_REQUEST,
  UPLOAD_BULK_CUSTOMERS_LOCATION_SUCCESS,
  UPLOAD_BULK_CUSTOMERS_LOCATION_FAILURE,
} from 'pages/Customers/CustomersLocations/UploadBulkLocation/actionTypes';

export const uploadBulkLocationRequest = () => ({ type: UPLOAD_BULK_CUSTOMERS_LOCATION_REQUEST });

export const uploadBulkLocationSuccess = (location) => ({
  type: UPLOAD_BULK_CUSTOMERS_LOCATION_SUCCESS,
  location,
});

export const uploadBulkLocationFailure = (bulkErrors) => ({
  type: UPLOAD_BULK_CUSTOMERS_LOCATION_FAILURE,
  bulkErrors,
});

import { netsparkAxios } from 'core/services/HTTPService';
import { IEngagementsRevenueItem } from 'pages/Dashboard/Dashboard.models';
import { getEngagementsRevenueTechnicalOverlayUrl } from 'pages/Dashboard/api/apiUrls';
// eslint-disable-next-line no-unused-vars
import { TPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';

export const getEngagementsTORevenue = (
  signal: AbortSignal, params: TPeriod,
) => netsparkAxios
  .get<IEngagementsRevenueItem[]>(getEngagementsRevenueTechnicalOverlayUrl, { signal, params });

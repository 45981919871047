// eslint-disable-next-line no-unused-vars
import React, { FC } from 'react';
import { Grid } from '@mui/material';
import FormikTextField from 'shared/FormikField/FormikTextField/FormikTextField';
import FormikSelectField from 'shared/FormikField/FormikSelectField/FormikSelectField';
import { IOption } from 'shared/FormikField/FormikField.model';
import FormSection from './FormSection/FormSection';

interface ICustomersCreateFormCustomerInformationProps {
  isEditMode: boolean
  sellersOptions: IOption[]
}

const CustomersCreateFormCustomerInformation: FC<ICustomersCreateFormCustomerInformationProps> = ({
  isEditMode,
  sellersOptions,
}) => (
  <FormSection title="customer information">
    <Grid
      container
      item
      rowSpacing={3}
    >
      <FormikTextField
        label="Customer ID"
        fieldName="id"
        disabled
        fullWidth
      />
      <FormikSelectField
        label="Seller"
        fieldName="seller"
        options={sellersOptions}
        disabled={isEditMode}
        required
        fullWidth
      />
      <FormikTextField
        label="Company Name"
        fieldName="companyName"
        required
        fullWidth
      />
      <FormikTextField
        label="Sub-Account ID"
        fieldName="subAccountId"
        fullWidth
      />
    </Grid>
  </FormSection>
);

export default CustomersCreateFormCustomerInformation;

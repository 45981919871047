import React from 'react';
// eslint-disable-next-line no-unused-vars
import { Form, Formik, FormikProps } from 'formik';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { createTeamRole, updateTeamRoleById } from 'core/api/user.api';
import { IServerValidationError } from 'core/services/HTTPService.models';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useUserManagementCreateContext } from 'pages/UserManagement/UserManagementCreate/UserManagementCreateContext';
import { ITeamRole, UserType } from 'pages/UserManagement/UserManagement.models';
import {
  creatTeamRolesNotifications,
  updateTeamRolesNotifications,
} from 'pages/UserManagement/UserManagementCreate/constants';
import UserManagementCreateFormTeamRole from 'pages/UserManagement/UserManagementCreate/UserManagementTeamRoleForm/UserManagementCreateFormTeamRole';
import { teamRolesSchema } from 'pages/UserManagement/UserManagementCreate/UserManagementTeamRoleForm/UserManagementTeamRoleFormSchema';

const updateOrCreateTeamRole = async (id: string | number | undefined, params: ITeamRole) => {
  if (id) {
    const { data: updatedTeamRole } = await updateTeamRoleById(id, params);
    return updatedTeamRole;
  }
  const { data: newTeamRole } = await createTeamRole(params);
  return newTeamRole;
};

interface IUserManagementUserTypeFormProps {
  initialTeamRoles: ITeamRole[]
  setInitialTeamRoles: (teamRoles: ITeamRole[]) => void
  setIsLoading: (isLoading: boolean) => void
}

const UserManagementTeamRoleForm: React.FC<
  IUserManagementUserTypeFormProps
> = ({
  initialTeamRoles, setInitialTeamRoles, setIsLoading,
}) => {
  const { id } = useParams();
  const {
    indexEditModeTeamRole, onDisableTeamRoleEditMode, setIsTeamRoleFormDirty, userType,
  } = useUserManagementCreateContext();

  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();

  const isTeamUser = userType === UserType.TEAM_USER;

  const innerRef = (ref: FormikProps<ITeamRole[]>) => {
    setIsTeamRoleFormDirty(!!ref?.dirty && !isEmpty(ref?.touched));
  };

  const showSuccessNotification = (teamRoleId: string | number | undefined) => {
    const notificationMessage = teamRoleId
      ? updateTeamRolesNotifications.success
      : creatTeamRolesNotifications.success;
    showSuccessNotifier(notificationMessage);
  };

  const showErrorNotification = (
    teamRoleId: string | number | undefined,
    e: unknown = undefined,
  ) => {
    const notificationMessage = teamRoleId
      ? updateTeamRolesNotifications.error
      : creatTeamRolesNotifications.error;
    showErrorNotifier(notificationMessage, e);
  };

  const onSubmit = async (values: ITeamRole[], { setFieldValue, setErrors, resetForm }) => {
    const teamRole = values[indexEditModeTeamRole];
    const teamRoleId = teamRole?.id;

    if (isEmpty(teamRole) || !id) {
      showErrorNotification(teamRoleId);
      return;
    }

    try {
      setIsLoading(true);

      const draftTeamRole = {
        ...teamRole,
        userId: id,
      };

      const { id: newTeamRoleId } = await updateOrCreateTeamRole(teamRoleId, draftTeamRole);
      const updatedTeamRole = { ...values[indexEditModeTeamRole], id: newTeamRoleId || teamRoleId };
      setFieldValue(indexEditModeTeamRole, updatedTeamRole);

      onDisableTeamRoleEditMode();

      const newInitialValues = [...values];
      newInitialValues[indexEditModeTeamRole] = updatedTeamRole;
      setInitialTeamRoles(newInitialValues);

      resetForm({ values: newInitialValues });

      showSuccessNotification(teamRoleId);
    } catch (error) {
      const definedError = error as IServerValidationError;
      setErrors(definedError?.response?.data);

      showErrorNotification(teamRoleId, error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isTeamUser) {
    return null;
  }

  return (
    <Formik
      innerRef={innerRef}
      validationSchema={teamRolesSchema}
      onSubmit={onSubmit}
      initialValues={initialTeamRoles}
      enableReinitialize
      validateOnBlur
      validateOnChange
    >
      <Form>
        <UserManagementCreateFormTeamRole />
      </Form>
    </Formik>
  );
};

export default UserManagementTeamRoleForm;

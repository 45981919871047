import paths from 'core/routing/paths';
import CustomersList from './CustomersList';
import CustomersCreate from './CustomersCreate';
import CustomersEdit from './CustomersEdit';

const customerRoutes = [
  {
    path: paths.customersList,
    component: CustomersList,
  },
  {
    path: paths.customersCreate,
    component: CustomersCreate,
  },
  {
    path: paths.customersEdit,
    component: CustomersEdit,
  },
];

export default customerRoutes;

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createInsideSalesAttachment, deleteInsideSalesAttachmentItem, editInsideSalesAttachmentItem,
  getInsideSalesAttachmentList,
} from 'core/api/insideSales.api';
import { IInsideSalesRequestAttachment } from 'pages/InsideSales/model';

interface IError { detail: string }
interface IThunkError { rejectValue: IError }

interface ICreateInsideSalesAttachmentActionParams {
  insideSalesRequestId: string | number,
  attachmentFile: FormData,
}

export const createInsideSalesAttachmentAction = createAsyncThunk<
  IInsideSalesRequestAttachment,
  ICreateInsideSalesAttachmentActionParams,
  IThunkError
>(
  'createInsideSalesAttachmentAction',
  async (
    { insideSalesRequestId, attachmentFile },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await createInsideSalesAttachment(insideSalesRequestId, attachmentFile);
      return data;
    } catch (error) {
      return rejectWithValue(error as IError);
    }
  },
);

export const getInsideSalesAttachmentListAction = createAsyncThunk<
  IInsideSalesRequestAttachment[],
  { insideSalesRequestId: string | number, controller: AbortController },
  IThunkError
>(
  'getInsideSalesAttachmentListAction',
  async (
    { insideSalesRequestId, controller },
    { rejectWithValue },
  ) => {
    const { signal } = controller;

    try {
      const { data } = await getInsideSalesAttachmentList(signal, insideSalesRequestId);
      return data;
    } catch (error) {
      return rejectWithValue(error as IError);
    }
  },
);

interface IEditInsideSalesAttachmentItemActionParams {
  insideSalesRequestId: string | number
  attachmentId: string | number
  attachmentData: unknown
}
export const editInsideSalesAttachmentItemAction = createAsyncThunk<
  IInsideSalesRequestAttachment,
  IEditInsideSalesAttachmentItemActionParams,
  IThunkError
>(
  'editInsideSalesAttachmentItemAction',
  async (
    { insideSalesRequestId, attachmentId, attachmentData },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await editInsideSalesAttachmentItem(
        insideSalesRequestId,
        attachmentId,
        attachmentData,
      );
      return data;
    } catch (error) {
      return rejectWithValue(error as IError);
    }
  },
);

interface IDeleteInsideSalesAttachmentItemActionParams {
  insideSalesRequestId: string | number,
  attachmentId: string | number
}

export const deleteInsideSalesAttachmentItemAction = createAsyncThunk<
  void,
  IDeleteInsideSalesAttachmentItemActionParams,
  IThunkError
>(
  'deleteInsideSalesAttachmentItemAction',
  async (
    { insideSalesRequestId, attachmentId },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await deleteInsideSalesAttachmentItem(insideSalesRequestId, attachmentId);
      return data;
    } catch (error) {
      return rejectWithValue(error as IError);
    }
  },
);

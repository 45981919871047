import React, { memo } from 'react';
import { Grid } from '@mui/material';
import { IOrderDataSidebarItem } from 'pages/WirelineEngagements/shared/OrderDataSidebar/OrderDataSidebar.model';

interface IOrderDataItemProps {
  data: IOrderDataSidebarItem;
}

const OrderDataItem: React.FC<IOrderDataItemProps> = ({ data }) => {
  const { label, value } = data;

  return (
    <Grid container className="order-data-sidebar__item">
      <Grid item xs={12} md={4} className="order-data-sidebar__item__label">{label}</Grid>
      <Grid item xs={12} md={8} className="order-data-sidebar__item__value">{value}</Grid>
    </Grid>
  );
};

export default memo(OrderDataItem);

import React from 'react';
import LocationsListButtons from 'shared/widgets/Locations/LocationsList/LocationsListButtons';
import LocationsListView from 'shared/widgets/Locations/LocationsList/LocationsListView';
import BulkErrorsDisplay from 'shared/widgets/Locations/LocationsList/BulkErrorsDisplay';
import { ICustomerLocation } from 'pages/Customers/Customers.models';
import { IBulkError } from 'shared/widgets/Locations/Locations.models';

interface ILocationsListProps {
  locations : ICustomerLocation[];
  handleShowCreateLocation : ()=> void;
  handleRemoveLocation: (id: number)=>void;
  handleShowEditLocation: (showLocation: ICustomerLocation)=> void;
  addBulkFile: (data)=> void;
  bulkErrors: IBulkError[];
  clearErrors: ()=> void;
}

const LocationsList: React.FC<ILocationsListProps> = ({
  locations,
  handleShowCreateLocation,
  handleRemoveLocation,
  handleShowEditLocation,
  addBulkFile,
  bulkErrors,
  clearErrors,
}) => {
  const addLocationFile = ({ target }) => {
    const data = new FormData();
    const excelData = target.files[0];
    data.append('file', excelData);
    addBulkFile(data);
  };

  return (
    <div>
      <div className="location-list scroll" data-testid="location-list">
        <BulkErrorsDisplay
          bulkErrors={bulkErrors}
          clearErrors={clearErrors}
        />
        <LocationsListView
          goToEditLocation={handleShowEditLocation}
          locations={locations}
          onRemoveLocation={handleRemoveLocation}
        />
      </div>
      <LocationsListButtons
        goToCreateLocation={handleShowCreateLocation}
        addLocationFile={addLocationFile}
      />
    </div>
  );
};

export default LocationsList;

import React from 'react';
import { columns } from '@dashboardWirelineInsideSalesDepartment/InsideSalesBookingList/InsideSalesTable/constants';
import InsideSalesTable from '@dashboardWirelineInsideSalesDepartment/InsideSalesBookingList/InsideSalesTable';
import { TableProvider } from 'shared/Table/TableContext';
import { useInsideSalesDepartmentContext } from '@dashboardWirelineInsideSalesDepartment/InsideSalesDepartmentContext';

// eslint-disable-next-line no-unused-vars
import { TPeriodInsideSalesBooking } from 'shared/CustomRangePicker/CustomRangePicker.models';
import { tableIds } from 'shared/Table/constants';
import getInsideSalesDataAction from '@dashboardWirelineInsideSalesDepartment/GetInsideSalesTableData/actions';

const InsideSalesBookingList = () => {
  const { period } = useInsideSalesDepartmentContext();

  return (
    <TableProvider<TPeriodInsideSalesBooking>
      id={tableIds.insideSalesBooking}
      columns={columns}
      getDataAction={getInsideSalesDataAction}
      enableRowSelection={false}
      requestParams={{
        createdDatetimeRangeAfter: period.dateFrom,
        createdDatetimeRangeBefore: period.dateTo,
      }}
    >
      <InsideSalesTable />
    </TableProvider>
  );
};

export default InsideSalesBookingList;

import React from 'react';
import { Box, Grid } from '@mui/material';
import { parseOpenapiSchemaByEndpoint } from 'core/utilities/openapiSchema';
import { useAppSelector } from 'core/store';
import Loader from 'shared/Loader';
import FormikFieldsFromSchema from 'pages/WirelineEngagements/shared/FormikFieldsFromSchema';

interface IEditRowRenewalSowFormFieldsProps {
  editRowId: string | number | undefined | null
  endpointToGetSchemaName: string
  isLoadingItem: boolean
}

const EditRowRenewalSowFormFields: React.FC<IEditRowRenewalSowFormFieldsProps> = (
  {
    editRowId, endpointToGetSchemaName, isLoadingItem,
  },
) => {
  const openApiSchemas = useAppSelector((state) => state.openapiSchemas.schemas);
  const openApiPaths = useAppSelector((state) => state.openapiSchemas.paths);
  const isLoadingSchemas = useAppSelector((state) => state.openapiSchemas.isLoading);

  const retrieveSchema = (
    parseOpenapiSchemaByEndpoint(
      openApiSchemas,
      openApiPaths,
      endpointToGetSchemaName,
    )
  );

  if (!editRowId) {
    return null;
  }

  if (isLoadingSchemas) {
    return (
      <Loader className="edit-row-sidebar__loader" />
    );
  }

  return (
    <Box className="edit-row-sidebar__fields">
      {isLoadingItem && <Loader />}
      <Grid
        container
        item
        rowSpacing={3}
      >
        <FormikFieldsFromSchema retrieveSchema={retrieveSchema} />
      </Grid>
    </Box>
  );
};

export default EditRowRenewalSowFormFields;

import React from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useAppDispatch } from 'core/store';
import { closeSnackbar as closeSnackbarAction, enqueueSnackbar as enqueueSnackbarAction } from './actions';

const requestCanceledError = 'canceled';

const useNotifiers = () => {
  const dispatch = useAppDispatch();
  const notifierOptions = (message, variant, closeSnackbar) => {
    const key = new Date().getTime() + Math.random();
    const onCloseSnackbar = () => {
      closeSnackbar(key);
    };

    const action = (
      <CloseRoundedIcon color="action" onClick={onCloseSnackbar} />
    );

    return {
      message,
      options: {
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        key,
        variant,
        action,
      },
    };
  };

  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  const showSuccessNotifier = (successMessage) => {
    enqueueSnackbar(notifierOptions(successMessage, 'success', closeSnackbar));
  };

  const showErrorNotifier = (errorMessage, reason) => {
    const isCanceled = reason?.message === requestCanceledError;

    if (isCanceled) return;

    enqueueSnackbar(notifierOptions(errorMessage, 'error', closeSnackbar));
  };

  return {
    showSuccessNotifier,
    showErrorNotifier,
    closeSnackbar,
  };
};

export default useNotifiers;

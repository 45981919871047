import React from 'react';
import { List } from '@mui/material';
import AttachmentsEmpty from 'shared/widgets/Attachments/AttachmentList/AttachmentsEmpty';
import AttachmentListItem from 'shared/widgets/Attachments/AttachmentList/AttachmentListItem';
import { IAttachmentInEditMode, Attachment } from 'shared/widgets/Attachments/Attachments.model';

interface IAttachmentListViewProps {
  attachments: Attachment[]
  attachmentInEditMode: IAttachmentInEditMode | null
  focusAttachment: (attachment: Attachment) => void
  changeAttachmentName: (e: React.SyntheticEvent) => void
  editAttachmentName: (attachmentId: number) => void
  removeAttachment: (attachmentId: number) => void
  isViewMode: boolean
}

const AttachmentListView: React.FC<IAttachmentListViewProps> = ({
  attachments, attachmentInEditMode, focusAttachment,
  changeAttachmentName, editAttachmentName, removeAttachment, isViewMode,
}) => {
  const hasAttachments = attachments.length;

  if (!hasAttachments) {
    return <AttachmentsEmpty />;
  }

  return (
    <List disablePadding>
      {[...attachments].reverse().map((attachment) => (
        <AttachmentListItem
          key={attachment.id}
          attachment={attachment}
          attachmentInEditMode={attachmentInEditMode}
          focusAttachment={focusAttachment}
          changeAttachmentName={changeAttachmentName}
          editAttachmentName={editAttachmentName}
          removeAttachment={removeAttachment}
          isViewMode={isViewMode}
        />
      ))}
    </List>
  );
};

export default AttachmentListView;

/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { getInsideSalesItem, patchInsideSalesItem } from 'core/api/insideSales.api';
import { IInsideSalesListItem } from 'pages/InsideSales/model';

export const getInsideSalesListItemAction = createAsyncThunk(
  'getInsideSalesListItemAction',
  async (
    { controller, id } : { controller: AbortController, id: string | number },
    { rejectWithValue }) => {
    const { signal } = controller;

    try {
      const { data } = await getInsideSalesItem(signal, id);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  });

export const patchInsideSalesListItemAction = createAsyncThunk(
  'patchInsideSalesListItemAction',
  async (
    { id, params } : { id: string | number, params: object },
    { rejectWithValue }) => {
    try {
      await patchInsideSalesItem(id, params);
    } catch (error) {
      return rejectWithValue(error);
    }
  });

export interface IState {
  result: IInsideSalesListItem;
  isLoading: boolean;
}

const initialState: IState = {
  result: {} as IInsideSalesListItem,
  isLoading: false,
};

const insideSalesListItemSlice = createSlice({
  name: 'insideSalesListItemSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        getInsideSalesListItemAction.fulfilled,
        (state, action) => {
          state.result = action.payload;
          state.isLoading = false;
        },
      )
      .addMatcher(
        isAnyOf(
          getInsideSalesListItemAction.rejected,
          patchInsideSalesListItemAction.rejected,
          patchInsideSalesListItemAction.fulfilled,
        ),
        (state) => {
          state.isLoading = false;
        },
      )
      .addMatcher(
        isAnyOf(
          getInsideSalesListItemAction.pending,
          patchInsideSalesListItemAction.pending,
        ),
        (state) => {
          state.isLoading = true;
        },
      );
  },
});

export const insideSalesListItemReducer = insideSalesListItemSlice.reducer;

import { ICustomerComment } from 'pages/Customers/Customers.models';
import {
  CREATE_CUSTOMERS_COMMENT_REQUEST,
  CREATE_CUSTOMERS_COMMENT_SUCCESS,
  CREATE_CUSTOMERS_COMMENT_FAILURE,
} from 'pages/Customers/CustomersComments/CreateComment/actionTypes';

import {
  GET_CUSTOMERS_COMMENTS_REQUEST,
  GET_CUSTOMERS_COMMENTS_SUCCESS,
  GET_CUSTOMERS_COMMENTS_FAILURE,
} from 'pages/Customers/CustomersComments/GetComments/actionTypes';

import {
  EDIT_CUSTOMERS_COMMENT_REQUEST,
  EDIT_CUSTOMERS_COMMENT_SUCCESS,
  EDIT_CUSTOMERS_COMMENT_FAILURE,
} from 'pages/Customers/CustomersComments/EditComment/actionTypes';

import {
  REMOVE_CUSTOMERS_COMMENT_REQUEST,
  REMOVE_CUSTOMERS_COMMENT_SUCCESS,
  REMOVE_CUSTOMERS_COMMENT_FAILURE,
} from 'pages/Customers/CustomersComments/RemoveComment/actionTypes';
import {
  CLEAR_ERRORS_CUSTOMERS_COMMENT,
} from 'pages/Customers/CustomersComments/ClearErrorsComments/actionTypes';

interface ICustomerCommentsState {
  error: string
  isLoading: boolean
  data: {
    results: ICustomerComment[]
  }
}

const initialState: ICustomerCommentsState = {
  error: '',
  isLoading: false,
  data: {
    results: [],
  },
};

const commentsReducer = (state = initialState, {
  type, comments, newComment, commentId, editedComment, error,
}) => {
  switch (type) {
    case GET_CUSTOMERS_COMMENTS_REQUEST:
    case CREATE_CUSTOMERS_COMMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CUSTOMERS_COMMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          results: comments,
        },
        error: '',
      };
    case CREATE_CUSTOMERS_COMMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          results: [
            ...state.data.results.map((comment) => (
              {
                ...comment,
                error: '',
              }
            )),
            newComment,
          ],
        },
        error: '',
      };
    case GET_CUSTOMERS_COMMENTS_FAILURE:
    case CREATE_CUSTOMERS_COMMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error,
      };
    case EDIT_CUSTOMERS_COMMENT_REQUEST:
    case REMOVE_CUSTOMERS_COMMENT_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          results: state.data.results.map((comment) => ((comment.id === commentId) ? {
            ...comment,
            isLoading: true,
          } : comment)),
        },
      };
    case EDIT_CUSTOMERS_COMMENT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          results: state.data.results.map((comment) => ((comment.id === editedComment.id) ? {
            ...comment,
            ...editedComment,
            isLoading: false,
            error: '',
          } : comment)),
        },
      };
    case REMOVE_CUSTOMERS_COMMENT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          results: state.data.results.filter(
            (comment) => comment.id !== commentId,
          ),
        },
      };
    case EDIT_CUSTOMERS_COMMENT_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          results: state.data.results.map((comment) => ((comment.id === commentId) ? {
            ...comment,
            isLoading: false,
            error,
          } : comment)),
        },
      };
    case REMOVE_CUSTOMERS_COMMENT_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          results: state.data.results.map((comment) => ((comment.id === commentId) ? {
            ...comment,
            isLoading: false,
            error,
          } : comment)),
        },
      };
    case CLEAR_ERRORS_CUSTOMERS_COMMENT:
      return {
        ...state,
        data: {
          ...state.data,
          results: state.data.results.map((comment) => ({
            ...comment,
            error: '',
          })),
        },
        error: '',
      };
    default:
      return state;
  }
};

export default commentsReducer;

/* eslint-disable no-param-reassign */
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  createInsideSalesAttachmentAction,
  getInsideSalesAttachmentListAction,
  editInsideSalesAttachmentItemAction,
  deleteInsideSalesAttachmentItemAction,
} from 'core/actions/crm/InsideSales/InsideSalesAttachments/InsideSalesAttachmentAction';
import { IInsideSalesRequestAttachment } from 'pages/InsideSales/model';

export interface IState {
  error: string
  isLoading: boolean
  results: IInsideSalesRequestAttachment[]
}

const initialState: IState = {
  error: '',
  isLoading: false,
  results: [],
};

const InsideSalesAttachmentsSlice = createSlice({
  name: 'InsideSalesAttachmentsSlice',
  initialState,
  reducers: {
    clearAttachmentsErrorsAction: (state) => {
      state.error = '';
      state.results = state.results.map((item) => ({
        ...item,
        error: '',
      }));
    },
    clearAttachmentsAction: (state) => {
      state.results = [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(
        createInsideSalesAttachmentAction.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.results = [
            ...state.results,
            action.payload,
          ];
        },
      )
      .addCase(
        getInsideSalesAttachmentListAction.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.results = action.payload;
        },
      )
      .addCase(
        editInsideSalesAttachmentItemAction.fulfilled,
        (state, action) => {
          state.results = state.results.map((attachment) => {
            if (attachment.id === action.payload.id) {
              return {
                ...action.payload,
                isLoading: false,
              };
            }

            return attachment;
          });
        },
      )
      .addCase(
        deleteInsideSalesAttachmentItemAction.fulfilled,
        (state, action) => {
          const id = action.meta.arg.attachmentId;

          state.results = state.results.filter((attachment) => (
            attachment.id !== id
          ));
        },
      )
      .addMatcher(
        isAnyOf(
          editInsideSalesAttachmentItemAction.pending,
          deleteInsideSalesAttachmentItemAction.pending,
        ),
        (state, action) => {
          const id = action.meta.arg.attachmentId;

          state.results = state.results.map((attachment) => {
            if (attachment.id === id) {
              return {
                ...attachment,
                isLoading: true,
              };
            }

            return attachment;
          });
        },
      )
      .addMatcher(
        isAnyOf(
          createInsideSalesAttachmentAction.pending,
          getInsideSalesAttachmentListAction.pending,
        ),
        (state) => {
          state.isLoading = true;
        },
      )
      .addMatcher(
        isAnyOf(
          createInsideSalesAttachmentAction.rejected,
          getInsideSalesAttachmentListAction.rejected,

        ),
        (state, action) => {
          state.isLoading = false;
          state.error = action.payload?.detail || '';
        },
      )
      .addMatcher(
        isAnyOf(
          editInsideSalesAttachmentItemAction.rejected,
          deleteInsideSalesAttachmentItemAction.rejected,
        ),
        (state, action) => {
          const id = action.meta.arg.attachmentId;

          state.results = state.results.map(
            (attachment) => {
              if (attachment.id === id) {
                return {
                  ...attachment,
                  isLoading: false,
                  error: action.payload?.detail || '',
                };
              }

              return attachment;
            },
          );
        },
      );
  },
});

export const {
  clearAttachmentsErrorsAction, clearAttachmentsAction,
} = InsideSalesAttachmentsSlice.actions;

export const InsideSalesAttachmentsReducer = InsideSalesAttachmentsSlice.reducer;

import React, { useMemo, memo } from 'react';
import { useAppSelector } from 'core/store';
import { parseOpenapiSchema } from 'core/utilities/openapiSchema';
import OrderDataItem from 'pages/WirelineEngagements/shared/OrderDataSidebar/OrderDataItem';
import { getLabelesFromShema, getOrderDataFromMeta } from 'pages/WirelineEngagements/shared/OrderDataSidebar/utilites';
import { IParsedOrderData } from 'pages/WirelineEngagements/WirelineEngagementsTables/wirelineEngagementsTables.model';

import './OrderDataSidebar.scss';

interface IOrderDataContentProps {
  schemaName: string
  orderData: IParsedOrderData
}

const OrderDataContent: React.FC<IOrderDataContentProps> = ({
  orderData,
  schemaName,
}) => {
  const openApiSchemas = useAppSelector((state) => state.openapiSchemas.schemas);

  const shemaData = useMemo(() => {
    const parsedSchema = parseOpenapiSchema(openApiSchemas, schemaName);

    return getLabelesFromShema(parsedSchema);
  }, [openApiSchemas]);

  const renderData = useMemo(() => getOrderDataFromMeta(shemaData, orderData),
    [shemaData, orderData]);

  return (
    <div className="order-data-sidebar">
      {renderData.map(
        (renderItem) => (<OrderDataItem key={renderItem.label} data={renderItem} />),
      )}
    </div>
  );
};

export default memo(OrderDataContent);

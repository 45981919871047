import {
  REMOVE_ENGAGEMENTS_ATTACHMENT_REQUEST,
  REMOVE_ENGAGEMENTS_ATTACHMENT_SUCCESS,
  REMOVE_ENGAGEMENTS_ATTACHMENT_FAILURE,
} from 'pages/WirelineEngagements/WirelineEngagementsAttachments/RemoveEngagementsAttachment/actionTypes';

export const removeEngagementsAttachmentRequest = (attachmentId) => ({
  type: REMOVE_ENGAGEMENTS_ATTACHMENT_REQUEST,
  attachmentId,
});

export const removeEngagementsAttachmentSuccess = (attachmentId) => ({
  type: REMOVE_ENGAGEMENTS_ATTACHMENT_SUCCESS,
  attachmentId,
});

export const removeEngagementsAttachmentFailure = (attachmentId, error) => ({
  type: REMOVE_ENGAGEMENTS_ATTACHMENT_FAILURE,
  attachmentId,
  error,
});

import React from 'react';
import { Button } from '@mui/material';
import styles from 'assets/styles/_resources.scss';
import PropTypes from 'prop-types';

const CreateButton = ({
  onClick, text, className, ...other
}) => (
  <Button
    className={className}
    variant="contained"
    size="large"
    color="primary"
    sx={{
      fontWeight: 700,
      boxShadow: 'none !important',
      padding: '6px 12px',
      '&:hover': {
        backgroundColor: `${styles.primaryColor400} !important`,
      },
    }}
    onClick={onClick}
    {...other}
  >
    {text}
  </Button>
);

CreateButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};
CreateButton.defaultProps = {
  className: '',
};

export default CreateButton;

import map from 'lodash/map';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
/* eslint-disable no-unused-vars */
import {
  IOpenapiSchema,
  IOpenapiSchemaProperties,
  IOpenapiSchemasParsedItem,
  IOpenapiSchemasPaths,
} from 'core/models/openapi.models';
/* eslint-enable no-unused-vars */

export const getSchemaNameFromRef = (refToSchema?: string | null) => refToSchema && refToSchema.split('/').pop();

export const getOpenapiSchemaNameFromEndpoint = (paths: IOpenapiSchemasPaths, endpoint: string) => {
  const openapiSchemaNamePath = `${endpoint}.get.responses.200.content['application/json'].schema.$ref`;

  const refToSchema = get(paths, openapiSchemaNamePath, null) as string | null;
  return getSchemaNameFromRef(refToSchema);
};

export const parseOpenapiSchema = (
  schema: IOpenapiSchema, schemaName?: string | null,
): IOpenapiSchemasParsedItem[] => {
  if (!schemaName || isEmpty(schema)) {
    return [];
  }

  const schemaItem = get(schema, schemaName);
  const mainProperties = get(schemaItem, 'properties') as IOpenapiSchemaProperties;

  if (!mainProperties) {
    return [];
  }

  return map(
    mainProperties,
    (value, key) => {
      const refToSchema = get(value, 'allOf.0.$ref', '') as string;
      const innerSchemaName = getSchemaNameFromRef(refToSchema);

      const properties = innerSchemaName ? parseOpenapiSchema(schema, innerSchemaName) : null;

      const innerSchema = get(schema, String(innerSchemaName), {});

      return {
        ...value,
        ...innerSchema,
        fieldName: key,
        properties,
      };
    },
  );
};

export const getSchemaNameByProduct = (
  schema: IOpenapiSchema,
  schemaName: string,
  product: string,
) => {
  const selectProductSchema = get(schema, schemaName, {}) as IOpenapiSchema;
  const listOfRefs = get(selectProductSchema, 'oneOf', []);

  const productRefWrapper = find(listOfRefs, (refWrapper) => {
    const productRef = get(refWrapper, '$ref', '') as string;
    const productSchemaName = getSchemaNameFromRef(productRef) as string;
    const productSchema = get(schema, productSchemaName);

    return get(productSchema, 'properties.product.default') === product;
  });

  const productRef = get(productRefWrapper, '$ref', '') as string;
  return getSchemaNameFromRef(productRef);
};

export const parseOpenapiSchemaByEndpointAndProduct = (
  schema: IOpenapiSchema,
  paths: IOpenapiSchemasPaths,
  endpoint: string,
  product: string | null | undefined,
) => {
  const schemaName = getOpenapiSchemaNameFromEndpoint(paths, endpoint);

  if (!schemaName || isEmpty(schema) || !product) {
    return [];
  }

  const productSchemaName = getSchemaNameByProduct(schema, schemaName, product);

  if (!productSchemaName) {
    return [];
  }

  return parseOpenapiSchema(schema, productSchemaName);
};

export const parseOpenapiSchemaByEndpoint = (
  schema: IOpenapiSchema,
  paths: IOpenapiSchemasPaths,
  endpoint: string,
) => {
  const schemaName = getOpenapiSchemaNameFromEndpoint(paths, endpoint);

  if (!schemaName || isEmpty(schema)) {
    return [];
  }

  return parseOpenapiSchema(schema, schemaName);
};

import {
  createCustomerFromDraftRequest,
  createCustomerFromDraftSuccess,
  createCustomerFromDraftFailure,
} from './actionCreators';

import putCustomers from './api.service';

export const createCustomerFromDraftAction = (id, params) => async (dispatch) => {
  try {
    dispatch(createCustomerFromDraftRequest());
    const { data } = await putCustomers(id, params);
    dispatch(createCustomerFromDraftSuccess(data));
  } catch (e) {
    // @ts-ignore
    dispatch(createCustomerFromDraftFailure(e?.message));
    throw e;
  }
};

export default { createCustomerFromDraftAction };

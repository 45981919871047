import { isEmpty, isPlainObject } from 'lodash';
// eslint-disable-next-line no-unused-vars
import { IAuthorizedModule, IUserProfileData, IActions } from 'layouts/BaseLayout/GetUserProfile/GetUserProfile.models';

export const flatActionsData = (data: IActions, parentKey: string) => {
  if (isEmpty(data)) return {};

  return Object.entries(data)
    .reduce((acc, [key, value]) => {
      const fullKey = `${parentKey}.${key}`;
      const isNestedObject = isPlainObject(value);

      if (isNestedObject) {
        const parsedData = flatActionsData(value as IActions, fullKey);
        return { ...acc, ...parsedData };
      }

      return { ...acc, [fullKey]: value };
    }, {});
};

export const getActionsData = (modules: IAuthorizedModule[], name?: string) => {
  if (!modules.length) return {};

  return modules.reduce((value, current) => {
    const fullName = name ? `${name}.${current.name}` : current.name;

    const flatedActionsData = flatActionsData(current.actions, fullName);
    const nestedActionsData = getActionsData(current.tabs, fullName);
    return {
      ...value,
      ...flatedActionsData,
      ...nestedActionsData,
    };
  }, {});
};

export const getPermissionsData = ({ authorizedModules }: IUserProfileData) => {
  if (!authorizedModules?.length) return {};

  return getActionsData(authorizedModules);
};

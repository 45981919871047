import {
  CREATE_CUSTOMERS_COMMENT_REQUEST,
  CREATE_CUSTOMERS_COMMENT_SUCCESS,
  CREATE_CUSTOMERS_COMMENT_FAILURE,
} from 'pages/Customers/CustomersComments/CreateComment/actionTypes';

export const createCommentRequest = () => ({ type: CREATE_CUSTOMERS_COMMENT_REQUEST });

export const createCommentSuccess = (newComment) => (
  { type: CREATE_CUSTOMERS_COMMENT_SUCCESS, newComment }
);

export const createCommentFailure = (error) => ({ type: CREATE_CUSTOMERS_COMMENT_FAILURE, error });

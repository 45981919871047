import {
  EDIT_CUSTOMERS_LOCATION_REQUEST,
  EDIT_CUSTOMERS_LOCATION_SUCCESS,
  EDIT_CUSTOMERS_LOCATION_FAILURE,
} from 'pages/Customers/CustomersLocations/EditLocation/actionTypes';

export const editLocationRequest = () => ({ type: EDIT_CUSTOMERS_LOCATION_REQUEST });

export const editLocationSuccess = (location) => (
  { type: EDIT_CUSTOMERS_LOCATION_SUCCESS, location }
);

export const editLocationFailure = (errors) => ({ type: EDIT_CUSTOMERS_LOCATION_FAILURE, errors });

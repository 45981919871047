import Dashboard from 'pages/Dashboard';
import WirelineEngagements from 'pages/WirelineEngagements';
import MobilityEngagements from 'pages/MobilityEngagements';
import MobilityRequests from 'pages/MobilityRequests';
import Customers from 'pages/Customers';
import CustomerSupportRequests from 'pages/CustomerSupportRequests';
import UserManagement from 'pages/UserManagement';
import userManagementRoutes from 'pages/UserManagement/routes';
import accessNames from 'core/routing/accessRoutesNames';
import paths from 'core/routing/paths';
import customerRoutes from 'pages/Customers/routes';
import { IRoutingConfigItem } from 'core/routing/models';
import InsideSales from 'pages/InsideSales';
import wirelineEngagementsRoutes from 'pages/WirelineEngagements/routes';
import contractManagementRoutes from 'pages/ContractManagment/routes';
import ContractManagement from 'pages/ContractManagment';

export const routes = [
  {
    // child and redirect for dashboard defines dynamically
    path: paths.dashboard,
    component: Dashboard,
    accessName: accessNames.dashboard,
  },
  {
    path: paths.wirelineEngagements,
    component: WirelineEngagements,
    accessName: accessNames.wirelineEngagements,
  },
  {
    path: paths.mobilityEngagements,
    component: MobilityEngagements,
    accessName: accessNames.mobilityEngagements,
  },
  {
    path: paths.insideSales,
    component: InsideSales,
    accessName: accessNames.insideSalesRequests,
  },
  {
    path: paths.mobilityRequests,
    component: MobilityRequests,
    accessName: accessNames.mobilityRequests,
  },
  {
    path: paths.customerSupportRequests,
    component: CustomerSupportRequests,
    accessName: accessNames.customerSupportRequests,
  },
  {
    path: paths.customers,
    component: Customers,
    accessName: accessNames.customers,
    child: customerRoutes,
    redirect: customerRoutes[0].path,
  },
  {
    path: paths.userManagement,
    component: UserManagement,
    accessName: accessNames.userManagement,
    child: userManagementRoutes,
    redirect: userManagementRoutes[0].path,
  },
  {
    path: paths.contractManagement,
    component: ContractManagement,
    accessName: accessNames.contractManagement,
    child: contractManagementRoutes,
    redirect: contractManagementRoutes[0].path,
  },
  {
    path: paths.wirelineEngagements,
    component: WirelineEngagements,
    accessName: accessNames.wirelineEngagements,
    child: wirelineEngagementsRoutes,
    redirect: wirelineEngagementsRoutes[0].path,
  },
] as IRoutingConfigItem[];

export default routes;

import React from 'react';
import { Text } from 'recharts';
import styles from 'assets/styles/_resources.scss';
import { IChartItem, ITickPayload } from 'core/models/Recharts.models';

interface ISimpleBarChartXAxisTick {
    x: number,
    y: number,
    payload: ITickPayload,
    width: number,
    barSize: number,
    data: IChartItem[],
    isNeedSeparateLine: boolean,
}

const SimpleBarChartXAxisTick: React.FC<ISimpleBarChartXAxisTick > = (
  {
    x, y, payload, width, barSize, data, isNeedSeparateLine,
  }) => {
  const dataLength = data.length;

  if (!payload) {
    return null;
  }

  const barGap = (width - (barSize * dataLength)) / (dataLength);

  const lineX = x + (barSize / 2) + (barGap / 2);

  return (
    <g>
      <Text
        width={75}
        x={x}
        y={y}
        textAnchor="middle"
        verticalAnchor="start"
        fill={styles.darkColor500}
      >
        {payload.value}
      </Text>
      {isNeedSeparateLine && (
      <g transform={`translate(0,${y - 20})`}>
        <line
          x1={lineX}
          y1={20}
          x2={lineX}
          y2={5}
          stroke={styles.lightColor500}
          strokeWidth={1}
        />
      </g>
      )}
    </g>
  );
};

export default SimpleBarChartXAxisTick;

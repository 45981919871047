import React from 'react';
import Table from 'shared/Table';
import { useAppSelector } from 'core/store';
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import EscalationSupportRowActions
  from '@dashboardEscalationSupport/EscalationSupportTable/EscalationSupportRowActions';
import {
  colDefOptionActionsSingleIcon,
} from 'shared/Table/constants';
import ContentShrink from 'shared/ContentShrink';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { IEscalationSupportItem } from '@dashboardEscalationSupport/EscalationSupportTable/model';
import './EscalationSupportTable.scss';

interface IEscalationSupportTable {
  isAvailableEditRow: boolean;
}

const EscalationSupportTable: React.FC<IEscalationSupportTable> = ({ isAvailableEditRow }) => {
  const { activeRowId } = useTableActiveRowContext<IEscalationSupportItem>();

  const isEscalationSupportDataLoading = useAppSelector((state) => state.dashboard
    .escalationSupport.isLoading);

  const escalationSupportDataCount = useAppSelector(
    (state) => state.dashboard.escalationSupport.count);

  const escalationSupportData = useAppSelector(
    (state) => state.dashboard.escalationSupport.results);

  const renderRowActions = isAvailableEditRow
    ? ({ row: { original } }) => (
      <EscalationSupportRowActions
        row={original as unknown as ISowDataListItem}
      />
    ) : undefined;

  return (
    <ContentShrink isShrinked={!!activeRowId}>
      <div className="escalation-support__table">
        <Table
          isLoading={isEscalationSupportDataLoading}
          rowCount={escalationSupportDataCount}
          showColumnFilters
          showGlobalFilter={false}
          showToolbar={false}
          rows={escalationSupportData}
          renderRowActions={renderRowActions}
          displayColumnDefOptions={{
            ...(isAvailableEditRow && colDefOptionActionsSingleIcon),
          }}
        />
      </div>
    </ContentShrink>
  );
};

export default EscalationSupportTable;

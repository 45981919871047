/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  IRetentionDataItem,
  // eslint-disable-next-line no-unused-vars
  IRetentionParams,
} from '@dashboardWirelineRetentionDepartment/RetentionManagerDepartment.models';
import getInProgressEngagements from '@dashboardWirelineRetentionDepartment/GetInProgressEngagements/api.service';

export interface IState {
  data: IRetentionDataItem;
  isLoading: boolean;
  error: object | null;
}

const initialState: IState = {
  data: {},
  isLoading: false,
  error: null,
};

export const getInProgressEngagementsAction = createAsyncThunk(
  'getInProgressEngagementsAction',
  async ({ controller, params }: { controller: AbortController, params: IRetentionParams },
    { rejectWithValue }) => {
    try {
      const { signal } = controller;
      const { data } = await getInProgressEngagements(signal, params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const InProgressEngagementsSlice = createSlice({
  name: 'getInProgressEngagements',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getInProgressEngagementsAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getInProgressEngagementsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(getInProgressEngagementsAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
        state.data = {};
      });
  },
});

export const getInProgressEngagementsReducer = InProgressEngagementsSlice.reducer;

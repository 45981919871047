import React from 'react';
import get from 'lodash/get';
import Actions from 'shared/Table/Actions';
import { orderActionTitle } from 'core/labels';
import CommentIcon from '@mui/icons-material/Comment';
import NextWeekOutlinedIcon from '@mui/icons-material/NextWeekOutlined';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { IRenewalSowDataItem } from '@EngagementsRenewalSowData/renewalSowData.models';

interface IRenewalSowDataTableRowActionsProps {
  row: IRenewalSowDataItem
}

const RenewalSowDataTableRowActions: React.FC<
  IRenewalSowDataTableRowActionsProps
> = ({ row }) => {
  const {
    activeRowId,
    activeRowMode,
    setActiveRowEdit,
    setActiveRowEscalationSupport,
    setActiveRowComment,
  } = useTableActiveRowContext<IRenewalSowDataItem>();
  const { isEscalationSupportMode, isCommentMode } = getTableRowModeBoolean(activeRowMode);

  const rowId = get(row, 'id');

  const isEscalationSupportBtnSelected = isEscalationSupportMode && rowId === activeRowId;
  const isCommentButtonSelected = isCommentMode && rowId === activeRowId;

  const isEscalationSupportBtnDisabled = !!activeRowId && !isEscalationSupportBtnSelected;
  const isCommentButtonDisabled = !!activeRowId && !isCommentButtonSelected;

  const isMoreButtonDisabled = isEscalationSupportBtnDisabled && isCommentButtonDisabled;

  const moreButtonConfig = [
    {
      onClick: () => setActiveRowEscalationSupport(row),
      isDisabled: isEscalationSupportBtnDisabled,
      icon: <NextWeekOutlinedIcon />,
      text: 'Escalation Support',
    },
    {
      onClick: () => setActiveRowComment(row),
      isDisabled: isCommentButtonDisabled,
      icon: <CommentIcon />,
      text: 'Order Comments',
    },
  ];

  return (
    <Actions<IRenewalSowDataItem>
      row={row}
      activeRowId={activeRowId}
      activeRowMode={activeRowMode}
      setActiveRowEdit={setActiveRowEdit}
      moreButtonConfig={moreButtonConfig}
      moreButtonTooltipTitle={orderActionTitle}
      isMoreButtonDisabled={isMoreButtonDisabled}
    />
  );
};

export default RenewalSowDataTableRowActions;

import React from 'react';
import { useFormikContext } from 'formik';
import { ISowDataListItem, OrderActions } from '@EngagementsSowData/sowData.model';
import OrderItem from '@EngagementsSowData/WirelineEngagementsSowDataModals/ActionsModal/OrderItem';
import {
  orderActionsTitle,
} from '@EngagementsSowData/constants';
import CustomModal from 'shared/CustomModal';

interface IRequestContractModalProps {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  orderAction: OrderActions;
  selectedOrders: ISowDataListItem[];
}

const RequestContractModal: React.FC<IRequestContractModalProps> = ({
  isModalOpen,
  setIsModalOpen,
  orderAction,
  selectedOrders,
}) => {
  const { submitForm } = useFormikContext();

  const onClose = () => {
    setIsModalOpen(false);
  };

  const onConfirm = () => {
    submitForm();
    onClose();
  };

  const orderList = (
    <div>
      {selectedOrders.map((order) => (
        <OrderItem key={order.id} order={order} orderAction={orderAction} />
      ))}
    </div>
  );

  return (
    <CustomModal
      title={orderActionsTitle[OrderActions.REQUEST_CONTRACTS]}
      confirmButtonText="Yes"
      cancelButtonText="No"
      isModalOpen={isModalOpen}
      onClickConfirm={onConfirm}
      onCloseModal={onClose}
      areButtonsVisible
    >
      {orderList}
    </CustomModal>
  );
};

export default RequestContractModal;

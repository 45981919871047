import getCustomersList from 'pages/Customers/CustomersList/GetCustomersList/api.service';
import {
  getCustomersListRequest,
  getCustomersListSuccess,
  getCustomersListFailure,
} from 'pages/Customers/CustomersList/GetCustomersList';

const getCustomersListAction = (controller, pagination) => async (dispatch) => {
  try {
    const { signal } = controller;
    dispatch(getCustomersListRequest());
    const { data } = await getCustomersList(signal, pagination);
    dispatch(getCustomersListSuccess(data));
  } catch (error) {
    dispatch(getCustomersListFailure(error));
  }
};

export default getCustomersListAction;

import React from 'react';
import FiltersByPeriod from 'pages/Dashboard/shared/FiltersByPeriod';
import { useEscalationSupportChartsContext } from '@dashboardEscalationSupport/EscalationSupportCharts/EscalationSupportChartsContext';

const SellerFiltersByPeriod = () => {
  const {
    getEscalationSupportData,
    period,
  } = useEscalationSupportChartsContext();

  return (
    <FiltersByPeriod
      period={period}
      onChange={getEscalationSupportData}
    />
  );
};

export default SellerFiltersByPeriod;

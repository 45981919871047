import { getEngagementAttachments } from 'core/api/wirelineEngagements.api';
import {
  getEngagementsAttachmentsRequest,
  getEngagementsAttachmentsSuccess,
  getEngagementsAttachmentsFailure,
} from 'pages/WirelineEngagements/WirelineEngagementsAttachments/GetEngagementsAttachments/actionsCreators';

const getEngagementAttachmentsAction = (
  controller: AbortController,
  engagementId: number | string,
) => async (dispatch) => {
  try {
    const { signal } = controller;
    dispatch(getEngagementsAttachmentsRequest());

    const { data } = await getEngagementAttachments(signal, engagementId);

    dispatch(getEngagementsAttachmentsSuccess(data));
  } catch (error) {
    dispatch(getEngagementsAttachmentsFailure(error));
  }
};

export default getEngagementAttachmentsAction;

import React from 'react';
import BookingInfo from '@dashboardWirelineSellerDepartment/BookingInfo';
import TechnicalOverlay from '@dashboardWirelineSellerDepartment/TechnicalOverlay';
import SellerEngagementsMrcChart from '@dashboardWirelineSellerDepartment/SellerEngagementsMrcChart';
import { useSellerDepartmentContext } from '@dashboardWirelineSellerDepartment/SellerDepartmentContext';
import SellerEngagementsTotalsChart from '@dashboardWirelineSellerDepartment/SellerEngagementsTotalsChart';
import SellerEngagementsRevenueChart from '@dashboardWirelineSellerDepartment/SellerEngagementsRevenueChart';
import SellerEngagementsByPeriodChart from '@dashboardWirelineSellerDepartment/SellerEngagementsByPeriodChart';
import SellerFiltersByPeriod from 'pages/Dashboard/BusinessDirections/Wireline/WirelineDepartments/SellerDepartment/SellerFiltersByPeriod';

import './SellerDepartment.scss';

const SellerDepartmentContent = () => {
  const { actionsPermissions } = useSellerDepartmentContext();

  const {
    isAvailableGetMRCChart,
    isAvailableGetTotalChart,
    isAvailableGetBookingTable,
    isAvailableGetByPeriodChart,
    isAvailableGetByRevenueChart,
    isAvailableGetTechnicalOverlayChart,
  } = actionsPermissions;

  return (
    <div className="seller-department">
      <SellerFiltersByPeriod />
      <div className="seller-department__charts">
        {isAvailableGetTotalChart && <SellerEngagementsTotalsChart />}
        {isAvailableGetByPeriodChart && <SellerEngagementsByPeriodChart />}
        {isAvailableGetByRevenueChart && <SellerEngagementsRevenueChart />}
        {isAvailableGetMRCChart && <SellerEngagementsMrcChart />}
      </div>
      {isAvailableGetTechnicalOverlayChart && <TechnicalOverlay />}
      {
        isAvailableGetBookingTable && (
          <div className="seller-department__boking-info">
            <BookingInfo />
          </div>
        )
      }
    </div>
  );
};

export default SellerDepartmentContent;

import React from 'react';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import EscalationSupportModalForm from 'pages/WirelineEngagements/shared/EscalationSupportModalForm';
import CommentsRowSidebar from 'pages/WirelineEngagements/shared/CommentsRowSidebar';
import EditRowRenewalSowSidebar from '@EngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableModals/EditRowRenewalSowSidebar';
import { IRenewalSowDataItem } from '@EngagementsRenewalSowData/renewalSowData.models';
import { TableRowMode } from 'shared/Table/Table.models';

const escalationSupportRequestParams = { isRenewal: true };

const RenewalSowDataTableModals = () => {
  const {
    activeRowMode, activeRow, activeRowId, cleanActiveRow,
    secondarySidebarMode, setSecondarySidebarMode,
  } = useTableActiveRowContext<IRenewalSowDataItem>();

  const { isEscalationSupportMode, isCommentMode } = getTableRowModeBoolean(activeRowMode);

  const escalationSupportRowId = isEscalationSupportMode ? activeRowId : undefined;
  const escalationSupportRow = isEscalationSupportMode ? activeRow : undefined;

  const isCommentsInsideEditOpen = secondarySidebarMode === TableRowMode.COMMENT;

  const isActiveRow = isCommentMode || isCommentsInsideEditOpen;
  const commentsActiveRowId = (isActiveRow ? activeRowId : null) as string | number | null;

  return (
    <>
      <EditRowRenewalSowSidebar />
      <EscalationSupportModalForm
        escalationSupportRowId={escalationSupportRowId}
        cleanEscalationSupportRow={cleanActiveRow}
        escalationSupportRow={escalationSupportRow}
        requestParams={escalationSupportRequestParams}
      />
      <CommentsRowSidebar
        title="Order Comments"
        activeRowId={commentsActiveRowId}
        cleanActiveRow={cleanActiveRow}
        isCommentsInsideEditOpen={isCommentsInsideEditOpen}
        setSecondarySidebarMode={setSecondarySidebarMode}
      />
    </>
  );
};

export default RenewalSowDataTableModals;

import {
  CREATE_CUSTOMERS_DRAFT_REQUEST,
  CREATE_CUSTOMERS_DRAFT_SUCCESS,
  CREATE_CUSTOMERS_DRAFT_FAILURE,
} from './actionTypes';

export const createCustomersDraftRequest = () => ({ type: CREATE_CUSTOMERS_DRAFT_REQUEST });
export const createCustomersDraftSuccess = (draftFields) => (
  { type: CREATE_CUSTOMERS_DRAFT_SUCCESS, draftFields }
);
export const createCustomersDraftFailure = (error) => ({
  type: CREATE_CUSTOMERS_DRAFT_FAILURE, error,
});

import React from 'react';
import { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux';

import store from 'core/store';
import KeycloakService from 'core/services/KeycloakService';

import App from './App';

const rootElement = document.getElementById('root');

const root = createRoot(rootElement);

const renderApp = () => (
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
  )
);

KeycloakService.initKeycloak(renderApp);

import React from 'react';
import cx from 'classnames';
import { Status } from '@EngagementsSowData/sowData.model';
import { statusLabels } from 'pages/WirelineEngagements/WirelineEngagementsTables/constants';

interface IActiveNumbersStatusProps {
  orderStatus: string;
}

const ActiveNumbersStatus: React.FC<IActiveNumbersStatusProps> = ({ orderStatus }) => {
  const statusClassName = cx({
    future: orderStatus === Status.FUTURE,
    progress: orderStatus === Status.IN_PROGRESS,
    completed: orderStatus === Status.COMPLETED,
  });

  return (
    <div className={`order-status ${statusClassName}`}>
      {statusLabels[orderStatus]}
    </div>
  );
};

export default ActiveNumbersStatus;

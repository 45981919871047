import find from 'lodash/find';
import get from 'lodash/get';
// eslint-disable-next-line no-unused-vars
import { IControlledFieldsConfigItem } from 'pages/WirelineEngagements/shared/FormikFieldSelector/models';

export const getIsControlledFieldDisabled = (
  controlledFieldsConfigs: IControlledFieldsConfigItem[],
  fieldName: string,
  values: Record<string, any>,
) => {
  const {
    switchEnabledValues, switchFieldName,
  } = find(controlledFieldsConfigs, { fieldName }) || {};

  if (!switchFieldName || !switchEnabledValues) {
    return false;
  }

  const switchFieldValue = get(values, switchFieldName);

  return !switchEnabledValues.includes(switchFieldValue);
};

import React from 'react';
import { Paper } from '@mui/material';
import BusinessDirections from './BusinessDirections';

const Dashboard = () => (
  <Paper className="dashboard">
    <BusinessDirections />
  </Paper>
);

export default Dashboard;

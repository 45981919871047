import React from 'react';
import {
  Grid,
  Button,
} from '@mui/material';
import { noop } from 'lodash';

import './CustomDialog.scss';

export type IButtonVariant = 'text' | 'outlined' | 'contained';
interface ICustomDialogButtonsProps {
  onClickSecondButton: () => void;
  secondButtonText?: string | undefined;
  firstButtonText?: string | undefined;
  onClickFirstButton?: (() => void) | undefined;
  areButtonsVisible?: boolean | undefined;
  firstButtonDisable?: boolean | undefined;
  buttonWidth?: string | undefined;
  isSecondButtonVisible?: boolean | undefined;
  firstButtonVariant?: IButtonVariant | undefined;
  secondButtonVariant?: IButtonVariant | undefined;
}

const CustomDialogButtons:React.FC<ICustomDialogButtonsProps> = ({
  buttonWidth,
  firstButtonText,
  secondButtonText,
  areButtonsVisible,
  firstButtonDisable,
  firstButtonVariant,
  onClickFirstButton,
  secondButtonVariant,
  onClickSecondButton,
  isSecondButtonVisible,
}) => (
  areButtonsVisible ? (
    <Grid
      sx={{ mt: 2 }}
      container
      direction="row"
      alignItems="center"
      justifyContent="center"
    >
      <Button
        onClick={onClickFirstButton}
        sx={{ mr: 2, width: buttonWidth }}
        disabled={firstButtonDisable as boolean}
        variant={firstButtonVariant as IButtonVariant}
      >
        {firstButtonText}
      </Button>
      {isSecondButtonVisible && (
      <Button
        sx={{ width: buttonWidth }}
        onClick={onClickSecondButton}
        variant={secondButtonVariant as IButtonVariant}
      >
        {secondButtonText}
      </Button>
      )}
    </Grid>
  ) : null
);

CustomDialogButtons.defaultProps = {
  buttonWidth: '157px',
  onClickFirstButton: noop,
  areButtonsVisible: true,
  secondButtonText: 'Cancel',
  firstButtonDisable: false,
  firstButtonText: 'Create',
  isSecondButtonVisible: true,
  firstButtonVariant: 'contained',
  secondButtonVariant: 'outlined',
};
export default CustomDialogButtons;

import { ITableCell } from 'shared/Table/Table.models';
import { ICustomerLocation } from 'pages/Customers/Customers.models';
import { CombinedExportTableParams } from 'shared/ExportTableXlsxFile/ExportTableXlsxFile.models';

export enum PmEngagementType {
  CANCELED = 'CANCELED',
}

export enum ORDER_TYPE {
  NEW = 'NEW',
  CANCELED = 'CANCELED',
  ADMIN_SUPPORT = 'ADMIN_SUPPORT',
  BILLING = 'BILLING',
  DISCONNECT = 'DISCONNECT',
  ESCALATION = 'ESCALATION',
  FUTURE_ORDER = 'FUTURE_ORDER',
  GENERAL_QUESTIONS = 'GENERAL_QUESTIONS',
  MACD = 'MACD',
  MOVE_BVOIP = 'MOVE_BVOIP',
  OUTAGE_MAINTENANCE = 'OUTAGE_MAINTENANCE',
  PORT_BVOIP = 'PORT_BVOIP',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  RE_ORDER = 'RE_ORDER',
  NEW_42393_UPFRONT = 'NEW_42393_UPFRONT',
  NEW_50212_RESIDUAL = 'NEW_50212_RESIDUAL',
  NEW_NON_AT_T = 'NEW_NON_AT_T',
  RE_ORDER_42393_UPFRONT = 'RE_ORDER_42393_UPFRONT',
  RE_ORDER_50212_RESIDUAL = 'RE_ORDER_50212_RESIDUAL',
  RE_ORDER_NON_AT_T = 'RE_ORDER_NON_AT_T',
  RENEWAL_50212_RESIDUAL = 'RENEWAL_50212_RESIDUAL',
  RENEWAL_52632_RESIDUAL = 'RENEWAL_52632_RESIDUAL',
}

export enum REQUEST_TYPE {
  NOT_SELECTED = 'NOT_SELECTED',
  CREDIT_REQUEST = 'CREDIT_REQUEST',
  CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT',
  EARLY_TERM_FREE = 'EARLY_TERM_FREE',
  GENERAL_BILLING_QUESTION = 'GENERAL_BILLING_QUESTION',
  SALES_SUPPORT = 'SALES_SUPPORT',
  OTHER = 'OTHER',
}

export enum ENGAGEMENT_BILING_TYPE {
  CREDIT_REQUEST = 'CREDIT_REQUEST',
  ADDRESS_CHANGE = 'ADDRESS_CHANGE',
  BILL_SEPARATION = 'BILL_SEPARATION',
  PAYMENT_ADVICE = 'PAYMENT_ADVICE',
  DEBIT_REQUEST = 'DEBIT_REQUEST',
  TAX_EXEMPTION = 'TAX_EXEMPTION',
  EARLY_TERM_FREE = 'EARLY_TERM_FREE',
  CREDIT_TRANSFER = 'CREDIT_TRANSFER',
  PAYMENT_INVESTIGATION_REROUTE = 'PAYMENT_INVESTIGATION_REROUTE',
}

export enum CREDIT_BUCKET {
  NOT_SELECTED = 'NOT_SELECTED',
  EFF = 'EFF',
  CC = 'CC',
  PARTIAL = 'PARTIAL',
}

export enum Status {
  NOT_SELECTED = 'NOT_SELECTED',
  FUTURE = 'FUTURE',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export enum PortDescription {
  NOT_SELECTED = 'NOT_SELECTED',
  PORT_BASED = 'PORT_BASED',
  VLAN_BASED = 'VLAN_BASED',
}

export enum System {
  ASAP = 'ASAP',
  BOOST = 'BOOST',
  NON_AT_T = 'NON_AT_T',
  NI_EXPRESS = 'NI_EXPRESS',
  OPUS = 'OPUS',
  RDS = 'RDS',
  SALES_EXPRESS = 'SALES_EXPRESS',
  USELL = 'USELL',
  ATT_ACC = 'ATT_ACC',
  BUSINESS_CENTER = 'BUSINESS_CENTER',
  CALNET = 'CALNET',
  CLOUDRUNNER = 'CLOUDRUNNER',
  SOSP = 'SOSP',
  SSPP = 'SSPP',
}

export enum ManagementOptions {
  AT_T_MANAGED_ROUTER = 'AT_T_MANAGED_ROUTER',
  CUSTOMER_MANAGED_ROUTER = 'CUSTOMER_MANAGED_ROUTER',
}

export enum CurrentCarrier {
  AT_T = 'AT_T',
  CABLE = 'CABLE',
  CLEC_HOSTED_VOIP = 'CLEC_HOSTED_VOIP',
  VERIZON = 'VERIZON',
}

export enum InternetServiceType {
  CABLE = 'CABLE',
  DSL = 'DSL',
  ETHERNET = 'ETHERNET',
  T1 = 'T1',
}

export enum ClassOfServiceType {
  NON_CRITICAL_HIGH = 'NON_CRITICAL_HIGH',
  BUSINESS_CRITICAL_MEDIUM = 'BUSINESS_CRITICAL_MEDIUM',
  BUSINESS_CRITICAL_HIGH = 'BUSINESS_CRITICAL_HIGH',
  INTERACTIVE = 'INTERACTIVE',
  REAL_TIME = 'REAL_TIME',
}

export enum HandoffType {
  ELECTRICAL_100 = 'ELECTRICAL_100',
  SMF_1000 = 'SMF_1000',
  MMF_1000 = 'MMF_1000',
  ELECTRICAL_1000 = 'ELECTRICAL_1000',
  SMF_10G = 'SMF_10G',
  MMF_10G = 'MMF_10G',
}

export enum Carrier {
  ATT = 'ATT',
  AVANT = 'AVANT',
  INTELISYS = 'INTELISYS',
  SANDLER = 'SANDLER',
  TBI = 'TBI',
  TCG = 'TCG',
  TELARUS = 'TELARUS',
}

export enum WhosLogin {
  CHARLES_BARKER = 'CHARLES_BARKER',
  LANDON_SOUTHWELL = 'LANDON_SOUTHWELL',
  LATANYA_COLLIER = 'LATANYA_COLLIER',
  SARAH_JANE_HARRIS = 'SARAH_JANE_HARRIS',
}

export enum Term {
  ZERO = '0',
  ONE = '12',
  TWO = '24',
  THREE = '36',
  FOUR = '48',
  FIVE = '60',
}

export enum PortSpeed {
  ONE_HUNDRED_MBPS = 'ONE_HUNDRED_MBPS',
  ONE_GBPS = 'ONE_GBPS',
  TEN_GBPS = 'TEN_GBPS',
  FORTY_GBPS = 'FORTY_GBPS',
  ONE_HUNDRED_GBPS = 'ONE_HUNDRED_GBPS',
}

export enum Cir {
  TWO_MBPS = 'TWO_MBPS',
  FOUR_MBPS = 'FOUR_MBPS',
  FIVE_MBPS = 'FIVE_MBPS',
  EIGHT_MBPS = 'EIGHT_MBPS',
  TEN_MBPS = 'TEN_MBPS',
  TWENTY_MBPS = 'TWENTY_MBPS',
  FIFTY_MBPS = 'FIFTY_MBPS',
  ONE_HUNDRED_MBPS = 'ONE_HUNDRED_MBPS',
  ONE_HUNDRED_FIFTY_MBPS = 'ONE_HUNDRED_FIFTY_MBPS',
  TWO_HUNDRED_FIFTY_MBPS = 'TWO_HUNDRED_FIFTY_MBPS',
  FOUR_HUNDRED_MBPS = 'FOUR_HUNDRED_MBPS',
  FIVE_HUNDRED_MBPS = 'FIVE_HUNDRED_MBPS',
  SIX_HUNDRED_MBPS = 'SIX_HUNDRED_MBPS',
  ONE_GBPS = 'ONE_GBPS',
  TWO_GBPS = 'TWO_GBPS',
  THREE_GBPS = 'THREE_GBPS',
  FOUR_GBPS = 'FOUR_GBPS',
  FIVE_GBPS = 'FIVE_GBPS',
  SIX_GBPS = 'SIX_GBPS',
  SEVEN_GBPS = 'SEVEN_GBPS',
  EIGHT_GBPS = 'EIGHT_GBPS',
  NINE_GBPS = 'NINE_GBPS',
  TEN_GBPS = 'TEN_GBPS',
  TWENTY_GBPS = 'TWENTY_GBPS',
  FORTY_GBPS = 'FORTY_GBPS',
  FIFTY_GBPS = 'FIFTY_GBPS',
  ONE_HUNDRED_GBPS = 'ONE_HUNDRED_GBPS',
}

export enum Jurisdiction {
  INTRASTATE = 'INTRASTATE',
  INTERSTATE = 'INTERSTATE',
}

export enum EvcType {
  MULTIPOINT = 'MULTIPOINT',
  POINT_TO_POINT = 'POINT_TO_POINT',
}

export enum NewTfNumbers {
  YES = 'YES',
  NO = 'NO',
}

export enum RespOrg {
  YES = 'YES',
  NO = 'NO',
}

export enum CopyBillForResporgProvided {
  YES = 'YES',
  NO = 'NO',
}

export enum NumberOfIpAddresses {
  NUMBER_24 = 'NUMBER_24',
  NUMBER_25 = 'NUMBER_25',
  NUMBER_26 = 'NUMBER_26',
  NUMBER_27 = 'NUMBER_27',
  NUMBER_28 = 'NUMBER_28',
  NUMBER_29 = 'NUMBER_29',
  NUMBER_30 = 'NUMBER_30',
  USABLE_14 = 'USABLE_14',
  USABLE_6 = 'USABLE_6',
  OTHER = 'OTHER',
}

export enum CosProfileAseod {
  NON_CRITICAL_HIGH = 'NON_CRITICAL_HIGH',
  BUSINESS_CRITICAL_MEDIUM = 'BUSINESS_CRITICAL_MEDIUM',
  BUSINESS_CRITICAL_HIGH = 'BUSINESS_CRITICAL_HIGH',
  INTERACTIVE = 'INTERACTIVE',
  REAL_TIME = 'REAL_TIME',
}

export enum AsnType {
  CABLE = 'CABLE',
  DSL = 'DSL',
  ETHERNET = 'ETHERNET',
  T1 = 'T1',
}

export enum CerType {
  CABLE = 'CABLE',
  DSL = 'DSL',
  ETHERNET = 'ETHERNET',
  T1 = 'T1',
}

export enum PerType {
  CABLE = 'CABLE',
  DSL = 'DSL',
  ETHERNET = 'ETHERNET',
  T1 = 'T1',
}

export enum OneTypeInstallationOptions {
  AT_T_INSTALL = 'AT_T_INSTALL',
  CUSTOMER_INSTALL = 'CUSTOMER_INSTALL',
}

export enum IPAddressType {
  STATIC = 'STATIC',
  DYNAMIC = 'DYNAMIC',
}

export enum PbxInterfaceType {
  SIP = 'SIP',
  ANALOG = 'ANALOG',
  PRI = 'PRI',
}

export enum StateEnum {
  NEW = 'NEW',
  EXISTING = 'EXISTING',
}

export enum CreditRequest {
  CANCELLED_ORDER = 'CANCELLED_ORDER',
  EARLY_BILLING = 'EARLY_BILLING',
  INCORRECT_RATES = 'INCORRECT_RATES',
  LATE_DISCONNECT = 'LATE_DISCONNECT',
  NREC = 'NREC',
  OTC = 'OTC',
  POST_BILL_ETF = 'POST_BILL_ETF',
  THRIFTY_BILLING = 'THRIFTY_BILLING',
}

export enum CustomerSupport {
  BILL_REVIEW = 'BILL_REVIEW',
  OTHER = 'OTHER',
}

export enum EtfEnum {
  WAIVER = 'WAIVER',
  QUOTE = 'QUOTE',
}

export enum GbqEnum {
  ADDRESS_CHANGE = 'ADDRESS_CHANGE',
  BILL_SPLIT = 'BILL_SPLIT',
  CHARGE_EXPLANATION = 'CHARGE_EXPLANATION',
}

export enum SalesSupport {
  BILL_QUESTION = 'BILL_QUESTION',
  OTHER = 'OTHER',
}

export interface ISowDataCustomerSeller {
  id: number,
  firstName: string,
  lastName: string,
}

export interface ISowDataCustomer {
  seller: ISowDataCustomerSeller
  companyName: string
  projectManagers: string[]
  contractSpecialists: string[]
  engagement: {
    id: number
  }
}

export interface ISowDataBillingInfo {
  requestType: REQUEST_TYPE
  creditBucket: CREDIT_BUCKET
  creditAmount: boolean
}

export interface ISowDataShippingInfo {
  erbTrackingNumber: string
  trackingNumber: string
}

export interface IAttInfo {
  accountNumber: string
}

export interface ISowDataListItem {
  id: number
  productIndex: number
  orderType: ORDER_TYPE
  // eslint-disable-next-line no-restricted-globals
  status: Status
  customer: ISowDataCustomer
  // eslint-disable-next-line no-restricted-globals
  location: ICustomerLocation
  product: string
  billingInfo: ISowDataBillingInfo
  shippingInfo: ISowDataShippingInfo
  attInfo: IAttInfo
  provideQuoteDatetime?: string
  requestQuoteDatetime?: string
  provideContractDatetime?: string
  requestOrderActionDatetime?: string
  provideOrderActionDatetime?: string
  contractEngagementAcceptedDate?: string
  quoteId?: number
  requestedContractId?: number
  orderActionId?: number
  customerLocation: string
  subAccountId: number
  requestContractDatetime?: string
  orderNumber?: string
  projectManagerFullname?: string
  customerCompanyName?: string
  tsd: string
  sellerRequestType: string
  existingService?: string | null
  existingContractId?: string | null
  bandwidth?: string | null
  newServiceMrc: string | null
  newServiceContractId: string | null
  contractSentDate: string | null
  contractCountersigningDate: string | null
  existingAccountNumber: string | null
  term: number | null
  businessCenterProfile?: string | null
  businessCenterProfileUsername: string | null
  businessCenterProfileCompanyId: string | null
  oppId: string | null
  pmEngagementType: string | null
  contractOrderSupport: string | null
  useExistingContract: string | null
}

export type ITableCellSowData<ICellValue = unknown> = (
  ITableCell<ISowDataListItem, ICellValue>
);

export enum OrderActions {
  REQUEST_QUOTES = 'requestQuotes',
  PROVIDE_QUOTES = 'provideQuotes',
  REQUEST_CONTRACTS = 'requestContracts',
  PROVIDE_CONTRACTS = 'provideContracts',
  REQUEST_ORDERS = 'requestOrders',
  PROVIDE_ORDERS = 'provideOrders',
}

export enum ESCALATION_TYPE {
  PROJECT_MANAGER = 'PROJECT_MANAGER',
  CONTRACT_SPECIALIST = 'CONTRACT_SPECIALIST',
  BILLING_SPECIALIST = 'BILLING_SPECIALIST',
  SELLER = 'SELLER',
}

export interface IExportSowDataRequestParams {
  customerId: string
}

export type IExportSowDataParams = CombinedExportTableParams<IExportSowDataRequestParams>;

export interface IRenewalSowDataItem {
    id: number
    renewalEngagementAcceptedDate: string;
    engagement: string
    retentionManager: string,
    customerName: string,
    // eslint-disable-next-line no-restricted-globals
    location: string,
    product: string,
    notes: string,
    exsitingId:number | string,
    attCircuitId: number | string,
    newContractId: number| string,
    countersigned: string,
    manualBookingRequest: string,
    confirmedManuallyBooked: string,
    hasRenewalEscalationRequests: boolean,
    accountNumber: number | string,
    term: number,
    mrc: string,
    nrc: string,
    newAccountNumber: number | string,
    newTerm: number,
    newMrc: string,
    newNrc: string,
}

export enum RenewalStatusSow {
    DECLINED = 'DECLINED',
    RENEWED = 'RENEWED',
    IN_PROGRESS = 'IN_PROGRESS',
}

import React from 'react';
import { useFormikContext } from 'formik';
import RouterFormConfirmDialog from 'shared/RouterFormConfirmDialog/RouterFormConfirmDialog';

interface IRouterFormConfirmDialogFormikProps {
  when?: boolean
}

const RouterFormConfirmDialogFormik: React.FC<
  IRouterFormConfirmDialogFormikProps
> = ({ when }) => {
  const { dirty, isSubmitting } = useFormikContext();

  return (
    <RouterFormConfirmDialog when={!!when && dirty && !isSubmitting} />
  );
};

RouterFormConfirmDialogFormik.defaultProps = {
  when: true,
};

export default RouterFormConfirmDialogFormik;

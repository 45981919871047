import React from 'react';
import { Box, Grid } from '@mui/material';
import { Form } from 'formik';

import FormikTextField from 'shared/FormikField/FormikTextField/FormikTextField';
import FormikSelectField from 'shared/FormikField/FormikSelectField';
import { USStatesOptions } from 'core/models/USStatesConstants';
import { useAppSelector } from 'core/store';
// eslint-disable-next-line no-unused-vars
import { IOption } from 'shared/FormikField/FormikField.model';
import { getOptionsFromUser } from 'core/utilities/getOptionsFromUser';

const CustomersCreateQuickFormFields = () => {
  const sellersList = useAppSelector((state) => state.customers.users.results) || [];
  const sellersOptions: IOption[] = getOptionsFromUser(sellersList);
  return (
    <Form>
      <Box className="customers-create-form__wrapper" sx={{ p: 2 }}>
        <Grid
          container
          item
          rowSpacing={3}
        >
          <FormikTextField
            label="Customer ID"
            fieldName="id"
            disabled
            fullWidth
          />
          <FormikTextField
            label="Company Name"
            fieldName="companyName"
            required
            fullWidth
          />
          <FormikSelectField
            label="Seller"
            fieldName="seller"
            options={sellersOptions}
            required
            fullWidth
          />
          <FormikTextField
            label="Address 1"
            fieldName="billingInfo.address"
            required
            fullWidth
          />
          <FormikTextField
            label="City"
            fieldName="billingInfo.city"
            required
            fullWidth
          />
          <FormikSelectField
            label="State"
            fieldName="billingInfo.state"
            options={USStatesOptions}
            required
            fullWidth
          />
          <FormikTextField
            label="Zip"
            fieldName="billingInfo.zipCode"
            required
            fullWidth
          />
        </Grid>
      </Box>
    </Form>
  );
};

export default CustomersCreateQuickFormFields;

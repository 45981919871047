import React from 'react';
import {
  Sidebar as ProSidebar,
} from 'react-pro-sidebar';

import styles from 'assets/styles/_resources.scss';
import { useSidebarContext } from './SidebarContext';
import SidebarMenu from './SidebarMenu';

import './Sidebar.scss';

const Sidebar = () => {
  const { isCollapsed, isToggled, switchIsSidebarToggled } = useSidebarContext();

  return (
    <ProSidebar
      backgroundColor={styles.sidebarColor}
      toggled={isToggled}
      collapsed={isCollapsed}
      breakPoint="xl"
      className="sidebar"
      width="250px"
      collapsedWidth="50px"
      data-testid="sidebar"
      onBackdropClick={switchIsSidebarToggled}
    >
      <SidebarMenu />
    </ProSidebar>
  );
};

export default Sidebar;

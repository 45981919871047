import dayjs, { ManipulateType } from 'dayjs';
// eslint-disable-next-line no-unused-vars
import { TPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';
import { dateFormat } from 'pages/Dashboard/shared/FiltersByPeriod/constants';
import { PERIOD_SELECT } from 'shared/PeriodAutocomplete/PeriodAutocomplete.models';

const {
  ONE_MONTH, THREE_MONTHS, SIX_MONTHS, ONE_YEAR,
} = PERIOD_SELECT;

const shouldStartOfMonth = [ONE_MONTH, THREE_MONTHS, SIX_MONTHS, ONE_YEAR];

const calculatePeriod = (periodMapping, selectedPeriod): TPeriod => {
  const today = dayjs();

  const { value, unit } = periodMapping[selectedPeriod];
  const subtractDate = today.subtract(value, unit as ManipulateType);

  const startDate = shouldStartOfMonth.includes(selectedPeriod) ? subtractDate.startOf('month') : subtractDate;

  return {
    dateFrom: startDate.format(dateFormat),
    dateTo: today.format(dateFormat),
  };
};

export default calculatePeriod;

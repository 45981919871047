import { createSlice } from '@reduxjs/toolkit';
import { IActiveNumbersItem } from '@EngagementsActiveNumbers/activeNumbers.model';

export interface ISalesActiveNumbersState {
  count: number
  isLoading: boolean
  error: object | null
  results: IActiveNumbersItem[]
}

const initialState: ISalesActiveNumbersState = {
  count: 0,
  error: null,
  results: [],
  isLoading: true,
};

const ActiveNumbersSlice = createSlice({
  name: 'getActiveNumbersData',
  initialState,
  reducers: {
    getSalesActiveNumbersRequest: (state) => ({
      ...state,
      isLoading: true,
    }),
    getSalesActiveNumbersSuccess: (state, action) => ({
      ...state,
      ...action.payload,
      isLoading: false,
    }),
    getSalesActiveNumbersFailure: (state, action) => ({
      ...state,
      results: [],
      isLoading: false,
      error: action.payload,
    }),
    clearSalesActiveNumbersData: () => initialState,
  },
});

export const {
  clearSalesActiveNumbersData,
  getSalesActiveNumbersRequest,
  getSalesActiveNumbersSuccess,
  getSalesActiveNumbersFailure,
} = ActiveNumbersSlice.actions;

export const getActiveNumbersReducer = ActiveNumbersSlice.reducer;

import {
  getUserManagementLisRequest,
  getUserManagementLisSuccess,
  getUserManagementLisFailure,
} from './actionsCreators';
import getUserManagementLis from './api.service';

const getUserManagementLisAction = (controller, params) => async (dispatch) => {
  try {
    const { signal } = controller;
    dispatch(getUserManagementLisRequest());
    const { data } = await getUserManagementLis(signal, params);
    dispatch(getUserManagementLisSuccess(data));
  } catch (error) {
    dispatch(getUserManagementLisFailure(error));

    throw error;
  }
};

export default getUserManagementLisAction;

/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { postSetInsideSalesManager } from 'core/api/insideSales.api';

export const PostSetInsideSalesManagerAction = createAsyncThunk(
  'PostSetInsideSalesManagerAction',
  async (
    { id } : { id: string | number },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await postSetInsideSalesManager(id);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export interface IState {
  isLoading: boolean;
}

const initialState: IState = {
  isLoading: false,
};

const setInsideSalesManagerSlice = createSlice({
  name: 'setInsideSalesManagerSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        PostSetInsideSalesManagerAction.fulfilled,
        (state) => { state.isLoading = false; },
      )
      .addCase(
        PostSetInsideSalesManagerAction.rejected,
        (state) => { state.isLoading = false; },
      )
      .addCase(
        PostSetInsideSalesManagerAction.pending,
        (state) => { state.isLoading = true; },
      );
  },
});

export const setInsideSalesManagerReducer = setInsideSalesManagerSlice.reducer;

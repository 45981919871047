// eslint-disable-next-line no-unused-vars
import { ICustomerAttachment } from 'pages/Customers/Customers.models';
import {
  clearErrorsAttachments,
} from 'pages/Customers/CustomersAttachments/ClearErrorsAttachments/actionCreators';

const clearAttachmentsErrorsAction = (
  attachments: ICustomerAttachment[],
) => async (dispatch) => {
  dispatch(clearErrorsAttachments(attachments));
};

export default clearAttachmentsErrorsAction;

import {
  GET_CUSTOMERS_COMMENTS_REQUEST,
  GET_CUSTOMERS_COMMENTS_SUCCESS,
  GET_CUSTOMERS_COMMENTS_FAILURE,
} from 'pages/Customers/CustomersComments/GetComments/actionTypes';

export const getCommentsRequest = () => ({ type: GET_CUSTOMERS_COMMENTS_REQUEST });

export const getCommentsSuccess = (comments) => (
  { type: GET_CUSTOMERS_COMMENTS_SUCCESS, comments });

export const getCommentsFailure = (error) => ({ type: GET_CUSTOMERS_COMMENTS_FAILURE, error });

import { createAsyncThunk } from '@reduxjs/toolkit';
import get from 'lodash/get';
import {
  createOrderComment, getOrderCommentsList,
  updateOrderCommentItem, deleteOrderCommentItem,
} from 'core/api/sales.api/salesOrderComments.api';
import { IComment } from 'shared/widgets/Comments/Comments.models';

interface IError { detail: string }

interface IThunkError { rejectValue: IError }

export const createSalesOrderCommentAction = createAsyncThunk<
  IComment,
  { parentId: string | number, params: Partial<IComment> },
  IThunkError
>(
  'createSalesOrderCommentAction',
  async (
    { parentId, params },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await createOrderComment(parentId, params);
      return data;
    } catch (error) {
      const errorData = get(error, 'response.data') || get(error, 'error') || {};
      return rejectWithValue(errorData as IError);
    }
  },
);

export const getSalesOrderCommentsListAction = createAsyncThunk<
  IComment[],
  { parentId: string | number, controller: AbortController },
  IThunkError
>(
  'getSalesOrderCommentsListAction',
  async ({ parentId, controller }, { rejectWithValue }) => {
    try {
      const { signal } = controller;

      const { data } = await getOrderCommentsList(signal, parentId);
      return data;
    } catch (error) {
      const errorData = get(error, 'response.data') || get(error, 'error') || {};
      return rejectWithValue(errorData as IError);
    }
  },
);

export const editSalesOrderCommentItemAction = createAsyncThunk<
  IComment,
  { parentId: string | number, id: string | number, params: Partial<IComment> },
  IThunkError
>(
  'editSalesOrderCommentItemAction',
  async (
    { parentId, id, params },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await updateOrderCommentItem(parentId, id, params);
      return data;
    } catch (error) {
      const errorData = get(error, 'response.data') || get(error, 'error') || {};
      return rejectWithValue(errorData as IError);
    }
  },
);

export const deleteSalesOrderCommentItemAction = createAsyncThunk<
  void,
  { parentId: string | number, id: string | number },
  IThunkError
>(
  'deleteSalesOrderCommentItemAction',
  async (
    { parentId, id },
    { rejectWithValue },
  ) => {
    try {
      await deleteOrderCommentItem(parentId, id);
    } catch (error) {
      const errorData = get(error, 'response.data') || get(error, 'error') || {};
      return rejectWithValue(errorData as IError);
    }
  },
);

import {
  GET_INSIDE_SALES_REQUEST,
  GET_INSIDE_SALES_SUCCESS,
  GET_INSIDE_SALES_FAILURE,
} from 'core/actions/crm/InsideSales/GetInsideSalesList/actionsTypes';
import { IInsideSalesListItem } from 'pages/InsideSales/model';

interface IActiveNumbersState {
  isLoading: boolean,
  count: number,
  results: IInsideSalesListItem[],
  error: object | null,
}

const initialState: IActiveNumbersState = {
  isLoading: true,
  count: 0,
  results: [],
  error: {},
};

const getInsideSalesReducer = (state = initialState, {
  type, data, error,
}) => {
  if (type === GET_INSIDE_SALES_REQUEST) {
    return {
      ...state,
      isLoading: true,
      results: [],
    };
  }

  if (type === GET_INSIDE_SALES_SUCCESS) {
    return {
      ...state,
      ...data,
      isLoading: false,
    };
  }

  if (type === GET_INSIDE_SALES_FAILURE) {
    return {
      ...state,
      isLoading: false,
      results: [],
      error,
    };
  }

  return state;
};

export default getInsideSalesReducer;

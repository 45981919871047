import React, { useState } from 'react';
import get from 'lodash/get';
import { Grid, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import FormikSelectField from 'shared/FormikField/FormikSelectField/FormikSelectField';
import EditSaveControls from 'shared/EditSaveControls';
import {
  userTeamRolesTeamAlignmentsOptions,
  userTeamRolesTeamDepartmentsOptionsMobility,
  userTeamRolesTeamDepartmentsOptionsWireline,
} from 'pages/UserManagement/constants';
import { ITeamRole, UserTeamRoleTeamAlignment } from 'pages/UserManagement/UserManagement.models';
import { useUserManagementCreateContext } from 'pages/UserManagement/UserManagementCreate/UserManagementCreateContext';
import {
  getTeamRoleFieldNames,
  getUserTeamRolesNamesOptions,
} from 'pages/UserManagement/UserManagementCreate/UserManagementTeamRoleForm/UserManagementCreateFormTeamRole/utilities';

interface IUserManagementCreateFormUserTypeItemTeamRolesProps {
  index: number;
}

const UserManagementCreateFormTeamRoleItemTeam: React.FC<
  IUserManagementCreateFormUserTypeItemTeamRolesProps
> = ({ index }) => {
  const [initialTeamRole, setInitialTeamRole] = useState<ITeamRole | undefined>();

  const {
    setIndexEditModeTeamRole, isEditModeGeneral, isEditModeTeamRole,
    onDisableTeamRoleEditMode, getIsTeamRoleInEditMode,
  } = useUserManagementCreateContext();

  const {
    submitForm, values, setFieldValue, setValues, resetForm,
  } = useFormikContext<ITeamRole[]>();

  const { teams } = useUserManagementCreateContext();

  const {
    teamRolesItemFieldName,
    teamRolesItemIdFieldName,
    teamRolesItemNameFieldName,
    teamRolesItemTeamAlignmentFieldName,
    teamRolesItemTeamDepartmentFieldName,
    teamRolesItemAssociatesFieldName,
  } = getTeamRoleFieldNames(index);

  const teamRoleId = get(values, teamRolesItemIdFieldName);
  const alignment = get(values, teamRolesItemTeamAlignmentFieldName);
  const department = get(values, teamRolesItemTeamDepartmentFieldName);

  const isEditMode = getIsTeamRoleInEditMode(index);
  const isDisabledEditControls = (!isEditMode && isEditModeTeamRole) || isEditModeGeneral;

  const departmentOptions = (
    alignment === UserTeamRoleTeamAlignment.WIRELINE
      ? userTeamRolesTeamDepartmentsOptionsWireline
      : userTeamRolesTeamDepartmentsOptionsMobility
  );

  const cleanUpKey = () => setFieldValue(teamRolesItemNameFieldName, '');
  const cleanUpDepartment = () => setFieldValue(teamRolesItemTeamDepartmentFieldName, '');
  const cleanUpAssociates = () => setFieldValue(teamRolesItemAssociatesFieldName, []);

  const onChangeTeamAlignment = () => {
    cleanUpDepartment();
    cleanUpKey();
    cleanUpAssociates();
  };

  const onChangeTeamDepartment = () => {
    cleanUpKey();
    cleanUpAssociates();
  };

  const onChangeName = () => {
    cleanUpAssociates();
  };

  const setIsEditMode = () => {
    setInitialTeamRole(values[index]);
    setIndexEditModeTeamRole(index);
  };

  const onCancelEdit = () => {
    onDisableTeamRoleEditMode();

    if (initialTeamRole) {
      setFieldValue(teamRolesItemFieldName, initialTeamRole);
    } else {
      setValues(values.filter((_, i) => i !== index));
    }

    setInitialTeamRole(undefined);
    resetForm();
  };

  const userTeamRolesNamesOptions = getUserTeamRolesNamesOptions(alignment, department, teams);

  const isTeamAlignmentDisabled = !isEditMode || !!teamRoleId;
  const isDepartmentDisabled = !alignment || !isEditMode || !!teamRoleId;
  const isUserRoleDisabled = !alignment || !department || !isEditMode || !!teamRoleId;

  return (
    <Grid
      item
      container
      direction="column"
      rowSpacing={3}
      md={6}
      xl={4}
    >
      <Grid item className="user-management-create-form__section-header">
        <Typography
          className="user-management-create-form__section-title"
          variant="body1"
          component="p"
        >
          User Type
        </Typography>
        <EditSaveControls
          onCancelEdit={onCancelEdit}
          onSave={submitForm}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          isDisabled={isDisabledEditControls}
        />
      </Grid>
      <FormikSelectField
        fieldName={teamRolesItemTeamAlignmentFieldName}
        label="Team Alignment"
        options={userTeamRolesTeamAlignmentsOptions}
        onChange={onChangeTeamAlignment}
        disabled={isTeamAlignmentDisabled}
        required
        fullWidth
      />
      <FormikSelectField
        fieldName={teamRolesItemTeamDepartmentFieldName}
        label="Department"
        options={departmentOptions}
        onChange={onChangeTeamDepartment}
        required
        fullWidth
        disabled={isDepartmentDisabled}
      />
      <FormikSelectField
        fieldName={teamRolesItemNameFieldName}
        label="User Role"
        options={userTeamRolesNamesOptions}
        onChange={onChangeName}
        required
        fullWidth
        disabled={isUserRoleDisabled}
      />
    </Grid>
  );
};

export default UserManagementCreateFormTeamRoleItemTeam;

import React from 'react';
import { useAppSelector } from 'core/store';
import styles from 'assets/styles/_resources.scss';
import SimpleBarChart from 'pages/Dashboard/shared/SimpleBarChart';

const RequestTypeChart = () => {
  const requestTypeData = useAppSelector((state) => state.dashboard.requestType.data);
  const isLoading = useAppSelector((state) => state.dashboard.requestType.isLoading);

  return (
    <SimpleBarChart
      data={requestTypeData}
      color={styles.secondaryColor}
      isLoading={isLoading}
      title="Request Type"
      isNeedSeparateLine
      barSize={20}
    />
  );
};

export default RequestTypeChart;

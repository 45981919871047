import React from 'react';
// eslint-disable-next-line no-unused-vars
import { Formik, Form, FormikProps } from 'formik';
import { IUser, UserType } from 'pages/UserManagement/UserManagement.models';
import { useNavigate, useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import paths from 'core/routing/paths';
import { IServerValidationError } from 'core/services/HTTPService.models';
import { createUser, updateUserById } from 'core/api/user.api';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useUserManagementCreateContext } from 'pages/UserManagement/UserManagementCreate/UserManagementCreateContext';
import { userManagementGeneralFormSchema } from 'pages/UserManagement/UserManagementCreate/UserManagementGeneralForm/userManagementGeneralFormSchema';
import UserManagementCreateFormGeneral from 'pages/UserManagement/UserManagementCreate/UserManagementGeneralForm/UserManagementCreateFormGeneral';
import { createUserNotifications, updateUserNotifications } from 'pages/UserManagement/UserManagementCreate/constants';

import './UserManagementGeneralForm.scss';

const updateOrCreateUser = async (id, params) => {
  if (id) {
    const { data: updatedUser } = await updateUserById(id, params);
    return updatedUser;
  }
  const { data: newUser } = await createUser(params);
  return newUser;
};

interface IUserManagementCreateFormProps {
  initialUserValues: IUser
  setInitialUserValues: (user: IUser) => void
  setIsLoading: (isLoading: boolean) => void
}

const UserManagementGeneralForm: React.FC<IUserManagementCreateFormProps> = ({
  initialUserValues, setInitialUserValues, setIsLoading,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();

  const {
    setIsEditModeGeneral, setIsGeneralFormDirty, setUserType,
  } = useUserManagementCreateContext();

  const innerRef = (ref: FormikProps<IUser>) => {
    setUserType(ref?.values?.type as UserType);
    setIsGeneralFormDirty(!!ref?.dirty && !isEmpty(ref?.touched));
  };

  const showSuccessNotification = () => {
    const notificationMessage = id
      ? updateUserNotifications.success
      : createUserNotifications.success;
    showSuccessNotifier(notificationMessage);
  };

  const showErrorNotification = (e) => {
    const notificationMessage = id
      ? updateUserNotifications.error
      : createUserNotifications.error;
    showErrorNotifier(notificationMessage, e);
  };

  const onSubmit = async (params, { setErrors, resetForm }) => {
    setIsLoading(true);

    try {
      // FIXME: for now BE returns customer field on update or create
      //  but it will return 500 if you try to send this field back, so I remove it
      // eslint-disable-next-line no-unused-vars
      const { customer, ...user } = await updateOrCreateUser(id, params);

      showSuccessNotification();
      setIsEditModeGeneral(false);

      const newInitialUserValues = {
        ...initialUserValues,
        ...user,
      };

      setInitialUserValues(newInitialUserValues);

      resetForm({ values: newInitialUserValues });

      navigate(`${paths.userManagementEdit}/${user.id || id}`);
    } catch (error) {
      const definedError = error as IServerValidationError;
      setErrors(definedError?.response?.data);

      showErrorNotification(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Formik
      innerRef={innerRef}
      validationSchema={userManagementGeneralFormSchema}
      onSubmit={onSubmit}
      initialValues={initialUserValues}
      enableReinitialize
      validateOnBlur
      validateOnChange
    >
      <Form>
        <UserManagementCreateFormGeneral />
      </Form>
    </Formik>
  );
};

export default UserManagementGeneralForm;

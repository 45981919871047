import React from 'react';
import { status } from 'shared/constants';
import MoneyCell from 'shared/Table/shared/MoneyCell';
import EngagementsStatus from 'pages/WirelineEngagements/WirelineEngagementsMainPage/WirelineEngagementsList/EngagementsStatus';

export const columns = [
  // TODO: uncomment this when the react material table v2(!) is installed
  // {
  //   accessorKey: 'id',
  //   header: 'ID',
  //   enableHiding: false,
  //   visibleInShowHideMenu: false,
  // },
  {
    accessorKey: 'companyName',
    muiTableHeadCellFilterTextFieldProps: { placeholder: 'Enter customer name' },
    enableColumnFilter: true,
    size: 200,
    sortDescFirst: false,
  },
  {
    accessorKey: 'engagementType',
    Cell: ({ renderedCellValue }) => (
      <EngagementsStatus engagementType={renderedCellValue} />
    ),
    filterVariant: 'multi-select',
    filterFn: 'equals',
    filterSelectOptions: [
      { value: 1, text: status.active },
      { value: 0, text: status.inactive },
    ],
    muiTableHeadCellFilterTextFieldProps: { placeholder: 'Status' },
    sortDescFirst: false,
    enableColumnFilter: true,
  },
  {
    accessorKey: 'mrc',
    Cell: MoneyCell,
  },
];

export const createEngagementButtonText = 'create engagement';

export const createEngagementMobileButtonText = 'create';

export const endpointToGetEngagementListSchemaName = '/api/crm/engagements/';

export const defaultVisibility = { id: false };

import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Typography } from '@mui/material';
import { useAppDispatch } from 'core/store';
import { HTTPService } from 'core/services';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import TechnicalOverlayChart from '@dashboardWirelineSellerDepartment/TechnicalOverlay/TechnicalOverlayChart';
import TechnicalOverlayFilters from '@dashboardWirelineSellerDepartment/TechnicalOverlay/TechnicalOverlayFilters';
import { getTechnicalOverlayAction } from '@dashboardWirelineSellerDepartment/TechnicalOverlay/GetTechnicalOverlay';
import {
  DateFilter,
  ITechnicalOverlayFilters,
} from '@dashboardWirelineSellerDepartment/TechnicalOverlay/TechnicalOverlay.models';
import {
  defaultFilters, modifiers,
  notifierMessage, technicalOverlayModifier,
  technicalOverlayTitle,
} from '@dashboardWirelineSellerDepartment/TechnicalOverlay/constants';
import './TechnicalOverlay.scss';

let technicalOverlayController = HTTPService.getController();

const getFormattedDate = (date: DateFilter) => {
  if (date === DateFilter.LAST_MONTH) {
    const currentMonth = dayjs().format('YYYY-MM');
    const lastMonth = dayjs(currentMonth).subtract(1).format('YYYY-MM');

    return {
      month: dayjs(lastMonth).month() + 1,
      year: dayjs(lastMonth).year(),
    };
  }

  return {
    month: dayjs().month() + 1,
    year: dayjs().year(),
  };
};

const TechnicalOverlay = () => {
  const dispatch = useAppDispatch();
  const { showErrorNotifier } = useNotifiers();

  const [filters, setFilters] = useState<ITechnicalOverlayFilters>(defaultFilters);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const cancelTechnicalOverlayRequest = () => {
    HTTPService.cancelRequest(technicalOverlayController);
  };

  const getTechnicalOverlay = () => {
    cancelTechnicalOverlayRequest();

    technicalOverlayController = HTTPService.getController();

    const { month, year } = getFormattedDate(filters.date);

    const params = {
      state: filters.state,
      month,
      year,
    };

    dispatch(getTechnicalOverlayAction({
      controller: technicalOverlayController, params,
    }))
      .unwrap()
      .catch((reason) => {
        showErrorNotifier(notifierMessage.technicalOverlay.error, reason);
      });
  };

  useEffect(() => {
    getTechnicalOverlay();
  }, [filters]);

  return (
    <div className={technicalOverlayModifier}>
      <Typography variant="h3" className={modifiers.title}>
        {technicalOverlayTitle}
      </Typography>
      <TechnicalOverlayFilters
        filters={filters}
        setFilters={setFilters}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
      />
      <TechnicalOverlayChart
        selectedUsers={selectedUsers}
      />
    </div>
  );
};

export default TechnicalOverlay;

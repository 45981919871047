import React, { memo } from 'react';
import CustomSidebar from 'shared/CustomSidebar';
import OrderDataContent from 'pages/WirelineEngagements/shared/OrderDataSidebar/OrderDataContent';
import { IParsedOrderData } from 'pages/WirelineEngagements/WirelineEngagementsTables/wirelineEngagementsTables.model';

interface IOrderDataSidebarProps {
  isOpen: boolean
  isLoading: boolean
  schemaName: string
  onClose: () => void
  orderData: IParsedOrderData
}

const OrderDataSidebar: React.FC<IOrderDataSidebarProps> = ({
  isOpen,
  onClose,
  isLoading,
  orderData,
  schemaName,
}) => (
  <CustomSidebar
    isOpen={isOpen}
    title="Order Data"
    isLoading={isLoading}
    isConfirmDialog={false}
    onCloseSideBar={onClose}
    areButtonsVisible={false}
  >
    <OrderDataContent
      orderData={orderData}
      schemaName={schemaName}
    />
  </CustomSidebar>
);

export default memo(OrderDataSidebar);

import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import Show from 'shared/Show';
import { Menu, Button, Tooltip } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useTableContext } from 'shared/Table/TableContext';
import EngagementAccepted from '@EngagementsSowData/EngagementAccepted';
// eslint-disable-next-line no-unused-vars
import { ISowDataListItem, OrderActions } from '@EngagementsSowData/sowData.model';
import SowDataActionsRequest from '@EngagementsSowData/SowDataActionsButton/SowDataActionsRequest';
import SowDataActionsProvide from '@EngagementsSowData/SowDataActionsButton/SowDataActionsProvide';
import { checkIfOrdersIsAttTSD, checkIfOrdersRequestTypeIsNonCotractSupport } from '@EngagementsSowData/utilities';
import { useSowDataActionsButtonPermissions } from '@EngagementsSowData/SowDataActionsButton/useSowDataActionsButtonPermissions';

import './SowDataActionsButton.scss';

interface ISowDataActionsButtonProps {
  selectedOrders: ISowDataListItem[],
  setIsActionsModalOpen: (isModalOpen: boolean) => void
  setOrderAction: (orderAction: OrderActions) => void
}

const SowDataActionsButton: React.FC<ISowDataActionsButtonProps> = ({
  selectedOrders, setIsActionsModalOpen, setOrderAction,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);
  const { rowSelection } = useTableContext();

  const {
    isProvideActionsHidden,
    isRequestActionsHidden,
    isEngagementAcceptedVisible,
    isUnavailableActionsBuntton,
  } = useSowDataActionsButtonPermissions();

  const hasRowNonAttTSD = checkIfOrdersIsAttTSD(selectedOrders);
  const hasRowRequestTypeIsNonContractOrder = checkIfOrdersRequestTypeIsNonCotractSupport(
    selectedOrders,
  );

  const isActionsBtnDisabled = isUnavailableActionsBuntton
  || hasRowNonAttTSD
  || hasRowRequestTypeIsNonContractOrder
  || isEmpty(rowSelection);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title="Actions" placement="top">
        <span>
          <Button
            className="actions-btn"
            id="sow-data-actions-btn"
            aria-controls={isOpen ? 'sow-data-actions-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isOpen ? 'true' : undefined}
            onClick={handleOpenMenu}
            disabled={isActionsBtnDisabled}
          >
            <MoreHorizIcon />
          </Button>
        </span>
      </Tooltip>
      <Menu
        className="actions-menu"
        id="sow-data-actions-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'sow-data-actions-btn',
        }}
      >
        <Show on={!isRequestActionsHidden}>
          <SowDataActionsRequest
            selectedOrders={selectedOrders}
            handleClose={handleClose}
            setOrderAction={setOrderAction}
            setIsActionsModalOpen={setIsActionsModalOpen}
          />
        </Show>
        <Show on={!isProvideActionsHidden}>
          <SowDataActionsProvide
            selectedOrders={selectedOrders}
            handleClose={handleClose}
            setOrderAction={setOrderAction}
            setIsActionsModalOpen={setIsActionsModalOpen}
          />
        </Show>
      </Menu>
      {isEngagementAcceptedVisible && <EngagementAccepted />}
    </>
  );
};

export default SowDataActionsButton;

import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import cx from 'classnames';

interface IActionsMenuItemProps {
  onAction: () => void
  isDisable?: boolean
  isRequest?: boolean
  content: string
}

const ActionsMenuItem: React.FC<IActionsMenuItemProps> = ({
  onAction, isDisable, isRequest, content,
}) => {
  const onClick = () => {
    if (isDisable) {
      return;
    }

    onAction();
  };

  return (
    <MenuItem
      onClick={onClick}
      className={cx({
        'disabled-action': isDisable,
      })}
    >
      <CheckCircleOutlineIcon className={cx({
        'actions-menu__icon': true,
        'request-icon': isRequest,
        'provided-icon': !isRequest,
      })}
      />
      {' '}
      {content}
    </MenuItem>
  );
};

ActionsMenuItem.defaultProps = {
  isDisable: false,
  isRequest: false,
};

export default ActionsMenuItem;

import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import Loader from 'shared/Loader';
import { getUserById } from 'core/api/user.api';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { getUsersData } from 'pages/Customers/CustomersList/ReassignSellerModal/utilities';
import { loadUserNotifications } from 'pages/UserManagement/UserManagementCreate/constants';
import { ITeamMembersData, ITeamMembersRenderListItem } from 'pages/Customers/CustomersList/ReassignSellerModal/ReassignSellerModal.models';

interface ITeamMembersListProps {
  title: string
  description?: string
  sellerId: number | null
  renderList: ITeamMembersRenderListItem[]
}

const TeamMembersList:React.FC<ITeamMembersListProps> = ({
  title,
  sellerId,
  renderList,
  description,
}) => {
  const { showErrorNotifier } = useNotifiers();

  const [isLoading, setIsLoading] = useState(false);
  const [usersData, setUsersData] = useState<ITeamMembersData>({});

  const getTeamUsers = async (id: number | string) => {
    setIsLoading(true);

    try {
      const { data } = await getUserById(id);
      const renderUserData = getUsersData(data);
      setUsersData(renderUserData);
    } catch (e) {
      showErrorNotifier(loadUserNotifications.error, e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (sellerId) {
      getTeamUsers(sellerId);
    }
  }, [sellerId]);

  return (
    <Box className="reassign-seller-modal__members">
      <p className="reassign-seller-modal__members__title">{title}</p>
      {description && (
      <p className="reassign-seller-modal__members__subtitle">{description}</p>
      )}
      {renderList.map(({ label, key }) => (
        <Grid key={key} container xs={12} className="reassign-seller-modal__members__item">
          <Grid item xs={6}>
            {label}
          </Grid>
          <Grid item xs={6} className="reassign-seller-modal__members__item__value">
            {
              isLoading
                ? <Loader size={24} />
                : usersData[key] || '-'
            }
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};

TeamMembersList.defaultProps = {
  description: '',
};

export default TeamMembersList;

import React from 'react';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
// eslint-disable-next-line no-unused-vars
import { DateValidationError, PickerChangeHandlerContext, PickersActionBarProps } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import { Box, Button } from '@mui/material';
import { dateFormat } from 'pages/Dashboard/shared/FiltersByPeriod/constants';

interface IDatePicker {
    value: Dayjs;
    onChange: (
        newValue: Dayjs | null, context: PickerChangeHandlerContext<DateValidationError>
    ) => void;
    onClose: () => void;
    format?: string;
    minDate: Dayjs;
    maxDate: Dayjs;
    closeOnSelect?: boolean;
    // eslint-disable-next-line no-restricted-globals
    open : boolean;
    onOpen: ()=> void;
    onAccept: ()=> void;
    onCancel?: ()=> void;
}

const DatePicker: React.FC<IDatePicker > = ({
  value, onCancel, onAccept, onChange, onClose,
  format, onOpen, minDate, maxDate, open, ...otherProps
}) => {
  const CustomActionBar = (props: PickersActionBarProps) => {
    const { className } = props;
    return (
      <Box className={className} sx={{ justifySelf: 'center', pb: 2 }}>
        <Button
          sx={{ width: 100 }}
          onClick={onCancel}
          data-testid="cancel-btn"
        >
          Cancel
        </Button>
        <Button
          onClick={onAccept}
          variant="outlined"
        >
          OK
        </Button>
      </Box>
    );
  };

  return (
    <Box className="date-picker">
      <MUIDatePicker
        value={value}
        onChange={onChange}
        onClose={onClose}
        format={format as string}
        minDate={minDate}
        maxDate={maxDate}
        closeOnSelect={false}
        open={open}
        slots={{
          actionBar: CustomActionBar,
        }}
        slotProps={{
          textField: {
            onClick: onOpen,
          },
          inputAdornment: {
            position: 'start',
            sx: {
              marginRight: '0 !important',
            },

          },
          actionBar: {
            actions: ['cancel', 'accept'],
          },

        }}
        {...otherProps}
      />
    </Box>
  );
};

DatePicker.defaultProps = {
  closeOnSelect: false,
  format: dateFormat,
  onCancel: () => {},
};

export default DatePicker;
